import {BadRequestHandler, GlobalMessageType} from '@spartacus/core';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpRequest} from "@angular/common/http";

const OAUTH_ENDPOINT = '/authorizationserver/oauth/token';
const AZURE_NO_ROLES = 'ssabAzureAuthenticationProvider.noRoles';
const CDC_NO_ROLES = 'ssabCdcAuthenticationProvider.noRoles';
const CDC_NO_AUTH_DATA = 'ssabCdcAuthenticationProvider.noAuthData';

@Injectable({
  providedIn: 'root'
})
export class SsabBadRequestHandler extends BadRequestHandler {

  handleError(request: HttpRequest<any>, response: HttpErrorResponse): void {
    if (!this.handleNoAzureRoles(request, response)) {
      super.handleError(request, response);
    }
  }

  protected handleNoAzureRoles(request: HttpRequest<any>, response: HttpErrorResponse): boolean {
    if (this.isAuthenticationExceptionOfType(response, request, AZURE_NO_ROLES)) {
      // No roles assigned for the user in Azure -> ERROR
      return this.addErrorMessage('httpHandlers.badRequestNoAzureRoles');
    } else if (this.isAuthenticationExceptionOfType(response, request, CDC_NO_ROLES)) {
      // No roles assigned for the user in CDC -> ERROR
      return this.addErrorMessage('httpHandlers.badRequestNoCdcRoles');
    } else if (this.isAuthenticationExceptionOfType(response, request, CDC_NO_AUTH_DATA)) {
      // No authorization data found for the user in CDC -> ERROR
      return this.addErrorMessage('httpHandlers.badRequestNoCdcAuthData');
    }
    return false;
  }

  private addErrorMessage(msgKey: string) {
    this.globalMessageService.add(
      {
        key: msgKey
      },
      GlobalMessageType.MSG_TYPE_ERROR
    );
    this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_CONFIRMATION);
    return true;
  }

  private isAuthenticationExceptionOfType(response: HttpErrorResponse, request: HttpRequest<any>, type: string) {
    return this.isAuthenticationException(response, request) && response.error?.error_description === type;
  }

  private isAuthenticationException(response: HttpErrorResponse, request: HttpRequest<any>) {
    return response.url?.includes(OAUTH_ENDPOINT) && response.error?.error === 'invalid_grant' && request.body?.get('grant_type') === 'password';
  }
}
