import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SsabSubscriptionsAdapter } from './ssab-subscriptions.adapter';

@Injectable({
  providedIn: 'root'
})
export class SsabSubscriptionsConnector {
  constructor(protected adapter: SsabSubscriptionsAdapter) {}

  subscribeProductAvailability(userId: string, productCode: string): Observable<{}> {
    return this.adapter.subscribeProductAvailability(userId, productCode);
  }
}
