import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Subscription} from "rxjs";

@Component({
  selector: 'ssab-cx-page-size',
  templateUrl: './ssab-page-size.component.html'
})
export class SsabPageSizeComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  constructor(private activatedRoute: ActivatedRoute, protected router: Router) {
  }

  pageSizes = [25, 50, 100];
  @Input() selectedPageSize = 25;
  @Input() skipLocationChange = false;
  @Output() pageSizeEvent: EventEmitter<number> = new EventEmitter<number>();

  changeListingItems(pageSize: number): void {
    const queryParams: Params = {pageSize};
    if (this.skipLocationChange) {
      this.pageSizeEvent.emit(pageSize);
    } else {
      this.router.navigate(
        [],
        {
          relativeTo: this.activatedRoute,
          queryParams,
          queryParamsHandling: 'merge'
        });
    }
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params) => {
        if (params.pageSize) {
          this.selectedPageSize = params.pageSize;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
