import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SsabContactFormAdapter } from './ssab-contactform.adapter';

@Injectable({
  providedIn: 'root'
})
export class SsabContactFormConnector {
  constructor(protected adapter: SsabContactFormAdapter) {}

  sendStockAvailabilityRequest(userId: string, cartCode: string, message?: string): Observable<{}> {
    return this.adapter.sendStockAvailabilityRequest(userId, cartCode, message);
  }
}
