import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, shareReplay, switchMap, take} from 'rxjs/operators';
import {AuthConfigService, AuthHttpHeaderService, AuthInterceptor, AuthService, getLastValueSync, GlobalMessageService, GlobalMessageType, HttpErrorHandler, resolveApplicable, UnifiedInjector} from '@spartacus/core';

//The code here is based on HttpErrorInterceptor in the core Spartacus libraries, however we can't extend it as it's not exposed to us
@Injectable({providedIn: 'root'})
export class SsabHttpErrorInterceptor extends AuthInterceptor {
  protected handlers$: Observable<HttpErrorHandler[]>;

  constructor(
    protected authHttpHeaderService: AuthHttpHeaderService,
    protected authConfigService: AuthConfigService,
    protected unifiedInjector: UnifiedInjector,
    protected globalMessageService: GlobalMessageService,
    protected auth: AuthService) {
    super(authHttpHeaderService, authConfigService);
    this.handlers$ = this.unifiedInjector
      .getMulti(HttpErrorHandler)
      .pipe(shareReplay(1))
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const shouldAddAuthorizationHeader = this.authHttpHeaderService.shouldAddAuthorizationHeader(request);

    const token$ = shouldAddAuthorizationHeader
      ? // emits sync, unless there is refresh or logout in progress, in which case it emits async
      this.authHttpHeaderService.getStableToken()
        .pipe(take(1))
      : of(undefined);
    const requestAndToken$ = token$.pipe(
      map((token) => ({
        token,
        request: this.authHttpHeaderService.alterRequest(request, token),
      })),
    );

    return requestAndToken$.pipe(
      switchMap(({request, token}) =>
        next.handle(request).pipe(
          catchError((response: any) => {
            if (response.status !== 500 && !response.url.endsWith('authorizationserver/oauth/token')) {
              this.handleErrorResponse(request, response);
              if (response.status == 401) {
                this.auth.coreLogout();
              }
            } else {
              console.error('Auth error occurred in HTTP request', response);
            }

            if ((response.message.includes('/cms/components') || response.message.includes('/cms/pages')) && response.status == 400) {
              //The default behavior would display message banners to the user, the below line gets rid of them
              this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
              //If we throw response the Spartacus interceptor will be triggered and unwanted banners will appear
              return throwError(new HttpErrorResponse({
                error: response.message,
                status: response.status,
                statusText: response.statusText
              }));
            } else {
              return throwError(response);
            }
          })
        )
      ));
  }

  protected handleErrorResponse(
    request: HttpRequest<any>,
    response: HttpErrorResponse
  ): void {
    const handler = this.getResponseHandler(response);
    if (handler) {
      handler.handleError(request, response);
    }
  }

  protected getResponseHandler(
    response: HttpErrorResponse
  ): HttpErrorHandler | undefined {
    return resolveApplicable(getLastValueSync(this.handlers$) ?? [], [
      response,
    ]);
  }

}
