import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {LAUNCH_CALLER, LaunchDialogService} from "@spartacus/storefront";
import {openCloseSpinner} from "../../shared/utils/functions/ssab-functions-utils";
import {RoutingService} from "@spartacus/core";
import {SsabActiveCartService} from "../../../service/cart/ssab-active-cart.service";
import {SsabClearCartModalData} from "./ssab-clear-cart-modal-layout.config";
import {Subscription} from "rxjs";
import {SsabSubscriptionService} from "../../../service/subscription/ssab-subscription.service";
import {DOCUMENT} from "@angular/common";


@Component({
  selector: 'ssab-cx-clear-cart',
  templateUrl: './ssab-clear-cart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabClearCartComponent {
  subscriptions: Subscription = new Subscription();
  cartCode: string;
  userId: string;

  constructor(
    protected subscribeService: SsabSubscriptionService,
    protected launchDialogService: LaunchDialogService,
    private routingService: RoutingService,
    protected activeCartService: SsabActiveCartService,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }
  ngOnInit(): void {
    this.subscriptions.add(
      this.launchDialogService.data$.subscribe((dialogData: SsabClearCartModalData) => {
        if (dialogData) {
          this.cartCode = dialogData.cartCode;
          this.userId = dialogData.userId;
        }
      })
    );
  }

  deleteCart(): void {
    this.close();
    this.activeCartService.deleteCart(this.cartCode, this.userId);
  }

  onSuccess(): void {
    openCloseSpinner(this.document, false);
    this.activeCartService.reLoadCart(false);
    this.routingService.go({cxRoute: 'cart'});
  }

  closeDialog(): void {
    this.subscriptions.unsubscribe();
    this.launchDialogService.clear(LAUNCH_CALLER.REMOVE_CART);
    this.launchDialogService.closeDialog('closed');
  }

  close(): void {
    this.subscriptions.unsubscribe();
    this.launchDialogService.closeDialog('closed');
  }
}
