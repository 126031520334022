import {Injectable} from '@angular/core';
import {CmsService, CurrencyService, Page} from '@spartacus/core';
import {filter} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class PageHistoryService{

  private currentPageName: Page;
  private previousPageName: string;
  private activeCurrencyIso: string;

  constructor(protected cmsService: CmsService,
              protected currencyService: CurrencyService) {
  }
  public getCurrentPage(): Page {
    this.cmsService.getCurrentPage()
      .pipe(filter((page) => page != null))
      .subscribe((page) => {
      this.currentPageName = page;
    })
    return this.currentPageName;
  }

  public getActiveCurrencyIso(): string {
    this.currencyService.getActive().subscribe((activeCurrency) => {
      this.activeCurrencyIso = activeCurrency;
    });
    return this.activeCurrencyIso;
  }

  public setPreviousPageName(pageName: string): void{
    this.previousPageName = pageName;
  }

  public getPreviousPageName(pageName: string): string{
    return this.previousPageName;
  }
}
