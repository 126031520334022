import {OrderTrackingTables, SsabOrder} from '../../../../../model/order.model';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {DateUtils} from '../../../../../shared/util/date-utils';
import {LanguageService} from '@spartacus/core';
import {Observable} from 'rxjs';
import {SsabRelatedDocumentList} from '../../../../../model/document.model';
import {SsabUserService} from '../../../../../service/user/ssab-user.service';
import {SsabGlobalconfigurationService} from "../../../../../service/general/configuration/ssab-globalconfiguration.service";

@Component({
  selector: 'ssab-cx-order-data-tables',
  templateUrl: './ssab-order-data-tables.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabOrderDataTablesComponent implements OnInit {

  @Input() order: SsabOrder;
  $documentList: Observable<SsabRelatedDocumentList>;
  @Input() selectedTable: string = OrderTrackingTables.Order;
  @Input() hasTrackAndTrace = false;
  orderTables = OrderTrackingTables;

  constructor(
    protected language: LanguageService,
    protected cdr: ChangeDetectorRef,
    protected userService: SsabUserService,
    private globalConfigurationService: SsabGlobalconfigurationService,
  ) {
  }

  ngOnInit(): void {
    this.$documentList = this.userService.getRelatedDocuments(this.order.code, this.order.unit?.uid, true);
  }

  convertUIDate(d: string): Date {
    return DateUtils.convertDate(d);
  }

  changeTab(tab: string): void {
    this.selectedTable = tab;
    this.cdr.detectChanges();
  }

  hasDispatch(): boolean {
    return this.order.entries.filter(e => e.deliveries.length > 0).length > 0;
  }

  getDateFormat(): Observable<string> {
    return this.globalConfigurationService.getDateFormat();
  }
}

