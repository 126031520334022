import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ICON_TYPE, SearchBoxComponent} from '@spartacus/storefront';

@Component({
  selector: 'ssab-cx-searchbox',
  templateUrl: './ssab-search-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabSearchBoxComponent  extends SearchBoxComponent {
  iconTypes = ICON_TYPE;
  searchMinCharacters(query: string): void {
    if (query.length >= this.config.minCharactersBeforeRequest){
      this.search(query);
    }
  }

  clearAndClose(event: UIEvent, el: HTMLInputElement){
    this.close(event, true);
    this.clear(el);
  }

  // overridden method to not check query length before firing search
  launchSearchResult(event: UIEvent, query: string): void {
    this.close(event);
    this.searchBoxComponentService.launchSearchPage(query);
  }

  onlyClear(el: HTMLInputElement): void {
    el.value = '';
    this.searchBoxComponentService.clearResults();
  }
}
