import {Component} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {SsabCMSHeroComponent} from '../../../model/cms.model';
import {Observable} from "rxjs";

@Component({
  selector: 'ssab-cx-hero',
  templateUrl: './ssab-hero.component.html'
})
export class SsabHeroComponent {

  data$: Observable<SsabCMSHeroComponent>;

  constructor(protected component: CmsComponentData<SsabCMSHeroComponent>) {
    this.data$ = this.component.data$;
  }
}
