<div class="cx-order-items" *ngIf="order$ | async as order">
  <div class="items-details header-block">
    <ssab-cx-cart-item-list
      *ngIf="!order.consignment"
      [items]="order.entries"
      [readonly]="true"
      [cart]="order"
      [showCartTotals]="true"
      [orderConfirmation]="true"
      [showPricesOrConsignmentPricesStock]="showPricesOrConsignmentPricesStock$ | async"
    ></ssab-cx-cart-item-list>
    <ssab-cx-cart-item-list-consignment
      *ngIf="order.consignment"
      [items]="order.entries"
      [readonly]="true"
      [cart]="order"
      [orderConfirmation]="true"
      [showCartTotals]="true"
      [showPricesOrConsignmentPricesStock]="showPricesOrConsignmentPricesStock$ | async"
    ></ssab-cx-cart-item-list-consignment>
  </div>
</div>
