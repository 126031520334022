import {AfterViewInit, Component, OnDestroy, OnInit} from "@angular/core";
import {SsabCdcConfig} from "../../../service/cdc/ssab-cdc-config";
import {SsabCdcService} from "../../../service/cdc/ssab-cdc.service";
import {SsabScriptService} from "../../../service/script/ssab-script.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'ssab-cdc-my-profile-update',
  templateUrl: './ssab-cdc-my-profile-update.component.html'
})
export class SsabCdcMyProfileUpdateComponent implements OnInit, AfterViewInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  constructor(protected cdcConfig: SsabCdcConfig,
              protected cdcService: SsabCdcService,
              protected scriptService: SsabScriptService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.renderCdcProfileUpdateScreen();
  }

  public startCDCProfileUpdate(): string {
    return this.cdcConfig.cdc.screenSets.profileUpdate.containerId;
  }

  public renderCdcProfileUpdateScreen(): void {
    this.subscriptions.add(
      this.scriptService.isCdcScriptLoaded().subscribe(loaded => {
        if (loaded) {
          this.cdcService.openMyProfileUpdate();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
