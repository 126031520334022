import {InjectionToken, Provider} from '@angular/core';
import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {SiteContextActions, StateUtils} from '@spartacus/core';
import {LoaderAction} from '@spartacus/core/src/state/utils/loader/loader.action';
import {LOAD_GLOBAL_CONFIGURATION, LOAD_GLOBAL_CONFIGURATION_SUCCESS, SsabGlobalConfigurationsState, SsabGlobalConfigurationState, SsabLoadGlobalConfigurationSuccess} from "./ssab-globalconfiguration-actions";


export const initialState: SsabGlobalConfigurationState = {
  configuration: null,
  loaded: false,
};
// eslint-disable-next-line
export const metaReducers: MetaReducer<any>[] = [clearState];

export const reducerToken: InjectionToken<ActionReducerMap<SsabGlobalConfigurationsState>> = new InjectionToken<ActionReducerMap<SsabGlobalConfigurationsState>>('GlobalConfigurationReducers');

export const ssabGlobalConfigurationReducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getSsabGlobalConfigurationReducers,
};

// eslint-disable-next-line
export function clearState(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (
      action.type === SiteContextActions.SET_ACTIVE_BASE_SITE
    ) {
      state = initialState;
    }
    return reducer(state, action);
  };
}

export function getSsabGlobalConfigurationReducers(): ActionReducerMap<SsabGlobalConfigurationsState> {
  return {
    active: StateUtils.loaderReducer<SsabGlobalConfigurationState>(LOAD_GLOBAL_CONFIGURATION_SUCCESS, SsabGlobalConfigurationReducer),
  };
}

export function SsabGlobalConfigurationReducer(state = initialState, action: LoaderAction): SsabGlobalConfigurationState {
  switch (action.type) {
    case LOAD_GLOBAL_CONFIGURATION: {
      return {
        ...initialState,
        loaded: true,
      };
    }
    case LOAD_GLOBAL_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        configuration: (action as SsabLoadGlobalConfigurationSuccess).configuration,
      };
    }

  }
  return state;
}
