import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {LAUNCH_CALLER, LaunchDialogService} from '@spartacus/storefront';
import {Observable} from 'rxjs';
import {SsabCoil, SsabCoilComparisonResponse} from "../../model/product.model";
import {SsabCoilsComparisonService} from "../../service/product/ssab-coils-comparison.service";
import {RoutingService} from "@spartacus/core";
import {Location} from '@angular/common';

@Component({
  selector: 'ssab-cx-coils-comparison-preview',
  templateUrl: './ssab-coils-comparison-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabCoilsComparisonPreviewComponent implements OnInit {
  coils$: Observable<SsabCoilComparisonResponse>;
  queryParams: string;

  constructor(
    protected coilsComparisonService: SsabCoilsComparisonService,
    protected launchDialogService: LaunchDialogService,
    protected routingService: RoutingService,
    private location: Location
  ) {
    this.routingService.getRouterState().subscribe(
      router => {
        if (router.state.url.indexOf('/coil-comparison/popup') > 0) {
          if (router.state.queryParams.q) {
            this.queryParams = router.state.queryParams.q;
          }
          this.location.replaceState("/coil-comparison/popup?q=" + this.queryParams);
          this.openCompare();
        } else if (router.state.url.indexOf('/coil-comparison') > 0) {
          this.queryParams = router.state.queryParams.q;
          this.launchDialogService.closeDialog(null);
        }
      }
    );
  }

  ngOnInit(): void {
    this.coils$ = this.coilsComparisonService.getCompareCoils(0, 9999);
  }

  remove(coil: SsabCoil): void {
    this.coilsComparisonService.removeCoilFromComparison(coil.coilId);
  }

  clear(): void {
    this.launchDialogService.closeDialog(null);
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.COILS_CLEAR, undefined);
  }

  compare(): void {
    this.routingService.go('/coil-comparison/popup');
  }

  openCompare(): void {
    this.launchDialogService.closeDialog(null);
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.COILS_COMPARISON, undefined, this.queryParams);
  }
}
