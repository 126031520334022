import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from, Observable} from 'rxjs';
import {catchError, concatMap, filter, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {GlobalMessageService, LoggerService, normalizeHttpError, OCC_USER_ID_ANONYMOUS, SiteContextActions, UserIdService} from '@spartacus/core';
import {CartActions} from '@spartacus/cart/base/core';
import {MultiCartFacade} from '@spartacus/cart/base/root';
import {CREATE_CONTACTSALES_PRODUCT_LIST, CreateContactSalesProductList, CreateContactSalesProductListFail, CreateContactSalesProductListSuccess, LOAD_CONTACTSALES_PRODUCT_LIST, LoadContactSalesProductList, LoadContactSalesProductListFail, LoadContactSalesProductListSuccess, REMOVE_CONTACTSALES_PRODUCT_LIST, RemoveContactSalesProductList} from './ssab-contactsales.action';
import {SsabCart} from '../../model/cart.model';
import {SsabContactSalesConnector} from './ssab-contactsales.connector';


@Injectable()
export class ContactSalesProductListEffects {
  createContactSalesProductList$: Observable<CreateContactSalesProductListFail | CreateContactSalesProductListSuccess | CartActions.LoadCartSuccess>;
  loadContactSalesProductList$: Observable<CreateContactSalesProductList | LoadContactSalesProductListFail | CartActions.LoadCartSuccess>;
  reemitLoadCartSuccessAsLoadContactSalesProductListSuccess$: Observable<LoadContactSalesProductListSuccess | LoadContactSalesProductListFail>;
  removeContactSalesProductListAndCreateNew$: Observable< CartActions.RemoveCart>;
  reloadContactSalesProductListOnSiteContextChange$: Observable<LoadContactSalesProductList | CreateContactSalesProductListFail>;

  constructor(
    private actions$: Actions,
    private connector: SsabContactSalesConnector,
    protected globalMessageService: GlobalMessageService,
    protected multiCartFacade: MultiCartFacade,
    protected userIdService: UserIdService,
    protected logger: LoggerService
  ) {

    this.createContactSalesProductList$ = createEffect(() =>
      this.actions$.pipe(ofType(CREATE_CONTACTSALES_PRODUCT_LIST), map((action: CreateContactSalesProductList) => action.payload), switchMap((payload) => {
        return this.connector
          .create(payload.userId)
          .pipe(switchMap((cart) => {
              var events = [];
              events.push(new CreateContactSalesProductListSuccess({
                cart: cart,
                userId: payload.userId,
              }));
              if (payload.rethrowLoadCartSuccess) {
                events.push(new CartActions.LoadCartSuccess({
                  cart: cart,
                  userId: payload.userId,
                  cartId: cart.code
                }));
              }
              return events;
            }
          ), catchError((error) => from([
            new CreateContactSalesProductListFail({
              error: normalizeHttpError(error,this.logger)
            }),
          ])));
      })));


    this.loadContactSalesProductList$ = createEffect(() =>
      this.actions$.pipe(ofType(LOAD_CONTACTSALES_PRODUCT_LIST), map((action: LoadContactSalesProductList) => action.payload), concatMap((payload) => {
        const {userId} = payload;
        return this.connector.loadAll(userId).pipe(switchMap((carts) => {
          if (carts) {
            const savedCart = carts.find((cart: SsabCart) => cart.cartType && cart.cartType === 'ContactSalesProductList');
            if (Boolean(savedCart)) {
              return [
                // fire LoadCartSuccess so the cart is saved in multiCart store - it will be later handled by reemitLoadCartSuccessAsLoadContactSalesProductListSuccess
                new CartActions.LoadCartSuccess({
                  cart: savedCart as SsabCart,
                  userId,
                  cartId: savedCart.code,
                })
              ];
            } else {
              return [
                new CreateContactSalesProductList({
                  userId,
                  rethrowLoadCartSuccess: true
                })
              ];
            }
          }
        }), catchError((error) => from([
          new LoadContactSalesProductListFail({
            userId,
            error: normalizeHttpError(error,this.logger)
          }),
        ])));
      })));

    // this effect checks if the loadCartSucces was emitted for a saved cart and reemits it as LoadContactSalesProductListSuccess event

    this.reemitLoadCartSuccessAsLoadContactSalesProductListSuccess$ = createEffect(() =>
      this.actions$.pipe(ofType(CartActions.LOAD_CART_SUCCESS),
        map((action: CartActions.LoadCartSuccess) => action.payload),
        filter(payload => payload.cart && (payload.cart as SsabCart).cartType === 'ContactSalesProductList'),
        map((payload) =>
          new LoadContactSalesProductListSuccess({
            cart: payload.cart as SsabCart,
            userId: payload.userId,
            cartId: payload.cart.code,
          })
        )
      )
    );


    this.removeContactSalesProductListAndCreateNew$ = createEffect(() =>
      this.actions$.pipe(ofType(REMOVE_CONTACTSALES_PRODUCT_LIST), map((action: RemoveContactSalesProductList) => action.payload),
        switchMap((payload) => {
          return this.multiCartFacade.getCart(payload.cartId).pipe(
            // cart is found in multiCart store and has saveTime (indication of savedCart) => re emit the event
            filter(cart => cart && (cart as SsabCart).cartType === 'ContactSalesProductList'),
            switchMap((savedCart) => {
              // remove cart from store and fire load event to load (if not found then create) saved cart of the same type
              return [
                new CartActions.RemoveCart({
                  cartId: payload.cartId
                })
              ];
            })
          );
        })));


    this.reloadContactSalesProductListOnSiteContextChange$ = createEffect(() =>
      this.actions$.pipe(
        ofType(
          SiteContextActions.LANGUAGE_CHANGE,
          SiteContextActions.CURRENCY_CHANGE
        ),
        withLatestFrom(this.userIdService.getUserId()),
        filter(([action, userId]) => userId !== OCC_USER_ID_ANONYMOUS),
        mergeMap(([action, userId]) => {
          return [
            new LoadContactSalesProductList({
              userId
            })
          ];
        }),
        catchError((error) => from([
            new CreateContactSalesProductListFail({
              error: normalizeHttpError(error,this.logger)
            })]
          )
        )
      ));
  }
}
