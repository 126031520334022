import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CmsConfig, ConfigModule, I18nModule} from "@spartacus/core";
import {GenericLinkModule, IconModule} from "@spartacus/storefront";
import {SsabNavigationUiComponent} from "./ssab-navigation-ui.component";
import {NgbPopover} from "@ng-bootstrap/ng-bootstrap";


@NgModule({
  declarations: [SsabNavigationUiComponent],
    imports: [
        CommonModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                NavigationUIComponent: {
                    component: SsabNavigationUiComponent,
                },
            }
        }),
        IconModule,
        I18nModule,
        GenericLinkModule,
        NgbPopover
    ],
  exports: [SsabNavigationUiComponent]
})
export class SsabNavigationUiModule {
}
