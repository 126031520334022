import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { AdministrationRootModule, ORGANIZATION_ADMINISTRATION_FEATURE } from '@spartacus/organization/administration/root';

@NgModule({
  declarations: [],
  imports: [
    AdministrationRootModule
  ],
  providers: [provideConfig(<CmsConfig>{
    featureModules: {
      [ORGANIZATION_ADMINISTRATION_FEATURE]: {
        module: () =>
          import('@spartacus/organization/administration').then((m) => m.AdministrationModule),
      },
    }
  })
  ]
})
export class OrganizationAdministrationFeatureModule { }
