import {Inject, Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {GlobalMessageService, GlobalMessageType, StateWithProcess, StateWithUser, UserIdService} from '@spartacus/core';
import {B2BUnitOption, SsabUser} from '../../model/user.model';
import {SsabUserOrderConnector} from './ssab-user-order.connector';
import {catchError, filter, map, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {OrderTrackingTypes, SsabOrderHistory, SsabOrderHistoryRequest} from '../../model/order.model';
import {SsabUserProfileConnector} from './ssab-user-profile.connector';
import {OCRResult, SsabCertificateDocumentList, SsabDocumentIdentifier, SsabDocumentIdentifierList, SsabDocumentSearchRequest, SsabInvoiceDocumentList, SsabOrderConfirmationDocumentList, SsabRelatedDocumentList, SsabTransportDocumentList} from '../../model/document.model';
import {SsabUserDocumentConnector} from './ssab-user-document.connector';
import {SetShowInches, SubscribeLateOrderRequest} from './ssab-user.action';
import {HttpParams} from '@angular/common/http';
import {UserAccountFacade} from '@spartacus/user/account/root';

import {saveAs} from 'file-saver';
import {openCloseSpinner} from "../../cms-components/shared/utils/functions/ssab-functions-utils";
import {LAUNCH_CALLER, LaunchDialogService} from "@spartacus/storefront";
import {SsabScannerClearDocumentsDialogData} from "../../scanner/dropdown-menu/dropdown-menu-layout.config";
import {SsabGlobalconfigurationConnector} from "../general/configuration/ssab-globalconfiguration.connector";
import {DOCUMENT} from "@angular/common";

@Injectable({
  providedIn: 'root',
})
export class SsabUserService {

  constructor(
    protected store: Store<StateWithUser | StateWithProcess<void>>,
    protected userIdService: UserIdService,
    protected userOrderConnector: SsabUserOrderConnector,
    protected userProfileConnector: SsabUserProfileConnector,
    protected userAccountFacade: UserAccountFacade,
    protected userDocumentConnector: SsabUserDocumentConnector,
    protected globalMessageService: GlobalMessageService,
    protected launchDialogService: LaunchDialogService,
    protected globalconfigurationConnector: SsabGlobalconfigurationConnector,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  get(): Observable<SsabUser> {
    return this.userAccountFacade.get()
      .pipe(
        filter(user => user !== undefined)
      );
  }

  // UserAccountService.get() do not get an updated user when switching from units
  // so deprecated function will be used as this one do get updated
  getUserDetails(): Observable<SsabUser> {
    return this.userAccountFacade.get();
  }

  getOrderHistory(selectedView, params: SsabOrderHistoryRequest): Observable<SsabOrderHistory> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap(userId => {
        return this.userOrderConnector.getOrderHistory(userId, selectedView, params);
      })
    );
  }

  getOrderHistoryDownload(selectedView: OrderTrackingTypes, params: SsabOrderHistoryRequest): Observable<any> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap(userId => {
        return this.userOrderConnector.getOrderHistoryDownload(userId, selectedView, params);
      })
    );
  }

  getRelatedDocuments(orderId: string, customerId: string, searchRelevantIdsFromOrders: boolean): Observable<SsabRelatedDocumentList> {
    return this.userIdService.getUserId().pipe(
      take(1),
      filter(() => orderId !== undefined && orderId !== ''),
      switchMap(userId => {
        return this.userDocumentConnector.getRelatedDocuments(userId, orderId, customerId, searchRelevantIdsFromOrders);
      })
    );
  }

  getCertificateDocuments(params: SsabDocumentSearchRequest, trimMaterialIds?: string): Observable<SsabCertificateDocumentList> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap(userId => {
        return this.userDocumentConnector.getCertificateDocumentsList(userId, params, trimMaterialIds);
      })
    );
  }

  getOrderConfirmationDocuments(params: SsabDocumentSearchRequest): Observable<SsabOrderConfirmationDocumentList> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap(userId => {
        return this.userDocumentConnector.getOrderConfirmationDocumentsList(userId, params);
      })
    );
  }

  getInvoiceDocuments(params: SsabDocumentSearchRequest): Observable<SsabInvoiceDocumentList> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap(userId => {
        return this.userDocumentConnector.getInvoiceDocumentsList(userId, params);
      })
    );
  }

  getTransportDocuments(params: SsabDocumentSearchRequest): Observable<SsabTransportDocumentList> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap(userId => {
        return this.userDocumentConnector.getTransportDocumentsList(userId, params);
      })
    );
  }

  downloadDocument(params: SsabDocumentIdentifier, showHeaders?: boolean): Observable<any> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap(userId => {
        return this.userDocumentConnector.downloadDocument(userId, params, showHeaders);
      })
    ).pipe(
      catchError(err => this.documentErrorHandler())
    );
  }

  downloadDocuments(params: SsabDocumentIdentifierList): Observable<any> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap(userId => {
        return this.userDocumentConnector.downloadDocuments(userId, params);
      })
    ).pipe(
      catchError(err => this.documentErrorHandler())
    );
  }

  documentErrorHandler() {
    openCloseSpinner(this.document, false);
    this.globalMessageService.add(
      {
        key: 'ssab.download.failed',
      },
      GlobalMessageType.MSG_TYPE_ERROR
    );
    return null;
  }

  downloadAllDocuments(params: SsabDocumentSearchRequest): void {
    const sub = this.userIdService.getUserId().pipe(
      take(1),
      switchMap(userId => {
        return this.userDocumentConnector.downloadAllDocuments(userId, params);
      })
    ).subscribe((response) => {
      const file = new Blob([response], {type: 'application/zip"'});
      saveAs(file, 'documents.zip');
      sub.unsubscribe();
    });
  }

  downloadFile(data: any, fileName: string, fileType: string): void {
    if (data) {
      saveAs(new Blob([data], {
        type: 'application/' + fileType // must match the Accept type
      }), fileName + '.' + fileType);
    }
  }

  getElOQUABaseUrl(elqFormName: string = ''): Observable<string> {
    return this.globalconfigurationConnector.getGlobalConfiguration()
      .pipe(
        take(1),
        withLatestFrom(this.get()),
        map(([globalConfiguration, user]) => {
          const eloqua = this.getCookie('ELOQUA');
          if (eloqua) {
            let params = new HttpParams();
            params = params.set('elqFormName', elqFormName + (globalConfiguration.elqSuffixForm ? globalConfiguration.elqSuffixForm : ''));
            params = params.set('elqSiteID', globalConfiguration.elqSiteID);
            params = params.set('elqCookieWrite', globalConfiguration.elqCookieWrite);
            params = params.set('elqCustomerGUID', eloqua.replace('GUID=', ''));
            if (user.email) {
              params = params.set('emailAddress', user.email);
            }
            return globalConfiguration.elqUrl + params.toString();
          }
          return '';
        })
      );
  }

  sendELOQUAData(url: string, params?: HttpParams): Observable<any> {
    return this.userDocumentConnector.sendELOQUAData(url, params);
  }

  getCookie(name): string {
    const value = `; ${this.document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
    return '';
  }

  searchUnitForCompanyFilter(ctx: string, term: string, selectedView: OrderTrackingTypes): Observable<B2BUnitOption[]> {
    return this.get().pipe(
      filter(Boolean),
      switchMap(() => this.userProfileConnector.searchUnitForCompanyFilter(ctx, term, selectedView)));
  }

  searchUnitForShipToFilter(ctx: string, term: string, id: string, id2?: string, selectedView?: OrderTrackingTypes): Observable<B2BUnitOption[]> {
    return this.get().pipe(
      filter(Boolean),
      switchMap(() => this.userProfileConnector.searchUnitForShipToFilter(ctx, term, id, id2, selectedView)));
  }

  searchUnitForCustomerIdFilter(ctx: string, term: string, id?: string, selectedView?: OrderTrackingTypes): Observable<B2BUnitOption[]> {
    // backend search of customer ids is allowed only for internal users (so for now relying on customerSelection == true)
    // for the external customers this list of ids is prepared by ES/service layer and should not be modifiable
    return this.isShowCustomerSelection().pipe(
      filter(Boolean),
      switchMap(() => this.userProfileConnector.searchUnitForCustomerIdFilter(ctx, term, id, selectedView)));
  }

  searchUnits(term: string): Observable<B2BUnitOption[]> {
    return this.userProfileConnector.searchUnits(term);
  }

  selectUnit(uid: string): Observable<B2BUnitOption> {
    return this.userProfileConnector.selectUnit(uid);
  }

  getCurrentUnit(): Observable<B2BUnitOption> {
    return this.get().pipe(
      filter(Boolean),
      map((user: SsabUser) => user.unit)
    );
  }

  public isShowPrices(): Observable<boolean> {
    return this.get().pipe(
      filter(user => user !== undefined),
      map(
        (user: SsabUser) => user.showPrices)
    );
  }

  public isLateOrderNotification(): Observable<boolean> {
    return this.get().pipe(
      map((user: SsabUser) => !!user.lateOrderSubscription)
    );
  }

  public isShowConsignmentStockPrices(): Observable<boolean> {
    return this.get().pipe(
      map(user => user?.showConsignmentStockPrices)
    );
  }

  public isShowPricesOrConsignmentStockPrices(isShowPrices: boolean): Observable<boolean> {
    if (isShowPrices) { // If it is set to true we will use showPrices
      return this.isShowPrices();
    } else { // if false means we will use consignment Stock Prices
      return this.isShowConsignmentStockPrices();
    }
  }

  delayedOrderSubscription(isLateOrderSubscription: boolean): void {
    this.userIdService.getUserId().pipe(
      take(1)
    ).subscribe(userId => {
      this.store.dispatch(
        new SubscribeLateOrderRequest({userId, isLateOrderSubscription})
      );
    });
  }

  setShowInches(isShowInches: boolean, customerId: string, refreshPLP: boolean = false): void {
    this.store.dispatch(
      new SetShowInches({userId: customerId, isShowInches, refreshPLP})
    );
  }

  public isShowCustomerSelection(): Observable<boolean> {
    return this.get().pipe(
      filter(Boolean),
      map((user: SsabUser) => !!user.customerSelection)
    );
  }

  public hasStockLocations(): Observable<boolean> {
    return this.get().pipe(
      filter(Boolean),
      map((user: SsabUser) => !!user.hasStockLocations)
    );
  }

  searchByMaterialId(materialId: string): Observable<SsabCertificateDocumentList> {
    return this.userIdService.getUserId().pipe(
      switchMap(userId =>
        this.userDocumentConnector.searchByMaterialId(userId, materialId)
      )
    );
  }

  searchByOcr(data: Blob): Observable<OCRResult> {
    return this.userIdService.getUserId().pipe(
      switchMap(userId =>
        this.userDocumentConnector.searchByOcr(userId, data)
      )
    );
  }

  sendDocumentsAsEmail(params: SsabDocumentIdentifierList, email: string): void {
    this.userIdService.getUserId().pipe(
      switchMap(userId =>
        this.userDocumentConnector.sendDocumentsAsEmail(userId, params, email)
      )
    ).pipe(
      take(1),
      catchError(err => this.documentErrorHandler()),
      tap(() => {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.SCANNER_CLEAR_DOCUMENTS, undefined, {
          emailSent: true,
        } as SsabScannerClearDocumentsDialogData);
      })
    ).subscribe();
  }
}
