
<ng-container>
  <div *ngIf="isUpdating$ | async; else updateForm">
    <div class="cx-spinner">
      <cx-spinner></cx-spinner>
    </div>
  </div>
  <ng-template #updateForm>
    <ng-container *ngIf="configuration$ | async as configuration">
      <h2 class="cx-page-header-title text-white">{{'ssab.user.myAccount.title'|cxTranslate}}</h2>
      <div class="cx-my-details">
        <div class="user-details mx-auto" *ngIf="user$ | async as user">
          <div class="row" *ngIf="!configuration.showCdcProfileUpdate || (configuration.showCdcProfileUpdate && user.internalUser) ; else cdcUpdateProfile">
            <section class="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2 offset-xxl-0" >
              <h4 class="pb-3">{{'ssab.user.myAccount.personalInfo'|cxTranslate}}</h4>
              <p class="text-bold">{{user.name}}</p>
              <p>{{user.email ? user.email : user.uid}}</p>
              <h4 class="pt-4 pb-3">{{'ssab.user.myAccount.companyInfo'|cxTranslate}}</h4>
              <ng-container *ngIf="user.companyDetails">
                <div class="pb-3" *ngFor="let companyInformation of user.companyDetails">
                  <span class="text-bold">{{companyInformation.name}}</span><br/>
                  <span>{{companyInformation.address?.formattedAddress}}</span>
                </div>
              </ng-container>
              <h4 class="pt-2 pb-3">{{'ssab.user.myAccount.salesContact'|cxTranslate}}</h4>
              <ng-container *ngIf="user.salesContacts">
                <div class="pb-3" *ngFor="let salesInformation of user.salesContacts">
                  <span>{{salesInformation.salesContactName}}</span><br/>
                  <span>{{salesInformation.email}}</span>
                </div>
              </ng-container>
              <cx-page-slot position="TopContent" class="p-0 pt-3"></cx-page-slot>
              <cx-page-slot position="BottomContent" class="p-0"></cx-page-slot>
            </section>
          </div>
        </div>
      </div>
    </ng-container>

  </ng-template>
</ng-container>

<ng-template #cdcUpdateProfile>
  <ssab-cdc-my-profile-update></ssab-cdc-my-profile-update>
</ng-template>
