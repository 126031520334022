import {DIALOG_TYPE, LayoutConfig} from '@spartacus/storefront';
import {SsabClearCartComponent} from "./ssab-clear-cart.component";

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    REMOVE_CART = 'REMOVE_CART',
  }
}

export const cartHeaderModalLayout: LayoutConfig = {
  launch: {
    REMOVE_CART: {
      inlineRoot: true,
      component: SsabClearCartComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    }
  },
};

export interface SsabClearCartModalData {
  cartCode: string;
  userId: string;
}
