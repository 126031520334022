import {OccConfig} from '@spartacus/core';
import {defaultOccUserAccountConfig} from '@spartacus/user/account/occ';


export const ssabB2bOccConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        user: defaultOccUserAccountConfig.backend.occ.endpoints.user,
        baseSitesForConfig: 'basesites?fields=DEFAULT',
      },
    },
  },
};
