<div class="item-image">

  <a
    [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
    class="cx-product-image-container vertical-align-item"
    tabindex="-1"
  >
    <cx-media
      class="cx-product-image gtmData"
      [attr.data-product]="product.code"
      [attr.data-pagename]="(currentPage$| async)?.name"
      [attr.data-position]="productIndex"
      [container]="product.images?.PRIMARY"
      [alt]="product.summary"
      format="thumbnail"
    ></cx-media>
  </a>
  <ssab-cx-add-to-wishlist
    [product]="product"></ssab-cx-add-to-wishlist>
</div>
<h4 class="item-title">
  <a
    [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
    class="cx-product-name gtmData"
    [attr.data-product]="product.code"
    [attr.data-pagename]="(currentPage$| async)?.name"
    [attr.data-position]="productIndex"
    [innerHtml]="product.nameHtml"
  ></a>
</h4>
<div class="item-attributes">
  <div *ngIf="!product.nonSapProduct">
    <strong>{{'product.productID' | cxTranslate }}: </strong>{{product.code}}
  </div>
  <div *ngIf="product.summary">
    <p class="cx-product-summary" [innerHtml]="product.summary"></p>
  </div>
  <div *ngIf="product.customerArticleIds">
    <strong>{{'product.customerMaterialNumbers.name' | cxTranslate}}: </strong>{{product.customerArticleIds.join(',')}}
  </div>
  <div class="cx-product-classifications product-classifications">
    <ng-container *ngIf="false">
      <!-- weight is hidden: ticket #9312-->
      <strong>{{'ssab.product.weight'|cxTranslate}}</strong>{{weight}} {{product?.unitOfWeight.name}}
      <br>
    </ng-container>
    <ng-container *ngIf="fromUnit">
      <div *ngIf="fromUnit.bundleSize && fromUnit.bundleSizeTitle">
        <!-- weight in PLP different from PDP -->
        <strong>{{ (fromUnit.bundleSizeTitle.toLowerCase().includes('weight')) ? ('ssab.product.weight'|cxTranslate) : fromUnit.bundleSizeTitle}} </strong>
        <span [innerHTML]="fromUnit.bundleSize"></span>
      </div>
    </ng-container>
    <ng-container *ngFor="let classification of product.classifications">
      <ng-container *ngIf="classification.name">
        <strong *ngIf="classification.code">{{'ssab.product.' + classification.code |cxTranslate}}</strong> {{classification.name}}
        <br/>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="product.stock?.viewOnly">
      {{product.productForm}}
    </ng-container>
  </div>
</div>

<div class="item-price">
  <ssab-cx-product-price [product]="product"
                         [isPLPView]="true"
                         #productPrice
                         (update)="onSelectedUnitChange($event)"></ssab-cx-product-price>
</div>
