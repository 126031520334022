import {LanguagesState, Occ, SiteContextActions} from "@spartacus/core";
import Language = Occ.Language;

export const initialState: LanguagesState = {
  entities: null,
  activeLanguage: null,
};

export function languageReducer(
  state = initialState,
  action: SiteContextActions.LanguagesAction | SiteContextActions.SetActiveBaseSite
): LanguagesState {
  switch (action.type) {
    case SiteContextActions.SET_ACTIVE_BASE_SITE: {
      return {...initialState};
    }
    case SiteContextActions.LOAD_LANGUAGES_SUCCESS: {
      const languages: Language[] = action.payload;
      const entities = languages.reduce(
        (langEntities: { [isocode: string]: Language }, language: Language) => {
          return {
            ...langEntities,
            [language.isocode ?? '']: language,
          };
        },
        {}
      );

      return {
        activeLanguage: state.activeLanguage,
        entities,
      };
    }

    case SiteContextActions.SET_ACTIVE_LANGUAGE: {
      const isocode = action.payload;

      return {
        ...state,
        activeLanguage: isocode,
      };
    }
  }
  return state;
}
