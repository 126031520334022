import {Component, Inject} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {SsabCMSInformationLinkColumnComponent} from '../../../../model/cms.model';
import {Observable} from 'rxjs';
import {SalesInformation, SsabUser} from '../../../../model/user.model';
import {getEmails} from '../../../shared/utils/functions/ssab-functions-utils';
import {SsabUserService} from '../../../../service/user/ssab-user.service';
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'ssab-cx-information-link-column',
  templateUrl: './ssab-information-link-column.component.html'
})
export class SsabInformationLinkColumnComponent {

  data$: Observable<SsabCMSInformationLinkColumnComponent>;
  user$: Observable<SsabUser>;

  constructor(protected component: CmsComponentData<SsabCMSInformationLinkColumnComponent>,
              protected userService: SsabUserService,
              @Inject(DOCUMENT) private document: Document,) {
    this.data$ = this.component.data$;
    this.user$ = this.userService.get();
  }

  emails(contacts: SalesInformation[]): string {
    return getEmails(contacts);
  }

  downloadDocument(documentFile: string): void {
    if (documentFile) {
      this.document.defaultView?.window.open('assets/pdf/' + documentFile, '_blank');
    }
  }
}
