<ng-container *ngIf="data$| async as data">
  <article class="row no-gutters" [ngClass]="data.mediaFullScreen === 'true' ? 'wide-image': ''">
    <div class="col-12 col-lg-5 col-xxl-6">
      <div class="banner-content">
        <section>
          <h1 class="pb-4">{{data.headline}}</h1>
          <h3 class="preamble">{{data.content}} </h3>
        </section>
      </div>
    </div>
    <div [ngClass]="data.mediaFullScreen === 'true' ? '': 'col-12 col-lg-7 col-xxl-6 pl-xxl-5' ">
      <cx-media  [container]="data.media"></cx-media>
    </div>
  </article>
</ng-container>
