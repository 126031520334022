import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SsabAuthService} from './ssab-auth.service';
import {AuthHttpHeaderService, AuthRedirectService, AuthService} from '@spartacus/core';
import { SsabAuthHttpHeaderService } from './ssab-auth-http-header.service';
import { SsabAuthRedirectService } from './ssab-auth-redirect.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: AuthService,
      useClass: SsabAuthService
    },
    {
      provide: AuthRedirectService,
      useClass: SsabAuthRedirectService
    },
    {
      provide: AuthHttpHeaderService,
      useClass: SsabAuthHttpHeaderService
    }
  ]
})
export class SsabUserAuthModule { }
