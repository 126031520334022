<div id="vision-footer" class="footer">
  <div class="dynamic-footer-container segment">
    <div class="footer-container">
      <div class="footer-vision-box-wrapper inner-grid">

        <ng-container *ngIf="columnList$| async as data">
          <ng-container *ngFor="let column of data; let indexOfelement=index;">
            <ssab-cx-footer-column [column]="column" id="footer-column-{{indexOfelement}}">
            </ssab-cx-footer-column>
          </ng-container>
        </ng-container>

      </div>
    </div>
  </div>
</div>
