import {Inject, Injectable} from '@angular/core';
import {AuthService, SemanticPathService, WindowRef} from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router, UrlTree } from '@angular/router';

import {GlobalLogin} from '../../model/misc.model';
import {DOCUMENT} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class ImpersonationGuard  {

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected semanticPathService: SemanticPathService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return of(this.authService.coreLogout()).pipe(map(() => {
      const path = this.semanticPathService.get('login');
      this.getLocalStorage()?.setItem(GlobalLogin.AsmActive, 'true');
      return this.router.parseUrl(path + '?asm=true');
    }));
  }

  protected getLocalStorage(): Storage {
    return this.document.defaultView?.localStorage;
  }
}
