import {Component, Input, OnInit} from '@angular/core';
import {BannerComponent, CmsComponentData} from '@spartacus/storefront';
import {tap} from 'rxjs/operators';
import {SsabCMSCarouselBannerComponent} from '../../../model/cms.model';
import {CmsService, SemanticPathService} from '@spartacus/core';
import {of} from "rxjs";


@Component({
  selector: 'ssab-cx-banner-column',
  templateUrl: './ssab-carousel-banner-column.component.html'
})
export class SsabCarouselBannerComponent extends BannerComponent implements OnInit{

  @Input()
  protected data: SsabCMSCarouselBannerComponent;

  constructor(protected component: CmsComponentData<SsabCMSCarouselBannerComponent>,
              protected urlService: SemanticPathService,
              protected cmsService: CmsService) {
    super(component, urlService, cmsService);
  }

  ngOnInit() :void{
    this.data$ = this.data ? of(this.data) : this.component.data$.pipe(
      tap((data) => (this.styleClasses = data.styleClasses))
    )
  }
}
