import {DIALOG_TYPE, LayoutConfig} from '@spartacus/storefront';
import {SsabProductAvailabilityModalComponent} from './ssab-product-availability-modal.component';
import {SsabUser} from '../../../model/user.model';
import {Observable} from 'rxjs';

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    PRODUCT_AVAILABILITY_MODAL = 'PRODUCT_AVAILABILITY_MODAL',
  }
}

export const productAvailabilityModalLayout: LayoutConfig = {
  launch: {
    PRODUCT_AVAILABILITY_MODAL: {
      inlineRoot: true,
      component: SsabProductAvailabilityModalComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};

export interface SsabProductAvailabilityModalData {
  productCode: string;
  isSubscriptionSuccess: boolean;
  isSubscriptionFail: boolean;
  user: Observable<SsabUser>;
}
