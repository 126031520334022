import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subscription} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {SsabOrderEntry} from '../../../model/cart.model';
import {OCC_USER_ID_ANONYMOUS, UserIdService} from '@spartacus/core';
import {Actions, ofType} from '@ngrx/effects';
import {SsabCollectedItemsComponent} from './collected-items/ssab-collected-items.component';
import {SalesInformation, SsabUser} from '../../../model/user.model';
import {getEmails} from '../../shared/utils/functions/ssab-functions-utils';
import {SsabUserService} from '../../../service/user/ssab-user.service';
import {LOAD_CONTACTSALES_PRODUCT_LIST_SUCCESS, LoadContactSalesProductListSuccess} from '../../../service/contactsales/ssab-contactsales.action';
import {SsabContactSalesProductListService} from '../../../service/contactsales/ssab-contactsales.service';
import {LAUNCH_CALLER, LaunchDialogService} from '@spartacus/storefront';
import {SsabCollectedItemsDialogData} from './collected-items/ssab-collected-items-layout.config';

@Component({
  selector: 'ssab-cx-saved-minicart',
  templateUrl: './ssab-contactsales.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsabContactSalesProductListComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();
  protected modal: SsabCollectedItemsComponent;
  user$: Observable<SsabUser>;
  items$: Observable<SsabOrderEntry[]>;
  quantity$: Observable<number>;

  constructor(protected actions$: Actions,
              private userIdService: UserIdService,
              protected contactSalesProductListService: SsabContactSalesProductListService,
              protected userService: SsabUserService,
              protected cdr: ChangeDetectorRef,
              protected launchDialogService: LaunchDialogService) {

  }

  ngOnInit(): void {
    this.user$ = this.userService.get();

    this.subscriptions.add(
      this.actions$.pipe(
        ofType(LOAD_CONTACTSALES_PRODUCT_LIST_SUCCESS)
      ).subscribe((action: LoadContactSalesProductListSuccess) => {
          this.items$ = of(action.payload.cart.entries);
          this.quantity$ = of(action.payload.cart.entries.length);
          this.cdr.detectChanges();
        }
      )
    );

    this.userIdService.getUserId().pipe(
      take(1),
      filter(userId => userId !== OCC_USER_ID_ANONYMOUS)
    ).subscribe((userId) => {
      this.contactSalesProductListService.loadContactSalesProductList(userId);
    });
  }

  removeItem(entry: SsabOrderEntry): void {
    this.contactSalesProductListService.removeEntry(entry);
  }

  openCollectedItemsModal(): void {
    this.launchDialogService.closeDialog(null);
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.COLLECTED_ITEMS, undefined, {
      items: this.items$,
      user: this.user$
    } as SsabCollectedItemsDialogData);
  }

  emails(contacts: SalesInformation[]): string {
    return getEmails(contacts);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
