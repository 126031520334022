<label class="searchbox small" [class.dirty]="!!searchInput.value">
  <input
    #searchInput
    [placeholder]="'searchBox.placeholder' | cxTranslate"
    aria-label="search"
    (focus)="open()"
    (click)="open()"
    (input)="searchMinCharacters(searchInput.value)"
    (blur)="close($event)"
    (keydown.escape)="close($event)"
    (keydown.enter)="close($event, true);
        launchSearchResult($event, searchInput.value);
        onlyClear(searchInput)
    "
    (keydown.arrowup)="focusPreviousChild($event)"
    (keydown.arrowdown)="focusNextChild($event)"
  />

  <button
    class="reset icon-inside"
    (mousedown)="clearAndClose($event, searchInput)"
    (keydown.enter)="clearAndClose($event, searchInput)"
  >
    <cx-icon class="ssab-icon icon-navigation-close icon-md"></cx-icon>
  </button>

  <cx-icon
    id="dirty-toggle"
    (mousedown)="avoidReopen($event)"
    (keydown.enter)="avoidReopen($event)"
    class="search ssab-icon icon-navigation-search"
    aria-label="search"
    tabindex="0"
  ></cx-icon>

</label>

<div
  *ngIf="results$ | async as result"
  class="results"
  (click)="close($event, true)"
>
  <div
    *ngIf="result.message"
    class="message"
    [innerHTML]="result.message"
  ></div>

  <div class="suggestions">
    <a
      *ngFor="let suggestion of result.suggestions"
      [innerHTML]="suggestion | cxHighlight: searchInput.value"
      [routerLink]="
        {
          cxRoute: 'search',
          params: { query: suggestion }
        } | cxUrl
      "
      (keydown.arrowup)="focusPreviousChild($event)"
      (keydown.arrowdown)="focusNextChild($event)"
      (keydown.enter)="close($event, true)"
      (keydown.escape)="close($event, true)"
      (blur)="close($event)"
      (mousedown)="preventDefault($event)"
      (click)="
        dispatchSuggestionEvent({
          freeText: searchInput.value,
          selectedSuggestion: suggestion,
          searchSuggestions: result.suggestions
        });
        updateChosenWord(suggestion)
      "
    >
    </a>
  </div>

  <div class="products" *ngIf="result.products">
    <a
      *ngFor="let product of result.products; let i = index"
      [routerLink]="
        {
          cxRoute: 'product',
          params: product
        } | cxUrl
      "
      [class.has-media]="config.displayProductImages"
      (keydown.arrowup)="focusPreviousChild($event)"
      (keydown.arrowdown)="focusNextChild($event)"
      (keydown.enter)="close($event, true)"
      (keydown.escape)="close($event, true)"
      (blur)="close($event)"
      (mousedown)="preventDefault($event)"
      (click)="
        dispatchProductEvent({
          freeText: searchInput.value,
          productCode: product.code
        })
      "
    >
      <cx-media
        *ngIf="config.displayProductImages"
        [container]="product.images?.PRIMARY"
        format="thumbnail"
        [alt]="product.summary"
      ></cx-media>
      <h4 class="name"
          [innerHTML]="product.nameHtml"
      ></h4>
    </a>
  </div>
</div>
