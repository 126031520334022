<div class="d-flex">
  <div class="vertical-align-item">
    <i class="ssab-icon icon-inline icon-cursor"
       [ngClass]="isSplitBundle ? 'icon-switch-blue': 'icon-switch'"
       (click)="toggleSplitBundle()"></i>
  </div>
  <span class="ml-2 text-left text-sm">{{label  | cxTranslate }}</span>
</div>



