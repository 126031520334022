<ng-container *ngIf="order$ | async as order">
  <div class="pb-lg-4">
    <h2 class="text-white">{{ 'ssab.cart.checkout.title' | cxTranslate }}</h2>
    <div class="cx-order-confirmation-message">
      <div class="d-flex row no-margin">
        <div class="col-sm-8 col-12 col-no-padding">
          <h3 class="pb-md-4 ">
            {{ (order.consignment ? 'ssab.consignment.list.order.confirmation.title': 'ssab.order.confirmation.thankyou') | cxTranslate }}
          </h3>
        </div>

        <div class="col-sm-4 col-12 col-no-padding" >
          <h4 *ngIf="!order.isOrderSplit">{{ 'ssab.order.confirmation.yourOrder' | cxTranslate }} {{order.code}}</h4>
          <div *ngIf="order.isOrderSplit">
            <p class="text-paragraph-md text-bold text-left text-sm-right mb-0">{{ 'ssab.order.confirmation.orderSplit' | cxTranslate }}</p>
            <ul  class="list-unstyled text-left text-sm-right">
              <li *ngFor="let externalNumber of order.externalOrderNumbersList">
                {{externalNumber}}
              </li>
            </ul>
          </div>
        </div>

        <div class="col-sm-8 col-12 col-no-padding">
          <div class="pb-4">
            <span *ngIf="!order.consignment">{{ 'ssab.order.confirmation.text' | cxTranslate }}<br/></span>
            {{ 'ssab.order.confirmation.email' | cxTranslate :{email: order.orderConfirmationEmail} }}
          </div>
          <ng-container *ngIf="!order.consignment">
            <div class="pb-4" *ngIf="order.paymentMode?.prePayment">
              <p class="text-bold mb-2">{{ 'ssab.order.confirmation.prePayment.title' | cxTranslate }}</p>
              {{ 'ssab.order.confirmation.prePayment.text' | cxTranslate }}
            </div>

            <div class="mb-sm-5">
              <p class="text-bold mb-1">{{ 'ssab.order.confirmation.delivery.title' | cxTranslate }} </p>
              {{ 'ssab.order.confirmation.delivery.expressDelivery' | cxTranslate }} <br/>
              {{ 'ssab.order.confirmation.delivery.multipleDeliveries' | cxTranslate }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
