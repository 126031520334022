import {Component, Input, OnInit} from '@angular/core';
import {SsabCMSFooterColumnComponent, SsabCMSFooterContainer} from "../../../model/cms.model";
import {SsabUserService} from "../../../service/user/ssab-user.service";
import {Observable} from "rxjs";
import {SsabUser} from "../../../model/user.model";
import {getEmails} from '../../shared/utils/functions/ssab-functions-utils';
@Component({
  selector: 'ssab-cx-footer-column',
  templateUrl: './ssab-footer-column.component.html'
})
export class SsabFooterColumnComponent{
  @Input() column: SsabCMSFooterColumnComponent;
  user$: Observable<SsabUser>;

  constructor(
    protected userService: SsabUserService,
  ) {
    this.user$ = userService.get();
  }

  protected emails(user: SsabUser){
    return getEmails(user.salesContacts);
  }
}
