import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {I18nModule, UrlModule} from '@spartacus/core';
import {SsabFacetComponent} from './ssab-facet.component';
import {IconModule, KeyboardFocusModule} from '@spartacus/storefront';
import {SsabInputModule} from '../../../../../shared/input/ssab-input.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {SharedModule} from '../../../../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    IconModule,
    KeyboardFocusModule,
    SsabInputModule,
    NgSelectModule,
    SharedModule
  ],
  declarations: [SsabFacetComponent],
  exports: [SsabFacetComponent],
})
export class SsabFacetModule {
}
