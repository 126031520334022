import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthGuard, CmsConfig, I18nModule, provideDefaultConfig} from '@spartacus/core';
import {SsabMyDetailsComponent} from './ssab-my-details.component';
import {FormErrorsModule, PageSlotModule, SpinnerModule} from '@spartacus/storefront';
import {UpdateProfileModule} from '@spartacus/user/profile/components';
import {SsabCdcMyProfileUpdateComponent} from "../cdc-my-profile-update/ssab-cdc-my-profile-update.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SpinnerModule,
        FormErrorsModule,
        UpdateProfileModule,
        I18nModule,
        PageSlotModule
    ],
    providers: [
        provideDefaultConfig({
            cmsComponents: {
                UpdateProfileComponent: {
                    component: SsabMyDetailsComponent,
                    guards: [AuthGuard],
                },
            },
        } as CmsConfig),
    ],
    declarations: [SsabMyDetailsComponent, SsabCdcMyProfileUpdateComponent],
    exports: [SsabMyDetailsComponent]
})
export class SsabMyDetailsModule extends UpdateProfileModule {}
