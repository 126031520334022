import {Component, Input, OnInit} from '@angular/core';
import {
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import {SsabCartItemConsignmentLabelDialogComponent} from "./ssab-cart-item-consignment-label-dialog.component";
import {SsabCartItemBaseComponent} from "../../ssab-cart-item.base.component";
import {SsabActiveCartService} from "../../../../../../service/cart/ssab-active-cart.service";
import {CartItemContextSource} from "@spartacus/cart/base/components";
import {SsabOrderEntry} from "../../../../../../model/cart.model";
import {SsabUnitOfMeasure} from "../../../../../../model/product.model";

@Component({
  selector: 'ssab-cart-item-consignment-label',
  templateUrl: 'ssab-cart-item-consignment-label.component.html'
})
export class SsabCartItemConsignmentLabelComponent extends SsabCartItemBaseComponent implements OnInit {
  private currentDialogRef: MatDialogRef<SsabCartItemConsignmentLabelDialogComponent> | null = null;
  data: string;
  @Input() currentItem: SsabOrderEntry;
  @Input() identifier: String;

  constructor(public dialog: MatDialog, protected activeCartService: SsabActiveCartService,
              protected cartItemContextSource: CartItemContextSource
  ) {
    super(activeCartService, cartItemContextSource);
  }

  ngOnInit(): void {
    this.data = this.currentItem?.batchItemNote;
  }

  openDialog(item: SsabOrderEntry): void {
    if (this.currentDialogRef) {
      // Close the current dialog if it's open
      this.currentDialogRef.close();
    }

    this.currentDialogRef = this.dialog.open(SsabCartItemConsignmentLabelDialogComponent, {
      data: {batchItemNote: item.batchItemNote, identifier: this.identifier}
    });

    this.currentDialogRef.afterClosed().subscribe(result => {
      this.currentDialogRef = null;
      if (result !== undefined) {
        this.data = result;
        this.updateBatchNote(item, this.data);
      }
    });
  }

  updateBatchNote(item: SsabOrderEntry, batchNote: string): void {
    this.activeCartService?.updateSsabEntry({
      product: {code: item?.product?.code},
      entryNumber: item.entryNumber,
      quantity: item.quantity,
      unit: item.unit as SsabUnitOfMeasure,
      batchId: item.batchId,
      batchItemNote: batchNote
    } as SsabOrderEntry);
  }
}
