import {Provider} from '@angular/core';
import {HttpErrorHandler} from '@spartacus/core';
import {SsabBadRequestHandler} from "./ssab-bad-request.handler";


export const ssabHandlers: Provider[] = [
  {
    provide: HttpErrorHandler,
    useExisting: SsabBadRequestHandler,
    multi: true
  }
];
