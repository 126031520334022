import {AuthGuard, CmsConfig, ConfigModule, I18nModule, UrlModule} from '@spartacus/core';
import {RouterModule} from '@angular/router';
import {SsabMaterialDataComponent} from './ssab-material-data.component';
import {CommonModule} from '@angular/common';
import {SsabMaterialDataTableComponent} from './material-data-table/ssab-material-data-table.component';
import {SsabTableHeadModule} from '../../shared/table-head/ssab-table-head.module';
import {NgModule} from '@angular/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {SsabInputRangeModule} from '../../../shared/input-range/ssab-input-range.module';
import {SsabInputModule} from '../../../shared/input/ssab-input.module';
import {ReactiveFormsModule} from '@angular/forms';
import {SsabMaterialDataFormComponent} from './material-data-form/ssab-material-data-form.component';
import {SsabPaginationBottomModule} from '../../../shared/pagination-bottom/ssab-pagination-bottom.module';
import {SsabDatepickerModule} from '../../shared/datepicker/ssab-datepicker.module';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UrlModule,
        I18nModule,
        SsabDatepickerModule,
        SsabInputModule,
        NgSelectModule,
        ReactiveFormsModule,
        NgbPopoverModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                AccountMaterialDataComponent: {
                    component: SsabMaterialDataComponent,
                    guards: [AuthGuard],
                },
            },
        }),
        SsabInputRangeModule,
        SsabTableHeadModule,
        SsabPaginationBottomModule
    ],
    declarations: [SsabMaterialDataComponent, SsabMaterialDataFormComponent, SsabMaterialDataTableComponent],
    exports: [SsabMaterialDataComponent, SsabMaterialDataFormComponent, SsabMaterialDataTableComponent]
})
export class SsabMaterialDataModule{
}
