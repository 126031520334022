import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {BreakpointService, FacetService, ProductFacetNavigationComponent} from '@spartacus/storefront';
import {BehaviorSubject, Subscription} from "rxjs";

@Component({
  selector: 'ssab-cx-product-facet-navigation',
  templateUrl: './ssab-product-facet-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabProductFacetNavigationComponent extends ProductFacetNavigationComponent implements OnInit, OnDestroy {
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  subscriptions = new Subscription();

  constructor(protected breakpointService: BreakpointService,
              protected facetService: FacetService) {
    super(breakpointService);
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.facetService.facetList$.subscribe(
        () => this.loading$.next(false)
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

