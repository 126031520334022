import {ClickOutsideDirective} from './directives/ssab-clickout.directive';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {SsabAsmAwareHttpInterceptor} from "./http-interceptors/ssab-asm-aware-http-interceptor";

@NgModule({
  exports: [ClickOutsideDirective],
  declarations: [ClickOutsideDirective],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: SsabAsmAwareHttpInterceptor,
      multi: true
    }
  ]
})
export class SharedModule {
}
