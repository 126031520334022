import {NgModule} from '@angular/core';

import {ReactiveFormsModule} from "@angular/forms";

import {NgbDateParserFormatter, NgbDatepickerI18n, NgbDatepickerModule} from "@ng-bootstrap/ng-bootstrap";
import {CommonModule} from "@angular/common";
import {SsabDatepickerInputComponent} from "./ssab-datepicker-input.component";
import {SsabDatepickerDayComponent} from "./ssab-datepicker-day.component";
import {SsabNgbDatepickerI18n} from "./ssab-datepicker-i18n";
import {SsabNgbDateParserFormatter} from "./ssab-datepicker-parser-formatter";
import {I18nModule} from "@spartacus/core";


@NgModule({
    exports: [
        SsabDatepickerInputComponent,
        SsabDatepickerDayComponent,
    ],
    providers: [
        { provide: NgbDatepickerI18n, useClass: SsabNgbDatepickerI18n },
        { provide: NgbDateParserFormatter, useClass: SsabNgbDateParserFormatter }
    ],
    imports: [
        ReactiveFormsModule,
        I18nModule,
        NgbDatepickerModule,
        CommonModule,
    ],
    declarations: [SsabDatepickerInputComponent, SsabDatepickerDayComponent]
})
export class SsabDatepickerModule {
}
