import {CmsConfig} from "@spartacus/core";


export const ssabDefaultCmsModuleConfig: CmsConfig = {
  backend: {
    occ: {
      endpoints: {
        components: 'cms/components?customerEmulationParameter=true',
        pages: 'cms/pages?customerEmulationParameter=true',
      },
    },
  },
  cmsComponents: {},
};
