import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ConverterService, OccEndpointsService} from "@spartacus/core";
import {OccCartAdapter} from '@spartacus/cart/base/occ';
import {SsabUserService} from '../user/ssab-user.service';

@Injectable()
export class SsabCartAdapter extends OccCartAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected converterService: ConverterService,
    protected userService: SsabUserService
  ) {
    super(http, occEndpointsService, converterService);
  }

}
