<div class="context-item-wrapper" *ngIf="items$ | async as items">
  <div [ngClass]="{'context-select':true,
                  'Language ssab-icon icon-navigation-globe navigation-icon': isLanguage,
                  'Currency': isCurrency,
                  'navigation-icon-active': this.isOpen}"
       *ngIf="items?.length > 0"
       (click)="toggleOpen()">
    {{(activeItem$ | async)}}
  </div>
  <div [ngClass]="{'context-options-container':true,
                  'site-language': isLanguage,
                   'content-select-visible': this.isOpen}">
    <p class="text-uppercase text-bold"> {{'ssab.store.selector.select' | cxTranslate}}</p>
    <a *ngFor="let item of items"
       (click)="contextClicked(item.isocode) "
       [ngClass]="{'context-select-option':true,
                'content-select-visible': this.isOpen} ">
      <span [ngClass]="{'text-bold active-language position-relative': item.isocode === (activeLanguage$ | async)}">{{ item.name }}</span>
    </a>
    <ng-container *ngIf="isLanguage">
      <ng-container *ngIf="user$ | async as user">
        <div *ngIf="user.storeAccesses.length > 1" class="cx-multiple-stores">
          <p class="text-bold text-uppercase"> {{'ssab.store.selector.current' | cxTranslate}}</p>
          <p class="text-capitalize-first"
             *ngIf="baseSite$ | async as activeSite">{{ 'ssab.store.selector.'+activeSite | cxTranslate }}</p>
          <button class="btn btn-primary btn-block p-3" (click)="changeStore()"> {{'ssab.store.selector.button' | cxTranslate}}</button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

