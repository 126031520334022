<ssab-floating-scrollbar>
  <div class="table-responsive" *ngIf="invoiceDocuments && invoiceDocuments.length > 0; else noInvoiceDocuments">
    <table class="table cx-documents-table cx-documents-invoices">
      <thead table-head
             (sortChange)="sort($event)"
             [activeSort]="activeSort"
             [label]="'ssab.documents.search.'"
             [tableHeadType]="documentTypes.Invoice"
             (selectAllDocuments)="checkAllDocuments($event)">
      </thead>
      <tbody>
      <tr *ngFor="let invoice of invoiceDocuments">
        <td>
          <div class="form-check mb-0 text-center">
            <input #selectedDocument
                   class="form-check-input position-relative"
                   type="checkbox" aria-label="..."
                   [checked]="selectAllDocuments"
                   (change)="onChange(selectedDocument.checked, invoice)">
          </div>
        </td>
        <td class="text-center">
          <i class="ssab-icon icon-system-download  w-auto icon-cursor"
             (click)="onDownload(invoice)"></i>
        </td>
        <td>{{invoice.orderNumber}}</td>
        <td>{{invoice.customerRef}}</td>
        <td>{{invoice.invoiceNumber}}</td>
        <td>{{invoice.documentDate ? (convertUIDate(invoice.documentDate)|cxDate: (getDateFormat()|async)):''}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</ssab-floating-scrollbar>
<ng-template #noInvoiceDocuments>
  <div *ngIf="activeSort"
       class="empty-data-div row no-margin text-center">
    <div [ngClass]="'col-6'"
    >
      <h4 class="text-primary">{{ 'ssab.documents.search.noDocuments.' + (documentTypes.Invoice | lowercase) | cxTranslate }}</h4>
    </div>
  </div>
</ng-template>
