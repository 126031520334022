import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from "@spartacus/core";
import { ProductImageZoomRootModule, PRODUCT_IMAGE_ZOOM_FEATURE } from "@spartacus/product/image-zoom/root";

@NgModule({
  declarations: [],
  imports: [
    ProductImageZoomRootModule
  ],
  providers: [provideConfig(<CmsConfig>{
    featureModules: {
      [PRODUCT_IMAGE_ZOOM_FEATURE]: {
        module: () =>
          import('@spartacus/product/image-zoom').then((m) => m.ProductImageZoomModule),
      },
    }
  })]
})
export class ProductImageZoomFeatureModule { }
