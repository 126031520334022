import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {EventService, OCC_USER_ID_ANONYMOUS, UserIdService} from '@spartacus/core';
import {Observable} from 'rxjs';
import {SsabCartAddEntries, SsabCartAddEntry, SsabCartUpdateEntry, SsabUpdateCartHeader} from './ssab-entry.action';
import {ActivatedRoute, Router} from '@angular/router';
import {SsabCart, SsabOrderEntry} from '../../model/cart.model';
import {ExtraMessages} from '../../model/misc.model';
import {CartActions, MultiCartService, StateWithMultiCart} from '@spartacus/cart/base/core';
import {SsabCheckoutDeliveryAddressClearedEvent} from '../../checkout/events/ssab-checkout-delivery-address-cleared-event';


@Injectable({
  providedIn: 'root',
})
export class SsabMultiCartService extends MultiCartService {
  constructor(
    protected store: Store<StateWithMultiCart>,
    protected userIdService: UserIdService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected eventService: EventService
  ) {
    super(store, userIdService);
  }

  getCart(cartId: string): Observable<SsabCart> {
    return super.getCart(cartId) as Observable<SsabCart>;
  }

  getEntries(cartId: string): Observable<SsabOrderEntry[]> {
    return super.getEntries(cartId) as Observable<SsabOrderEntry[]>;
  }

  addSsabEntry(
    userId: string,
    cartId: string,
    entry: SsabOrderEntry,
    extraMessage?: ExtraMessages
  ): void {
    this.store.dispatch(
      new SsabCartAddEntry(
        userId,
        cartId,
        entry,
        (extraMessage ? extraMessage : null),
      )
    );
  }

  addSsabEntries(
    userId: string,
    cartId: string,
    entries: SsabOrderEntry[]
  ): void {
    this.store.dispatch(new SsabCartAddEntries(userId, cartId, entries));
  }

  updateSsabEntry(
    userId: string,
    cartId: string,
    entry: SsabOrderEntry
  ): void {
    this.store.dispatch(
      new SsabCartUpdateEntry(
        userId,
        cartId,
        entry
      )
    );
  }

  updateCartHeader(userId: string, cartId: string, cart: SsabCart, runErpSimulation: boolean) {
    this.store.dispatch(
      new SsabUpdateCartHeader(
        userId,
        cartId,
        cart,
        runErpSimulation
      )
    );
  }

  reloadCartData() {
    this.eventService.dispatch(new SsabCheckoutDeliveryAddressClearedEvent);
    this.store.dispatch(new CartActions.ResetCartDetails());
  }

  reloadCart() {
    this.reloadCartData()
    this.router.navigated = false;
    this.router.navigate(
      ['./'],
      {
        relativeTo: this.route
      });
  }

  loadCart({cartId, userId, extraData}: {
    cartId: string;
    userId: string;
    extraData?: any;
  }): void {
    if (userId === OCC_USER_ID_ANONYMOUS) {
      // we do not support anonymous carts, no need in unnecessary backend stacktraces or unnecessary anonymous carts
      return;
    }
    super.loadCart({cartId, userId, extraData});
  }

}
