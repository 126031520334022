import {NgModule} from '@angular/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {CommonModule} from '@angular/common';
import {CmsConfig, ConfigModule, I18nModule, UrlModule} from '@spartacus/core';
import {RouterModule} from '@angular/router';
import {PageSlotModule} from '@spartacus/storefront';
import {SsabCustomerSelectComponent} from './ssab-customer-select.component';
import {FormsModule} from '@angular/forms';


@NgModule({
    declarations: [SsabCustomerSelectComponent],
    imports: [
        NgSelectModule,
        CommonModule,
        ConfigModule.withConfig({
            cmsComponents: {
                CustomerSelectionComponent: {
                    component: SsabCustomerSelectComponent,
                },
            },
        } as CmsConfig),
        RouterModule,
        PageSlotModule,
        I18nModule,
        UrlModule,
        FormsModule,
    ],
    exports: [SsabCustomerSelectComponent]
})
export class SsabCustomerSelectModule{}

