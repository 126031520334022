<ng-container *ngIf="data$ | async as  tile">
  <ng-container *ngIf="isFullGridTile(tile.fullGridTile); else normalTile">
    <cx-generic-link
      [url]="tile.urlLink"
      *ngIf="isFullGridTile(tile.fullGridTile)"
    >
      <div class="tile">
        <div class="tile-box">
          <h2 class="text-white" *ngIf="tile.headline">{{tile.headline}}</h2>
          <i class="tile-icon ssab-icon icon-white icon-arrow-long"></i>
        </div>
      </div>
    </cx-generic-link>
  </ng-container>
  <ng-template #normalTile>
    <div class="tile-image">
      <cx-media *ngIf="tile.media && !isFullGridTile(tile.fullGridTile)" [container]="tile.media"></cx-media>
      <div class="tile-image-box" *ngIf="!isFullGridTile(tile.fullGridTile)">
        <p class="text-gray text-uppercase" *ngIf="tile.headline">{{tile.headline}}</p>
        <p class="tile-content text-bold" *ngIf="tile.content">{{tile.content}}</p>
        <cx-generic-link
          [url]="tile.urlLink"
        >
        <span class="tile-icon">
          <i class="ssab-icon icon-white icon-navigation-chevron-right icon-inline"></i>
        </span>
          <h5 class="text-uppercase d-inline-block">{{tile.ctaText}}</h5>
        </cx-generic-link>
      </div>
    </div>
  </ng-template>
</ng-container>
