import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map, take, withLatestFrom} from 'rxjs/operators';

import {SsabCart, SsabOrderEntry} from '../../model/cart.model';

import {UserIdService, WindowRef} from "@spartacus/core";
import {SsabMultiCartService} from './ssab-multi-cart.service';
import {ActiveCartService, getCartIdByUserId, StateWithMultiCart} from '@spartacus/cart/base/core';


@Injectable({
  providedIn: 'root',
})
export class SsabActiveCartService extends ActiveCartService {

  constructor(
    protected store: Store<StateWithMultiCart>,
    protected multiCartService: SsabMultiCartService,
    protected userIdService: UserIdService,
    protected winRef: WindowRef
  ) {
    super(multiCartService, userIdService, winRef);
  }

  /**
   * Returns active cart
   */
  getActive(): Observable<SsabCart> {
    return super.getActive() as Observable<SsabCart>;
  }

  isConsignmentCart(): Observable<boolean> {
    return this.getActive().pipe(
      map(cart => cart?.consignment)
    );
  }

  /**
   * Returns cart entries
   */
  getEntries(): Observable<SsabOrderEntry[]> {
    return super.getEntries() as Observable<SsabOrderEntry[]>;
  }

  /**
   * Add entry to active cart
   *
   * @param productCode
   * @param quantity
   */
  addSsabEntry(entry: SsabOrderEntry): void {
    this.requireLoadedCart()
      .pipe(withLatestFrom(this.userIdService.getUserId()), take(1))
      .subscribe(([cart, userId]) => {
        this.multiCartService.addSsabEntry(
          userId,
          getCartIdByUserId(cart, userId),
          entry,
        );
      });
  }

  addSsabEntries(entries: SsabOrderEntry[], cartId?: string): void {
    if (entries) {
      this.requireLoadedCart()
        .pipe(withLatestFrom(this.userIdService.getUserId()))
        .pipe(take(1))
        .subscribe(([cart, userId]) => {
          this.multiCartService.addSsabEntries(
            userId,
            // eslint-disable-next-line
            cartId ? cartId : getCartIdByUserId(cart, userId),
            entries,
          );
        });
    }
  }

  updateSsabEntry(entry: SsabOrderEntry): void {
    this.getActiveCartId()
      .pipe(withLatestFrom(this.userIdService.getUserId()), take(1))
      .subscribe(([cartId, userId]) => {
        this.multiCartService.updateSsabEntry(
          userId,
          cartId,
          entry
        );
      }).unsubscribe();
  }

  updateCartHeader(cart: SsabCart, runErpSimulation: boolean): void {
    this.userIdService.getUserId()
      .subscribe((userId) => {
        this.multiCartService.updateCartHeader(
          userId,
          getCartIdByUserId(cart, userId),
          cart,
          runErpSimulation
        );
      }).unsubscribe();
  }

  reLoadCart(reloadPage = true) {
    /* this.multiCartService.loadCart({
       cartId: 'current',
       userId: 'current',
       extraData: {
         active: true,
       }
     });*/
    this.getActiveCartId();
    if (reloadPage) {
      this.multiCartService.reloadCart();
    } else {
      this.multiCartService.reloadCartData();
    }
  }

  deleteCart(cartCode: string, userId: string) {
    this.multiCartService.deleteCart(
      cartCode, userId
    );
  }

}
