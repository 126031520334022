<div class="ssab-modal-md ssab-modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title d-inline">
          {{'ssab.coil.comparison.clear.title'|cxTranslate}}
        </h3>
        <i class="float-right icon-md ssab-icon icon-navigation-close icon-inline" (click)="close()"></i>
      </div>
      <div class="modal-body">
        <p>{{'ssab.coil.comparison.clear.description'|cxTranslate}}</p>

        <div class="buttons d-flex justify-content-end ">
          <button
            type="button"
            (click)="close()"
            class="btn btn-link text-uppercase ">
            {{ 'common.cancel' | cxTranslate }}
          </button>
          <button
            type="button"
            (click)="clear()"
            class="btn btn-primary ml-4">
            {{ 'ssab.coil.comparison.preview.clear' | cxTranslate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
