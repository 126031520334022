import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {SsabScriptService} from "../../../../service/script/ssab-script.service";
import {SsabCdcService} from "../../../../service/cdc/ssab-cdc.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'ssab-cdc-login',
  templateUrl: './ssab-cdc-login.component.html'
})
export class SsabCdcLoginComponent implements OnInit, AfterViewInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  constructor(
    protected cdcService: SsabCdcService,
    protected scriptService: SsabScriptService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.renderCdcLoginScreen();
  }

  public renderCdcLoginScreen(): void {
    this.subscriptions.add(
      this.scriptService.isCdcScriptLoaded().subscribe(loaded => {
        if (loaded) {
          this.cdcService.openLoginForm();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
