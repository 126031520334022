import {Injectable} from '@angular/core';
import {ProductFacetService} from '@spartacus/storefront';
import {ActivatedRouterStateSnapshot, PageType, ProductSearchPage, RoutingService} from '@spartacus/core';
import {SsabProductListComponentService} from "./ssab-product-list-component.service";

@Injectable({
  providedIn: 'root',
})
export class SsabProductFacetService extends ProductFacetService{

  constructor(
    protected routing: RoutingService,
    protected productListComponentService: SsabProductListComponentService
  ) {
    super(routing, productListComponentService);
  }

  protected filterForPage(
    state: ActivatedRouterStateSnapshot,
    page: ProductSearchPage
  ): boolean {
    if (state.context.type === PageType.CATEGORY_PAGE) {
      return (
        page.currentQuery?.query?.value?.indexOf(
          `allCategories:${state.context.id}`
        ) > -1
      );
    }

    if (state.context.type === PageType.CONTENT_PAGE && state.context.id === 'search') {
      return page.currentQuery.query.value.startsWith(`${state.params.query}:`);
    }
    // If context id is /search and we do not send any query parameters we return true so it can be aded
    if (state.context.type === PageType.CONTENT_PAGE && state.context.id === '/search' && !state.params.query) {
      return true;
    }
    return false;
  }
}
