import {CmsConfig, provideConfig} from '@spartacus/core';
import {CHECKOUT_FEATURE, CheckoutRootModule} from '@spartacus/checkout/base/root';
import {NgModule} from '@angular/core';


@NgModule({
  declarations: [],
  imports: [
    CheckoutRootModule
  ],
  providers: [provideConfig(<CmsConfig>{
    featureModules: {
      [CHECKOUT_FEATURE]: {
        module: () => import('@spartacus/checkout/base').then((m) => m.CheckoutModule),
      },
    }
  })
  ]
})
export class CheckoutFeatureModule { }
