<ng-container *ngIf="tableHeads.length > 0">
  <tr *ngIf="isDocumentType">
    <th scope="col">
      <div class="form-check mb-0 text-center">
        <input #selectAllDocuments
               class="form-check-input check-color-inverse position-relative"
               type="checkbox" aria-label="..."
               (change)="onChange(selectAllDocuments.checked)">
      </div>
    </th>
    <th scope="col" class="text-center">
      {{ 'ssab.documents.search.download.title' | cxTranslate }}
    </th>
    <th scope="col" class="is-sortable {{head.className}}" *ngFor="let head of tableHeads">
      <div class="pr-4 mr-3 {{head.labelClass}}">{{ getText(head)|async }}</div>
      <span (click)="sort(head.field)" class="cx-sorting">
          <i class="ssab-icon float-right icon-inline icon-cursor icon-sortable-down icon-sm icon-rotate-180"
             [ngClass]="activeSort?.field === head.field && isSortDescending(head.field) ? '' : 'icon-white'"></i>
          <i class="ssab-icon float-right icon-inline icon-cursor icon-sortable-down icon-sm"
             [ngClass]="activeSort?.field === head.field && !isSortDescending(head.field) ? '' : 'icon-white'"></i>
      </span>
    </th>
  </tr>
  <tr *ngIf="!isDocumentType">
    <th scope="col" class="is-sortable {{tableClass}} {{head.className ? head.className : 'text-space-nowrap'}}" *ngFor="let head of tableHeads">
      <div [ngClass]="{'pr-0' : head.isNotSortable, 'pr-5': !head.isNotSortable && !head.labelClass}" class="{{head.labelClass}}" [innerHTML]="getText(head,'.label')|async">
        <i *ngIf="head.tooltipInfo"
           ngbPopover="{{ head.tooltipInfo | cxTranslate }}"
           triggers="mouseenter:mouseleave"
           container="body"
           placement="bottom"
           popoverClass="popover-sm popover-info"
           class="ssab-icon icon-system-info icon-inline icon-white {{head.className ? 'ml-2': 'ml-3'}}"></i>
      </div>
      <ng-container *ngIf="!head.isNotSortable && activeSort">
        <span (click)="sort(head.field)" class="cx-sorting">
          <i class="ssab-icon float-right icon-inline icon-cursor icon-sortable-down icon-sm icon-rotate-180"
             [ngClass]="activeSort?.field === head.field && isSortDescending(head.field) ? '' : 'icon-white'"></i>
          <i class="ssab-icon float-right icon-inline icon-cursor icon-sortable-down icon-sm"
             [ngClass]="activeSort?.field === head.field && !isSortDescending(head.field) ? '' : 'icon-white'"></i>
        </span>
      </ng-container>

    </th>
  </tr>
</ng-container>




