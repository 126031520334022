<label class="searchbox" [class.dirty]="!!searchInput.value">
  <div class="d-flex align-items-center small">
    <cx-icon
      aria-label="search"
      class="search ssab-icon icon-navigation-search"
      (mousedown)="searchMinCharacters(searchInput.value)"
      (keydown.enter)="searchMinCharacters(searchInput.value)"
      tabindex="0"
    ></cx-icon>

    <input #searchInput
           [placeholder]="labelPlaceHolder"
           aria-label="search"
           (input)="searchMinCharacters(searchInput.value)"
    />
    <cx-icon
      aria-label="reset"
      (mousedown)="onlyClear(searchInput)"
      (keydown.enter)="onlyClear(searchInput)"
      class="reset icon-inside ssab-icon icon-navigation-close"
      tabindex="0"
    ></cx-icon>
  </div>
</label>
