<ng-container *ngIf="aComponentData$ | async as componentData">
  <h3 class="text-center pb-md-5 pb-3">{{componentData.titleText}}</h3>
</ng-container>

<ssab-cx-carousel
  [showBanners]="true"
  [items]="filteredItems$ | async"
  [template]="template"
  [hideIndicators]="true"
  itemWidth="{{sizeOfPicture()}}"
  class="inline-navigation pb-lg-5 pb-md-3"

></ssab-cx-carousel>

<ng-template #template let-item="item">
  <ng-container
    [cxComponentWrapper]="{
      flexType: item.typeCode,
      typeCode: item.typeCode,
      uid: item?.uid,
      properties: {hide: item.hide}
    }"
  >
  </ng-container>
</ng-template>
