export const ssabOccNotificationsConfig = {
  backend: {
    occ: {
      endpoints: {
        getNotifications: 'users/${userId}/notifications?customerEmulationParameter=true',
        dismissNotification: 'users/${userId}/notifications/${uid}?customerEmulationParameter=true'
      }
    }
  }
};
