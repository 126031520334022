import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SsabCart } from '../../model/cart.model';
import { SsabContactSalesAdapter } from './ssab-contactsales.adapter';

@Injectable({
  providedIn: 'root'
})
export class SsabContactSalesConnector {
  constructor(protected adapter: SsabContactSalesAdapter) {
  }

  loadAll(userId: string): Observable<SsabCart[]> {
    return this.adapter.loadAll(userId);
  }

  create(userId: string): Observable<SsabCart> {
    return this.adapter.create(userId);
  }
}
