import {DIALOG_TYPE, LayoutConfig} from '@spartacus/storefront';
import {SsabConsignmentListComponent} from './ssab-consignment-list.component';
import {SsabProduct} from '../../../../model/product.model';

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    CONSIGNMENT_LIST = 'CONSIGNMENT_LIST',
  }
}

export const consignmentListLayout: LayoutConfig = {
  launch: {
    CONSIGNMENT_LIST: {
      inlineRoot: true,
      component: SsabConsignmentListComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};

export interface SsabConsignmentListDialogData {
  product: SsabProduct;
  batchesID: string[];
}
