<div class="text-white cx-page-header-title">
  <h5 class="pb-sm-4 pb-2">
    <a (click)="back()">
      <span class="ssab-icon icon-navigation-chevron-left icon-inline icon-white"></span>
      <span class="pl-2">{{ 'ssab.back' | cxTranslate }}</span>
    </a>
  </h5>

  <h2>{{ product?.name }}</h2>
</div>

