import {SsabMiniCartBaseComponent} from '../ssab-mini-cart-base.component';
import {Component} from '@angular/core';


@Component({
  selector: 'ssab-cx-minicart-normal',
  templateUrl: './ssab-mini-cart-normal.component.html'
})
export class SsabMiniCartNormalComponent extends SsabMiniCartBaseComponent {
}
