import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {SsabCertificateDocument, SsabDocumentIdentifier} from "../../model/document.model";

@Injectable({
  providedIn: 'root',
})
// saving selected products in localstorage
export class SsabScannerService {
  private cartDataSubject: BehaviorSubject<SsabDocumentIdentifier[]> = new BehaviorSubject<SsabDocumentIdentifier[]>(this.getInitialCartData());
  public cartData$: Observable<SsabDocumentIdentifier[]> = this.cartDataSubject.asObservable();

  constructor() {
  }

  getInitialCartData(): SsabDocumentIdentifier[] {
    const initialData = JSON.parse(localStorage.getItem('productList') || '[]');
    return initialData;
  }

  updateCartData(data: SsabDocumentIdentifier[], message?: string): void {
    this.cartDataSubject.next(data);
    this.saveToLocalStorage(data);
  }

  private saveToLocalStorage(data: SsabDocumentIdentifier[]): void {
    localStorage.setItem('productList', JSON.stringify(data));
  }

  addToCart(doc: SsabDocumentIdentifier): void {
    const currentCartData = this.cartDataSubject.value;
    if (currentCartData.filter(data => data.documentId == doc.documentId).length === 0) {
      const updatedCartData = [...currentCartData, doc];
      this.updateCartData(updatedCartData);
    }
  }

  removeFromCart(doc: SsabDocumentIdentifier): void {
    const currentCartData = this.cartDataSubject.value;
    const updatedCartData = currentCartData.filter(
      (item) => item.documentId !== doc.documentId
    );
    this.updateCartData(updatedCartData);
  }

  clearList(): void {
    this.cartDataSubject.next([]);
  }
}
