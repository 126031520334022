import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {SiteContextActions, withdrawOn} from '@spartacus/core';
import {Observable} from 'rxjs';
import {concatMap, map} from 'rxjs/operators';
import {LOAD_GLOBAL_CONFIGURATION, SsabLoadGlobalConfigurationSuccess} from "./ssab-globalconfiguration-actions";
import {SsabGlobalconfigurationConnector} from "./ssab-globalconfiguration.connector";

@Injectable()
export class AimoGlobalConfigurationEffects {
  private contextChange$;
  loadConfiguration$: Observable<| SsabLoadGlobalConfigurationSuccess>;

  constructor(protected actions$: Actions, protected globalconfigurationConnector: SsabGlobalconfigurationConnector) {

    this.contextChange$ = this.actions$.pipe(
      ofType(
        SiteContextActions.SET_ACTIVE_BASE_SITE
      )
    );

    this.loadConfiguration$ = createEffect(() =>
      this.actions$.pipe(
        ofType(LOAD_GLOBAL_CONFIGURATION),
        concatMap(() => {
          return this.globalconfigurationConnector.getGlobalConfiguration().pipe(
            map(conf => new SsabLoadGlobalConfigurationSuccess(conf))
          );
        }),
        withdrawOn(this.contextChange$)
      ),
    );
  }
}
