import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, OnInit, Output, Renderer2,} from '@angular/core';
import {FacetListComponent, FacetService} from '@spartacus/storefront';
import {map} from "rxjs/operators";
import {SsabFacet} from "../../../../../model/product.model";

@Component({
  selector: 'ssab-cx-facet-list',
  templateUrl: './ssab-facet-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabFacetListComponent extends FacetListComponent implements OnInit {

  @Output() afterViewInit = new EventEmitter<boolean>(false);

  constructor(
    protected facetService: FacetService,
    protected elementRef: ElementRef,
    protected renderer: Renderer2
  ) {
    super(facetService, elementRef, renderer);
  }

  ngOnInit(): void {
    this.facetList$ = this.facetService.facetList$.pipe(
      map(fl => (
        {
          ...fl,
          facets: fl.facets
            ?.map((f: SsabFacet) => (
              {
                ...f,
                values: f.values
                  .filter(v =>
                    f.rangeMax !== undefined || v.count > 0
                  )
              }
            ))
            .filter(f => f.values.length > 0)
        }))
    );
  }
}
