import {Component, OnDestroy, OnInit} from '@angular/core';
import {MsalService} from '../services/msal.service';
import {AuthenticationResult} from '@azure/msal-browser';
import {RoutingService} from '@spartacus/core';
import {AZURE_LOGIN_USERID_ASM, SsabAuthService} from '../../../service/user-auth/ssab-auth.service';
import {SsabCsAgentAuthService} from '../../../asm/ssab-cs-agent-auth.service';
import {AsmEnablerService} from '@spartacus/asm/root';
import {Subscription} from "rxjs";

@Component({
  selector: 'ssab-process-azure-login',
  template: ''
})
export class SsabProcessAzureLoginComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  
  constructor(
    private azureAuthService: MsalService,
    private authService: SsabAuthService,
    private routingService: RoutingService,
    private csAgentAuthService: SsabCsAgentAuthService,
    private asmEnablerService: AsmEnablerService) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.azureAuthService.handleRedirectObservable().subscribe({
        next: (result: AuthenticationResult) => {
          if (result) {
            this.azureAuthService.setActiveAccount(result.account);
            if (this.asmEnablerService.isEnabled()) {
              // asm login
              this.csAgentAuthService.authorizeCustomerSupportAgentAzure(AZURE_LOGIN_USERID_ASM, result.accessToken)
            } else {
              // normal login
              this.authService.commerceLoginForAzureAuthentication(result);
            }
          }
        },
        error: (error) => console.error(error)
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
