import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';
import {SsabProductSummaryComponent} from './ssab-product-summary.component';
import {CarouselModule, OutletModule, ProductImagesModule, ProductSummaryModule} from '@spartacus/storefront';
import { AddToCartModule } from '@spartacus/cart/base/components/add-to-cart';
import {SsabAddToCartModule} from '../../cart/add-to-cart/ssab-add-to-cart.module';
import {SsabItemStepperModule} from '../product-list/item-stepper/ssab-item-stepper.module';
import {SsabProductPriceModule} from '../product-price-data/ssab-product-price.module';
import { SsabProductAttributesComponent } from './ssab-product-attributes.component';
import { SsabProductIntroModule } from '../product-intro/ssab-product-intro.module';
import { SsabProductAvailabilityModule } from '../../subscriptions/ssab-product-availability-modal/ssab-product-availability-modal.module';
import {SsabProductImagesModule} from '../product-images/ssab-product-images.module';

@NgModule({
    imports: [CommonModule,
        SsabProductIntroModule,
        SsabProductImagesModule,
        OutletModule,
        I18nModule,
        AddToCartModule,
        SsabAddToCartModule,
        SsabItemStepperModule,
        SsabProductPriceModule,
        SsabProductAvailabilityModule,
        ConfigModule.withConfig({
            cmsComponents: {
                ProductSummaryComponent: {
                    component: SsabProductSummaryComponent
                }
            },
        } as CmsConfig), ProductImagesModule, CarouselModule,
    ],
    declarations: [SsabProductSummaryComponent, SsabProductAttributesComponent],
    exports: [SsabProductSummaryComponent, SsabProductAttributesComponent]
})
export class SsabProductSummaryModule extends ProductSummaryModule {
}
