import {DIALOG_TYPE, LayoutConfig} from '@spartacus/storefront';
import {SsabOrderEntry} from '../../../../model/cart.model';
import {SsabCartNewAddressComponent} from './ssab-cart-new-address.component';
import {SsabUser} from '../../../../model/user.model';
import {Observable} from 'rxjs';
import {Address} from '@spartacus/core';

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    CART_NEW_ADDRESS = 'CART_NEW_ADDRESS',
  }
}

export const cartNewAddressLayout: LayoutConfig = {
  launch: {
    CART_NEW_ADDRESS: {
      inlineRoot: true,
      component: SsabCartNewAddressComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};

export interface SsabCartNewAddressDialogData {
  newAddress: Address;
}
