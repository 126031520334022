import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {ItemCounterModule} from '@spartacus/storefront';
import {SsabItemStepperComponent} from './ssab-item-stepper.component';
import {DisableControlDirective} from '../../../../shared/directives/ssab-disable-inputs.directive';
import {NumbersOnlyDirective} from '../../../../shared/directives/ssab-only-numbers-input.diretive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [SsabItemStepperComponent, DisableControlDirective, NumbersOnlyDirective],
  exports: [SsabItemStepperComponent],
})
export class SsabItemStepperModule extends ItemCounterModule {
}
