<div class="header-block " [ngClass]="showInCartList ? '': 'summary text-white'">
  <div class="row">
    <div *ngIf="!readonly" [ngClass]="showInCartList? 'col-sm-4 col-12': 'col-lg-7 col-xxl-8 col-md-6 col-12'">
      <div class="field-header">
        {{'ssab.cart.header.summary'|cxTranslate}}
      </div>
    </div>
    <div class="order-totals align-items-end col-auto ml-auto">
      <table>
        <tr>
          <td class="text-bold pb-3">
            {{'ssab.cart.header.price.total'|cxTranslate}}
          </td>
          <td class="pb-3">{{cart.subTotal?.formattedValue}}</td>
        </tr>
        <tr *ngIf="!cart.consignment && cart.deliveryCost">
          <td class="text-bold pb-3">
            <ng-container
              *ngIf="cart.deliveryCostSurcharge; else regularDeliveryCost">{{'ssab.cart.header.price.deliverySurcharge'|cxTranslate}}</ng-container>
            <ng-template #regularDeliveryCost>
              {{'ssab.cart.header.price.deliveryCost'|cxTranslate}}
            </ng-template>
          </td>
          <td class="pb-3">{{cart.deliveryCost?.formattedValue}}</td>
        </tr>

        <tr *ngIf="cart.bundleSplitTotal?.value;">
          <td class="text-bold pb-3">
            {{'ssab.cart.header.price.bundleSplitTotal'|cxTranslate}}
          </td>
          <td class="pb-3">{{cart.bundleSplitTotal?.formattedValue}}</td>
        </tr>

        <tr>
          <td class="text-bold pb-3">
            {{'ssab.cart.header.price.totalTax'|cxTranslate}}
          </td>

          <td class="pb-3">
            {{cart.totalTax?.formattedValue}}<span
            *ngIf="cart.totalTaxInBasestoreCurrency && cart.totalTaxInBasestoreCurrency.formattedValue"
            innerHTML="({{ 'ssab.cart.checkout.exchange.value' |cxTranslate :{value: cart.totalTaxInBasestoreCurrency?.formattedValue} }})"></span>
          </td>
        </tr>

        <ng-container *ngIf="cart.currencyConversions && cart.currencyConversions.length > 0 && false">
          <tr *ngFor="let cc of cart.currencyConversions">
            <td class="pb-3">
              {{'ssab.cart.checkout.exchange.fromTo' |cxTranslate :{currencyFrom: cc.currencyFrom, currencyTo: cc.currencyTo} }}
            </td>
            <td class="pb-3">
              <div [innerHTML]="('ssab.cart.checkout.exchange.value' |cxTranslate :{value: cc.value})"></div>
            </td>
          </tr>
        </ng-container>

        <tr>
          <td class="text-uppercase total-sum text-bold pr-5">
            {{'ssab.cart.header.price.totalPriceWithTax'|cxTranslate}}
          </td>
          <td class="total-sum text-bold field-value">
            {{cart.totalPriceWithTax?.formattedValue}}
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
