<!-- Template for days -->
<ng-template #customHeaderDay let-date let-currentMonth="currentMonth" let-today="today" let-disabled="disabled"
             let-selected="selected">
  <ssab-datepicker-day
    [today]="today"
    [disabled]="!isDateAllowed(date)"
    [selected]="selected"
    [hidden]="date.month !== currentMonth"
    [hasContent]="isDateAllowed(date)"
    [date]="date"
  >
  </ssab-datepicker-day>
</ng-template>

<div class="cx-item-list pb-0">
  <div class="item-header">
    <h3 *ngIf="!orderConfirmation">{{'ssab.cart.checkout.consignment.title' |cxTranslate}}</h3>
    <h3 *ngIf="orderConfirmation">{{'ssab.cart.orderconfirmation.consignment.title' |cxTranslate}}</h3>
    <p class="mb-3 pt-2 text-paragraph-lg d-none d-md-block">{{'ssab.order.header.amount' | cxTranslate : {value: cart.entries.length} }}</p>
    <label class="pt-2 d-block d-md-none">{{'ssab.order.header.amount' | cxTranslate : {value: cart.entries.length} }}</label>
  </div>

  <div class="cx-item-list-table pb-0">
    <div *ngIf="hasHeader" class="pt-2">
      <div class="cx-item-list-header row text-bold col-no-margin">
        <div [ngClass]="showPricesOrConsignmentPricesStock? 'cx-item-list-desc col-4 row' : 'cx-item-list-desc col-5 row'">
          <div class="col-2">
          </div>
          <div class="col-9">
            {{ 'ssab.cart.item.description' | cxTranslate }}
          </div>
        </div>
        <div class="col-8 ml-auto pr-0">
          <div class="row col-12 no-gutters">
            <div class="col-3">
              {{ 'ssab.cart.item.materialID' | cxTranslate }}
            </div>
            <div class="col-2">
              {{ 'ssab.cart.item.quantity' | cxTranslate }}
            </div>
            <div class="col-1">
              {{ 'ssab.cart.item.unit' | cxTranslate }}
            </div>
            <div class="col-2">
              {{ 'ssab.cart.item.warehouse' | cxTranslate }}
            </div>
            <ng-container *ngIf="showPricesOrConsignmentPricesStock">

              <div class="cx-item-list-price col-2">
                {{ 'ssab.cart.item.unitPrice' | cxTranslate }}
              </div>
              <div class="cx-item-list-price col-2">
                {{ 'ssab.cart.item.totalPrice' | cxTranslate }}
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="cx-item-list-row" *ngFor="let consignmentWrapper of cart.consignmentEntries">
      <ssab-cx-cart-item-consignment
        [orderConfirmation]="orderConfirmation"
        [items]="consignmentWrapper.batches"
        [readonly]="readonly"
        [showPricesOrConsignmentPricesStock]="showPricesOrConsignmentPricesStock"
      ></ssab-cx-cart-item-consignment>
    </div>
  </div>

</div>
