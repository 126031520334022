<ng-container *ngIf="user$ | async as user">
  <ng-container *ngIf="configuration$ | async as config">
    <ng-container *ngIf=" config.displayInches">

      <ng-container *ngIf="myAccountStyle; else plpStyle">
        <h4 class="pt-2 pb-3 text-opacity-60">{{'ssab.user.showInches.title'|cxTranslate}}</h4>
        <div class="row">
          <div class="col-8 col-md-9">
            {{ 'ssab.user.showInches.toggle' | cxTranslate }}
            <span class="text-sm">{{'ssab.user.showInches.description' | cxTranslate}}</span>
          </div>
          <div class="vertical-align-item col-4 col-md-3">
            <i class="ssab-icon icon-inline icon-cursor"
               [ngClass]="user.showInches ? 'icon-switch-blue': 'icon-switch'"
               (click)="toggleShowInches(!user.showInches, user.customerId)"></i>
          </div>
        </div>
      </ng-container>
      <ng-template #plpStyle>
        <div class="d-flex cx-show-inches-plp">
          <span class="text-bold text-primary"> {{'ssab.user.showInches.title' | cxTranslate}}</span>
          <div class="vertical-align-item ml-auto">
            <i class="ssab-icon icon-inline icon-cursor"
               [ngClass]="user.showInches ? 'icon-switch-blue': 'icon-switch'"
               (click)="toggleShowInches(!user.showInches, user.customerId)"></i>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
