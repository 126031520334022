import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserIdService} from '@spartacus/core';
import {switchMap, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AsmEnablerService} from '@spartacus/asm/root';

const USERS_CURRENT = '/users/current';

@Injectable({providedIn: 'root'})
export class SsabAsmAwareHttpInterceptor implements HttpInterceptor {

  constructor(protected userIdService: UserIdService, protected asmEnablerService: AsmEnablerService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isAsmMode() && request.url.indexOf(USERS_CURRENT) >= 0) {
      // 'current' cannot be used in ASM mode. We need to send emulated customer's id
      this.userIdService.getUserId().pipe(
        take(1),
        switchMap(user => {
          const clone = request.clone({
            url: request.url.replace(USERS_CURRENT, '/users/' + user),
          });
          return next.handle(clone);
        })
      );
    }
    return next.handle(request);
  }

  private isAsmMode():boolean {
    return this.asmEnablerService.isEnabled();
  }
}
