import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconModule} from '@spartacus/storefront';
import {I18nModule, provideDefaultConfig, UrlModule} from '@spartacus/core';
import {RouterModule} from '@angular/router';
import {SsabConsignmentListComponent} from './ssab-consignment-list.component';
import {SsabFilterModule} from '../../../../shared/filter/ssab-filter.module';
import {consignmentListLayout} from './ssab-consignment-list-layout.config';
import {SsabBundleSplitToggleModule} from '../../../my-account/bundle-split/ssab-bundle-split-toggle.module';
import {SsabItemStepperModule} from '../../../product/product-list/item-stepper/ssab-item-stepper.module';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    I18nModule,
    UrlModule,
    RouterModule,
    SsabFilterModule,
    SsabBundleSplitToggleModule,
    SsabItemStepperModule,
  ],
  declarations: [SsabConsignmentListComponent],
  exports: [SsabConsignmentListComponent],
  providers: [provideDefaultConfig(consignmentListLayout)]
})
export class SsabConsignmentListModule {
}
