import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CmsService, ConfigModule} from '@spartacus/core';
import {adjustRouterConfig, ssabRoutingConfig} from './ssab-routing.config';
import {Router} from '@angular/router';
import { SsabCmsService } from './ssab-cms.service';


@NgModule({
  imports: [
    ConfigModule.withConfig({
      routing: ssabRoutingConfig.routing
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [Router],
      useFactory: adjustRouterConfig,
    },
    {
      provide: CmsService,
      useClass: SsabCmsService
    }
  ]
})
export class SsabRoutingModule {}
