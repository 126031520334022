<a
  class="btn btn-block btn-link-no-underline text-left"
  [routerLink]="{ cxRoute: 'login' } | cxUrl"
>
  <i class="fas fa-chevron-left pr-1"></i>
  {{ 'common.back' | cxTranslate }}</a
>
<div class="text-center text-primary pb-5">
  <i class="fas fa-unlock-alt fa-6x"></i>
</div>

<p>
  {{'forgottenPassword.description' | cxTranslate}}
</p>

<form
  (ngSubmit)="onSubmit()" [formGroup]="form"
>
  <div class="form-group input-group-icon pb-4">
      <span class="ssab-icon icon-system-profile"></span>
      <input
        type="email"
        class="form-control"
        placeholder="{{
          'forgottenPassword.emailAddress.placeholder' | cxTranslate
        }}"
        formControlName="userEmail"
      />
      <cx-form-errors
        [control]="form.get('userEmail')"
      ></cx-form-errors>
  </div>

  <button type="submit" class="btn btn-block btn-secondary">
    {{ 'forgottenPassword.resetPassword' | cxTranslate }}
  </button>
  <a
    class="btn btn-block btn-link"
    [routerLink]="{ cxRoute: 'login' } | cxUrl"
  >{{ 'common.cancel' | cxTranslate }}</a
  >
</form>
