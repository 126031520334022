import {Component, OnInit} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {CmsService, ContentSlotComponentData} from '@spartacus/core';
import {SsabCMSInformationLinkComponent} from '../../../model/cms.model';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

@Component({
  selector: 'ssab-cx-information-link',
  templateUrl: './ssab-information-link.component.html'
})
export class SsabInformationLinkComponent {
  componentData$: Observable<SsabCMSInformationLinkComponent>;
  items$: Observable<Observable<ContentSlotComponentData>[]>;

  constructor(private componentData: CmsComponentData<SsabCMSInformationLinkComponent>, private cmsService: CmsService) {
    this.componentData$= this.componentData.data$.pipe(
      filter(Boolean)
    );
    this.items$ = this.componentData$.pipe(
      map((data) => data.columns.trim().split(' ')),
      map((codes) => codes?.map((code) => this.cmsService.getComponentData(code)))
    );
  }
}
