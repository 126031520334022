import {ChangeDetectionStrategy, Component, Input,} from '@angular/core';
import {SsabCertificateDocument} from '../../../../model/document.model';
import {SsabDocumentsBaseComponent} from '../documents/ssab-documents-base-component';
import {LanguageService} from '@spartacus/core';
import {HttpParams} from '@angular/common/http';
import {SsabGlobalconfigurationService} from "../../../../service/general/configuration/ssab-globalconfiguration.service";
import {Observable} from "rxjs";

@Component({
  selector: 'ssab-cx-certificates',
  templateUrl: './ssab-certificates.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabCertificatesComponent extends SsabDocumentsBaseComponent {
  @Input() certificateDocuments: SsabCertificateDocument[];

  constructor(protected globalConfigurationService: SsabGlobalconfigurationService,) {
    super(globalConfigurationService);
  }

  getCertificateParams(certificate: SsabCertificateDocument): HttpParams {
    let params = new HttpParams();
    params = params.set('pdfLink', '');
    params = params.set('certificateNumber', certificate.certificateId);
    params = params.set('deliveryNumber', certificate.deliveryNumber);
    params = params.set('customerReference', certificate.customerRef);
    params = params.set('invoiceID', certificate.invoiceNumber);
    params = params.set('orderID', certificate.orderNumber);
    params = params.set('materialID', certificate.materialId);
    params = params.set('heatID', certificate.heatId);
    params = params.set('grade', certificate.grade);
    params = params.set('dimensions', certificate.dimension);
    params = params.set('documentDate', certificate.documentDate.toString());
    params = params.set('documentId', certificate.documentId);
    return params;
  }

  getDateFormat(): Observable<string> {
    return this.globalConfigurationService.getDateFormat();
  }
}

