import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import {SsabGlobalMessageComponent} from './ssab-global-message.component';
import {IconModule} from '@spartacus/storefront';
import {SsabPipesModule} from '../utils/safe-html/ssab-pipes.module';

@NgModule({
  imports: [CommonModule, IconModule, I18nModule, SsabPipesModule],
  declarations: [SsabGlobalMessageComponent],
  exports: [SsabGlobalMessageComponent],
})
export class SsabGlobalMessageModule{}
