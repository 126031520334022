<div class="ssab-modal-md ssab-modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header cx-dialog-header">
        <h3 class="modal-title">
          {{ 'ssab.session.expired.title' | cxTranslate }}
        </h3>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="confirm()"
        >
            <span aria-hidden="true">
              <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
            </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="cx-row">
          <h3 class="cx-confirmation">
            {{ 'ssab.session.expired.body' | cxTranslate }}
          </h3>
        </div>
        <div class="cx-row pt-5">
          <div class="cx-btn-group row">
            <div class="col-12">
              <button class="btn btn-block btn-primary btn-default-height" (click)="confirm()">
                {{ 'ssab.session.expired.button' | cxTranslate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
