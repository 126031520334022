import {Action} from '@ngrx/store/src/models';

export const SSAB_GTM_PRODUCT = 'SSAB_[GTM] Product';

export class SsabGtmProduct implements Action {
  readonly type = SSAB_GTM_PRODUCT;

  constructor(
    public products: string[],
    public event: string,
    public pageLabel: string,
    public isAction?: boolean,
    public quantity?: number,
    public runAfter?: boolean
  ) {
  }
}
