<scanner-header-main title="{{'ssab.scanner.qr.title'|cxTranslate}}" [hideBack]="true"></scanner-header-main>
<div class="bg-white pb-4">
  <div class="text-center pt-3 pb-3">
    <button class="btn text-uppercase" (click)="openScannerHowToScanModal()">
      <i class="ssab-icon icon-system-info icon-inline mr-2"></i>
      <span>{{'ssab.scanner.home.title1'|cxTranslate}}</span>
    </button>

  </div>

  <app-qrscanner (valueChange)="handleInputValueChange($event)"></app-qrscanner>

  <hr class="ml-3 mr-3"/>

  <app-search-input [inputValue]="ocrResult" (valueChange)="handleInputValueChange($event)"></app-search-input>
</div>
