import {CmsConfig, I18nModule, provideDefaultConfig} from '@spartacus/core';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SsabConfirmationComponent} from './ssab-confirmation.component';
import {SsabOrderConfirmationModule} from '../ssab-order-confirmation.module';
import {OrderConfirmationGuard} from '@spartacus/order/components';


@NgModule({
    imports: [
        CommonModule,
        I18nModule,
        SsabOrderConfirmationModule,
    ],
    providers: [
        provideDefaultConfig({
            cmsComponents: {
                OrderConfirmationComponent: {
                    component: SsabConfirmationComponent,
                    guards: [OrderConfirmationGuard],
                },
            },
        } as CmsConfig),
    ],
    declarations: [SsabConfirmationComponent],
    exports: [SsabConfirmationComponent]
})
export class SsabConfirmationModule {}
