import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconModule} from '@spartacus/storefront';
import {I18nModule, provideDefaultConfig, UrlModule} from '@spartacus/core';
import {SsabCollectedItemsComponent} from './ssab-collected-items.component';
import {SsabItemStepperModule} from '../../../product/product-list/item-stepper/ssab-item-stepper.module';
import {RouterModule} from '@angular/router';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {collectedItemsLayout} from './ssab-collected-items-layout.config';
import {SsabCollectedItemModule} from '../collected-item/ssab-collected-item.module';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        I18nModule,
        UrlModule,
        SsabItemStepperModule,
        RouterModule,
        NgSelectModule,
        FormsModule,
        SsabCollectedItemModule
    ],
    declarations: [SsabCollectedItemsComponent],
    exports: [SsabCollectedItemsComponent],
    providers: [provideDefaultConfig(collectedItemsLayout)]
})
export class SsabCollectedItemsModule {
}
