import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconModule} from '@spartacus/storefront';
import {I18nModule, provideDefaultConfig, UrlModule} from '@spartacus/core';
import {RouterModule} from '@angular/router';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import { SsabProductAvailabilityModalComponent } from './ssab-product-availability-modal.component';
import {productAvailabilityModalLayout} from './ssab-product-availability-modal-layout.config';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        I18nModule,
        UrlModule,
        RouterModule,
        NgSelectModule,
        FormsModule,
    ],
    declarations: [SsabProductAvailabilityModalComponent],
    exports: [SsabProductAvailabilityModalComponent],
  providers: [provideDefaultConfig(productAvailabilityModalLayout)]
})
export class SsabProductAvailabilityModule {
}
