import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ConfigModule, NotAuthGuard} from '@spartacus/core';
import {SsabProcessAzureLoginComponent} from '../components/ssab-process-azure-login.component';
import {PASSWORD_RESET_PATH, PROCESS_LOGIN_PATH} from '../model/msal.constants';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        data: {
          cxRoute: 'processLogin'
        },
        path: null,
        canActivate: [NotAuthGuard],
        component: SsabProcessAzureLoginComponent
      }
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          processLogin: {
            paths: [PROCESS_LOGIN_PATH],
            protected: false
          },
          passwordResetPath: {
            paths: [PASSWORD_RESET_PATH],
            protected: false
          }
        }
      }
    })
  ]
})
export class AzureAuthenticationRoutingModule {
}
