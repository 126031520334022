import {LanguageService} from "@spartacus/core/src/site-context/facade/language.service";

export class DateUtils {
  static stdTimezoneOffset(d: Date): number {
    const jan = new Date(d.getFullYear(), 0, 1);
    const jul = new Date(d.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  }

  static isDstObserved(d: Date): boolean {
    return d.getTimezoneOffset() < DateUtils.stdTimezoneOffset(d);
  }

  static getNow(): Date {
    const d = new Date();
    const utc = d.getTime() + (d.getTimezoneOffset() * 60000);
    return new Date(utc + (3600 * 1000 * (DateUtils.isDstObserved(d) ? 3 : 2)));
  }

  static convertDate(date: any): Date {
    if (typeof (date) === 'string') {
      return new Date(date.split('+')[0]);
    }

    if (date instanceof Date) {
      return date;
    }

    return new Date(date);
  }

  // https://dev.to/riversun/introducing-a-handy-javascript-date-formatting-function-5cd7
  public static convertUIDate(format: string, date: Date, opts?): string {
    if (!format) {
      return '';
    }
    if (!date) {
      date = new Date();
    }
    opts = opts || {};

    let _days = opts.days;

    if (!_days) {
      _days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    }

    let _months = opts.months;

    if (!_months) {
      _months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    }

    const pad = (number: number, strDigits: string, isUnpad?: boolean): string => {
      const strNum = Math.abs(number).toString();
      if (!isUnpad && strNum.length > strDigits.length) {
        return strNum;
      } else {
        return ('0000' + strNum).slice(-strDigits.length);
      }
    };

    const timezone = (date: Date, letter: string): string => {
      const chunk = [];
      const offset = -date.getTimezoneOffset();
      chunk.push(offset === 0 ? 'Z' : offset > 0 ? '+' : '-');//add Z or +,-
      if (offset === 0) return chunk.join('');
      chunk.push(pad(Math.floor(offset / 60), '00'));//hour
      if (letter === 'X') return chunk.join('');
      if (letter === 'XXX') chunk.push(':');
      chunk.push(pad((offset % 60), '00'));//min
      return chunk.join('');
    };

    const DELIM = '\0\0';
    const escapeStack = [];

    const escapedFmtStr = format.replace(/'.*?'/g, m => {
      escapeStack.push(m.replace(/'/g, ''));
      return `${DELIM}${escapeStack.length - 1}${DELIM}`;
    });

    const formattedStr = escapedFmtStr
      .replace(/y{4}|y{2}/g, m => pad(date.getFullYear(), m, true))
      .replace(/M{3}/g, m => _months[date.getMonth()])
      .replace(/M{1,2}/g, m => pad(date.getMonth() + 1, m))
      .replace(/M{1,2}/g, m => pad(date.getMonth() + 1, m))
      .replace(/d{1,2}/g, m => pad(date.getDate(), m))
      .replace(/H{1,2}/g, m => pad(date.getHours(), m))
      .replace(/h{1,2}/g, m => {
        const hours = date.getHours();
        return pad(hours === 0 ? 12 : hours > 12 ? hours - 12 : hours, m);
      })
      .replace(/a{1,2}/g, m => date.getHours() >= 12 ? 'PM' : 'AM')
      .replace(/m{1,2}/g, m => pad(date.getMinutes(), m))
      .replace(/s{1,2}/g, m => pad(date.getSeconds(), m))
      .replace(/S{3}/g, m => pad(date.getMilliseconds(), m))
      .replace(/[E]+/g, m => _days[date.getDay()])
      .replace(/[Z]+/g, m => timezone(date, m))
      .replace(/X{1,3}/g, m => timezone(date, m));

    const unescapedStr = formattedStr.replace(new RegExp(`${DELIM}\\d+${DELIM}`, 'g'),
      m => {
        const unescaped = escapeStack.shift();
        return unescaped.length > 0 ? unescaped : '\'';
      });

    return unescapedStr;
  }

  static convertDateToOccString(language: LanguageService, date: Date): string {
    return this.convertDateToString(date, "-") + 'T00:00:00+0000';//UTC time
  }

  private static convertDateToString(d: Date, delimeter: string): string {
    return d.getFullYear() + delimeter + ("0" + (d.getMonth() + 1)).slice(-2) + delimeter + ("0" + d.getDate()).slice(-2);
  }

  /**
   *  Returns an array of dates between the two dates
   */
  static getDates(startDate: Date, endDate: Date): Date[] {
    const dates = [];
    let currentDate = startDate;
    const addDays = function (days) {
      const date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    while (currentDate < endDate) {
      dates.push(currentDate);
      currentDate = addDays.call(currentDate, 1);
    }
    return dates;
  }

  /**
   * Get the first day of month and returns the date of previous Monday or given date if that day is Monday
   * @param date
   */
  static getFirstMondayDate(date: Date) {
    const firstDayOfMonth: Date = this.getFirstDayOfMonth(date);
    return new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth(), firstDayOfMonth.getDate() - DateUtils.diffToSunday(firstDayOfMonth));
  }

  /**
   * Get the last day of month and returns the date of next Sunday or given date if that is Sunday
   * @param date
   */
  static getLastSundayDate(date: Date) {
    const lastDayOfMonth: Date = this.getLastDayOfMonth(date);
    return new Date(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth(), lastDayOfMonth.getDate() + DateUtils.diffToSunday(lastDayOfMonth));
  }

  /**
   * Gets the first day of month
   * @param date date
   */
  static getFirstDayOfMonth(date: Date) {

    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  /**
   * Gets the last day of month
   * @param date date
   */
  static getLastDayOfMonth(date: Date) {
    // Get first day of next month and roll back one day
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  /**
   * Amount of days to same weeks Monday
   * @param date Date
   */
  static diffToMonday(date: Date) {
    const dayOfWeek: number = date.getDay();
    return dayOfWeek === 0 ? 6 : dayOfWeek - 1;
  }

  /**
   * Amount of days to same weeks Sunday
   * @param date Date
   */
  static diffToSunday(date: Date) {
    const dayOfWeek: number = date.getDay();
    return dayOfWeek === 0 ? 0 : 7 - dayOfWeek;
  }

  static getISO8601WeekNo(dt: Date) {
    const tdt: Date = new Date(dt.valueOf());
    const dayn: number = (dt.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    const firstThursday: number = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
    }
    return 1 + Math.ceil((firstThursday - tdt.valueOf()) / 604800000);
  }

}
