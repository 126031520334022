<ssab-floating-scrollbar>
  <div class="table-responsive" *ngIf="transportDocuments && transportDocuments.length> 0; else noTransportDocuments">
    <table class="table cx-documents-table">
      <thead table-head
             (sortChange)="sort($event)"
             [activeSort]="activeSort"
             [label]="'ssab.documents.search.'"
             [tableHeadType]="documentTypes.Transport"
             (selectAllDocuments)="checkAllDocuments($event)">
      </thead>
      <tbody>
      <tr *ngFor="let transportation of transportDocuments">
        <td>
          <div class="form-check mb-0 text-center">
            <input #selectedDocument
                   class="form-check-input position-relative"
                   type="checkbox" aria-label="..."
                   [checked]="selectAllDocuments"
                   (change)="onChange(selectedDocument.checked, transportation)">
          </div>
        </td>
        <td class="text-center">
          <i class="ssab-icon icon-system-download  w-auto icon-cursor"
             (click)="onDownload(transportation)"></i>
        </td>
        <td>{{transportation.orderNumber}}</td>
        <td>{{transportation.customerRef}}</td>
        <td>{{transportation.documentNumber}}</td>
        <td>{{transportation.sendingUnit}}</td>
        <td>{{transportation.documentDate ? (convertUIDate(transportation.documentDate)|cxDate: (getDateFormat()|async)) : ''}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</ssab-floating-scrollbar>
<ng-template #noTransportDocuments>
  <div *ngIf="activeSort"
       class="empty-data-div row no-margin text-center">
    <div [ngClass]="'col-6'"
    >
      <h4 class="text-primary">{{ 'ssab.documents.search.noDocuments.' + (documentTypes.Transport | lowercase) | cxTranslate }}</h4>
    </div>
  </div>
</ng-template>
