import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {OccEndpointsService} from '@spartacus/core';

@Injectable()
export class SsabAsmAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService
  ) {
  }

  public refreshEmulatedUser(userId: string): Observable<any> {
    const url = this.occEndpointsService.buildUrl('refreshEmulatedUser', {urlParams: {userId}});
    return this.http.get(url);
  }
}
