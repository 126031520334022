import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {OccEndpointsService} from '@spartacus/core';
import {SsabOccEndpointsService} from './ssab-occ-endpoints.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: OccEndpointsService,
      useClass: SsabOccEndpointsService
    }
  ]
})
export class SsabOccEndpointsModule { }
