import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {CmsComponentData, HamburgerMenuService} from '@spartacus/storefront';
import {CmsNavigationComponent} from '@spartacus/core';
import {SsabNavigationService} from '../../../service/navigation/ssab-navigation.service';
import {SsabNavigationNode} from '../../../model/cms.model';
import {Observable, Subscription} from 'rxjs';
import {SsabGlobalconfigurationService} from '../../../service/general/configuration/ssab-globalconfiguration.service';
import {SsabGlobalConfiguration} from '../../../model/misc.model';
import {EventService} from '@spartacus/core';
import {SsabCheckoutDeliveryAddressClearedEvent} from '../../../checkout/events/ssab-checkout-delivery-address-cleared-event';

@Component({
  selector: 'ssab-cx-category-navigation',
  templateUrl: './ssab-category-navigation.component.html'
})
export class SsabCategoryNavigationComponent implements OnInit, OnDestroy {

  node$: Observable<SsabNavigationNode>;
  data$: Observable<CmsNavigationComponent>;
  configuration$: Observable<SsabGlobalConfiguration>;
  subscriptions: Subscription = new Subscription();

  constructor(protected hamburgerMenuService: HamburgerMenuService,
              protected componentData: CmsComponentData<CmsNavigationComponent>,
              protected service: SsabNavigationService,
              private globalConfigurationService: SsabGlobalconfigurationService,
              private eventService: EventService,
              protected cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.eventService.get(SsabCheckoutDeliveryAddressClearedEvent).subscribe((e) => {
        this.configuration$ = this.globalConfigurationService.getGlobalConfiguration();
        this.cdr.detectChanges();
      })
    );
    this.node$ = this.service.getNavigationNode(
      this.componentData.data$
    );
    this.data$ = this.componentData.data$;
    this.configuration$ = this.globalConfigurationService.getGlobalConfiguration();
  }

  toggle(): void {
    this.hamburgerMenuService.toggle();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
