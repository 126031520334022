export const environment = {
  production: true,
  backend : {
    occ : {
      baseUrl: null
    }
  },
  googleManager: {
    id: 'GTM-TMLK2BJ',
    url: 'https://www.googletagmanager.com/gtm.js?id='
  }
};
