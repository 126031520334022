import {CommonModule} from '@angular/common';
import {CmsConfig, ConfigModule, I18nModule, UrlModule,} from '@spartacus/core';
import {SsabSearchBoxComponent} from './ssab-search-box.component';
import {IconModule, MediaModule, SearchBoxModule} from '@spartacus/storefront';
import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {HighlightPipe} from './ssab-highlight.pipe';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MediaModule,
        UrlModule,
        I18nModule,
        ConfigModule.withConfig({
            cmsComponents: {
                SearchBoxComponent: {
                    component: SsabSearchBoxComponent
                }
            },
        } as CmsConfig),
        IconModule,
    ],
    declarations: [SsabSearchBoxComponent, HighlightPipe],
    exports: [SsabSearchBoxComponent]
})
export class SsabSearchBoxModule extends SearchBoxModule {
}
