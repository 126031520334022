import {NgModule} from '@angular/core';
import {UserComponentModule} from '@spartacus/storefront';
import {SsabLoginFormModule} from './login-form/ssab-login-form.module';
import {SsabForgotPasswordModule} from './forgot-password/ssab-forgot-password.module';
import {SsabCustomerSelectModule} from './customer-select/ssab-customer-select.module';
import {SsabCustomerSelectConfirmModule} from './ssab-customer-select-confirm/ssab-customer-select-confirm.module';
import {SsabStoreSelectorModule} from './store-selector/ssab-store-selector.module';

@NgModule({
  imports: [
    SsabLoginFormModule,
    SsabForgotPasswordModule,
    SsabCustomerSelectModule,
    SsabCustomerSelectConfirmModule,
    SsabStoreSelectorModule
  ]
})
export class SsabUserComponentModule extends UserComponentModule{}
