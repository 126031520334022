<ng-container *ngIf="user$ | async as user">
  <cx-page-slot position="HeaderLinks"></cx-page-slot>
</ng-container>

<ng-template #login>
  <a class="btn btn-primary btn-lg btn-square btn-icon d-inline-block d-lg-none" role="link"
     [routerLink]="{ cxRoute: 'login' } | cxUrl">
    <span class="ssab-icon icon-system-log-in"></span>
  </a>
  <a class="btn btn-primary btn-lg d-none d-lg-inline-block" role="link" [routerLink]="{ cxRoute: 'login' } | cxUrl">
    {{ 'miniLogin.signIn' | cxTranslate }}
  </a>
</ng-template>
