import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {CmsConfig, FeaturesConfigModule, I18nModule, provideDefaultConfig, UrlModule,} from '@spartacus/core';

import {IconModule, ItemCounterModule, KeyboardFocusModule, SpinnerModule} from "@spartacus/storefront";
import {SsabAddToCartComponent} from "./ssab-add-to-cart.component";
import {SsabItemStepperModule} from "../../product/product-list/item-stepper/ssab-item-stepper.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {SsabConsignmentListModule} from '../minicart/consignment-list/ssab-consignment-list.module';
import {AddToCartModule} from '@spartacus/cart/base/components/add-to-cart';
import {CartSharedModule} from '@spartacus/cart/base/components';
import {SsabBundleSplitToggleModule} from '../../my-account/bundle-split/ssab-bundle-split-toggle.module';
import {SsabUnitPickerComponent} from "./ssab-unit-picker.component";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        CartSharedModule,
        RouterModule,
        SpinnerModule,
        FeaturesConfigModule,
        UrlModule,
        IconModule,
        I18nModule,
        ItemCounterModule,
        KeyboardFocusModule,
        SsabItemStepperModule,
        NgSelectModule,
        FormsModule,
        SsabConsignmentListModule,
        SsabBundleSplitToggleModule
    ],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                ProductAddToCartComponent: {
                    component: SsabAddToCartComponent,
                },
            },
        }),
    ],
  declarations: [SsabAddToCartComponent, SsabUnitPickerComponent, SsabUnitPickerComponent],
  exports: [SsabAddToCartComponent, SsabUnitPickerComponent, SsabUnitPickerComponent]
})
export class SsabAddToCartModule extends AddToCartModule {
}
