import {Injectable} from '@angular/core';
import {Cart} from '@spartacus/cart/base/root';
import {CART_WISH_LIST_CORE_FEATURE, WishListFacade} from '@spartacus/cart/wish-list/root';
import {Observable} from 'rxjs';
import {facadeFactory} from '@spartacus/core';
import {SsabProduct} from '../../model/product.model';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: SsabWishListFacade,
      feature: CART_WISH_LIST_CORE_FEATURE,
      methods: [
        'createWishList',
        'getWishList',
        'loadWishList',
        'addEntry',
        'removeEntry',
        'getWishListLoading',
        'getWishList',
        'reloadWishlist',
        'addSsabEntry',
      ],
      async: true,
    }),
})
export abstract class SsabWishListFacade extends WishListFacade {
  abstract getWishList(forceReload?: boolean): Observable<Cart>;

  abstract reloadWishlist():void;

  abstract addSsabEntry(product: SsabProduct, remove?: boolean): void;
}
