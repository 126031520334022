<!-- navigation header -->
<div class="top-header">
  <h2 class="text-white" [innerHTML]="'ssab.scanner.home.maintitle'|cxTranslate">{{'ssab.scanner.home.maintitle'|cxTranslate}}</h2>
  <p class="text-paragraph-md text-white mb-0">{{'ssab.scanner.home.maindescription'|cxTranslate}}</p>
</div>
<div class="header">
  <div>
    <button *ngIf="!hideBack" class="back-button" (click)="goBack()">
      <i class="ssab-icon icon-navigation-chevron-left"></i>
    </button>
  </div>
  <div class="product-header">
    <div class="text-lg">{{ title }}</div>
    <div *ngIf="searchTitle">{{ searchTitle }}</div>
  </div>
  <div>
    <a class="link" [routerLink]="'/scanner/documents'">
      <i class="ssab-icon icon-general-checklist"></i>
      <div class="cart-items" *ngIf="cartItems$|async as cartItems">
        {{ cartItems.length }}
      </div>
    </a>
  </div>
</div>
