<div [ngClass]="{'banner-normal' : componentData.isNotGrid === 'true'}" *ngIf="componentData$ | async as componentData">
  <h3 class="banner-title" *ngIf="componentData.title">{{componentData.title}}</h3>
  <div class="banner-link" [ngClass]="{'banner-link-media': componentData.media,
  'd-flex': items.length<=1}" *ngIf="items$ | async as items">
    <div class="banner-link-items_{{items.length}}" [ngClass]="{'align-self-end': items.length<=1,
    'banner-link-items' : componentData.isNotGrid === 'false',
    'banner-documents': componentData.isNotGrid === 'true'}">
      <ng-container *ngFor="let item of items">
        <ng-container *ngIf="item | async as data">
          <div [style]="data.styleClasses" class="align-self-start" [ngClass]="data.uid ==='HomePageLinksScanner' ? 'd-lg-none' : ''">
            <ng-container
              *ngTemplateOutlet="template; context: { item: data }"
            ></ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <cx-media class="banner-link-image" *ngIf="componentData.media" [container]="componentData.media"></cx-media>
  </div>
</div>


<ng-template #template let-item="item">
  <ng-container
    [cxComponentWrapper]="{
      flexType: item.typeCode,
      typeCode: item.typeCode,
      uid: item?.uid
    }"
  >
  </ng-container>
</ng-template>
