<ng-container *ngIf="cart$ | async as cart">
  <div class="table-responsive p-2" *ngIf="cart.consignmentEntries.length > 0; else emptyMiniCart">
    <h3 class="text-primary">{{ 'miniCart.title' | cxTranslate}}
      <span class="float-right icon-md ssab-icon icon-navigation-close icon-inline" (click)="close(true)"></span>
    </h3>
    <div class="table-consignment">
      <ng-container *ngFor="let consignmentWrapper of cart.consignmentEntries">
        <table class="table no-border text-sm mt-0">
          <tr class="d-flex">
            <td class="col-2 pl-0">
              <a
                [routerLink]="{ cxRoute: 'product', params: consignmentWrapper.batches[0].product } | cxUrl"
                cxModal="dismiss"
                tabindex="-1"
                class="d-inline"
              >
                <cx-media
                  [container]="consignmentWrapper.batches[0].product.images?.PRIMARY"
                  format="cartIcon"
                ></cx-media>
              </a>
            </td>
            <td class="col-10 pr-0 pb-0">
              <a
                [routerLink]="{ cxRoute: 'product', params: consignmentWrapper.batches[0].product } | cxUrl"
                cxModal="dismiss"
                tabindex="-1"
              >
                <span class="d-inline-block text-bold text-md">{{consignmentWrapper.batches[0].product.name}} </span>
              </a>
              <table class="table inner-table no-border mt-0" *ngFor="let entry of consignmentWrapper.batches">
                <tr class="d-flex">
                  <td class="col-8 col-md-7">
                    {{entry.quantity}} <span [innerHTML]="entry.unit?.name"></span> /
                    {{'ssab.consignment.list.materialID' |cxTranslate}}: {{entry.batchId}}
                  </td>
                  <td class="col-3 col-md-4 pr-3 vertical-align-item-end">
                    <ng-container *ngIf="showPricesOrConsignmentPricesStock$ | async">
                      {{ entry.totalPrice?.formattedValue }}
                    </ng-container>
                  </td>
                  <td class="col-1 vertical-align-item-end w-100">
                    <cx-icon
                      aria-label="search"
                      class="ssab-icon icon-system-trash icon-cursor"
                      (click)="removeItem(entry)"
                      tabindex="0"
                    ></cx-icon>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
        <div class="d-flex mb-3" *ngIf="showPricesOrConsignmentPricesStock$ | async">
          <div class="col-10 offset-2 pr-0">
            <div class="d-flex align-items-baseline">
              <span class="col-8 col-md-7 pr-1 text-sm text-bold text-uppercase text-right">{{'miniCart.consignment.total' |cxTranslate}}</span>
              <span class="col-3 col-md-4 pl-0 pr-3 text-bold text-right text-nowrap">{{consignmentWrapper.totalPrice.formattedValue}}</span>
            </div>
          </div>
        </div>
        <hr>
      </ng-container>
    </div>

    <div class="d-flex pt-4 mt-2 mb-4" *ngIf="cart$ |async as cart">
      <div class="col-10 offset-2 pr-0">
        <div class="d-flex align-items-baseline">
          <span class="col-8 col-md-7 pr-1 text-sm text-bold text-uppercase text-right">{{'miniCart.total' |cxTranslate}}</span>
          <span class="col-3 col-md-4 pl-0 pr-3 text-paragraph-lg text-bold text-right text-nowrap">{{cart.subTotal?.formattedValue}}</span>
        </div>
      </div>
    </div>
    <div class="text-right w-100 pb-2 pt-2">
      <button [routerLink]="{ cxRoute: 'cart' } | cxUrl" class="btn btn-primary btn-default-height pb-3 pt-3">
        {{ 'miniCart.proceedToCheckout' | cxTranslate | uppercase}}
      </button>
    </div>
    <div class="pt-4 d-flex justify-content-center text-danger text-center pb-4" *ngIf="hasCartNoAvailableProducts(cart.entries)">
      <span [innerHtml]="'miniCart.cartNoAvailableProducts' | cxTranslate"></span>
    </div>
  </div>
</ng-container>

<ng-template #emptyMiniCart>
  <div class="p-2">
    <h3 class="text-center">{{ 'miniCart.empty.title' | cxTranslate}}</h3>
    <div class="pt-3 text-center">
      <button [routerLink]="{ cxRoute: 'search', params: { query: '' } } | cxUrl" class="btn btn-primary btn-default-height">
        {{ 'miniCart.empty.search.products' | cxTranslate | uppercase}}
      </button>
    </div>
  </div>
</ng-template>
