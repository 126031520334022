import {DIALOG_TYPE, LayoutConfig} from '@spartacus/storefront';
import {SsabSessionExpiredComponent} from './ssab-session-expired.component';

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    SESSION_EXPIRED = 'SESSION_EXPIRED',
  }
}

export const sessionExpiredLayout: LayoutConfig = {
  launch: {
    SESSION_EXPIRED: {
      inlineRoot: true,
      component: SsabSessionExpiredComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};

export interface SsabSessionExpiredDialogData {
  sessionUpdated: boolean;
}
