import {
  AnonymousConsentsDialogModule, KeyboardFocusModule,
  OutletModule,
  OutletRefModule, PageLayoutModule,
  PageSlotModule,
  PwaModule,
  SeoModule,
  SkipLinkModule
} from '@spartacus/storefront';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SsabStorefrontComponent} from './ssab-storefront.component';
import {FeaturesConfigModule, I18nModule} from '@spartacus/core';
import {SsabGlobalMessageModule} from '../cms-components/shared/global-message/ssab-global-message.module';
import {SsabNotificationsModule} from '../cms-components/shared/notifications/ssab-notifications.module';
import {SsabAlertModule} from '../cms-components/shared/alert/ssab-alert.module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SsabGlobalMessageModule,
    OutletModule,
    OutletRefModule,
    PwaModule,
    PageLayoutModule,
    SeoModule,
    PageSlotModule,
    AnonymousConsentsDialogModule,
    FeaturesConfigModule,
    SkipLinkModule,
    KeyboardFocusModule,
    SsabNotificationsModule,
    I18nModule,
    SsabAlertModule,

  ],
  declarations: [SsabStorefrontComponent],
  exports: [SsabStorefrontComponent],
})
export class SsabMainModule {}
