import {DIALOG_TYPE, LayoutConfig} from '@spartacus/storefront';
import {ScannerHowToScanComponent} from "./how-to-scan.component";

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    SCANNER_HOW_TO_SCAN = 'SCANNER_HOW_TO_SCAN',
  }
}

export const scannerHowToScanLayout: LayoutConfig = {
  launch: {
    SCANNER_HOW_TO_SCAN: {
      inlineRoot: true,
      component: ScannerHowToScanComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};

export interface SsabScannerHowToScanDialogData {
  emailSent?: boolean;
}
