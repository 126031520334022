<div class="cx-page" *ngIf="model$ | async as model">
  <div class="cx-page-header text-white pl-4 mt-5">
    <h3 class="pt-4">
      {{ (model.freeTextSearch === '*' || model.freeTextSearch === '') ?
      ('productList.showingAll' | cxTranslate) :
      ('productList.searchFor' | cxTranslate: {search: model.freeTextSearch})
      }}
    </h3>
  </div>

  <section class="cx-page-section" *ngIf="viewMode$ | async as viewMode">
    <div class="cx-sorting top">
      <div class="row no-margin">
        <div class="col-4 col-md-auto mr-lg-auto pr-md-3 pr-lg-0 col-no-padding vertical-align-item justify-content-start">
              <span
                class="text-primary text-bold">{{'productList.nProducts' | cxTranslate: {number: model.pagination.totalResults} }}</span>
        </div>
        <div class="col-auto ml-auto p-0">
          <div class="form-group mb-0 cx-sort-dropdown">
            <cx-sorting
              [sortOptions]="model.sorts"
              (sortListEvent)="sortList($event)"
              [selectedOption]="model.pagination.sort"
              class="ssab-sorting"
              placeholder="{{
                      'productList.sortByRelevance' | cxTranslate
                    }}"
            ></cx-sorting>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-12 pl-md-3 p-0 pt-3 pt-md-0">
          <button class="btn btn-primary btn-default-height btn-block p-0"
                  (click)="stockAvaliabilityDownload(model?.pagination.totalResults, model?.pagination.currentPage, model?.pagination.sort)"
                  [disabled]="model?.pagination.totalResults<= 0">
            <i class="ssab-icon icon-system-download icon-white icon-inline mr-2"></i>
            <h5 class="mb-0 d-inline">{{'common.download.results' | cxTranslate}}</h5>
          </button>
        </div>
        <div class="col-auto ml-auto ml-lg-0" *ngIf="false">
          <cx-product-view
            (modeChange)="setViewMode($event)"
            [mode]="viewMode"
          ></cx-product-view>
        </div>
      </div>
    </div>
    <div class="cx-product-container">
      <!-- Product list when using pagination -->
      <ng-container *ngIf="!isInfiniteScroll; else infiniteScroll">
        <ng-container *ngIf="viewMode === ViewModes.Grid">
          <div class="row">
            <cx-product-grid-item
              *ngFor="let product of model?.products"
              [product]="product"
              class="col-12 col-sm-6 col-md-4"
            ></cx-product-grid-item>
          </div>
        </ng-container>

        <ng-container *ngIf="viewMode === ViewModes.List">
          <ng-container>
            <ssab-cx-product-list-item
              *ngFor="let product of model?.products; let i = index"
              [product]="product"
              [productIndex]="i"
              class="cx-product-search-list"
            ></ssab-cx-product-list-item>
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- Product list when using infinite scroll -->
      <ng-template #infiniteScroll>
        <cx-product-scroll
          [scrollConfig]="scrollConfig"
          [model]="model"
          [inputViewMode]="viewMode"
        ></cx-product-scroll>
      </ng-template>
    </div>

    <div class="cx-sorting bottom" *ngIf="model.pagination.totalResults == 0">
      <div class="row no-margin">
        <div class="col col-no-padding vertical-align-item align-self-center justify-content-center justify-content-md-start">
          <h4 class="text-primary">{{((noResultsMessage$ | async)) | cxTranslate}}</h4>
        </div>
      </div>
    </div>

    <ssab-cx-pagination-bottom
      [pagination]="model?.pagination"
      [skipLocationChange]="true"
      [selectedPageSize]="viewConfig.view.defaultPageSize"
      (pageSizeEvent)="changeListingProducts($event,  model.pagination.currentPage)"
      [isEventEmitterPagination]="false"
    ></ssab-cx-pagination-bottom>
  </section>
</div>


<ng-container *ngIf="ssabGtmService.pushProductsFromListingPage() | async"></ng-container>
