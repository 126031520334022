import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideDefaultConfig } from '@spartacus/core';
import { ssabOccSubscriptionsConfig } from './ssab-occ-subscriptions-config';
import { EffectsModule } from '@ngrx/effects';
import { SsabSubscriptionEffects } from './ssab-subscriptions.effect';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([SsabSubscriptionEffects])
  ],
  providers: [
    provideDefaultConfig(ssabOccSubscriptionsConfig)
  ]
})
export class SsabSubscriptionsModule {}
