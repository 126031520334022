import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CmsConfig, ConfigModule, I18nModule, UrlModule} from '@spartacus/core';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {IconModule, MediaModule} from '@spartacus/storefront';
import {SsabContactSalesProductListComponent} from './ssab-contactsales.component';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [SsabContactSalesProductListComponent],
  imports: [
    CommonModule,
    I18nModule,
    RouterModule,
    UrlModule,
    NgbPopoverModule,
    MediaModule,
    IconModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SsabContactSalesProductListComponent: {
          component: SsabContactSalesProductListComponent
        }
      },
    } as CmsConfig),
  ],
  exports: [SsabContactSalesProductListComponent]
})

export class SsabContactSalesComponentModule {
}
