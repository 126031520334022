import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {provideDefaultConfig} from '@spartacus/core';
import {ssabOccGlobalConfiguration} from './ssab-globalconfiguration-config';
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {GLOBAL_CONFIGURATION_FEATURE} from "./ssab-globalconfiguration-actions";
import {AimoGlobalConfigurationEffects} from "./ssab-globalconfiguration-effects";
import {metaReducers, reducerToken, ssabGlobalConfigurationReducerProvider} from './ssab-globalconfiguration.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(GLOBAL_CONFIGURATION_FEATURE, reducerToken, {metaReducers}),
    EffectsModule.forFeature([AimoGlobalConfigurationEffects]),
  ],
  providers: [
    provideDefaultConfig(ssabOccGlobalConfiguration),
    ssabGlobalConfigurationReducerProvider
  ]
})
export class SsabGlobalconfigurationModule {
}
