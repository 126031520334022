import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConverterService, OccEndpointsService} from '@spartacus/core';
import {B2BUnitOption} from '../../model/user.model';
import {OccUserProfileAdapter} from '@spartacus/user/profile/occ';
import {OrderTrackingTypes} from "../../model/order.model";

@Injectable({
  providedIn: 'root',
})
export class SsabOccUserProfileAdapter extends OccUserProfileAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) {
    super(http, occEndpoints, converter);
  }

  public searchUnit(term: string): Observable<B2BUnitOption[]> {
    const url = this.occEndpoints.buildUrl('searchUnits', {queryParams: {term}});
    return this.http.get<B2BUnitOption[]>(url);
  }

  public searchUnitForShipToFilter(ctx: string, term: string, id: string, id2?: string, selectedView?: OrderTrackingTypes): Observable<B2BUnitOption[]> {
    var urlref = '';
    var url = '';
    term = term ?? '';
    switch (ctx) {
      case 'Certificate':
        urlref = 'units/searchConsigneesForCertificates?customerEmulationParameter=true';
        url = this.occEndpoints.buildUrl(urlref, {queryParams: {term: term, buyerId: id}});
        break;
      case 'OrderConfirmation':
        urlref = 'units/searchConsigneesForOrderConfirmations?customerEmulationParameter=true';
        url = this.occEndpoints.buildUrl(urlref, {queryParams: {term: term, payerId: id}});
        break;
      case 'Invoice':
        urlref = 'units/searchConsigneesForInvoices?customerEmulationParameter=true';
        url = this.occEndpoints.buildUrl(urlref, {queryParams: {term: term, payerId: id}});
        break;
      case 'Transport':
        urlref = 'units/searchConsigneesForTransports?customerEmulationParameter=true';
        url = this.occEndpoints.buildUrl(urlref, {queryParams: {term: term, buyerId: id}});
        break;
      case 'orderhistory':
        urlref = 'units/searchShipToUnitsForOrderHistory?customerEmulationParameter=true';
        url = this.occEndpoints.buildUrl(urlref, {queryParams: {term: term, customer: id, company: id2, indexHeader: selectedView}});
        break;
      default:
        throw new Error('ctx must be specified');
    }
    return this.http.get<B2BUnitOption[]>(url);
  }

  public searchUnitForCustomerIdFilter(ctx: string, term: string, id?: string, selectedView?: OrderTrackingTypes): Observable<B2BUnitOption[]> {
    var urlref = '';
    switch (ctx) {
      case 'documents':
        urlref = 'searchUnitsForDocuments';
        break;
      case 'orderhistory':
        urlref = 'searchUnitsForOrderHistory';
        break;
      default:
        throw new Error('ctx must be specified');
    }
    const url = this.occEndpoints.buildUrl(urlref, {queryParams: {term: term, company: id, indexHeader: selectedView}});
    return this.http.get<B2BUnitOption[]>(url);
  }

  public searchUnitForCompanyFilter(ctx: string, term: string, selectedView: OrderTrackingTypes): Observable<B2BUnitOption[]> {
    var urlref = '';
    var url = '';
    switch (ctx) {
      case 'orderhistory':
        urlref = 'units/searchCompaniesForOrderHistory';
        url = this.occEndpoints.buildUrl(urlref, {queryParams: {term: term, indexHeader: selectedView}});
        break;
      default:
        throw new Error('ctx must be specified');
    }
    return this.http.get<B2BUnitOption[]>(url);
  }

  public selectUnit(uid: string): Observable<B2BUnitOption> {
    const url = this.occEndpoints.buildUrl('selectUnitNoCart', {queryParams: {uid}});
    return this.http.get<B2BUnitOption>(url);
  }

  public delayedOrderSubscription(uid: string, isLateOrderSubscription: boolean): Observable<any> {
    const url = this.occEndpoints.buildUrl('delayedOrderEmail', {urlParams: {uid}, queryParams: {isLateOrderSubscription}});
    return this.http.get<any>(url);
  }

  public setShowInches(uid: string, isShowInches: boolean): Observable<any> {
    const url = this.occEndpoints.buildUrl('setShowInches', {urlParams: {uid}, queryParams: {isShowInches}});
    return this.http.post<any>(url, null);
  }

  public storeSessionLanguage(userId: string): Observable<any> {
    const url = this.occEndpoints.buildUrl('storeSessionLanguage', {urlParams: {userId}});
    return this.http.get<any>(url);
  }
}
