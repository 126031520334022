import {CmsConfig, I18nModule, provideDefaultConfig, UrlModule} from '@spartacus/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {IconModule} from '@spartacus/storefront';
import {SsabAddToWishListComponent} from './ssab-add-to-wish-list.component';


@NgModule({
    imports: [CommonModule, I18nModule, IconModule, RouterModule, UrlModule],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                AddToWishListComponent: {
                    component: SsabAddToWishListComponent,
                },
            },
        }),
    ],
    declarations: [SsabAddToWishListComponent],
    exports: [SsabAddToWishListComponent]
})
export class SsabAddToWishListModule {}
