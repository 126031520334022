import {Component, ViewEncapsulation} from '@angular/core';
import {AsmMainUiComponent} from '@spartacus/asm/components';

@Component({
  selector: 'cx-asm-main-ui',
  templateUrl: './ssab-asm-main-ui.component.html',
  styleUrls: ['./ssab-asm-main-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SsabAsmMainUiComponent extends AsmMainUiComponent{

}
