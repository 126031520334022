<div class="ssab-modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header p-0">
        <h4 class="modal-title d-inline">
          {{ (emailSent ? 'ssab.scanner.documents.documentsSent' : 'ssab.scanner.documents.downloadCompleted') | cxTranslate }}
        </h4>
        <i class="float-right icon-md ssab-icon icon-navigation-close icon-inline" (click)="dismiss()"></i>
      </div>
      <div class="modal-body">
        <p>{{'ssab.scanner.documents.removeSelectedDocuments'|cxTranslate}}</p>

        <hr/>

        <div class="button-wrapper">
          <button class="btn btn-link text-uppercase text-nowrap" (click)="deleteCartList()">
            {{'ssab.scanner.documents.clearSelectedDocuments'|cxTranslate}}
          </button>
          <button class="btn btn-primary" (click)="dismiss()">
            {{'ssab.scanner.documents.keepSelectedDocuments'|cxTranslate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
