import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {SsabUserService} from '../../../service/user/ssab-user.service';
import {SsabPrice} from "../../../model/product.model";


@Component({
  selector: 'ssab-cx-bundle-split-toggle',
  templateUrl: './ssab-bundle-split-toggle.component.html',
})
export class SsabBundleSplitToggleComponent implements OnChanges{
  @Input() isSplitBundle: boolean =false;
  @Input() label: string;
  @Input() enableToggle: boolean = true;
  @Output() updateSplitBundle = new EventEmitter<boolean>();

  constructor(protected userService: SsabUserService,
              protected cdr: ChangeDetectorRef) {}

  ngOnChanges() {
    if(!this.enableToggle){
      this.isSplitBundle = false;
      this.updateSplitBundle.next(this.isSplitBundle);
      this.cdr.detectChanges();
    }
  }

  toggleSplitBundle(): void {
    if(this.enableToggle){
      this.isSplitBundle = !this.isSplitBundle;
      this.updateSplitBundle.next(this.isSplitBundle);
    }
  }
}
