<table class="table">
  <tr class="header">
    <th class="first text-left">{{'ssab.scanner.product.materialId'|cxTranslate}}</th>
    <th class="last text-left" colspan="2">{{'ssab.scanner.product.documentType'|cxTranslate}}</th>
  </tr>

  <tr *ngFor="let item of cartItems" class="content no-border">
    <td class="first">
      <ul class="list-unstyled text-left text-sm-right">
        <span *ngIf="!item.materialId">
          {{item.orderNumber}}
        </span>
        <li class="" *ngFor="let i of item.materialId?.split(',')">
          {{i}}
        </li>
      </ul>
    </td>
    <td class="text-left">{{ (item.docType) }}</td>
    <td class="last text-right">
      <button (click)="onDeleteFromTable(item)">
        <i class="ssab-icon icon-system-trash"></i>
      </button>
    </td>
  </tr>
</table>

<div *ngIf="!cartItems.length" class="no-product-div">
  <p class="mb-0">
    {{'ssab.scanner.product.nothingAdded'|cxTranslate}}
  </p>
</div>
