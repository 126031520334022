import {
  ChangeDetectionStrategy,
  Component, EventEmitter, Input, Output,
} from '@angular/core';

@Component({
  selector: 'ssab-filter',
  templateUrl: './ssab-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SsabFilterComponent {
  @Input() labelPlaceHolder: string;
  @Output() filterValue = new EventEmitter<string>();

  searchMinCharacters(query: string): void {
    this.filterValue.emit(query);
  }

  onlyClear(el: HTMLInputElement): void {
    this.filterValue.emit('');
    el.value = '';
  }
}
