<div *ngIf="notifications$ | async as notifications">
  <div
    class="alert alert-notification"
    *ngFor="
      let notification of notifications;
    "
  >
    <div class="container">
      <span [innerHTML]=" notification.content | safeHtml "> </span>
    </div>
  </div>
</div>
