<ng-container *ngIf="items$ | async as items">
  <ng-container *ngFor="let item of items">
    <ng-container *ngIf="item | async as data">
      <ng-container
        *ngTemplateOutlet="template; context: { item: data }"
      ></ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #template let-item="item">
  <ng-container
    [cxComponentWrapper]="{
      flexType: item.typeCode,
      typeCode: item.typeCode,
      uid: item?.uid
    }"
  >
  </ng-container>
</ng-template>
