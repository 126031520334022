import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {SsabNavigationService} from '../../../service/navigation/ssab-navigation.service';
import {CmsNavigationComponent} from '@spartacus/core';
import {Observable} from 'rxjs';
import {SsabNavigationNode} from '../../../model/cms.model';
import {map} from 'rxjs/operators';
import {SsabGlobalConfiguration} from "../../../model/misc.model";
import {SsabGlobalconfigurationService} from "../../../service/general/configuration/ssab-globalconfiguration.service";

@Component({
  selector: 'ssab-cx-navigation',
  templateUrl: './ssab-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabNavigationComponent implements OnInit {
  node$: Observable<SsabNavigationNode>;
  styleClass$: Observable<string>;
  configuration$: Observable<SsabGlobalConfiguration>;

  constructor(
    protected componentData: CmsComponentData<CmsNavigationComponent>,
    protected service: SsabNavigationService,
    private globalConfigurationService: SsabGlobalconfigurationService,
  ) {

  }

  ngOnInit(): void {
    this.node$ = this.service.createNavigation(
      this.componentData.data$
    );

    this.styleClass$ = this.componentData.data$.pipe(
      map(d => d?.styleClass)
    );

    this.configuration$ = this.globalConfigurationService.getGlobalConfiguration();
  }
}
