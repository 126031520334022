<div class="ssab-modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header p-0">
        <h4 class="modal-title d-inline">
          {{'ssab.scanner.documents.sendSelectedDocuments'|cxTranslate}}
        </h4>
        <i class="float-right icon-md ssab-icon icon-navigation-close icon-inline" (click)="dismiss()"></i>
      </div>
      <div class="modal-body" [formGroup]="form">
        <p>{{'ssab.scanner.documents.sendDocumentsDescription'|cxTranslate}}</p>

        <div class="text-sm text-gray">{{'ssab.scanner.documents.email'|cxTranslate}}</div>
        <div class="search">
          <ssab-cx-input
            idName="email"
            [isNotValidFunction]="isInvalid()"
            [nameForm]="'email'"
            [labelPlaceholder]="'ssab.scanner.documents.emailWrite'"
            [parentForm]="form"></ssab-cx-input>
          <button class="btn btn-primary ml-2" (click)="addAdditionalEmail()">
            {{'ssab.scanner.documents.addAdditionalEmail'|cxTranslate}}
          </button>
        </div>

        <div class="mt-2">
          <div class="email-item" *ngFor="let email of emails">
            {{email}} <i class="ssab-icon icon-navigation-close icon-inline icon-sm" (click)="removeEmail(email)"></i>
          </div>
        </div>

        <hr/>

        <div class="button-wrapper">
          <button class="btn btn-link text-uppercase" (click)="dismiss()">
            {{'ssab.scanner.documents.cancel'|cxTranslate}}
          </button>
          <button class="btn btn-primary" (click)="send()">
            {{'ssab.scanner.documents.send'|cxTranslate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
