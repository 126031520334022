<ng-container *ngIf="facetList$ | async as facetList">
  <div *ngIf="facetList?.activeFacets?.length > 0" class="w-100 pb-2 pb-xl-3 pb-lg-2">
    <p class="text-bold d-inline">{{ 'productList.appliedFilter' | cxTranslate }}</p>
    <p [routerLink]="{ cxRoute: 'search', params: { query: '' } } | cxUrl"
          class="text-sm cursor-pointer text-underline text-primary float-lg-none float-right float-xl-right text-clear text-bold">
      {{'productList.clearFilter' | cxTranslate}}
    </p>
  </div>
  <hr class="mt-0 hr-gray mb-2" *ngIf="facetList?.activeFacets?.length > 0">

  <a
    *ngFor="let facet of facetList?.activeFacets"
    routerLink="./"
    [queryParams]="getLinkParams(facet)"
    [cxFocus]="getFocusKey(facetList, facet)"
  >
    <span>{{ facet.facetValueName }}</span>
    <cx-icon aria-hidden="true" [type]="closeIcon"></cx-icon>
  </a>
</ng-container>
