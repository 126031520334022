<div class="ssab-modal-md ssab-modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header cx-dialog-header">
        <h3 class="modal-title">
          {{ 'custom.customer.change.modal.title' | cxTranslate }}
        </h3>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="dismiss()"
        >
      <span aria-hidden="true">
        <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
      </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="cx-row">
          <p class="cx-confirmation">
            {{ 'custom.customer.change.modal.confirmationMessage' | cxTranslate }}
          </p>
        </div>
        <div class="cx-row">
          <div class="cx-btn-group row">
            <div class="col-6">
              <button (click)="dismiss()" class="btn btn-block btn-secondary">
                {{ 'common.cancel' | cxTranslate }}
              </button>
            </div>
            <div class="col-6">
              <button class="btn btn-block btn-primary" (click)="confirm()">
                {{ 'custom.customer.change.confirm' | cxTranslate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

