<div class="col-25 col-tablet-50 footer-section">
  <div class="footer-section-content" >
    <h4>{{column.headerText}}</h4>
    <p [innerHTML]="column.longText | safeHtml" class="pt-3"></p>
    <div *ngIf="column.showLink">
      <a *ngIf="user$|async as user"
         [href]="'mailto:' + emails(user)">
        {{column.linkText}} </a>
    </div>
  </div>
</div>
