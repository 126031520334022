import {DIALOG_TYPE, LayoutConfig} from '@spartacus/storefront';
import {SsabCoilsComparisonComponentComponent} from "./ssab-coils-comparison.component";
import {SsabCoilsClearComponentComponent} from "./ssab-coils-clear.component";

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    COILS_COMPARISON = 'COILS_COMPARISON',
    COILS_CLEAR = 'COILS_CLEAR',
  }
}

export const coilComparisonModalLayout: LayoutConfig = {
  launch: {
    COILS_COMPARISON: {
      inlineRoot: true,
      component: SsabCoilsComparisonComponentComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
    COILS_CLEAR: {
      inlineRoot: true,
      component: SsabCoilsClearComponentComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};
