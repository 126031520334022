import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {SsabUser} from '../../../model/user.model';
import {SsabUserService} from '../../../service/user/ssab-user.service';


@Component({
  selector: 'ssab-cx-delayed-orders-toggle',
  templateUrl: './ssab-delayed-orders-toggle.component.html',
})
export class SsabDelayedOrdersToggleComponent {
  user$: Observable<SsabUser>;

  constructor(protected userService: SsabUserService) {
    this.user$ = this.userService.getUserDetails();
  }

  activateDeactivateDelayedEmails(isLateOrderSubscription: boolean): void {
    this.userService.delayedOrderSubscription(isLateOrderSubscription);
  }
}
