import {I18nModule} from '@spartacus/core';
import {SsabPageSizeModule} from '../../cms-components/shared/page-size/ssab-page-size.module';
import {SsabPaginationBottomComponent} from './ssab-pagination-bottom.component';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SsabPaginationModule} from '../pagination/ssab-pagination.module';


@NgModule({
  imports: [CommonModule, RouterModule, I18nModule, SsabPageSizeModule, SsabPaginationModule],
  declarations: [SsabPaginationBottomComponent],
  exports: [SsabPaginationBottomComponent],

})
export class SsabPaginationBottomModule {

}
