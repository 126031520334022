import {Component, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {Params, Router} from "@angular/router";
import {SsabCertificateDocument} from "../../model/document.model";
import {SsabScannerService} from "../../service/scanner/ssab-scanner.service";
import {Location} from '@angular/common';

@Component({
  selector: 'scanner-header-main',
  templateUrl: './header-main.component.html',
})
export class ScannerHeaderMainComponent {
  @Input() title: string = '';
  @Input() searchTitle: string = '';
  @Input() hideBack: boolean = false;
  @Input() backUrl: string;
  @Input() backUrlParams: Params;
  cartItems$: Observable<SsabCertificateDocument[]>;

  constructor(protected router: Router,
              protected scannerService: SsabScannerService,
              protected location: Location
  ) {
    this.cartItems$ = this.scannerService.cartData$;
  }

  goBack() {
    this.router.navigate([this.backUrl], {queryParams: this.backUrlParams});
  }
}
