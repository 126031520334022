import {ViewConfig} from '@spartacus/storefront';

export const ssabDefaultViewConfig: ViewConfig = {
  view: {
    defaultPageSize: 25,
    infiniteScroll: {
      active: false,
      productLimit: 0,
      showMoreButton: false,
    },
  },
};
