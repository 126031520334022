import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import {PaginationComponent, PaginationItemType} from '@spartacus/storefront';

/**
 * The `PaginationComponent` is a generic component that is used for
 * all lists in Spartacus that require pagination. The component supports
 * all common features, which can be configured or hidden by CSS.
 */
@Component({
  selector: 'ssab-cx-pagination',
  templateUrl: './ssab-pagination.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabPaginationComponent extends PaginationComponent{
  getAriaLabel(label: string, type: PaginationItemType): string {
    // Convert 'Start' to First, and 'End' to Last for a more natural screen read.
    type = type === PaginationItemType.START ? PaginationItemType.FIRST : type;
    type = type === PaginationItemType.END ? PaginationItemType.LAST : type;
    return type === PaginationItemType.PAGE
      ? `${type} ${label}`
      : `${type} ${PaginationItemType.PAGE}`;
  }
}
