<a
  [attr.aria-label]="'miniCart.item' | cxTranslate: { count: quantity$ | async }"
  placement="bottom-right"
  [ngbPopover]="popContentSavedMinicart" popoverClass="popover-md" triggers="manual" #popover2="ngbPopover"
  (click)="popover2.open();"
>
  <div [ngClass]="{'navigation-icon-active': popover2.isOpen()}" class="ssab-icon icon-saved-cart navigation-icon"></div>
  <ng-container *ngIf="quantity$ | async as quantity">
    <span class="count">
    {{ 'miniCart.count' | cxTranslate: {count: quantity} }}
    </span>
  </ng-container>
</a>

<ng-template #popContentSavedMinicart>

  <ng-container *ngIf="items$ | async as items; else emptyMiniSavedCart">
    <div class="table-responsive p-2" *ngIf="items.length > 0; else emptyMiniSavedCart">
      <h3 class="pb-2">{{ 'ssab.savedCart.ContactSalesProductList.title' | cxTranslate}}
        <span class="float-right icon-md ssab-icon icon-navigation-close icon-inline" (click)="popover2.close()"></span>
      </h3>
      <div [innerHTML]="'ssab.savedCart.ContactSalesProductList.content' | cxTranslate"></div>
      <table class="table">
        <tbody>
        <tr *ngFor="let item of items" class="d-flex">
          <td class="col-sm-8 col-6 pl-0">
            <a
              [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl"
              cxModal="dismiss"
              tabindex="-1"
            >
              <span class="d-inline-block text-bold">{{item.product.name}} </span>
            </a>
          </td>
          <td class="col-sm-3 col-4 pl-2 text-bold d-flex align-items-center w-100 text-primary">
            {{item.warehouse?.name}}
          </td>
          <td class="col-sm-1 col-1 vertical-align-item-end w-100 p-0">
            <cx-icon
              aria-label="search"
              class="ssab-icon icon-system-trash icon-cursor"
              (click)="removeItem(item)"
              tabindex="0"
            ></cx-icon>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="text-right pt-4">
        <button class="btn btn-primary btn-default-height" (click)="openCollectedItemsModal()">
          <i class="ssab-icon icon-inline mr-3 icon-white icon-saved-cart"></i>
          <h5 class="d-inline">{{ 'ssab.savedCart.ContactSalesProductList.button' | cxTranslate | uppercase}}</h5>
        </button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #emptyMiniSavedCart>
  <div class="p-2">
    <h3 class="text-center">{{ 'ssab.savedCart.ContactSalesProductList.empty.title' | cxTranslate}}</h3>
    <ng-container *ngIf="user$ | async as user">
      <ng-container *ngIf="user.salesContacts">
        <div class="no-items text-center" *ngIf="user.salesContacts && user.salesContacts.length > 0">
          <div [innerHTML]="'ssab.savedCart.ContactSalesProductList.empty.content' | cxTranslate :
          {email : 'mailto:' + emails(user.salesContacts)}"></div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
