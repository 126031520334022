import {Injectable} from '@angular/core';
import {AuthService, AuthStorageService, GlobalMessageService, OAuthLibWrapperService, OccEndpointsService, RoutingService} from '@spartacus/core';

import {AsmAuthHttpHeaderService, CsAgentAuthService} from '@spartacus/asm/root';
import {SsabAuthRedirectService} from './ssab-auth-redirect.service';


@Injectable({
  providedIn: 'root'
})
export class SsabAuthHttpHeaderService extends AsmAuthHttpHeaderService {
  constructor(protected authService: AuthService,
              protected authStorageService: AuthStorageService,
              protected csAgentAuthService: CsAgentAuthService,
              protected oAuthLibWrapperService: OAuthLibWrapperService,
              protected routingService: RoutingService,
              protected globalMessageService: GlobalMessageService,
              protected occEndpointsService: OccEndpointsService,
              protected authRedirectService: SsabAuthRedirectService) {
    super(authService, authStorageService, csAgentAuthService, oAuthLibWrapperService, routingService, globalMessageService, occEndpointsService, authRedirectService);
  }

  // when auth token is expired spartacus tries to refresh it. in most cases (e.g. login+logout with the same account) deletes access
  // and refresh token that causes a warning on server side and 400 (refresh token not found) or 401 (refresh token is expired)
  // but on frontend this is an error. in fact is important that user is redirected to login page
  handleExpiredRefreshToken(): void {
    this.authService.coreLogout().finally(() => {
      this.routingService.go({cxRoute: 'login'});
    });
    this.routingService.go({cxRoute: 'login'});
    // no unnecessary alerts
  }
}
