import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CmsConfig, ConfigModule} from "@spartacus/core";
import {SsabFooterColumnComponent} from "./ssab-footer-column.component";
import {SsabPipesModule} from '../../shared/utils/safe-html/ssab-pipes.module';

@NgModule({
    imports: [
        CommonModule,
        ConfigModule.withConfig({
            cmsComponents: {
                SsabFooterColumnComponent: {
                    component: SsabFooterColumnComponent,
                },
            },
        } as CmsConfig),
        SsabPipesModule,
    ],
    declarations: [SsabFooterColumnComponent],
    exports: [SsabFooterColumnComponent]
})
export class SsabFooterColumnModule { }
