import { CommonModule } from '@angular/common';
import {NgModule} from '@angular/core';
import {I18nModule, provideDefaultConfig} from '@spartacus/core';

import {SsabBaseModalComponent} from './ssab-base-modal.component';
import {baseModalLayout} from './ssab-base-modal-layout.config';

@NgModule({
    imports: [
        CommonModule,
        I18nModule
    ],
    declarations: [SsabBaseModalComponent],
    exports: [SsabBaseModalComponent, I18nModule],
    providers: [provideDefaultConfig(baseModalLayout)]
})
export class SsabBaseModalModule {
}
