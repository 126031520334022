<div class="cx-material-search">

  <h2 class="text-white cx-page-header-title">
    {{ 'ssab.material.data.title' | cxTranslate }}
  </h2>

  <!-- filter for tablet and mobile view -->
  <div class="cx-material-data-filter row no-gutters w-100 d-flex d-lg-none pt-4 pl-4 pr-4">
    <div class="col-auto my-auto pb-4">
      {{'common.swipe.instructions' | cxTranslate}}
    </div>
    <div class="col-auto col-md-4 d-flex justify-content-end text-right text-bold ml-auto pb-4">
          <span (click)="showFilterOptions($event)">
            {{ 'ssab.order.history.filter.true' | cxTranslate }}
            <i class="ssab-icon icon-system-filter icon-inline ml-3 icon-cursor"></i>
          </span>
    </div>
  </div>

  <ng-container *ngIf="docolProductList$| async as docolProductList">
    <ssab-cx-material-data-form [docolProductList]="docolProductList"
                                [searchDocolForm]="searchDocolForm"
                                (resetForm)="resetForm()"
    ></ssab-cx-material-data-form>


    <ng-container *ngIf="docolProductList && docolProductList?.products?.length> 0; else noMaterials">
      <!-- BODY -->
      <div class="cx-material-data-body">
        <ng-container *ngIf="docolProductList?.pagination?.totalResults > 0; else noMaterials">
          <!-- TABLES-->
          <ssab-cx-material-data-table [materials]="docolProductList.products"
                                       [activeSort]="activeSort$.getValue()"
                                       (sortChange)="sort($event)"
          ></ssab-cx-material-data-table>
          <!-- Select Form and Pagination Bottom -->
          <ssab-cx-pagination-bottom
            [pagination]="docolProductList?.pagination"
            (viewPageEvent)="pageChange($event)"
          ></ssab-cx-pagination-bottom>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>

  <!-- NO ORDER CONTAINER -->
  <ng-template #noMaterials>
    <div
      class="empty-data-div row no-margin text-center">
      <div [ngClass]="'col-6'"
      >
        <h4 class="text-primary">{{ 'ssab.material.data.noFound' | cxTranslate }}</h4>
      </div>
    </div>
  </ng-template>

</div>
