import {LayoutConfig} from '@spartacus/storefront';


export const ssabLayoutConfig: LayoutConfig = {
  layoutSlots: {
    // overriding Spartacus page templates
    header: {
      lg: {
        slots: [
          'PreHeader',
          'SiteLogo',
          'SearchBox',
          'SearchUnits',
          'SiteContext',
          'SiteLogin',
          'MiniCart'
        ],
      },
      slots: [
        'PreHeader',
        'SiteLogo',
        'SearchBox',
        'SiteContext',
        'SiteLogin',
        'MiniCart'
      ],
    },
    navigation: {
      lg: {slots: [ 'NavigationBar']},
      slots: [
        'NavigationBar',
      ],
    },
    footer: {
      slots: [
        'Footer'
      ],
    },

    LandingPage2Template: {
      slots: [
        'Section3',
        'SidePanel',
        'SectionWideScreen',
        'Section1',
        'Section4',
        'Section5'
      ],
    },
    ContentPage1Template: {
      slots: [
        'TopHeaderSlot',
        'Section1',
        'Section2A',
        'Section2B',
        'Section3'
      ],
    },
    CategoryPageTemplate: {
      slots: [
        'Section1',
        'Section2',
        'Section3'
      ],
    },
    ProductListPageTemplate: {
      slots: [
        'SidePanel',
        'ProductListSlot',
      ],
    },
    ProductGridPageTemplate: {
      slots: [
        'SidePanel',
        'ProductGridSlot',
      ],
    },
    SearchResultsListPageTemplate: {
      slots: [
        'SidePanel',
        'ProductLeftRefinements',
        'SearchResultsListSlot'
      ],
    },
    SearchResultsGridPageTemplate: {
      slots: [
        'SidePanel',
        'SearchResultsGridSlot',
      ],
    },
    ProductDetailsPageTemplate: {
      slots: [
        'MainNavigation',
        'Summary',
        'Nutrition',
      ],
    },
    CartPageTemplate: {
      slots: [
        'TopContent', 'CenterRightContentSlot'
      ],
    },
    AccountPageTemplate: {
      slots: [
        'SideContent',
        'BodyContent'
      ],
    },
    LoginPageTemplate: {
      slots: [
        'LeftContentSlot',
        'RightContentSlot'
      ],
    },
    StoreSelectorPageTemplate: {
      slots: [
        'LeftContentSlot'
      ],
    },
    ErrorPageTemplate: {
      slots: [
        'TopContent',
        'MiddleContent',
        'BottomContent'
      ],
    },
    OrderConfirmationPageTemplate: {
      slots: [
        'MainNavigation',
        'TopContent',
        'BodyContent',
        'SideContent'],
    },
    MultiStepCheckoutSummaryPageTemplate: {
      slots: [
        'MainNavigation',
        'TopContent',
        'BodyContent',
        'SideContent',
        'BottomContent'
      ],
    },
    CheckoutLoginPageTemplate: {
      slots: [
        'RightContentSlot'
      ],
    },

  },
};
