import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {OccEndpointsService} from '@spartacus/core';
import {catchError} from 'rxjs/operators';
import {SsabGlobalConfiguration} from '../../../model/misc.model';

@Injectable({
  providedIn: 'root'
})
export class SsabGlobalconfigurationAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
  ) {
  }

  getGlobalConfiguration(): Observable<SsabGlobalConfiguration> {
    return this.http
      .get<SsabGlobalConfiguration>(this.occEndpointsService.buildUrl('getGlobalConfiguration').replace('undefined', 'global'))
      .pipe(catchError((error) => throwError(error)));
  }
}
