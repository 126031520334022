import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {UserIdService} from '@spartacus/core';
import {StateWithMultiCart} from '@spartacus/cart/base/core';
import {Observable} from 'rxjs';
import {filter, isEmpty, map, take} from 'rxjs/operators';
import {SsabCart, SsabOrderEntry} from '../../model/cart.model';
import {SsabCartAddEntry, SsabCartUpdateEntry} from '../cart/ssab-entry.action';
import {SsabMultiCartService} from '../cart/ssab-multi-cart.service';
import {LoadContactSalesProductList} from './ssab-contactsales.action';

export const CART_ID = 'ContactSalesProductList';

@Injectable({
  providedIn: 'root'
})
export class SsabContactSalesProductListService {
  constructor(protected store: Store<StateWithMultiCart>, protected multiCartService: SsabMultiCartService, protected userIdService: UserIdService) {
  }

  public loadContactSalesProductList(userId: string): void {
    // before loading the cart check if it's already loaded - so the 'initialization' is possible e.g. from miniContactSalesProductList component
    // and from ssab-collected-items component, so if triggered from 2 components it's all safe and doesn't interfere with each other
    this.getContactSalesProductList()
      .pipe(
        isEmpty(),
        take(1)
      ).subscribe(
      () => this.store.dispatch(new LoadContactSalesProductList({userId}))
    );
  }

  addSsabEntry(entry: SsabOrderEntry): void {
    this.userIdService.getUserId().pipe(take(1))
      .subscribe((userId) => {
        this.store.dispatch(
          new SsabCartAddEntry(userId, CART_ID, entry, {successMessage: 'ssab.addtocart.ContactSalesProductList.notification.success', errorMessage: 'ssab.addtocart.ContactSalesProductList.notification.failed'}));
      });
  }

  getContactSalesProductList(): Observable<SsabCart> {
    return this.multiCartService.getCarts().pipe(
      map((carts) => carts.find(cart => cart && (cart as SsabCart).cartType === CART_ID))
    ) as Observable<SsabCart>;
  }

  removeEntry(entry: SsabOrderEntry): void {
    this.userIdService.getUserId().pipe(take(1))
      .subscribe((userId) => {
        this.multiCartService.removeEntry(userId, CART_ID, entry.entryNumber);
      });
  }

  updateEntry(entry: SsabOrderEntry): void {
    this.userIdService.getUserId().pipe(take(1))
      .subscribe((userId) => {
        this.store.dispatch(
          new SsabCartUpdateEntry(userId, CART_ID, entry, {successMessage: 'ssab.update.ContactSalesProductList.product.checkout.notification.success', errorMessage: 'ssab.update.ContactSalesProductList.product.checkout.notification.failed'})
        );
      });
  }
}
