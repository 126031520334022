import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CmsConfig, provideConfig} from '@spartacus/core';
import {SsabHamburgerMenuComponent} from './ssab-hamburger-menu.component';

@NgModule({
  declarations: [SsabHamburgerMenuComponent],
  imports: [
    CommonModule,
  ],
  exports: [SsabHamburgerMenuComponent],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        HamburgerMenuComponent: {
          component: SsabHamburgerMenuComponent,
        },
      }
    })
  ]
})
export class SsabHamburgerMenuModule {
}
