import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {CmsConfig, I18nModule, provideConfig} from '@spartacus/core';
import {GenericLinkModule, MediaModule, OutletModule} from '@spartacus/storefront';
import {SsabBackgroundImageComponent} from './ssab-background-image.component';


@NgModule({
    imports: [
        CommonModule,
        OutletModule,
        I18nModule,
        MediaModule,
        GenericLinkModule,
    ],
    declarations: [SsabBackgroundImageComponent],
    exports: [SsabBackgroundImageComponent],
    providers: [
        provideConfig(<CmsConfig>{
            cmsComponents: {
                SsabBackgroundImageComponent: {
                    component: SsabBackgroundImageComponent,
                },
            },
        })
    ]
})
export class SsabBackgroundImageModule {
}
