import {Injectable} from '@angular/core';
import {ProductActions, ProductSearchService, StateWithProduct} from '@spartacus/core';
import {SsabSearchConfig} from '../../../model/misc.model';
import {Store} from "@ngrx/store";
import {Actions, ofType} from "@ngrx/effects";
import {take} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class SsabProductSearchService extends ProductSearchService {

  constructor(protected store: Store<StateWithProduct>,
              private actions$: Actions,) {
    super(store);
  }

  search(query: string, searchConfig?: SsabSearchConfig): void {
    this.store.dispatch(
      new ProductActions.SearchProducts({
        queryText: query,
        searchConfig,
      })
    );

    const fullSearchConfig = {...searchConfig, suggestiveMode: false, disableSpinner: true};
    this.actions$.pipe(
      ofType(ProductActions.SEARCH_PRODUCTS_SUCCESS),
      take(1)
    ).subscribe(
      action => this.store.dispatch(new ProductActions.SearchProducts({
          queryText: query,
          searchConfig: fullSearchConfig
        })
      )
    );
  }
}
