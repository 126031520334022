import {DIALOG_TYPE, LayoutConfig} from '@spartacus/storefront';
import {SsabBaseModalComponent} from './ssab-base-modal.component';

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    BASE_MODAL = 'BASE_MODAL',
  }
}

export const baseModalLayout: LayoutConfig = {
  launch: {
    BASE_MODAL: {
      inlineRoot: true,
      component: SsabBaseModalComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};

export interface SsabBaseModalDialogData {
  titleText: string;
  bodyText: string;
  buttonText: string;
}
