import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {GlobalMessageService, GlobalMessageType, RoutingService, Translatable} from "@spartacus/core";
import {distinctUntilChanged, map, switchMap} from "rxjs/operators";
import {SsabCertificateDocument, SsabDocumentIdentifier, SsabRelatedDocumentList} from "../../model/document.model";
import {SsabUserService} from "../../service/user/ssab-user.service";
import {Observable, of} from "rxjs";
import {SsabScannerService} from "../../service/scanner/ssab-scanner.service";
import {SsabGlobalconfigurationService} from "../../service/general/configuration/ssab-globalconfiguration.service";

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
})
export class ScannerProductCardComponent implements OnInit {

  productData$: Observable<SsabCertificateDocument>;
  documentData$: Observable<SsabRelatedDocumentList>;
  searchTerm: string;
  certificateId: string
  noBackLink: boolean;

  constructor(private router: Router,
              protected routingService: RoutingService,
              protected userService: SsabUserService,
              protected globalMessageService: GlobalMessageService,
              protected scannerService: SsabScannerService,
              private globalConfigurationService: SsabGlobalconfigurationService,) {
  }

  ngOnInit(): void {
    this.productData$ = this.routingService.getRouterState().pipe(
      distinctUntilChanged((x, y) => {
        return x.state.url === y.state.url;
      }),
      switchMap((routingData) => {
        this.searchTerm = routingData.state.queryParams.searchTerm;
        this.noBackLink = routingData.state.queryParams.noBackLink === 'true';
        this.certificateId = routingData.state.queryParams.certificateId;
        return this.userService.searchByMaterialId(this.searchTerm);
      }),
      map(list => list.documents[0])
    );

    this.documentData$ = this.productData$.pipe(
      switchMap(p =>
        p.orderNumber === '' || true ?//related docs disabled for now
          of({
            certificates: [p]
          } as SsabRelatedDocumentList)
          : this.userService.getRelatedDocuments(
            p.orderNumber, p.buyerId, false
          ).pipe(map(docs => ({
              ...docs,
              certificates: docs.certificates
                ?.filter(cert =>
                  cert.certificateId === this.certificateId
                )
            }))
          )
      )
    );
  }

  addToList(data: SsabDocumentIdentifier, type: string): void {
    if (data) {
      this.scannerService.addToCart(data);
      this.globalMessageService.add({key: 'ssab.scanner.documents.addedToList'} as Translatable, GlobalMessageType.MSG_TYPE_INFO, 6000);
    }
  }

  onScanMoreProducts() {
    this.router.navigate(['/scanner']);
  }

  getDateFormat(): Observable<string> {
    return this.globalConfigurationService.getDateFormat();
  }
}
