import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {CustomFormValidators, LAUNCH_CALLER, LaunchDialogService} from "@spartacus/storefront";
import {SsabUserService} from "../../service/user/ssab-user.service";
import {SsabScannerService} from "../../service/scanner/ssab-scanner.service";
import {GlobalMessageService} from "@spartacus/core";

@Component({
  selector: 'app-email-dialog',
  templateUrl: './email-dialog.component.html',
})
export class ScannerEmailDialogComponent implements OnInit {
  form: UntypedFormGroup;
  emails = [];

  constructor(protected fb: UntypedFormBuilder,
              protected launchDialogService: LaunchDialogService,
              private userService: SsabUserService,
              private scannerService: SsabScannerService,
              protected globalMessageService: GlobalMessageService,) {
  }

  ngOnInit(): void {
    this.form = this.fb.group(
      {
        email: ['', [CustomFormValidators.emailValidator]]
      }
    );
  }

  removeEmail(email: string): void {
    this.emails = this.emails.filter(e => e !== email);
  }

  send() {
    if (this.form.valid || (this.form.controls.email.value === "" && this.emails?.length !== 0)) {
      this.addAdditionalEmail();
      if (this.emails?.length !== 0) {
        this.userService.sendDocumentsAsEmail({
          documentIdentifiers: this.scannerService.getInitialCartData(), docType: this.scannerService.getInitialCartData()[0].docType
        }, this.emails.reduce((str, eml) => str === '' ? eml : (str + ';' + eml), ''));
      }
    }
  }

  public dismiss(reason?: any): void {
    this.launchDialogService.clear(LAUNCH_CALLER.SCANNER_SEND_EMAIL);
    this.launchDialogService.closeDialog('closed');
  }

  addAdditionalEmail(): void {
    if (this.form.valid && this.form.controls.email.value !== "") {
      if (this.emails.find(e => e === this.form.controls.email.value) === undefined) {
        this.emails.push(this.form.controls.email.value);
        this.form.controls.email.setValue("");
        this.form.controls.email.setErrors(null); // don't show red border right after clearing the field
      }
    }
  }

  isInvalid(): boolean {
    return !this.form.controls.email.valid;
  }
}
