import {Injectable, OnDestroy} from "@angular/core";
import {NgbDate, NgbDateParserFormatter, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {OccConfig} from "@spartacus/core";
import {Observable, Subscription} from "rxjs";
import {DateUtils} from "../../../shared/util/date-utils";
import {SsabGlobalconfigurationService} from "../../../service/general/configuration/ssab-globalconfiguration.service";

@Injectable({
  providedIn: 'root',
})
export class SsabNgbDateParserFormatter extends NgbDateParserFormatter implements OnDestroy {
  dateFormat: string;
  private subscriptions: Subscription[] = [];

  constructor(protected config: OccConfig,
              private globalConfigurationService: SsabGlobalconfigurationService,) {
    super();
    this.subscriptions.push(
      this.globalConfigurationService.getDateFormat()
        .subscribe(
          format => this.dateFormat = format, err => console.error(err)
        )
    );
  }

  ngOnDestroy(): void {
    /* This method has to be called manually at component level on destroy method. ngOnDestroy is not automatically called on injectables */
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }

  parse(value: any): NgbDateStruct {
    if (value !== null && value !== undefined) {
      const date: Date = DateUtils.convertDate(value);
      return this.parseDate(date);
    }
    return null;
  }

  parseDate(date: Date): NgbDate {
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  format(date: NgbDateStruct): string {
    if (date !== undefined && date !== null) {
      const date1: Date = new Date(date.year, date.month - 1, date.day);
      return this.formatDate(date1);
    }
    return null;
  }

  formatDate(date: Date): string {
    if (date !== undefined && date !== null) {
      return DateUtils.convertUIDate(this.dateFormat, date);
    }
    return null;
  }

  toDate(ngbDate: NgbDateStruct): Date {

    return new Date(ngbDate.year + "/" + ngbDate.month + "/" + ngbDate.day);
  }

  toString(date: NgbDateStruct): string {
    return date.year + "-" + date.month + "-" + date.day;
  }

  getDateFormat(): Observable<string> {
    return this.globalConfigurationService.getDateFormat();
  }
}
