<!-- some.component.html -->
<div
  id="warning"
  class="rotation-warning"
  [ngClass]="{ hidden: isPortraitMode }"
>
  {{'ssab.scanner.qr.orientationWarning'|cxTranslate}}
</div>
<div class="wrapper">
  <div class="camera-wrapper">
    <div class="camera-div">
      <video poster="data:image/gif,AAAA" class="camera" #videoElement></video>
      <div class="overlay-element"></div>
      <div class="laser" *ngIf="scannerOn"></div>
      <app-ocr-initialize *ngIf="ocrOn"></app-ocr-initialize>
    </div>

    <h6 class="text-uppercase mt-3 mb-0">{{'ssab.scanner.qr.start'|cxTranslate}}</h6>

    <div class="button-wrapper">
      <button class="btn btn-primary" (click)="scan()">
        {{'ssab.scanner.qr.scan'|cxTranslate}}
      </button>
      <button class="btn btn-primary" (click)="ocr()" [disabled]="ocrOn">
        {{'ssab.scanner.qr.ocr'|cxTranslate}}
      </button>
    </div>
  </div>

  <canvas class="canvas" #canvasElement></canvas>
</div>
