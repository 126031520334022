<div class="cx-sorting bottom" *ngIf="pagination?.totalResults > 0">
  <div class="row no-margin">
    <div class="col-auto col-no-padding vertical-align-item justify-content-start">
      <ssab-cx-page-size
        [selectedPageSize]="selectedPageSize"
        [skipLocationChange]="skipLocationChange"
        (pageSizeEvent)="pageSizeChanged($event)"></ssab-cx-page-size>
      <div class="pr-md-4 pl-md-4 pl-2 d-block d-sm-none">
        <span class="text-gray">
                    {{'productList.showingNItems' |
            cxTranslate:
            {
                startNumber: pagination?.currentPage == 0 ? 1 : (pagination?.pageSize * pagination?.currentPage) + 1,
                endNumber: (pagination?.pageSize * (pagination?.currentPage + 1) > pagination?.totalResults ?
                    pagination?.totalResults : pagination?.pageSize * (pagination?.currentPage + 1)),
                totalItems: pagination?.totalResults
            }
          }}
        </span>
      </div>
    </div>
    <div class="col col-no-padding vertical-align-item justify-content-end">
      <div class="pr-4 pl-4 d-none d-sm-block">
        <span class="text-opacity">
                    {{'productList.showingNItems' |
            cxTranslate:
            {
                startNumber: pagination?.currentPage == 0 ? 1 : (pagination?.pageSize * pagination?.currentPage) + 1,
                endNumber: (pagination?.pageSize * (pagination?.currentPage + 1) > pagination?.totalResults ?
                    pagination?.totalResults : pagination?.pageSize * (pagination?.currentPage + 1)),
                totalItems: pagination?.totalResults
            }
          }}
        </span>
        <span class="text-gray" *ngIf="pagination?.totalResults == 0">
                  {{'productList.noResult' | cxTranslate }}
        </span>
      </div>

      <div
        class="cx-pagination pt-4 pt-md-0"
        aria-label="product search pagination"
      >
        <ssab-cx-pagination
          *ngIf="isEventEmitterPagination"
          [pagination]="pagination"
          (viewPageEvent)="pageChange($event)"
        ></ssab-cx-pagination>

        <ssab-cx-pagination
          *ngIf="!isEventEmitterPagination"
          [pagination]="pagination"
          queryParam="currentPage"
          [defaultPage]="0"
        ></ssab-cx-pagination>

      </div>
    </div>
  </div>
</div>
