import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SsabNotificationComponent } from './ssab-notifications.component';
import { IconModule } from '@spartacus/storefront';
import { SsabNotificationsOccModule } from '../../../shared/notifications/ssab-notifications.module';
import {SsabPipesModule} from '../utils/safe-html/ssab-pipes.module';

@NgModule({
    imports: [CommonModule, IconModule, SsabPipesModule, SsabNotificationsOccModule],
    declarations: [SsabNotificationComponent],
    exports: [SsabNotificationComponent],
})
export class SsabNotificationsModule { }
