import { Component, ViewEncapsulation } from '@angular/core';
import {CustomerEmulationComponent} from '@spartacus/asm/components';

@Component({
  selector: 'ssab-cx-customer-emulation',
  templateUrl: './ssab-customer-emulation.component.html',
  styleUrls: ['./ssab-customer-emulation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SsabCustomerEmulationComponent extends CustomerEmulationComponent{

}
