import {Component} from '@angular/core';
import {SsabScannerService} from "../../service/scanner/ssab-scanner.service";
import {LAUNCH_CALLER, LaunchDialogService} from "@spartacus/storefront";
import {SsabScannerClearDocumentsDialogData} from "./dropdown-menu-layout.config";


@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
})
export class ScannerDropdownMenuComponent {
  emailSent: boolean;

  constructor(
    private scannerService: SsabScannerService,
    protected launchDialogService: LaunchDialogService,
  ) {
    this.launchDialogService.data$
      .subscribe((dialogData: SsabScannerClearDocumentsDialogData) => {
        this.emailSent = dialogData.emailSent;
      })
      .unsubscribe();
  }

  public dismiss(reason?: any): void {
    this.launchDialogService.clear(LAUNCH_CALLER.SCANNER_CLEAR_DOCUMENTS);
    this.launchDialogService.closeDialog('closed');
  }

  deleteCartList() {
    this.scannerService.clearList();
    this.dismiss();
  }
}
