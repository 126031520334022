import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {OrderFacade} from "@spartacus/order/root";
import {Observable, Subscription} from 'rxjs';
import {SsabOrder} from '../../../model/order.model';
import {SsabUserService} from '../../../service/user/ssab-user.service';
import {OrderConfirmationItemsComponent} from '@spartacus/order/components/order-confirmation/order-confirmation-items/order-confirmation-items.component';

@Component({
  selector: 'ssab-order-confirmation-items',
  templateUrl: 'ssab-order-confirmation-items.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabOrderConfirmationItemsComponent implements OnInit, OnDestroy {
  order$: Observable<SsabOrder>;
  showPricesOrConsignmentPricesStock$: Observable<boolean>;
  subscriptions: Subscription = new Subscription();

  constructor(protected orderFacade: OrderFacade, protected userService: SsabUserService) {
  }

  ngOnInit() {
    this.order$ = this.orderFacade.getOrderDetails() as Observable<SsabOrder>;
    this.subscriptions.add(
      this.order$.subscribe((order) =>
        this.showPricesOrConsignmentPricesStock$ = this.userService.isShowPricesOrConsignmentStockPrices(!order?.consignment))
    );
  }

  ngOnDestroy() {
    this.orderFacade.clearPlacedOrder();
    this.subscriptions.unsubscribe();
  }
}
