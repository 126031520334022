export const ssabOccContactFormConfig = {
  backend: {
    occ: {
      endpoints: {
        sendStockAvailabilityRequest: 'users/${userId}/contactforms/stockAvailabilityRequest?fields=DEFAULT' //&spinner=true
      }
    }
  }
};

