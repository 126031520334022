import {Component, ViewEncapsulation} from '@angular/core';
import {AsmSessionTimerComponent} from '@spartacus/asm/components';

@Component({
  selector: 'ssab-cx-asm-session-timer',
  templateUrl: './ssab-asm-session-timer.component.html',
  styleUrls: ['./ssab-asm-session-timer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SsabAsmSessionTimerComponent extends AsmSessionTimerComponent{

}
