import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SsabCertificateDocument} from "../../model/document.model";
import {SsabGlobalconfigurationService} from "../../service/general/configuration/ssab-globalconfiguration.service";
import {Observable} from "rxjs";


@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
})
export class ScannerProductListComponent {
  @Input() productDataList: SsabCertificateDocument[];
  @Output() clickDataList = new EventEmitter<SsabCertificateDocument>();
  @Input() scannedCode: string;

  constructor(
    private globalConfigurationService: SsabGlobalconfigurationService) {
  }

  sendValueToParent(data: SsabCertificateDocument) {
    if (data) {
      this.clickDataList.emit(data);
    }
  }

  getDateFormat(): Observable<string> {
    return this.globalConfigurationService.getDateFormat();
  }
}
