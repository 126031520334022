import {StateUtils} from "@spartacus/core";
import {createFeatureSelector, createSelector, MemoizedSelector} from "@ngrx/store";
import {SsabGlobalConfiguration} from "../../../model/misc.model";

export const GLOBAL_CONFIGURATION_FEATURE = 'ssabGlobalConfigurationFeature';

const geGlobalConfigurationContentSelector = (state: SsabGlobalConfigurationState) => {
  return state.configuration;
};

export const getGlobalConfigurationsState: MemoizedSelector<SsabStateWithGlobalConfiguration, SsabGlobalConfigurationsState> =
  createFeatureSelector<SsabGlobalConfigurationsState>(GLOBAL_CONFIGURATION_FEATURE);

export const getActiveGlobalConfigurationsState: MemoizedSelector<
  SsabStateWithGlobalConfiguration,
  StateUtils.LoaderState<SsabGlobalConfigurationState>
  > = createSelector(getGlobalConfigurationsState, (confState: SsabGlobalConfigurationsState) => confState.active);

export const getGlobalConfigurationState: MemoizedSelector<SsabStateWithGlobalConfiguration, SsabGlobalConfigurationState> = createSelector(
  getActiveGlobalConfigurationsState,
  (state) => StateUtils.loaderValueSelector(state),
);

export const getGlobalConfigurationContent: MemoizedSelector<SsabStateWithGlobalConfiguration, SsabGlobalConfiguration> = createSelector(
  getGlobalConfigurationState,
  geGlobalConfigurationContentSelector,
);

export interface SsabStateWithGlobalConfiguration {
  [GLOBAL_CONFIGURATION_FEATURE]: SsabGlobalConfigurationState;
}

export interface SsabGlobalConfigurationsState {
  active: StateUtils.LoaderState<SsabGlobalConfigurationState>;
}

export interface SsabGlobalConfigurationState {
  configuration: SsabGlobalConfiguration;
  loaded: boolean;
}

export const GLOBAL_CONFIGURATION_DATA = '[Global configuration] Data';
export const LOAD_GLOBAL_CONFIGURATION = '[Global configuration] Load global configuration';

export class SsabLoadGlobalConfiguration extends StateUtils.LoaderLoadAction {
  readonly type = LOAD_GLOBAL_CONFIGURATION;

  constructor() {
    super(GLOBAL_CONFIGURATION_DATA);
  }
}

export const LOAD_GLOBAL_CONFIGURATION_SUCCESS = '[Global configuration] Load global configuration success';

export class SsabLoadGlobalConfigurationSuccess extends StateUtils.LoaderLoadAction {
  readonly type = LOAD_GLOBAL_CONFIGURATION_SUCCESS;

  constructor(public configuration: SsabGlobalConfiguration) {
    super(GLOBAL_CONFIGURATION_DATA);
  }
}
