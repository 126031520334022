<div class="cx-order-history-search">
  <div id="cx-order-history-header">
    <h2 class="text-white cx-page-header-title">
      {{ 'orderHistory.orderHistory' | cxTranslate }}
    </h2>
    <!-- Tabs -->
    <div class="cx-order-history-tab row no-gutters w-100">
      <div class="col-12 col-lg-4 pb-4 pb-lg-0">
        <h3 class="pr-4 d-inline"
            [ngClass]="selectedView == orderTypes.Orders ? 'active': ''"
            (click)="changeOrderType(orderTypes.Orders)">{{'orderHistory.orders' | cxTranslate}}</h3>
        <h3 class="d-inline"
            [ngClass]="selectedView == orderTypes.OrderItem ? 'active': ''"
            (click)="changeOrderType(orderTypes.OrderItem)">{{'orderHistory.orderItems' | cxTranslate}}</h3>
      </div>
      <div class=" col-12 col-lg-8 row no-gutters"  *ngIf="loadingComplete$| async">
        <ng-container *ngIf="user$ | async as user">
          <div class="col-12 col-md-8 col-lg-8 pt-3 pt-md-0 pb-4 pb-lg-0 d-flex ml-lg-auto justify-content-lg-end text-bold"
               *ngIf="user.externalUser">
            {{ 'ssab.order.history.delayed.orders.subscription' | cxTranslate }}
            <i class="ssab-icon icon-inline ml-auto ml-md-3 float-right float-md-none icon-cursor"
               [ngClass]="user.lateOrderSubscription ? 'icon-switch-blue': 'icon-switch'"
               (click)="activateDeactivateDelayedEmails(!user.lateOrderSubscription)"></i>
          </div>
        </ng-container>
        <div class="col-auto col-md-4 col-lg-4 d-flex text-right order-3 order-md-2 text-bold col-no-padding ml-auto d-lg-none d-md-block">
          <span (click)="showFilterOptions($event)">
            {{ 'ssab.order.history.filter.true' | cxTranslate }}
            <i class="ssab-icon icon-system-filter icon-inline ml-3 icon-cursor icon-white"></i>
          </span>
        </div>
        <ng-container *ngIf="user$ | async as user">
          <div class="d-block d-lg-none col-auto order-2 my-auto" [ngClass]="{'order-md-1':  !user.externalUser}">
            {{'common.swipe.instructions' | cxTranslate}}
          </div>
        </ng-container>
      </div>
    </div>
  </div>


  <ng-container *ngIf="orders$| async as orders">
    <ssab-cx-order-history-form [orders]="orders"
                                [selectedView]="selectedView"
                                [searchOrderHistoryForm]="searchOrderHistoryForm"
                                (resetForm)="resetForm()"
    ></ssab-cx-order-history-form>


    <ng-container *ngIf="orders && orders?.orders?.length> 0; else noOrder">
      <!-- BODY -->
      <div class="cx-order-history-body">
        <ng-container *ngIf="orders?.pagination?.totalResults > 0; else noOrder">
          <!-- TABLES-->
          <ssab-cx-order-history-tables [orders]="orders?.orders"
                                        [selectedView]="selectedView"
                                        [activeSort]="activeSort$.getValue()"
                                        (sortChange)="sort($event)"
          ></ssab-cx-order-history-tables>
          <!-- Select Form and Pagination Bottom -->
          <ssab-cx-pagination-bottom
            [pagination]="orders?.pagination"
            (viewPageEvent)="pageChange($event)"
          ></ssab-cx-pagination-bottom>
          <ng-container *ngIf="selectedView !== orderTypes.Orders">
            <!--- Buttons for download documents ---->
            <div class="cx-order-history-download row justify-content-center justify-content-sm-end no-margin is-sticky">
              <div class="col-12 col-sm-auto">
                <button class="btn btn-primary btn-default-height btn-block" (click)="orderItemDownload(orders?.pagination.totalResults)">
                  <i class="ssab-icon icon-system-download icon-inline pr-2"></i>
                  {{'ssab.order.history.download.csv' | cxTranslate}}
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>

  <!-- NO ORDER CONTAINER -->
  <ng-template #noOrder>
    <div
      class="empty-data-div row no-margin text-center">
      <div [ngClass]="'col-12 col-sm-6'"
      >
        <h4 class="text-primary">{{ 'orderHistory.noOrders' | cxTranslate }}</h4>
        <a class="btn btn-primary btn-lg mt-3" (click)="showFilterOptions($event)">
          {{ 'ssab.order.history.change.criteria' | cxTranslate }}
        </a>
      </div>
    </div>
  </ng-template>

</div>
