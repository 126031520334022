import {Component} from '@angular/core';
import {ForgotPasswordComponent} from '@spartacus/user/profile/components';

@Component({
  selector: 'ssab-cx-forgot-password',
  templateUrl: './ssab-forgot-password.component.html',
})
export class SsabForgotPasswordComponent extends ForgotPasswordComponent{

}
