import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CmsConfig, ConfigModule, I18nModule, UrlModule} from '@spartacus/core';
import {SsabMiniCartComponent} from "./ssab-mini-cart.component";
import {RouterModule} from "@angular/router";
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {IconModule, MediaModule} from '@spartacus/storefront';
import {SsabMiniCartNormalComponent} from './normal/ssab-mini-cart-normal.component';
import {SsabMiniCartConsignmentComponent} from './consignment/ssab-mini-cart-consignment.component';


@NgModule({

  imports: [
    CommonModule,
    I18nModule,
    RouterModule,
    UrlModule,
    NgbPopoverModule,
    MediaModule,
    IconModule,
    ConfigModule.withConfig({
      cmsComponents: {
        MiniCartComponent: {
          component: SsabMiniCartComponent
        }
      },
    } as CmsConfig),
  ],
  declarations: [SsabMiniCartComponent,
    SsabMiniCartNormalComponent,
    SsabMiniCartConsignmentComponent],
  exports: [SsabMiniCartComponent,
    SsabMiniCartNormalComponent,
    SsabMiniCartConsignmentComponent]
})
export class SsabMiniCartModule {
}
