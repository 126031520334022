import {Injectable} from '@angular/core';

import {ConverterService, Occ, OccProductSearchPageNormalizer, ProductSearchPage} from "@spartacus/core";

@Injectable({providedIn: 'root'})
export class SsabOccProductSearchPageNormalizer extends OccProductSearchPageNormalizer {
  constructor(private converterService2: ConverterService) {
    super(converterService2);
  }


  convert(source: Occ.ProductSearchPage, target: ProductSearchPage = {}): ProductSearchPage {
    source.products?.forEach(product => product.stock.stockLevel = (product.stock as any).stockLevelDouble);
    return super.convert(source, target);
  }
}
