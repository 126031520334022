import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {GlobalMessageService, GlobalMessageType, RoutingService, Translatable} from "@spartacus/core";
import {distinctUntilChanged, switchMap} from "rxjs/operators";
import {SsabCertificateDocumentList, SsabDocumentIdentifier} from "../../model/document.model";
import {SsabUserService} from "../../service/user/ssab-user.service";
import {Observable} from "rxjs";
import {SsabScannerService} from "../../service/scanner/ssab-scanner.service";
import {SsabGlobalconfigurationService} from "../../service/general/configuration/ssab-globalconfiguration.service";
import {SsabScannerDataValidationService} from "../../service/scanner/ssab-scanner-data-validation.service";

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
})
export class ScannerOrderComponent implements OnInit {

  orderData$: Observable<SsabCertificateDocumentList>;
  orderNumber: string;
  originalCertificateId: string;
  searchTerm: string;

  constructor(private router: Router,
              protected routingService: RoutingService,
              protected userService: SsabUserService,
              protected globalMessageService: GlobalMessageService,
              protected scannerService: SsabScannerService,
              protected scannerDataValidationService: SsabScannerDataValidationService,
              private globalConfigurationService: SsabGlobalconfigurationService,) {
  }

  ngOnInit(): void {
    this.orderData$ = this.routingService.getRouterState().pipe(
      distinctUntilChanged((x, y) => {
        return x.state.url === y.state.url;
      }),
      switchMap((routingData) => {
        this.orderNumber = routingData.state.queryParams.orderNumber;
        this.originalCertificateId = routingData.state.queryParams.originalCertificateId;
        this.searchTerm = routingData.state.queryParams.originalCertificateId;
        return this.userService.getCertificateDocuments(
          {
            docType: 'Certificate',
            keyword: this.orderNumber,
            pageNumber: 0,
            pageSize: 999,
            customerIds: [],
            addLeadingWildCard: false,
            addTrailingWildCard: false
          },
          this.orderNumber
        );
      })
    );
  }

  addToList(data: SsabDocumentIdentifier, type: string): void {
    if (data) {
      this.scannerService.addToCart(data);
      this.globalMessageService.add({key: 'ssab.scanner.documents.addedToList'} as Translatable, GlobalMessageType.MSG_TYPE_INFO, 6000);
    }
  }

  onScanMoreProducts() {
    this.router.navigate(['/scanner']);
  }

  getDateFormat(): Observable<string> {
    return this.globalConfigurationService.getDateFormat();
  }

  convertMaterialId(materialId: string): string {
    return this.scannerDataValidationService.convertMaterialId(materialId);
  }
}
