import {Inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {filter, map, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {SSAB_GTM_PRODUCT, SsabGtmProduct} from './ssab-gtm.action';
import {SsabGtmService} from './ssab-gtm.service';
import {AuthActions, ProductActions} from '@spartacus/core';
import {CartActions} from '@spartacus/cart/base/core';
import {GTMEvent} from '../../../model/gtm/gtm-product.model';
import {PageHistoryService} from '../../util/page-history.service';
import {SSAB_CART_ADD_ENTRY, SSAB_CART_UPDATE_ENTRY, SsabCartAddEntry, SsabCartUpdateEntry} from '../../cart/ssab-entry.action';
import {SsabOrderEntry} from '../../../model/cart.model';
import {SsabActiveCartService} from "../../cart/ssab-active-cart.service";
import {SsabProduct} from "../../../model/product.model";
import {DOCUMENT} from "@angular/common";

@Injectable()
export class SsabGtmEffects {

  productAddToCart$;
  productUpdateCartQuantity$;
  productsSearch$;

  constructor(
    private actions$: Actions,
    protected gtmService: SsabGtmService,
    private pageHistoryService: PageHistoryService,
    protected aActiveCartService: SsabActiveCartService,
    @Inject(DOCUMENT) private document: Document,
  ) {

    this.actions$.subscribe((action) => {
      switch (action.type) {
        case SSAB_GTM_PRODUCT:
          const ssabGtmProduct = action as SsabGtmProduct;
          if (ssabGtmProduct.isAction) {
            this.gtmService
              .pushProducts(ssabGtmProduct.products, null,
                ssabGtmProduct.quantity, ssabGtmProduct.event, ssabGtmProduct.pageLabel);
          } else {
            if (ssabGtmProduct.runAfter) {
              this.gtmService.setProductListData(ssabGtmProduct.products, ssabGtmProduct.pageLabel, ssabGtmProduct.event);
            } else {
              this.gtmService
                .pushProducts(ssabGtmProduct.products, ssabGtmProduct.pageLabel,
                  ssabGtmProduct.quantity, ssabGtmProduct.event, null);
            }

          }
          break;
        case CartActions.CART_REMOVE_ENTRY:
          const ssabCartRemove = action as CartActions.CartRemoveEntry;
          // pushing here activecart.entry because with gtmService not the removed entry is fetched but the one that received "old" entry number
          // e.g. cart with entries 0,1 -> delete 0 -> 1 becomes 0 -> gtmService fetches "old" 1 (current entryNumber is 0)
          // therefore fetch the entry before entries state is changed (entry removed)
          // also fetching cart/cart entries via gtm triggers unnecessary and slow cart calculation against ERP
          aActiveCartService.getActiveCartId().pipe(
            // fire gtm event for active cart only
            filter(activeCartId => activeCartId === ssabCartRemove.payload.cartId),
            switchMap(() => aActiveCartService.getEntries()),
            map(entries => entries.filter(entry => entry.entryNumber === +ssabCartRemove.payload.entryNumber)),
            take(1)
          ).subscribe(entry => {
            this.gtmService.pushRemoveCartEntry(entry[0] as SsabOrderEntry);
          });
          break;

        case AuthActions.LOGIN:
          this.gtmService.pushUserLogin();
          break;
      }
    });


    this.productsSearch$ = createEffect(() => this.actions$.pipe(
      ofType(ProductActions.SEARCH_PRODUCTS_SUCCESS),
      switchMap((action: ProductActions.SearchProductsSuccess) => {
          const events = [];
          if (this.pageHistoryService.getCurrentPage().pageId === GTMEvent.ProductListingPageID
            // exclude action search product sucess from search box
            && !this.document.body.classList.contains('searchbox-is-active')) {
            if (action.payload?.products?.find((p: SsabProduct) => p.suggestiveMode===false) !== undefined) {
              events.push(new SsabGtmProduct(action.payload.products.map(p => p.code),
                GTMEvent.ProductsImpressionView,
                this.pageHistoryService.getCurrentPage().name,
                false,
                null,
                true
              ));
            }
          }

          return events;
        }
      )
    ));


    this.productAddToCart$ = createEffect(() => this.actions$.pipe(
      ofType(SSAB_CART_ADD_ENTRY),
      withLatestFrom(this.aActiveCartService.getActiveCartId()),
      // fire gtm event for active cart only
      filter(([action, actCartId]) => actCartId === (action as SsabCartAddEntry).cartId),
      map(([action, actCartId]) =>
        new SsabGtmProduct([(action as SsabCartAddEntry).entry.product.code],
          GTMEvent.AddToCart,
          this.pageHistoryService.getCurrentPage().name,
          true,
          (action as SsabCartAddEntry).entry.quantity)
      )
    ));


    this.productUpdateCartQuantity$ = createEffect(() => this.actions$.pipe(
      ofType(SSAB_CART_UPDATE_ENTRY),
      withLatestFrom(this.aActiveCartService.getActiveCartId()),
      // fire gtm event for active cart only
      filter(([action, actCartId]) => actCartId === (action as SsabCartAddEntry).cartId),
      map(([action, actCartId]) =>
        new SsabGtmProduct([(action as SsabCartUpdateEntry).entry.product.code],
          (action as SsabCartUpdateEntry).entry.GTMevent === 'remove' ? GTMEvent.RemoveFromCart : GTMEvent.AddToCart,
          this.pageHistoryService.getCurrentPage().name,
          true,
          (action as SsabCartUpdateEntry).entry.GTMqty || (action as SsabCartUpdateEntry).entry.quantity
        )
      )
    ));
  }


}
