import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subscription} from 'rxjs';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {SsabOrder} from '../../../model/order.model';
import {SsabGtmService} from '../../../service/analytics/gtm/ssab-gtm.service';
import {SsabGlobalconfigurationService} from '../../../service/general/configuration/ssab-globalconfiguration.service';
import {OrderFacade} from '@spartacus/order/root';
import {SsabActiveCartService} from '../../../service/cart/ssab-active-cart.service';
import {WindowRef} from '@spartacus/core';
import {SsabGlobalConfiguration} from "../../../model/misc.model";
import {getEmails} from "../../shared/utils/functions/ssab-functions-utils";
import {SsabUser} from "../../../model/user.model";
import {SsabUserService} from "../../../service/user/ssab-user.service";

@Component({
  selector: 'ssab-order-confirmation-header',
  templateUrl: 'ssab-order-confirmation-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabOrderConfirmationHeaderComponent implements OnInit, OnDestroy {
  @Input()
  order$: Observable<SsabOrder>;
  isConsignmentCart$: Observable<boolean>;
  configuration$: Observable<SsabGlobalConfiguration>;
  user$: Observable<SsabUser>;
  subscriptions: Subscription = new Subscription();

  constructor(protected orderFacade: OrderFacade,
              protected gtmService: SsabGtmService,
              protected activeCartService: SsabActiveCartService,
              protected globalConfiguration: SsabGlobalconfigurationService,
              protected aWinRef: WindowRef,
              protected userService: SsabUserService) {
    this.configuration$ = this.globalConfiguration.getGlobalConfiguration();
    this.user$ = userService.get();
  }

  ngOnInit(): void {
    if (!this.order$) {
      this.order$ = this.orderFacade.getOrderDetails() as Observable<SsabOrder>;
    }
    this.subscriptions.add(
      this.order$.pipe(filter((order) => order != null))
        .pipe(distinctUntilChanged((prev, curr) => prev.code === curr.code))
        .subscribe((order) => {
            this.isConsignmentCart$ = of(order?.consignment);
            if (order.user && order.user.uid) {
              this.gtmService.pushOrderConfirmation(order.user.uid,
                order.code,
                JSON.parse(order.gtmEntrydata),
                'Online store',
                order.totalPrice.value,
                order.totalTax.value,
                order.deliveryCost.value,
                null
              );
            }
          }
        )
    );
  }

  ngOnDestroy(): void {
    this.orderFacade.clearPlacedOrder();
    //Refreshing the page so SsabWishListFacade and SsabMiniCartBaseComponent can register new cart
    this.subscriptions.unsubscribe();
    this.aWinRef.location.reload();
  }

  emails(user: SsabUser): string {
    return getEmails(user.salesContacts);
  }
}
