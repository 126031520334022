import {OccConfig} from '@spartacus/core';

export const ssabOccAsmConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        refreshEmulatedUser: 'users/${userId}?asmRefresh=true'
      }
    }
  }
};
