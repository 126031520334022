import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {CmsComponentData, NavigationService} from '@spartacus/storefront';
import {ActivatedRouterStateSnapshot, CmsBannerComponent, RoutingService} from '@spartacus/core';
import {pluck} from 'rxjs/operators';
import {Observable, Subscription} from "rxjs";


@Component({
  selector: 'ssab-cx-background-image',
  templateUrl: './ssab-background-image.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabBackgroundImageComponent implements OnInit, OnDestroy {

  data$: Observable<CmsBannerComponent>;
  pageClass: string;
  protected routeState$: Observable<ActivatedRouterStateSnapshot>;
  subscriptions: Subscription = new Subscription();

  constructor(
    protected componentData: CmsComponentData<CmsBannerComponent>,
    protected service: NavigationService,
    private routingService: RoutingService,
    protected cdr: ChangeDetectorRef
  ) {
    this.data$ = this.componentData.data$;
    this.routeState$ = this.routingService
      .getRouterState()
      .pipe(pluck('state'));
  }

  ngOnInit(): void {
    //Add class for specific page
    this.subscriptions.add(
      this.routeState$.subscribe(state => {
        this.pageClass = 'cx-background-image-' + state.context.id.split('/').pop().replace(/[^a-zA-Z ]/g, '');
        if (state.context.id.indexOf('scanner') != -1) {
          this.pageClass = 'cx-background-image-scanner-app';
        }
        this.cdr.detectChanges();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
