<ng-container *ngIf="data$ | async as data">
  <h4 class="text-center mb-3"
      [class.text-opacity]="data.hide===true"
  >{{ data.titleImage}}</h4>
  <cx-generic-link *ngIf="data.hide!==true"
                   [url]="data.urlLink"
                   [target]="getTarget(data)">
    <div class="carousel-icon" *ngIf="data.iconClass">
      <i [ngClass]="data.iconClass"></i>
    </div>
    <cx-media *ngIf="data.media" [container]="data.media" [ngClass]="{'has-icon': data.iconClass}"></cx-media>
    <p class="content" *ngIf="data.content" [innerHTML]="data.content"></p>
  </cx-generic-link>

  <div *ngIf="data.hide===true" class="disabled-carousel-item"
       ngbPopover="{{ 'ssab.navigation.productFormNotAvailable' | cxTranslate }}"
       triggers="mouseenter:mouseleave"
       container="body"
       placement="bottom"
       popoverClass="popover-sm popover-info">
    <div class="carousel-item text-opacity text-center">
      <div class="carousel-icon" *ngIf="data.iconClass">
        <i [ngClass]="data.iconClass"></i>
      </div>
      <cx-media *ngIf="data.media" [container]="data.media" [ngClass]="{'has-icon': data.iconClass}"></cx-media>
      <p class="content" *ngIf="data.content" [innerHTML]="data.content"></p>
    </div>
  </div>
</ng-container>

