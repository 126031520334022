import {Inject, Injectable, OnDestroy} from '@angular/core';
import {EventService, LogoutEvent} from '@spartacus/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SsabProductService} from "./ssab-product.service";
import {SsabCoilComparisonRequest, SsabCoilComparisonResponse} from "../../model/product.model";
import {DOCUMENT} from '@angular/common';

const STORAGE_KEY = 'coilsComparison';
const DEFAULT_VALUE = {
  referenceCoil: undefined,
  compareCoils: []
};

@Injectable({
  providedIn: 'root',
})
export class SsabCoilsComparisonService implements OnDestroy {
  coilCompareSubject: BehaviorSubject<SsabCoilComparisonRequest>;
  subscription: Subscription = new Subscription();

  constructor(
    protected productService: SsabProductService,
    protected eventService: EventService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.coilCompareSubject = new BehaviorSubject(this.getStorageValue());
    this.subscription.add(this.eventService.get(LogoutEvent).subscribe(() => this.clear()));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggleComparison(coilId: string): void {
    const coils = this.getStorageValue();
    if (coils?.referenceCoil === coilId || coils?.compareCoils.filter((c) => c === coilId).length > 0) {
      this.removeCoilFromComparison(coilId);
    } else {
      this.addCoilToComparison(coilId);
    }
  }

  addCoilToComparison(coilId: string): void {
    const coils = this.getStorageValue();
    if (!coils.referenceCoil) {
      this.addReferenceCoil(coilId);
    } else if (coils.compareCoils.filter((c) => c === coilId).length === 0) {
      coils.compareCoils.push(coilId);
      this.triggerSubject(coils);
    }
  }

  private addReferenceCoil(id: string): void {
    const coils = this.getStorageValue();
    this.triggerSubject({...coils, referenceCoil: id});
  }

  removeCoilFromComparison(coilId: string): void {
    const coils = this.getStorageValue();
    this.triggerSubject({
      referenceCoil: coilId === coils.referenceCoil ? undefined : coils.referenceCoil,
      compareCoils: coils?.compareCoils.filter((c) => c !== coilId)
    });
  }

  clear(): void {
    this.triggerSubject(DEFAULT_VALUE);
  }

  private triggerSubject(value: SsabCoilComparisonRequest): void {
    this.setStorageValue(JSON.stringify(value));
    this.coilCompareSubject.next(value);
  }

  private setStorageValue(value: string): void {
    this.document.defaultView?.sessionStorage?.setItem(STORAGE_KEY, value);
  }

  private getStorageValue(): SsabCoilComparisonRequest {
    const value = this.document.defaultView?.sessionStorage?.getItem(STORAGE_KEY);
    if (value) {
      return {...JSON.parse(value)};
    }
    return {...DEFAULT_VALUE};
  }

  getCompareCoils(from: number, size: number): Observable<SsabCoilComparisonResponse> {
    return this.coilCompareSubject.asObservable().pipe(
      switchMap((coils) =>
        this.productService.compareCoils(coils)
      ),
      map((res: SsabCoilComparisonResponse) => {
        return {...res, comparison: res?.comparison?.slice(from, from + size)} as SsabCoilComparisonResponse;
      })
    );
  }
}
