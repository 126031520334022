<div
  class="{{styleClass}} btn-group"
  role="group"
  tabindex="0"
  aria-label="Add more items"
  [class.focused]="focus"
  (keydown)="onKeyDown($event)"
  (blur)="onBlur($event)"
  (focus)="onFocus($event)"
>
  <div class="cx-counter-action">
    <button
        #decrementBtn
        type="button"
        class="btn ssab-icon icon-navigation-minus-small"
        [ngClass]="cartIsLoading || value <= min || isDisabled ? 'icon-gray': ''"
        (click)="decrement()"
        [disabled]="cartIsLoading || value <= min || isDisabled"
        *ngIf="isValueChangeable && showButtons"
    >
    </button>
  </div>


  <div class="cx-counter-stepper">
    <input
      autocomplete="off"
      #itemCounterInput
      class="cx-counter-value form-control"
      type="text"
      id="{{id}}"
      name="value"
      (change)="setInputValue(itemCounterInput)"
      (focus)="onInputFocus($event)"
      [formControl]="inputValue"
      [disableControl]="isDisabled"
      NumbersOnly
      *ngIf="isValueChangeable"
    />
    <div class="cx-counter-unit" *ngIf="unit">
      <span [innerHTML]="unit ? unit.name : ''"></span>
    </div>
    <div class="cx-counter-value" *ngIf="!isValueChangeable">
      {{ value }}
    </div>
  </div>

  <div class="cx-counter-action ">
    <button
        #incrementBtn
        type="button"
        class="btn ssab-icon icon-navigation-plus-small"
        [ngClass]="cartIsLoading || (max != null && value >= max) || isDisabled ? 'icon-gray': ''"
        (click)="increment()"
        [disabled]="cartIsLoading || (max != null && value >= max) || isDisabled"
        *ngIf="isValueChangeable && showButtons"
    >
    </button>
  </div>

</div>
