import { Action } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';

export const SUBSCRIBE_PRODUCT_AVAILABILITY_REQUEST = '[Subscriptions] Product Availability Request';
export const SUBSCRIBE_PRODUCT_AVAILABILITY_REQUEST_FAIL = '[Subscriptions] Product Availability Fail';
export const SUBSCRIBE_PRODUCT_AVAILABILITY_REQUEST_SUCCESS = '[Subscriptions] Product Availability Success';

export class SubscribeProductAvailabilityRequest implements Action {
  readonly type = SUBSCRIBE_PRODUCT_AVAILABILITY_REQUEST;
  constructor(public payload: {
    userId: string;
    productCode: string;
  }) {
    this.payload = payload;
  }
}

export class SubscribeProductAvailabilityRequestFail extends StateUtils.EntityFailAction {
  readonly type = SUBSCRIBE_PRODUCT_AVAILABILITY_REQUEST_FAIL;
  constructor(public payload: {
    contactFormType: string;
    error: any;
  }) {
    super('subscription', payload.contactFormType, payload.error);
  }
}

export class SubscribeProductAvailabilityRequestSuccess extends StateUtils.EntitySuccessAction {
  readonly type = SUBSCRIBE_PRODUCT_AVAILABILITY_REQUEST_SUCCESS;
  constructor(public payload: {
    userId: string;
    productCode: string;
    contactFormType: string;
  }) {
    super('subscription', payload.contactFormType);
  }
}
