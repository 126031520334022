import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SsabTransportDocument} from '../../../../model/document.model';
import {SsabDocumentsBaseComponent} from '../documents/ssab-documents-base-component';
import {SsabGlobalconfigurationService} from "../../../../service/general/configuration/ssab-globalconfiguration.service";

@Component({
  selector: 'ssab-cx-transport-documents',
  templateUrl: './ssab-transport-documents.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabTransportDocumentsComponent extends SsabDocumentsBaseComponent {
  @Input() transportDocuments: SsabTransportDocument[];

  constructor(protected globalConfigurationService: SsabGlobalconfigurationService,) {
    super(globalConfigurationService);
  }
}

