<ng-container *ngIf="!isAzureLoginInProgress()">
  <ng-container *ngIf="isCommerceLoginActive() | async">
    <form
      (ngSubmit)="onSubmit()"
      [formGroup]="csAgentLoginForm"
      *ngIf="!csAgentTokenLoading"
    >
      <label>
        <input
          type="text"
          formControlName="userId"
          placeholder="{{ 'asm.loginForm.userId.label' | cxTranslate }}"
        />
        <cx-form-errors [control]="csAgentLoginForm.get('userId')"></cx-form-errors>
      </label>

      <label>
        <input
          type="password"
          placeholder="{{ 'asm.loginForm.password.label' | cxTranslate }}"
          formControlName="password"
        />
        <cx-form-errors
          [control]="csAgentLoginForm.get('password')"
        ></cx-form-errors>
      </label>
      <button type="submit">
        {{ 'asm.loginForm.submit' | cxTranslate }}
      </button>
    </form>
  </ng-container>

  <ng-container *ngIf="isAzureLoginActive() | async">
    <ssab-start-azure-login (startLogin)="startAzureLogin()" id="ssoLoginFormAsm"
                            buttonId="ssoLoginButtonAsm"></ssab-start-azure-login>
    <ssab-process-azure-login></ssab-process-azure-login>
  </ng-container>

</ng-container>

<ng-container *ngIf="isAzureLoginInProgress()">
  <div class="text-center text-primary pb-5 azure-login-inprogress">
    {{ 'ssab.azure.login.inprogress' | cxTranslate }}
  </div>
  <ssab-process-azure-login></ssab-process-azure-login>
</ng-container>

<div
  *ngIf="csAgentTokenLoading"
  class="spinner"
  aria-hidden="false"
  aria-label="Loading"
>
  <div></div>
  <div></div>
  <div></div>
</div>
