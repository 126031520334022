import {Component, ElementRef, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {AuthRedirectStorageService, BaseSiteService, CmsService, Currency, CurrencyService, LanguageService, RoutingService, SiteConnector, SiteContextActions, SiteContextConfig, WindowRef} from '@spartacus/core';
import {SiteContextComponentService, SiteContextSelectorComponent} from '@spartacus/storefront';
import {filter, map, take, takeUntil, withLatestFrom} from 'rxjs/operators';
import {SsabUserService} from '../../service/user/ssab-user.service';
import {SsabI18nextTranslationService} from '../../shared/i18/ssab-i18next-translation.service';
import {createAction, Store} from '@ngrx/store';
import {SsabUser} from '../../model/user.model';
import {Observable, Subject, Subscription} from 'rxjs';
import {GlobalLogin} from '../../model/misc.model';
import {Router} from "@angular/router";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'ssab-cx-site-context-selector',
  templateUrl: './ssab-site-context-selector.component.html',
})
export class SsabSiteContextSelectorComponent extends SiteContextSelectorComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();

  isLanguage: boolean;
  isCurrency: boolean;
  componentClass: string;
  isOpen: boolean;
  siteContextComponentService: SiteContextComponentService;
  user$: Observable<SsabUser>;
  baseSite$: Observable<string>;
  activeLanguage$: Observable<string>;
  subscriptions: Subscription = new Subscription();

  constructor(componentService: SiteContextComponentService,
              protected userService: SsabUserService,
              protected baseSiteService: BaseSiteService,
              protected languageService: LanguageService,
              protected currencyService: CurrencyService,
              protected siteConnector: SiteConnector,
              protected actions$: Actions,
              protected store: Store,
              protected authRedirectStorageService: AuthRedirectStorageService,
              protected ssabTranslationService: SsabI18nextTranslationService,
              protected siteContextConfig: SiteContextConfig,
              @Inject(DOCUMENT) private document: Document,
              protected routing: RoutingService,
              protected cmsService: CmsService,
              protected router: Router,
              protected eRef: ElementRef) {
    super(componentService);
    this.siteContextComponentService = componentService;
    this.user$ = this.userService.get();
    this.baseSite$ = this.baseSiteService.getActive();
    this.activeLanguage$ = this.languageService.getActive();
  }

  ngOnInit(): void {
    this.isOpen = false;
    this.subscriptions.add(
      this.label$.subscribe(value => {
        this.componentClass = value;
        this.isCurrency = value === 'Currency';
        this.isLanguage = value === 'Language';
      })
    );

    if (this.isCurrency) {
      this.subscriptions.add(
        this.actions$.pipe(
          takeUntil(this.componentDestroyed$),
          filter(action => action.type === SiteContextActions.LOAD_CURRENCIES_SUCCESS)
        ).subscribe(ev => {
          const currencies: Currency[] = ev['payload'];
          let currencyCodes = [];
          currencies.forEach(cur => currencyCodes.push(cur.isocode));
          // updating sitecontextconfig with new list of available curencies for URL rewrite protection
          this.siteContextConfig.context['currency'] = currencyCodes;
          // try to set first available - if it's same as active it has no effect, otherwise reloaded
          this.siteContextComponentService.setActive(currencies[0].isocode);
        })
      );

      this.reloadCurrencies();
    }

    if (this.isLanguage) {
      this.subscriptions.add(
        this.actions$.pipe(
          filter(action => action.type === SiteContextActions.LANGUAGE_CHANGE),
          map(action => action['payload']),
          withLatestFrom(this.authRedirectStorageService.getRedirectUrl())
        ).subscribe(([payload, url]) => {
          if (url) {
            // the last url before logout is saved as redirect url for the next login, so if url exists
            // (can happen only on loging page for logged out users) we change language part in it on language switch
            const newurl = url.replace('/' + payload['previous'] + '/', '/' + payload['current'] + '/');
            if (newurl !== url) {
              this.authRedirectStorageService.setRedirectUrl(newurl);
            }
          }
        })
      );
    }
  }

  contextClicked(value: string) {
    if (this.isLanguage) {
      this.ssabTranslationService.verifyCachedTranslationBundles(value);
    }
    this.active = value;
    this.toggleOpen();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    const component = event.target as Node;
    const containerElement = this.eRef.nativeElement as Node;
    if (!containerElement.contains(component) || !(component instanceof HTMLElement)) {
      this.isOpen = false;
    }
  }

  toggleOpen() {
    if (this.isCurrency) {
      return;
    }
    this.isOpen = !this.isOpen;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  protected reloadCurrencies(): void {
    const reloadCurrencies = createAction(SiteContextActions.LOAD_CURRENCIES_SUCCESS, (payload: any) => ({payload: payload}));
    this.siteConnector.getCurrencies()
      .pipe(take(1))
      .subscribe(curr => {
        const ev = reloadCurrencies(curr);
        this.store.dispatch(ev);
      });
  }

  changeStore(): void {
    const sub = this.baseSiteService.getActive()
      .subscribe(
        store => {
          if (store === GlobalLogin.GlobalBaseSite) {
            this.cmsService.refreshPageById('storeselector');
            this.routing.go('/store-selector');
            sub.unsubscribe();
          }
        }
      );
    this.getLocalStorage()?.setItem(GlobalLogin.BaseSiteActive, GlobalLogin.GlobalBaseSite);
    this.baseSiteService.setActive(GlobalLogin.GlobalBaseSite);
  }

  protected getLocalStorage(): Storage {
    return this.document.defaultView?.localStorage;
  }
}
