import {CmsConfig, I18nModule, provideDefaultConfig} from '@spartacus/core';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SsabShowInchesToggleComponent} from './ssab-show-inches-toggle.component';


@NgModule({
  imports: [
    CommonModule,
    I18nModule,
  ],
  providers: [
    provideDefaultConfig({
      cmsComponents: {
        ShowInchesToggle: {
          component: SsabShowInchesToggleComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [SsabShowInchesToggleComponent],
  exports: [SsabShowInchesToggleComponent]
})
export class SsabShowInchesToggleModule {
}
