<div class="cx-previous-orders-title">
  <h2 class="text-white cx-page-header-title">
    {{ 'ssab.previous.orders.title' | cxTranslate }}
  </h2>
</div>


<ng-container *ngIf="orders$| async as orders">
  <ng-container>
    <!-- BODY -->
    <div class="cx-previous-orders">
      <ng-container *ngIf="orders?.pagination?.totalResults > 0; else noOrder">
        <ssab-cx-previous-orders-table
          [orders]="orders?.orders"
          [activeSort]="activeSort$.getValue()"
          (sortChange)="sort($event)"
        ></ssab-cx-previous-orders-table>
        <!-- Select Form and Pagination Bottom -->
        <ssab-cx-pagination-bottom
          [pagination]="orders?.pagination"
          (viewPageEvent)="pageChange($event)"
        ></ssab-cx-pagination-bottom>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<!-- NO ORDER CONTAINER -->
<ng-template #noOrder>
  <div
    class="empty-data-div row no-margin text-center">
    <div [ngClass]="'col-12 col-sm-6'"
    >
      <h4 class="text-primary">{{ 'ssab.previous.orders.noOrders' | cxTranslate }}</h4>
    </div>
  </div>
</ng-template>

