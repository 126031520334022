import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {OccEndpointsService} from '@spartacus/core';
import {SsabDocolSearchRequest, SsabDocolSearchResponse} from '../../model/docol.model';

@Injectable({
  providedIn: 'root',
})
export class SsabOccProductDocolAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  public loadDocolList(userId: string, params: SsabDocolSearchRequest): Observable<SsabDocolSearchResponse> {
    const url = this.occEndpoints.buildUrl('getDocolList', {urlParams: {userId}});
    return this.http.post<SsabDocolSearchResponse>(url, params);
  }
}
