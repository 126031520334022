<ng-container *ngIf="isMobile; else itemTablet">
  <div class="collected-item-info">
    <p class="text-bold mb-0">{{'ssab.savedCart.ContactSalesProductList.collected.items.product' | cxTranslate}}</p>
    <a
      [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl"
      tabindex="-1"
      (click)="closeContactSalesModal()"
    >
      <span class="d-inline-block text-bold pr-2">{{item.product.name}} </span>
    </a>
    <i class="ssab-icon icon-md icon-navigation-chevron-right" (click)="showHideContent($event, true)"></i>
  </div>

  <div class="d-none collected-item-content">
    <i class="ssab-icon icon-md icon-navigation-chevron-left" (click)="showHideContent($event, false)"></i>
    <div class="collected-item-section text-primary mt-5">
      <a
        [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl"
        tabindex="-1"
        (click)="closeContactSalesModal()"
      >
        <span class="d-inline-block text-bold">{{item.product.name}} </span>
      </a>
    </div>
    <div class="collected-item-section">
      <p class="text-bold mb-3">{{'ssab.savedCart.ContactSalesProductList.collected.items.location' | cxTranslate}}</p>
      {{item.warehouse?.name}}
    </div>
    <div class="collected-item-section row no-gutters align-items-center d-flex">
      <span class="text-bold col-6">{{'ssab.savedCart.ContactSalesProductList.collected.items.quantity' | cxTranslate}}</span>
      <div class="col-6">
        <ssab-cx-item-stepper
          [isValueChangeable]="true"
          [showButtons]="true"
          [min]="minQuantity"
          [value]="item.quantity"
          [step]="minQuantity"
          [stepperTimeout]="0"
          [id]="item.product.code+'_stepper_collected_itemMobile'"
          [ngClass]="item.availabilityMessage && item.availabilityMessage.key ? 'is-invalid item-update': ''"
          (update)="postBackQuantity($event, item.unit)"
        ></ssab-cx-item-stepper>
      </div>
    </div>
    <div class="collected-item-section row no-gutters" *ngIf="quantityUpdated$ | async as quantityUpdated">
      <span class="text-bold col-6 vertical-align-item justify-content-start">{{'ssab.savedCart.ContactSalesProductList.collected.items.unit' | cxTranslate}}</span>
      <ssab-unit-picker-cart
        [cssClassMulti]="'col-4 ml-auto'"
        [product]="item.product"
        [selectedUnit]="item.unit?.code"
        (update)="postBackUnit($event,quantityUpdated)"
      ></ssab-unit-picker-cart>
    </div>

  </div>
</ng-container>

<ng-template #itemTablet>
  <td class="text-primary">
    <a
      [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl"
      tabindex="-1"
      (click)="closeContactSalesModal()"
    >
      <span class="d-inline-block text-bold">{{item.product.name}} </span>
    </a>
  </td>
  <td>
    <!-- {{item.product.stock?.storageLocation}} -->
    {{item.warehouse?.name}}
  </td>
  <td class="pl-0 pr-0 float-lg-left float-md-none">
    <!-- min should be 0 and max is any number according to the story, not related to stocks - same for step -->
    <ssab-cx-item-stepper
      [isValueChangeable]="true"
      [showButtons]="true"
      [min]="minQuantity"
      [value]="item.quantity"
      [step]="minQuantity"
      [stepperTimeout]="0"
      [id]="item.product.code+'_stepper_collected_item'"
      [ngClass]="item.availabilityMessage && item.availabilityMessage.key ? 'is-invalid item-update': ''"
      (update)="postBackQuantity($event, item.unit)"
    ></ssab-cx-item-stepper>
  </td>
  <td *ngIf="quantityUpdated$ | async as quantityUpdated">
    <ssab-unit-picker-cart
      [appendTo]="'.modal-body'"
      [product]="item.product"
      [selectedUnit]="item.unit?.code"
      (update)="postBackUnit($event,quantityUpdated)"
    ></ssab-unit-picker-cart>
  </td>
  <td>
    <div class="vertical-align-item">
      <cx-icon
        aria-label="search"
        class="ssab-icon icon-system-trash icon-cursor"
        (click)="removeItem(item)"
        tabindex="0"
      ></cx-icon>
    </div>
  </td>

</ng-template>
