import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { OrderRootModule, ORDER_FEATURE } from '@spartacus/order/root';
import {SsabOrderHistoryModule} from "../cms-components/my-account/order/order-history/ssab-order-history.module";

@NgModule({
  declarations: [],
  imports: [
    OrderRootModule
  ],
  providers: [provideConfig(<CmsConfig>{
    featureModules: {
      [ORDER_FEATURE]: SsabOrderHistoryModule
    }
  })
  ]
})
export class OrderFeatureModule { }
