import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConverterService, OccEndpointsService } from '@spartacus/core';
import {CART_NORMALIZER} from '@spartacus/cart/base/root';
import { SsabCart } from '../../model/cart.model';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class SsabContactSalesAdapter {

  constructor(
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected converterService: ConverterService
  ) { }

  loadAll(userId: string): Observable<SsabCart[]> {
    return this.http
      .get(this.occEndpointsService.buildUrl('listContactSalesProductLists', { urlParams: {userId} }))
      .pipe(pluck('carts'), this.converterService.pipeableMany(CART_NORMALIZER)) as Observable<SsabCart[]>;
  }

  create(userId: string): Observable<SsabCart> {
    const httpParams = new HttpParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    return this.http
      .post(this.occEndpointsService.buildUrl('createContactSalesProductList', { urlParams: {userId} }), httpParams, { headers })
      .pipe(pluck('savedCartData'), this.converterService.pipeable(CART_NORMALIZER))  as Observable<SsabCart>;
  }
}
