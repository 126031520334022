import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SsabCart} from "../../../../model/cart.model";


@Component({
  selector: 'ssab-cx-cart-totals',
  templateUrl: './ssab-cart-totals.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabCartTotalsComponent {
  @Input()
  cart: SsabCart;
  @Input()
  readonly = false;
  @Input()
  showInCartList = false;
}
