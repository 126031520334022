export const ssabOccCartConfig = {
  backend: {
    occ: {
      endpoints: {
        cart: 'users/${userId}/carts/${cartId}?fields=DEFAULT',
        addEntries: 'users/${userId}/carts/${cartId}/entries?fields=DEFAULT&spinner=true',
        updateEntries: 'users/${userId}/carts/${cartId}/entries/${entryNumber}?fields=entryNumber,unit(code),requestedDeliveryDate,doubleQuantity,splitBundle,product(code),splitBatch,splitSurchargeExists,batchItemNote&spinner=true',
        removeEntries: 'users/${userId}/carts/${cartId}/entries/${entryNumber}',
        updateHeader: 'users/${userId}/carts/${cartId}/header?fields=requestedDeliveryDate,requestedDeliveryDateEnteredManually,deliveryMode(code),paymentMode(code),unloadingMethod(code),warehousePhoneNumber,orderConfirmationEmail,deliveryNoteEmail,materialCertificateEmail,billingAddress(id),deliveryAddress(FULL),purchaseOrderNumber,freeTextForTransportation,freeTextForWarehouse,freeTextForInvoice,orderConfirmationEmailEnteredManually,deliveryNoteEmailEnteredManually,materialCertificateEmailEnteredManually&spinner=true',
        placeOrder: 'users/${userId}/orders?fields=DEFAULT&spinner=true',
        multipleEntries: 'users/${userId}/carts/${cartId}/multipleEntries?fields=DEFAULT&spinner=true',
      }
    }
  }
};

