import {
  ChangeDetectionStrategy,
  Component, Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import {OrderFacade} from "@spartacus/order/root";
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {SsabCart} from '../../../model/cart.model';
import {GlobalMessageService, TranslationService} from '@spartacus/core';
import {openCloseSpinner} from "../../shared/utils/functions/ssab-functions-utils";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'ssab-cx-order-confirmation-thank-you-message',
  templateUrl: './ssab-order-confirmation-thank-you-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabOrderConfirmationThankYouMessageComponent
  implements OnInit, OnDestroy {
  order$: Observable<SsabCart>;
  orderGuid: string;

  constructor(protected orderFacade: OrderFacade,
              protected globalMessageService: GlobalMessageService,
              @Inject(DOCUMENT) private document: Document,) {
  }

  ngOnInit() {
    this.order$ = this.orderFacade.getOrderDetails().pipe(
      tap((order) => {
        this.orderGuid = order.guid;
      })
    ) as Observable<SsabCart>;
    openCloseSpinner(this.document, false);
  }

  ngOnDestroy() {
    this.orderFacade.clearPlacedOrder();
  }
}
