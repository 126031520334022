import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'ssab-start-azure-login',
  templateUrl: './ssab-start-azure-login.component.html'
})
export class SsabStartAzureLoginComponent implements OnInit {

  @Input() id: string;
  @Input() buttonId: string;
  @Output() startLogin = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  public startAzureLogin(): void {
    this.startLogin.emit();
  }

}
