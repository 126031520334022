import {SsabProductService} from '../../../service/product/ssab-product.service';
import {CmsComponentData, ProductCarouselComponent} from '@spartacus/storefront';
import {CmsProductCarouselComponent, WindowRef} from '@spartacus/core';
import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {filter, map, withLatestFrom} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {SsabProduct} from '../../../model/product.model';
import {SsabCMSProductCarouselComponent} from '../../../model/cms.model';
import {SsabWishListFacade} from '../../../service/cart/ssab-wish-list.facade';

@Component({
  selector: 'ssab-cx-product-carousel',
  templateUrl: './ssab-product-carousel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabProductCarouselComponent extends ProductCarouselComponent implements OnInit {
  data$: Observable<SsabCMSProductCarouselComponent>;

  isTablet: boolean;
  isMobile: boolean;

  constructor(
    protected componentData: CmsComponentData<CmsProductCarouselComponent>,
    protected productService: SsabProductService,
    protected wishListService: SsabWishListFacade,
    protected winRef: WindowRef
  ) {
    super(componentData, productService);
  }

  ngOnInit(): void {
    this.isTablet = this.winRef.nativeWindow.matchMedia('only screen and (max-width: 1020px)').matches;
    this.isMobile = this.winRef.nativeWindow.matchMedia('only screen and (max-width: 760px)').matches;
    this.data$ = this.componentData.data$.pipe(
      filter(Boolean)
    );

    this.items$ = this.wishListService.getWishList(true).pipe(
      filter((wishlist) => Boolean(wishlist)),
      map((wishList) => wishList.entries),
      withLatestFrom(this.data$),
      map(([entries, data]) => {
        if (entries.length > data.maxAmountProducts) {
          return [...entries.slice(-data.maxAmountProducts)].reverse();
        }
        return [...entries].reverse();
      }),
      map((entries) =>
        entries.map((entry) => this.productService.get(entry.product.code))
      )
    );
  }

  sizeOfPicture(): string {
    let size = '188px';
    if (this.isTablet) {
      size = '160px';
    }
    if (this.isMobile) {
      size = '140px';
    }
    return size;
  }
}
