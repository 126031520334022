import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DocumentTypes, SsabDocumentIdentifier} from '../../../../model/document.model';
import {Sort} from '../../../../model/order.model';
import {DateUtils} from '../../../../shared/util/date-utils';
import {HttpParams} from '@angular/common/http';
import {Observable} from "rxjs";
import {SsabGlobalconfigurationService} from "../../../../service/general/configuration/ssab-globalconfiguration.service";

@Component({
  template: ''
})
export abstract class SsabDocumentsBaseComponent {
  @Input() activeSort: Sort;
  @Output() sortChange = new EventEmitter<Sort>();
  @Output() selectDocument = new EventEmitter<SsabDocumentIdentifier>();
  @Output() unselectDocument = new EventEmitter<string>();
  @Output() downloadDocument = new EventEmitter<SsabDocumentIdentifier>();
  @Output() selectAll = new EventEmitter<boolean>();
  selectAllDocuments: boolean;
  documentTypes = DocumentTypes;

  constructor(protected globalConfigurationService: SsabGlobalconfigurationService) {
  }

  sort(sort: Sort): void {
    this.sortChange.emit(sort);
  }

  onChange(isChecked: boolean, document: SsabDocumentIdentifier, params?: HttpParams): void {
    if (isChecked) {
      this.selectDocument.emit(document);
    } else {
      this.unselectDocument.emit(document.documentId);
    }
  }

  onDownload(document: SsabDocumentIdentifier): void {
    this.downloadDocument.emit(document);
  }

  convertUIDate(d: Date): Date {
    return DateUtils.convertDate(d);
  }

  checkAllDocuments(isChecked: boolean): void {
    this.selectAllDocuments = isChecked;
    this.selectAll.emit(isChecked);
  }

  toggleWithTruncatedInfo(popover, info: string) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open({info});
    }
  }

  getDateFormat(): Observable<string> {
    return this.globalConfigurationService.getDateFormat();
  }
}
