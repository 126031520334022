<div class="ssab-modal-xl ssab-modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
<div class="modal-header cx-dialog-header p-0" id="collected-item-header">
  <h3 class="modal-title d-inline">
    {{ 'ssab.savedCart.ContactSalesProductList.collected.items.title' | cxTranslate }}
  </h3>
  <i class="float-right icon-md ssab-icon icon-navigation-close icon-inline" (click)="dismiss()"></i>
</div>
<div class="modal-body" >
  <div class="table-responsive d-none d-sm-block" *ngIf="items$ | async as items" (scroll)="hideSelects($event)" >
    <table class="table" *ngIf="items && items.length > 0">
      <thead>
      <tr>
        <th scope="col" class="col-md-4 col-lg-5" >{{'ssab.savedCart.ContactSalesProductList.collected.items.product' | cxTranslate}}</th>
        <th scope="col" class="col-md-2 col-lg-2">{{'ssab.savedCart.ContactSalesProductList.collected.items.location' | cxTranslate}}</th>
        <th scope="col" class="col-md-3 col-lg-2">{{'ssab.savedCart.ContactSalesProductList.collected.items.quantity' | cxTranslate}}</th>
        <th scope="col" class="col-md-2 col-lg-2">{{'ssab.savedCart.ContactSalesProductList.collected.items.unit' | cxTranslate}}</th>
        <th scope="col" class="col-md-1 col-lg-1 text-center">{{'ssab.savedCart.ContactSalesProductList.collected.items.remove' | cxTranslate}}</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let item of items">
        <tr ssab-cx-collected-item
            [item]="item"
            [tableScroll]="tableScroll.asObservable()"
            (removeEntry)="remove($event)"
            (unitQuantityUpdate)="updateQuantityOrUnit($event, item)" ></tr>
      </ng-container>
      </tbody>
    </table>
  </div>
  <div class="d-block d-sm-none collected-items-mobile" *ngIf="items$ | async as items">
    <ng-container *ngFor="let item of items">
      <div  class="collected-item" ssab-cx-collected-item
            [item]="item"
            [isMobile]="true"
            (removeEntry)="remove($event)">
      </div>
    </ng-container>
  </div>
  <div class="row pt-4">
    <div class="col-md-6 col-12 pb-4 pb-md-0">
      <span class="text-uppercase text-bold text-sm">{{'ssab.savedCart.ContactSalesProductList.collected.items.representative' | cxTranslate}}</span><br/>
      <ng-container *ngIf="user$ | async as user">
        <ng-container *ngIf="user.salesContacts">
          <div class="pb-3" *ngFor="let salesInformation of user.salesContacts">
            <span>{{salesInformation.salesContactName}}</span><br/>
            <span>{{salesInformation.email}}</span>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="col-md-6 col-12">
      <textarea rows="3" class="w-100 p-3"
                placeholder="{{'ssab.savedCart.ContactSalesProductList.collected.items.message' | cxTranslate}}"
                #contactFormMessage></textarea>
          </div>
        </div>
        <div class="text-right pt-4">
          <div class="row" *ngIf="!(contactRepresentativeAssigned | async)">
            <div class="col-md-12 col-12 pb-4 pb-md-0">
              <span class="text-red text-md">{{'ssab.savedCart.ContactSalesProductList.collected.items.noRepresentative' | cxTranslate}}</span>
            </div>
          </div>
          <button class="btn btn-primary btn-default-height collected-items-btn mb-5 mb-md-0"
                  [attr.disabled] = "(contactRepresentativeAssigned | async) && (items$ | async)?.length > 0 ? null : true"
                  (click)="sendRequest(contactFormMessage.value)">
            <i class="ssab-icon icon-inline mr-3 icon-white icon-saved-cart"></i>
            {{ 'common.send' | cxTranslate | uppercase}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
