import {Inject, Injectable, LOCALE_ID, OnDestroy} from '@angular/core';
import {NgbDatepickerI18n, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {LanguageService, TranslationService} from "@spartacus/core";
import {formatDate, FormStyle, getLocaleDayNames, TranslationWidth} from '@angular/common';
import {Subscription} from "rxjs";
import {SsabGlobalconfigurationService} from "../../../service/general/configuration/ssab-globalconfiguration.service";

@Injectable()
export class SsabNgbDatepickerI18n extends NgbDatepickerI18n implements OnDestroy {
  monthNames: string[] = [];
  shortMonthNames: string[] = [];
  weekdayNames: string[] = [];
  weekHeader: string;
  language: string;
  dateFormat: string;

  private subscriptions: Subscription[] = [];

  constructor(private translation: TranslationService,
              private languageService: LanguageService,
              private globalConfigurationService: SsabGlobalconfigurationService,
              @Inject(LOCALE_ID) private locale: string) {
    super();
    this.populateMonths();
    this.populateWeekDays();
    this.populateLanguage();
    this.populateWeekHeader();
  }

  populateWeekHeader(): void {
    this.subscriptions.push(
      this.translation.translate("ssab.calendar.week")
        .subscribe(header => this.weekHeader = header, err => console.error(err))
    );
    this.subscriptions.push(
      this.globalConfigurationService.getDateFormat()
        .subscribe(format => this.dateFormat = format, err => console.error(err))
    );
  }

  /**
   * Populates month names from localized properties
   */
  populateMonths(): void {
    for (let i = 1; i < 13; i++) {
      this.subscriptions.push(this.translation.translate("ssab.calendar.month." + (i))
        .subscribe(month => {
          this.monthNames[i] = month;
          this.shortMonthNames[i] = month.substr(0, 3);
        }, err => console.error(err)))
    }
  }

  /**
   * Populates weekday names from localized properties
   */
  populateWeekDays(): void {
    const weekdayMapKeys: string[] = ["0", "1", "2", "3", "4", "5", "6"];
    for (let i = 0; i < weekdayMapKeys.length; i++) {
      this.subscriptions.push(this.translation.translate("ssab.calendar.weekday.short." + weekdayMapKeys[i])
        .subscribe(t => {
          this.weekdayNames[i] = t;
        }, err => console.error(err)));
    }
  }

  /**
   * Reads language code
   */
  populateLanguage(): void {
    this.subscriptions.push(this.languageService.getActive().subscribe(lang => this.language = lang, err => console.error(err)));
  }

  ngOnDestroy(): void {
    /* This method has to be called manually at component level on destroy method. ngOnDestroy is not automatically called on injectables */
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }

  getWeekdayShortName(weekday: number): string {
    return this.weekdayNames[weekday - 1];
  }

  getMonthShortName(month: number): string {
    return this.shortMonthNames[month];
  }

  getMonthFullName(month: number): string {
    return this.monthNames[month];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    const date1: Date = new Date(date.year, date.month - 1, date.day);
    return formatDate(date1, this.dateFormat, this.language);
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    const weekdaysStartingOnSunday =
      getLocaleDayNames(this.locale, FormStyle.Standalone, width === undefined ? TranslationWidth.Short : width);
    const weekdays = weekdaysStartingOnSunday.map((day, index) => weekdaysStartingOnSunday[(index + 1) % 7]);
    return weekdays[weekday - 1] || '';
  }

}
