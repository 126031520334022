import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { provideDefaultConfig } from '@spartacus/core';
import { ssabOccAsmConfig } from './ssab-asm-config';
import { SsabAsmAdapter } from './ssab-asm.adapter';
import { SsabAsmConnector } from './ssab-asm.connector';
import { SsabCsAgentAuthService } from './ssab-cs-agent-auth.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    provideDefaultConfig(ssabOccAsmConfig),
    {
      provide: CsAgentAuthService,
      useClass: SsabCsAgentAuthService
    },
    {
      provide: SsabAsmConnector,
      useClass: SsabAsmConnector
    },
    {
      provide: SsabAsmAdapter,
      useClass: SsabAsmAdapter
    }
  ]
})
export class SsabAsmModule {
}
