import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import { OrderDetailsService } from '@spartacus/order/components';
import {Order} from '@spartacus/order/root';


@Component({
  selector: 'ssab-order-details-header',
  templateUrl: './ssab-order-detail-header.component.html',
})
export class SsabOrderDetailHeaderComponent implements OnInit {

  order$: Observable<Order>;

  constructor(protected orderDetailsService: OrderDetailsService) {
  }

  ngOnInit() {
    this.order$ = this.orderDetailsService.getOrderDetails();
  }
}
