import {Inject, Injectable} from '@angular/core';
import {AccountInfo, AuthenticationResult, EndSessionRequest, IPublicClientApplication, SilentRequest} from '@azure/msal-browser';
import {MSAL_INSTANCE} from '../model/msal.constants';
import {Observable} from 'rxjs';
import {Location} from '@angular/common';
import {mergeMap} from 'rxjs/operators';
import {SsabAuthService} from '../../../service/user-auth/ssab-auth.service';
import {CurrencyService, LanguageService} from "@spartacus/core";

@Injectable()
export class MsalService {
  private redirectHash: string;

  constructor(
    @Inject(MSAL_INSTANCE) public instance: Observable<IPublicClientApplication>,
    private authService: SsabAuthService,
    private language: LanguageService,
    private currency: CurrencyService,
    private location: Location
  ) {
    // Cache the code hash before Angular router clears it
    const hash = this.location.path(true).split('#').pop();
    if (hash && hash.indexOf('code=') === 0) {
      this.redirectHash = `#${hash}`;
      this.authService.setAzureLoginInProgress(true);
    }
  }

  public handleRedirectObservable(): Observable<AuthenticationResult> {
    return this.instance.pipe(
      mergeMap(inst =>
        inst.handleRedirectPromise(this.redirectHash))
    );
  }

  public startAzureLogin(): void {
    //azure login doesn't work without en/EUR
    this.currency.setActive('EUR');
    this.language.setActive('en');
    this.instance.subscribe(inst => {
        inst.loginRedirect().catch(() => {
          this.authService.setAzureLoginInProgress(false);
        });
      }
    );
  }

  public setActiveAccount(account: AccountInfo): void {
    this.instance.subscribe(inst => {
      inst.setActiveAccount(account);
    });
  }

}
