import {StateUtils} from '@spartacus/core';
import {MULTI_CART_DATA} from '@spartacus/cart/base/core';
import {SsabCart, SsabOrderEntry} from '../../model/cart.model';
import {SsabProductBatch} from '../../model/product.model';
import {ExtraMessages} from '../../model/misc.model';

export const SSAB_CART_ADD_ENTRY = 'SSAB_[Cart-entry] Add Entry';

export class SsabCartAddEntry extends StateUtils.EntityProcessesIncrementAction {
  readonly type = SSAB_CART_ADD_ENTRY;

  constructor(
    public userId: string,
    public cartId: string,
    public entry: SsabOrderEntry,
    public extraMessages?: ExtraMessages
  ) {
    super(MULTI_CART_DATA, cartId);
  }
}

export const SSAB_CART_ADD_ENTRIES = 'Ssab_[Cart-entry] Add Entries';

export class SsabCartAddEntries extends StateUtils.EntityProcessesIncrementAction {
  readonly type = SSAB_CART_ADD_ENTRIES;

  constructor(public userId: string, public cartId: string, public entries: SsabOrderEntry[]) {
    super(MULTI_CART_DATA, cartId);
  }
}

export const SSAB_CART_UPDATE_ENTRY = 'SSAB_[Cart-entry] Update Entry';

export class SsabCartUpdateEntry extends StateUtils.EntityProcessesIncrementAction {
  readonly type = SSAB_CART_UPDATE_ENTRY;

  constructor(
    public userId: string,
    public cartId: string,
    public entry: SsabOrderEntry,
    public extraMessages?: ExtraMessages
  ) {
    super(MULTI_CART_DATA, cartId);
  }
}

export const SSAB_CART_ADD_ENTRY_SUCCESS = '[Ssab-Cart-entry] Add Entry Success';

export class SsabCartAddEntrySuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = SSAB_CART_ADD_ENTRY_SUCCESS;

  constructor(public payload: {
    cartId: string;
    userId: string;
    cart: SsabCart;
  }) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export const SSAB_CART_UPDATE_HEADER = 'SSAB_[Cart] Update header';

export class SsabUpdateCartHeader extends StateUtils.EntityProcessesIncrementAction {
  readonly type = SSAB_CART_UPDATE_HEADER;

  constructor(
    public userId: string,
    public cartId: string,
    public cart: SsabCart,
    public runErpSimulation: boolean
  ) {
    super(MULTI_CART_DATA, cartId);
  }
}

export const SSAB_CART_UPDATE_HEADER_SUCCESS = 'SSAB_[Cart] Update header Success';

export const SSAB_PLACE_ORDER_FAIL = 'Place Order Fail';

export class SsabUpdateCartHeaderSuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = SSAB_CART_UPDATE_HEADER_SUCCESS;

  constructor(
    public payload: {
      cartId: string;
      userId: string;
      cart: SsabCart;
    }) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export const SSAB_CART_ADD_ENTRIES_FAIL = '[Ssab-Cart-entry] Add Entries Fail';

export class SsabCartAddEntriesFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = SSAB_CART_ADD_ENTRIES_FAIL;

  // eslint-disable-next-line
  constructor(public payload: { userId: string; cartId: string; productCodes: string[]; error: any }) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

