import {Injectable} from '@angular/core';
import {debounceTime, distinctUntilChanged, filter, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {SsabUserService} from '../user/ssab-user.service';
import {OCC_USER_ID_ANONYMOUS, UserIdService} from '@spartacus/core';
import {Store} from '@ngrx/store';
import {SsabMultiCartService} from './ssab-multi-cart.service';
import {SsabProduct} from '../../model/product.model';
import {SsabOrderEntry} from '../../model/cart.model';
import {ExtraMessages} from '../../model/misc.model';
import {Actions, ofType} from '@ngrx/effects';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {StateWithMultiCart} from '@spartacus/cart/base/core';
import {WishListActions, WishListService} from '@spartacus/cart/wish-list/core';
import {Cart} from '@spartacus/cart/base/root';
import {UserAccountFacade} from "@spartacus/user/account/root";

@Injectable({
  providedIn: 'root',
})
export class SsabWishListService extends WishListService {
  createInProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    protected store: Store<StateWithMultiCart>,
    protected userAccountFacade: UserAccountFacade,
    protected userService: SsabUserService,
    protected multiCartService: SsabMultiCartService,
    protected userIdService: UserIdService,
    private actions$: Actions
  ) {
    super(store, userAccountFacade, multiCartService, userIdService);
    this.actions$.pipe(ofType(WishListActions.CREATE_WISH_LIST), tap(() => {
      this.createInProgress.next(true)
    })).subscribe();

    this.actions$.pipe(ofType(WishListActions.CREATE_WISH_LIST_FAIL, WishListActions.CREATE_WISH_LIST_SUCCESS, WishListActions.LOAD_WISH_LIST_FAIL, WishListActions.LOAD_WISH_LIST_SUCCESS), tap(() => {
      this.createInProgress.next(false);
    })).subscribe();
  }

  getWishList(forceReload?: boolean): Observable<Cart> {
    return combineLatest([
      this.getWishListId(),
      this.userService.get(),
      this.userIdService.getUserId()
    ]).pipe(
      distinctUntilChanged(),
      // this needs to be removed to make the cart search smoother, but if you remove it, system will convert current cart into a wishlist and cart UI breaks
      debounceTime(20), // prevent simultanous loadwishlist request that create multiple carts, of which only one is saved as wishlist (e.g. searchResultsPage is 25 req/load)
      withLatestFrom(this.createInProgress),
      tap(([[wishListId, user, userId], create]) => {
        if ((!Boolean(wishListId) || forceReload) && !create &&
          userId !== OCC_USER_ID_ANONYMOUS &&
          Boolean(user) &&
          Boolean(user.customerId)) {
          if (!create) {
            this.createInProgress.next(true); // this is not really creation but still works
          }
          this.loadWishList(userId, user.customerId);
        }
      }),
      switchMap(([[wishListId]]) => this.multiCartService.getCart(wishListId)));
  }

  reloadWishlist(): void {
    this.getWishList(true).pipe(take(1)).subscribe();
  }

  addSsabEntry(product: SsabProduct, remove?: boolean): void {
    this.getWishListId()
      .pipe(
        distinctUntilChanged(),
        withLatestFrom(this.userIdService.getUserId(), this.userService.get()),
        tap(([wishListId, userId, user]) => {
          if (
            !Boolean(wishListId) &&
            Boolean(user) &&
            Boolean(user.customerId)
          ) {
            this.loadWishList(userId, user.customerId);
          }
        }),
        filter(([wishListId]) => Boolean(wishListId)),
        take(1)
      )
      .subscribe(([wishListId, userId]) => {
          if (remove) {
            this.multiCartService.getCart(wishListId)
              .pipe(take(1))
              .subscribe(
                wishList => {
                  this.multiCartService.removeEntry(
                    userId, wishListId, wishList.entries.find(e => e.product.code === product.code)?.entryNumber
                  );
                }
              )
          } else {
            this.multiCartService.addSsabEntry(userId, wishListId, {
                product,
                quantity: 1,
                unit: product.unit
              } as SsabOrderEntry,
              {
                successMessage: 'ssab.myFavorites.add.notification.success',
                errorMessage: 'ssab.myFavorites.add.notification.failed'
              } as ExtraMessages);
          }
        }
      );
  }
}
