import {SsabCartNotificationComponent} from './ssab-cart-notification.component';
import {CmsConfig, I18nModule, provideDefaultConfig, UrlModule} from '@spartacus/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UrlModule,
        I18nModule,
    ],
    providers: [
        provideDefaultConfig({
            cmsComponents: {
                CartNotificationComponent: {
                    component: SsabCartNotificationComponent,
                },
            },
        } as CmsConfig)
    ],
    declarations: [SsabCartNotificationComponent],
    exports: [SsabCartNotificationComponent]
})
export class SsabCartNotificationModule {
}
