import {ChangeDetectionStrategy, Component, Input, OnInit,} from '@angular/core';
import {Location} from '@angular/common';
import {SsabProduct} from "../../../model/product.model";
import {SsabGtmService} from '../../../service/analytics/gtm/ssab-gtm.service';
import {GTMEvent} from '../../../model/gtm/gtm-product.model';

@Component({
  selector: 'ssab-cx-product-intro',
  templateUrl: './ssab-product-intro.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabProductIntroComponent implements OnInit{

  @Input() product: SsabProduct;

  constructor(protected location: Location,
              protected ssabGTMService: SsabGtmService,) {

  }

  ngOnInit(): void {
    this.ssabGTMService
      .pushProducts([this.product.code], null,
        null, GTMEvent.ProductViewDetails, null);
  }

  back() {
    this.location.back();
  }
}
