import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {LAUNCH_CALLER, LaunchDialogService} from '@spartacus/storefront';
import {openCloseSpinner} from '../../shared/utils/functions/ssab-functions-utils';
import {SsabBaseModalDialogData} from './ssab-base-modal-layout.config';
import {Subscription} from "rxjs";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'ssab-base-modal',
  templateUrl: './ssab-base-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsabBaseModalComponent implements OnDestroy, OnInit {
  titleText: string;
  bodyText: string;
  buttonText: string;
  subscriptions: Subscription = new Subscription();

  constructor(
    protected launchDialogService: LaunchDialogService,
    @Inject(DOCUMENT) private document: Document,) {
  }

  ngOnInit() {
    this.subscriptions.add(
      this.launchDialogService.data$.subscribe((dialogData: SsabBaseModalDialogData) => {
        if (dialogData) {
          this.titleText = dialogData.titleText;
          this.bodyText = dialogData.bodyText;
          this.buttonText = dialogData.buttonText;
        }
        openCloseSpinner(this.document, false);
      })
    );
  }

  public dismiss(reason?: any): void {
    this.launchDialogService.clear(LAUNCH_CALLER.BASE_MODAL);
    this.launchDialogService.closeDialog('closed');
  }

  ngOnDestroy(): void {
    this.dismiss();
    this.subscriptions.unsubscribe();
  }
}
