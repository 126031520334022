import { Action } from '@ngrx/store';
export const SUBSCRIBE_LATE_ORDER_REQUEST = '[Subscriptions] Late Order Request';
export const SUBSCRIBE_LATE_ORDER_REQUEST_FAIL = '[Subscriptions] Late Order Fail';
export const SUBSCRIBE_LATE_ORDER_REQUEST_SUCCESS = '[Subscriptions] Late Order Success';

export const SHOW_INCHES = '[Show Inches] Show Inches Request';
export const SHOW_INCHES_FAIL = '[Show Inches] Show Inches Request Fail';
export const SHOW_INCHES_SUCCESS = '[Subscriptions] Show Inches Request Success';
export const CREDIT_CHECK_ACTION = '[SSAB Custom] Credit Check';
export const CREDIT_CHECK_COMPLETE_ACTION = '[SSAB Custom] Credit Check Complete';
export const STORE_LANGUAGE_CHANGE = '[Language] Store Language Change Success';
export const RELOAD_CURRENT_USER_REQUEST = '[SSAB Custom] Reload Current User Request';
export const RELOAD_CURRENT_USER_REQUEST_SUCCESS = '[SSAB Custom] Reload Current User Request Success';

export class SetShowInches implements Action {
  readonly type = SHOW_INCHES;
  constructor(public payload: {
    userId: string;
    isShowInches: boolean;
    refreshPLP?: boolean;
  }) {
  }
}
export class SetShowInchesFail implements Action{
  readonly type = SHOW_INCHES_FAIL;
  constructor(public payload: {userId: string; error: any}) {}
}
export class SetShowInchesSuccess implements Action {
  readonly type = SHOW_INCHES_SUCCESS;
  constructor(public userId: string) {}
}

export class SubscribeLateOrderRequest implements Action {
  readonly type = SUBSCRIBE_LATE_ORDER_REQUEST;
  constructor(public payload: {
    userId: string;
    isLateOrderSubscription: boolean;
  }) {
  }
}
export class SubscribeLateOrderRequestFail implements Action{
  readonly type = SUBSCRIBE_LATE_ORDER_REQUEST_FAIL;
  constructor(public payload: {userId: string; error: any}) {}
}
export class SubscribeLateOrderRequestSuccess implements Action {
  readonly type = SUBSCRIBE_LATE_ORDER_REQUEST_SUCCESS;
  constructor(public userId: string) {}
}

export class StoreLanguageChangeSuccess implements Action {
  readonly type = STORE_LANGUAGE_CHANGE;
  constructor(public userId: string) {}
}

export class CreditCheckPerformAction implements Action {
  readonly type = CREDIT_CHECK_ACTION;

  constructor(public payload: { stickyNotification: boolean, clearOldNotifications?: boolean, customMessageKey?: string, forceReloadCart?: boolean }) {
    this.payload = payload;
  }
}

export class CreditCheckCompleteAction implements Action {
  readonly type = CREDIT_CHECK_COMPLETE_ACTION;

  constructor() {
  }
}

export class ReloadCurrentUserRequest implements Action{
  readonly type = RELOAD_CURRENT_USER_REQUEST;
  constructor() {}
}
export class ReloadCurrentUserRequestSuccess implements Action {
  readonly type = RELOAD_CURRENT_USER_REQUEST_SUCCESS;
  constructor() {}
}


export declare type SubscribeLateOrderActions =
  | SubscribeLateOrderRequest
  | SubscribeLateOrderRequestFail
  | SubscribeLateOrderRequestSuccess
  | ReloadCurrentUserRequest
  | ReloadCurrentUserRequestSuccess ;
