import {Component} from '@angular/core';
import {ICON_TYPE, LAUNCH_CALLER, LaunchDialogService} from '@spartacus/storefront';
import {SsabCustomerSelectConfirmData} from './ssab-customer-select-confirm-layout.config';

@Component({
  selector: 'ssab-customer-select-confirm',
  templateUrl: './ssab-customer-select-confirm.component.html'
})
export class SsabCustomerSelectConfirmComponent {

  iconTypes = ICON_TYPE;

  constructor(protected launchDialogService: LaunchDialogService) { }

  public dismiss(reason?: any): void {
    this.launchDialogService.closeDialog(null);
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CUSTOMER_SELECT_CONFIRM, undefined, {
      afterDismiss: true,
      afterConfirm: false,
    });
  }

  public confirm(): void {
    this.launchDialogService.closeDialog(null);
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CUSTOMER_SELECT_CONFIRM, undefined, {
      afterDismiss: false,
      afterConfirm: true,
    } as SsabCustomerSelectConfirmData);
  }

}
