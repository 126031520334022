import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ProductConnector} from '@spartacus/core';
import {SsabCoilComparisonRequest, SsabCoilComparisonResponse, SsabCoilSearchRequest, SsabCoilSearchResult, SsabProduct, SsabStock, SsabUnitOfMeasure} from '../../model/product.model';
import {SsabOccProductAdapter} from './ssab-occ-product.adapter';
import {SsabSearchCriteria} from '../../model/misc.model';


@Injectable({
  providedIn: 'root',
})
export class SsabProductConnector extends ProductConnector {
  constructor(protected adapter: SsabOccProductAdapter) {
    super(adapter);
  }

  getCatalogPrice(product: SsabProduct, unit: SsabUnitOfMeasure, quantity: number, detailsPage: boolean): Observable<SsabProduct> {
    return this.adapter.getCatalogPrice(product, unit, quantity, detailsPage);
  }

  getCatalogAvailability(product: SsabProduct, unit: SsabUnitOfMeasure): Observable<SsabStock> {
    return this.adapter.getCatalogAvailability(product, unit);
  }

  get(productCode: string, scope?: string): Observable<SsabProduct> {
    return this.adapter.load(productCode, scope) as Observable<SsabProduct>;
  }

  getStockAvailability(criteria: SsabSearchCriteria): Observable<any> {
    return this.adapter.getStockAvailabilityDownload(criteria);
  }

  searchCoils(request: SsabCoilSearchRequest): Observable<SsabCoilSearchResult> {
    return this.adapter.searchCoils(request);
  }

  compareCoils(request: SsabCoilComparisonRequest): Observable<SsabCoilComparisonResponse> {
    return this.adapter.compareCoils(request);
  }
}
