import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
})
export class ScannerSearchInputComponent {
  @Input() inputValue: string = '';
  @Output() valueChange = new EventEmitter<string>();
  showLengthWarning: boolean = false;

  onInputChange(newValue: string) {
    this.inputValue = newValue;
  }

  sendValueToParent() {
    if (this.inputValue?.length >= 4) {
      this.valueChange.emit(this.inputValue);
    } else {
      this.showLengthWarning = true;
    }
  }
}
