import {SsabMiniCartBaseComponent} from '../ssab-mini-cart-base.component';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {SsabCart, SsabOrderEntry} from '../../../../model/cart.model';
import {Observable, Subscription} from 'rxjs';
import {SsabProduct} from '../../../../model/product.model';
import {SsabActiveCartService} from '../../../../service/cart/ssab-active-cart.service';
import {SsabUserService} from '../../../../service/user/ssab-user.service';
import {CurrencyService} from '@spartacus/core';
import {filter, map, withLatestFrom} from 'rxjs/operators';
import {MiniCartComponentService} from '@spartacus/cart/base/components/mini-cart';


@Component({
  selector: 'ssab-cx-minicart-consignment',
  templateUrl: './ssab-mini-cart-consignment.component.html'
})
export class SsabMiniCartConsignmentComponent extends SsabMiniCartBaseComponent implements OnInit, OnDestroy {
  cart$: Observable<SsabCart>;
  subscriptions: Subscription = new Subscription();

  constructor(protected activeCartService: SsabActiveCartService,
              protected userService: SsabUserService,
              protected miniCartComponentService: MiniCartComponentService) {
    super(activeCartService, miniCartComponentService, userService);
    this.cart$ = this.activeCartService.getActive();
    this.showPricesOrConsignmentPricesStock$ = this.userService.isShowPricesOrConsignmentStockPrices(false);
  }

  ngOnInit(): void {
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
