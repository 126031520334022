<div class="wrapper">
  <h6 class="text-uppercase mt-3 mb-3 text-center">{{'ssab.scanner.qr.searchtitle'|cxTranslate}}</h6>
  <div class="search" [ngClass]="{'has-error': showLengthWarning}">
    <input
      class="form-control ssab-input mb-0"
      autocomplete="true"
      type="text"
      [ngModel]="inputValue"
      (ngModelChange)="onInputChange($event)"
      placeholder="{{'ssab.scanner.qr.materialId'|cxTranslate}}"
    />

    <button class="btn btn-primary" (click)="sendValueToParent()">
      {{'ssab.scanner.qr.search'|cxTranslate}}
    </button>
  </div>
  <div class="search-error" *ngIf="showLengthWarning">
    {{'ssab.scanner.qr.searchLengthWarning'|cxTranslate}}
  </div>
</div>
