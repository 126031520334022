import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';
import {SsabSiteContextSelectorComponent} from './ssab-site-context-selector.component';

@NgModule({
    declarations: [SsabSiteContextSelectorComponent],
    imports: [
        CommonModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                CMSSiteContextComponent: {
                    component: SsabSiteContextSelectorComponent,
                },
            }
        }),
        I18nModule,
    ],
    exports: [SsabSiteContextSelectorComponent]
})
export class SsabSiteContextSelectorModule {
}

