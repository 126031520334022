import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SsabAsmAdapter } from './ssab-asm.adapter';

@Injectable({
  providedIn: 'root',
})
export class SsabAsmConnector {
  constructor(protected adapter: SsabAsmAdapter) {
  }

  public refreshEmulatedUser(userId: string): Observable<any> {
    return this.adapter.refreshEmulatedUser(userId);
  }
}
