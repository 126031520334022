import {DIALOG_TYPE, LayoutConfig} from '@spartacus/storefront';
import {ScannerDropdownMenuComponent} from "./dropdown-menu.component";

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    SCANNER_CLEAR_DOCUMENTS = 'SCANNER_CLEAR_DOCUMENTS',
  }
}

export const scannerClearDocumentsLayout: LayoutConfig = {
  launch: {
    SCANNER_CLEAR_DOCUMENTS: {
      inlineRoot: true,
      component: ScannerDropdownMenuComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};

export interface SsabScannerClearDocumentsDialogData {
  emailSent?: boolean;
}
