<div class="category-navigation-scroll-wrapper">
  <div
    class="ssab-icon icon-navigation-close icon-md icon-cursor"
    (click)="toggle()"
    aria-label="Menu">
  </div>

  <img class="logo" src="assets/images/ssab-logo.png">

  <ng-container *ngIf="node$ | async as node">
    <ng-container *ngIf="configuration$ | async as conf">
      <ng-container *ngIf="data$ | async as data">
        <ssab-cx-navigation-ui
          [currentNode]="node"
          [class]="data.styleClass"
          [wrapAfter]="data.wrapAfter"
          [stripNarrowAvailable]="!!(conf.stripNarrowAvailable)"
          [consignmentAvailable]="!!(conf.consignmentAvailable)">
        </ssab-cx-navigation-ui>
      </ng-container>
    </ng-container>
  </ng-container>

  <div class="navigation-user-dropdown">
    <div>
      <cx-page-slot class="d-block d-lg-none d-xl-none" position="SearchUnits"></cx-page-slot>
      <cx-page-slot position="TopNavigationLinks"></cx-page-slot>
    </div>
  </div>
</div>
