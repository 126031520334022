import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {SsabScriptService} from "../../../../service/script/ssab-script.service";
import {SsabCdcService} from "../../../../service/cdc/ssab-cdc.service";
import {SsabCdcConfig} from "../../../../service/cdc/ssab-cdc-config";
import {Subscription} from "rxjs";

@Component({
  selector: 'ssab-cdc-password-reset',
  templateUrl: './ssab-cdc-password-reset.component.html'
})
export class SsabCdcPasswordResetComponent implements OnInit, AfterViewInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  constructor(protected cdcConfig: SsabCdcConfig,
              protected cdcService: SsabCdcService,
              protected scriptService: SsabScriptService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.renderCdcPasswordResetScreen();
  }

  public getCdcPasswordResetContainerId(): string {
    return this.cdcConfig.cdc.screenSets.passwordReset.containerId;
  }

  public renderCdcPasswordResetScreen(): void {
    this.subscriptions.add(
      this.scriptService.isCdcScriptLoaded().subscribe(loaded => {
        if (loaded) {
          this.cdcService.openPasswordResetForm();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
