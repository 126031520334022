import {OccConfig} from '@spartacus/core';

export const ssabDefaultOccSiteContextConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        baseSites:
          'basesites?fields=baseSites(DEFAULT)',
      },
    },
  },
};
