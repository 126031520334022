import {SsabCartItemListBaseComponent} from '../ssab-cart-item-list-base.component';
import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'ssab-cx-cart-item-list',
  templateUrl: './ssab-cart-item-list-normal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabCartItemListNormalComponent extends SsabCartItemListBaseComponent {

}

