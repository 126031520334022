import {SsabCustomerSelectionComponent} from './customer-selection/ssab-customer-selection.component';
import {CommonModule} from '@angular/common';
import {FormErrorsModule} from '@spartacus/storefront';
import {I18nModule, provideConfig} from '@spartacus/core';
import {ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {SsabAsmMainUiComponent} from './main/ssab-asm-main-ui.component';
import {SsabAsmToggleUiComponent} from './asm-toggle-ui/ssab-asm-toggle-ui.component';
import {SsabAsmSessionTimerComponent} from './asm-session-timer/ssab-asm-session-timer.component';
import {SsabFormatTimerPipe} from './asm-session-timer/format-timer.pipe';
import {ssabDefaultAsmLayoutConfig} from './ssab-default-asm-layout.config';
import {SsabCsagentLoginFormComponent} from './csagent-login-form/ssab-csagent-login-form.component';
import {SsabCustomerEmulationComponent} from './customer-emulation/ssab-customer-emulation.component';
import {SsabAzureAuthenticationModule} from "../../shared/authentication/ssab-azure-authentication.module";


@NgModule({
    imports: [CommonModule, ReactiveFormsModule, I18nModule, FormErrorsModule, SsabAzureAuthenticationModule],
    declarations: [
        SsabCustomerSelectionComponent,
        SsabAsmMainUiComponent,
        SsabAsmToggleUiComponent,
        SsabAsmSessionTimerComponent,
        SsabFormatTimerPipe,
        SsabCsagentLoginFormComponent,
        SsabCustomerEmulationComponent
    ],
    providers: [provideConfig(ssabDefaultAsmLayoutConfig)]
})
export class SsabAsmComponentsModule{}
