import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConverterService, OccEndpointsService} from '@spartacus/core';
import {OccOrderHistoryAdapter} from '@spartacus/order/occ';
import {OrderTrackingTypes, SsabOrder, SsabOrderHistory, SsabOrderHistoryRequest} from '../../model/order.model';
import {map} from 'rxjs/operators';
import {SsabOccOrderNormalizer} from '../cart/ssab-order.normalizer';


@Injectable({
  providedIn: 'root',
})
export class SsabOccUserOrderAdapter extends OccOrderHistoryAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected orderNormalizer: SsabOccOrderNormalizer
  ) {
    super(http, occEndpoints, converter);
  }

  public loadOrderHistory(userId: string, selectedView: OrderTrackingTypes, params: SsabOrderHistoryRequest): Observable<SsabOrderHistory> {
    const url = this.occEndpoints.buildUrl('orderHistorySearch', {urlParams: {userId}, queryParams: {selectedView}});
    return this.http
      .patch<SsabOrderHistory>(url, params)
      .pipe(map(orders => {
        return {
            ...orders,
            orders: orders?.orders?.map(order => this.orderNormalizer.convert({...order} as any, {...order}) as SsabOrder)
          } as SsabOrderHistory;
        }
      ));
  }

  public downloadOrderHistory(userId: string, selectedView: OrderTrackingTypes, params: SsabOrderHistoryRequest): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'text/plain; charset=utf-8');
    headers = headers.set('Accept', 'text/plain');
    const url = this.occEndpoints.buildUrl('orderHistoryDownload', {urlParams: {headers, userId}, queryParams: {selectedView}});
    return this.http.patch(url, params, {responseType: 'blob' as 'json', observe: 'response'});
  }
}
