import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SsabDocolSearchRequest, SsabDocolSearchResponse} from '../../model/docol.model';
import {SsabOccProductDocolAdapter} from './ssab-occ-product-docol.adapter';

@Injectable({
  providedIn: 'root',
})
export class SsabProductDocolConnector {
  constructor(protected adapter: SsabOccProductDocolAdapter) {

  }

  public getDocolList(userId: string, params: SsabDocolSearchRequest): Observable<SsabDocolSearchResponse>{
    return this.adapter.loadDocolList(userId, params);
  }
}
