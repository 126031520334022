import {ChangeDetectionStrategy, Component} from '@angular/core';
import {SsabCartItemListBaseComponent} from '../ssab-cart-item-list-base.component';
import {SsabOrderEntry} from '../../../../../model/cart.model';
import {CartItemContext} from "@spartacus/cart/base/root";
import {CartItemContextSource} from "@spartacus/cart/base/components";

@Component({
  selector: 'ssab-cx-cart-item-list-consignment',
  templateUrl: './ssab-cart-item-list-consignment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CartItemContextSource,
    {
      provide: CartItemContext,
      useExisting: CartItemContextSource
    }
  ]
})
export class SsabCartItemListConsignmentComponent extends SsabCartItemListBaseComponent {

}

