import {Injectable} from '@angular/core';

import {ConverterService, Occ, PRODUCT_NORMALIZER} from "@spartacus/core";
import {Cart} from '@spartacus/cart/base/root';
import {OccCartNormalizer} from '@spartacus/cart/base/occ';
import {SsabCart} from "../../model/cart.model";

@Injectable({providedIn: 'root'})
export class SsabOccCartNormalizer extends OccCartNormalizer {
  constructor(protected converter2: ConverterService) {
    super(converter2);
  }

  convert(source: Occ.Cart, target?: Cart): Cart {
    if (source?.entries) {
      const cart = super.convert(source, target);
      cart.entries?.forEach(e => e.quantity = (e as any).doubleQuantity);
      (cart as SsabCart).consignmentEntries?.flatMap(e => e.batches)
        .forEach(e => {
          e.quantity = (e as any).doubleQuantity;
          e.product = this.converter2.convert(e.product, PRODUCT_NORMALIZER);
        });
    }
    return target;
  }

}
