<scanner-header-main
  [backUrl]="'/scanner'"
  title="{{'ssab.scanner.productlist.title'|cxTranslate}}"
  searchTitle="{{'ssab.scanner.productlist.searchTitle'|cxTranslate: { searchQuery: searchQuery } }}"
></scanner-header-main>
<div class="bg-white pb-4" *ngIf="productDataList | async as productList">
  <div class="pb-4">
    <app-product-list
      [scannedCode]="searchQuery"
      [productDataList]="productList"
      (clickDataList)="handleProductChoose($event, false)"
    ></app-product-list>
  </div>
  <div class="footer">
    <button class="btn btn-primary btn-default-height btn-block" (click)="onScanMoreProducts()">
      <i class="ssab-icon icon-scanner icon-white icon-inline mr-2"></i>
      <span>{{'ssab.scanner.qr.scanAgain'|cxTranslate}}</span>
    </button>
  </div>
</div>
