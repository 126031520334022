import {AnonymousConsentsDialogModule, KeyboardFocusModule, OutletModule, OutletRefModule, PageLayoutModule, PageSlotModule, PwaModule, SeoModule, SkipLinkModule} from '@spartacus/storefront';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AuthGuard, CmsConfig, ConfigModule, FeaturesConfigModule, I18nModule, provideDefaultConfig} from '@spartacus/core';
import {SsabGlobalMessageModule} from '../cms-components/shared/global-message/ssab-global-message.module';
import {SsabNotificationsModule} from '../cms-components/shared/notifications/ssab-notifications.module';
import {SsabAlertModule} from '../cms-components/shared/alert/ssab-alert.module';
import {ScannerHomeComponent} from "./home/home.component";
import {ScannerHeaderMainComponent} from "./header-main/header-main.component";
import {QrscannerComponent} from "./qrscanner/qrscanner.component";
import {ScannerSearchInputComponent} from "./search-input/search-input.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {OcrInitializeComponent} from "./ocr-initialize/ocr-initialize.component";
import {ScannerProductsComponent} from "./products/products.component";
import {ScannerProductListComponent} from "./product-list/product-list.component";
import {ScannerProductCardComponent} from "./product-card/product-card.component";
import {SsabProductImagesModule} from "../cms-components/product/product-images/ssab-product-images.module";
import {ScannerDocumentPageComponent} from "./document-page/document-page.component";
import {ScannerCartTableComponent} from "./cart-table/cart-table.component";
import {ScannerEmailDialogComponent} from "./email-dialog/email-dialog.component";
import {SsabInputModule} from "../shared/input/ssab-input.module";
import {ScannerDropdownMenuComponent} from "./dropdown-menu/dropdown-menu.component";
import {NgbAccordionBody, NgbAccordionButton, NgbAccordionCollapse, NgbAccordionDirective, NgbAccordionHeader, NgbAccordionItem} from "@ng-bootstrap/ng-bootstrap";
import {scannerSendEmailLayout} from "./email-dialog/email-dialog-layout.config";
import {scannerClearDocumentsLayout} from "./dropdown-menu/dropdown-menu-layout.config";
import {SsabCollectedItemModule} from "../cms-components/cart/contactsales/collected-item/ssab-collected-item.module";
import {ScannerHowToScanComponent} from "./how-to-scan/how-to-scan.component";
import {scannerHowToScanLayout} from "./how-to-scan/how-to-scan-layout.config";
import {ScannerOrderComponent} from "./order/order.component";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SsabGlobalMessageModule,
    OutletModule,
    OutletRefModule,
    PwaModule,
    PageLayoutModule,
    SeoModule,
    PageSlotModule,
    AnonymousConsentsDialogModule,
    FeaturesConfigModule,
    SkipLinkModule,
    KeyboardFocusModule,
    SsabNotificationsModule,
    I18nModule,
    SsabAlertModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        ScannerComponent: {
          component: ScannerHomeComponent,
          guards: [AuthGuard],
        },
        ScannerProductsComponent: {
          component: ScannerProductsComponent,
          guards: [AuthGuard],
        },
        ScannerProductComponent: {
          component: ScannerProductCardComponent,
          guards: [AuthGuard],
          childRoutes: []
        },
        ScannerDocumentComponent: {
          component: ScannerDocumentPageComponent,
          guards: [AuthGuard],
        },
        ScannerOrderComponent: {
          component: ScannerOrderComponent,
          guards: [AuthGuard],
        }
      },
    }),
    FormsModule,
    SsabProductImagesModule,
    RouterModule.forChild([
      {
        // @ts-ignore
        path: null,
        canActivate: [AuthGuard],
        component: ScannerProductCardComponent,
        data: {
          cxRoute: 'scannerProduct',
        },
      },
    ]),
    ReactiveFormsModule,
    SsabInputModule,
    NgbAccordionDirective,
    NgbAccordionItem,
    NgbAccordionHeader,
    NgbAccordionButton,
    NgbAccordionCollapse,
    NgbAccordionBody,
    SsabCollectedItemModule,
  ],
  declarations: [ScannerHomeComponent, ScannerHeaderMainComponent, QrscannerComponent,
    ScannerSearchInputComponent, OcrInitializeComponent, ScannerProductsComponent, ScannerEmailDialogComponent,
    ScannerProductListComponent, ScannerProductCardComponent, ScannerDocumentPageComponent,
    ScannerDropdownMenuComponent, ScannerHowToScanComponent, ScannerCartTableComponent,ScannerOrderComponent],
  exports: [ScannerHomeComponent, ScannerHeaderMainComponent, QrscannerComponent,
    ScannerSearchInputComponent, OcrInitializeComponent, ScannerProductsComponent, ScannerEmailDialogComponent,
    ScannerProductListComponent, ScannerProductCardComponent, ScannerDocumentPageComponent,
    ScannerCartTableComponent, ScannerDropdownMenuComponent, ScannerHowToScanComponent,ScannerOrderComponent],
  providers: [provideDefaultConfig(scannerClearDocumentsLayout), provideDefaultConfig(scannerSendEmailLayout), provideDefaultConfig(scannerHowToScanLayout)]
})
export class SsabScannerModule {
}
