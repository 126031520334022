<scanner-header-main title="{{'ssab.scanner.documents.title'|cxTranslate}}"
                     [backUrl]="'/scanner'"></scanner-header-main>
<div class="bg-white pb-4" *ngIf="cartItems$ |async as cartItems">
  <div class="pb-4">
    <app-cart-table
      [cartItems]="cartItems"
      (download)="downloadPDF()"
      (valueChange)="setSelectedItems($event)"
    ></app-cart-table>
  </div>
  <div class="footer">
    <button class="btn btn-primary btn-block" (click)="downloadPDF()">
      {{'ssab.scanner.documents.download'|cxTranslate}}
    </button>
    <button class="btn btn-primary btn-block" (click)="openScannerSendEmailModal()">
      {{'ssab.scanner.documents.sendEmail'|cxTranslate}}
    </button>
    <button class="btn btn-primary btn-default-height btn-block" (click)="onScanAgain()">
      <i class="ssab-icon icon-scanner icon-white icon-inline mr-2"></i>
      <span>{{'ssab.scanner.qr.scanAgain'|cxTranslate}}</span>
    </button>
  </div>
</div>
