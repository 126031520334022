<a
  [attr.aria-label]="'miniCart.item' | cxTranslate: { count: quantity$ | async }"
  placement="bottom-right"
  [ngbPopover]="popContent" popoverClass="popover-md" triggers="manual" #popover="ngbPopover"
  (click)="popover.open()"
>
  <div class="ssab-icon icon-system-shopping-cart navigation-icon"
       [ngClass]="{'navigation-icon-active': popover.isOpen()}"></div>
  <ng-container *ngIf="quantity$ | async as quantity">
    <span class="count">
    {{ 'miniCart.count' | cxTranslate: {count: quantity} }}
  </span>
  </ng-container>
</a>


<ng-template #popContent>
  <ng-container *ngIf="isConsignmentCart$ | async; else normalMiniCart">
    <ssab-cx-minicart-consignment (closeMinicart)="popover.close()"></ssab-cx-minicart-consignment>
  </ng-container>

  <ng-template #normalMiniCart>
    <ssab-cx-minicart-normal (closeMinicart)="popover.close()"></ssab-cx-minicart-normal>
  </ng-template>
</ng-template>
