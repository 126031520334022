<div class="ssab-modal-md ssab-modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
        <span class="w-100 d-flex justify-content-end">
          <i class="ssab-icon icon-navigation-close icon-md icon-cursor"
             aria-label="Close" (click)="dismiss()"></i>
        </span>
        <div class="text-center">
          <h3>{{ titleText | cxTranslate }}</h3>
          <div class="pt-4">
            <i class="ssab-icon icon-system-checkbox icon-inline icon-green mr-4"></i>
            <span class="text-opacity">{{ bodyText | cxTranslate }}</span>
          </div>
          <div class="pt-4 pr-5 pl-5">
            <button class="btn btn-primary btn-default-height text-uppercase btn-block" (click)="dismiss()">
              <h5 class="mb-0">{{ buttonText | cxTranslate | uppercase}}</h5>
            </button>
          </div>
        </div>
    </div>
  </div>
</div>




