import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {ICON_TYPE} from '@spartacus/storefront';
import {SsabNotificationsAdapter} from '../../../shared/notifications/ssab-occ-notifications.adapter';
import {combineLatest, Observable} from 'rxjs';
import {take, filter, tap, switchMap} from 'rxjs/operators';
import {SsabNotification} from '../../../model/misc.model';
import {SsabActiveCartService} from '../../../service/cart/ssab-active-cart.service';
import {BaseSiteService, OCC_USER_ID_ANONYMOUS, UserIdService} from "@spartacus/core";

@Component({
  selector: 'ssab-cx-notifications',
  templateUrl: './ssab-notifications.component.html',
})
export class SsabNotificationComponent implements OnInit {
  iconTypes = ICON_TYPE;
  notifications$: Observable<SsabNotification[]>;
  @Output() notificationsAmount = new EventEmitter<number>();

  constructor(private notificationsAdapter: SsabNotificationsAdapter,
              protected activeCartService: SsabActiveCartService,
              private userIdService: UserIdService,
              private baseSiteService: BaseSiteService) {
  }

  ngOnInit(): void {
    this.notifications$ = combineLatest([
      this.userIdService.getUserId(),
      this.baseSiteService.getActive()
    ])
      .pipe(
        filter(([userId, baseSite]) => userId !== OCC_USER_ID_ANONYMOUS),
        switchMap(([userId, baseSite]) =>
          this.notificationsAdapter.getNotifications(userId).pipe(
            tap((notifications) =>
              this.notificationsAmount.emit(notifications.length !== null ? notifications.length : 0)
            )
          )
        )
      );
  }

  dismiss(uid: string): void {
    this.userIdService.takeUserId()
      .pipe(
        filter(uid => uid !== OCC_USER_ID_ANONYMOUS),
        take(1)
      )
      .subscribe((userId) => {
        this.notifications$ = this.notificationsAdapter.dismissNotification(userId, uid);
      });
  }
}
