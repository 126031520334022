import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {EloquaForms, Sort, ValueRange} from '../../../model/misc.model';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, tap} from 'rxjs/operators';
import {SsabProductService} from '../../../service/product/ssab-product.service';
import {SsabDocolSearchRequest, SsabDocolSearchResponse} from '../../../model/docol.model';
import {SsabUserService} from '../../../service/user/ssab-user.service';
import {HttpParams} from '@angular/common/http';

@Component({
  selector: 'ssab-cx-material-data',
  templateUrl: './ssab-material-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabMaterialDataComponent implements OnInit, OnDestroy {
  defaultPageSize = 25;
  tensileRange: ValueRange = {from: 0, to: 0} as ValueRange;
  yieldRange: ValueRange = {from: 0, to: 0} as ValueRange;
  activeSort$ = new BehaviorSubject<Sort>({field: 'grade', descending: true});
  docolProductList$: Observable<SsabDocolSearchResponse> = of(null);
  elqUrlWithParameters: string = '';
  subscriptions = new Subscription();

  searchDocolForm: UntypedFormGroup;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected fb: UntypedFormBuilder,
    protected router: Router,
    protected productService: SsabProductService,
    private activatedRoute: ActivatedRoute,
    protected userService: SsabUserService) {
  }

  ngOnInit(): void {
    this.searchDocolForm = this.fb.group(
      {
        keyword: [null],
        yieldStress: this.fb.group(
          {
            sliderControl: [null, null],
          }),
        tensileStress: this.fb.group(
          {
            sliderControl: [null, null],
          }),
        types: [null],
        standards: [null],
      }
    );
    this.subscriptions.add(this.userService.getElOQUABaseUrl(EloquaForms.DocolSearch).subscribe((elqBaseUrl) => {
      this.elqUrlWithParameters = elqBaseUrl;
      this.cdr.detectChanges();
    }));
    this.getParametersFromUrl();
    this.search();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  sendEloquaMaterialDataSearch(params: HttpParams): void {
    if (this.elqUrlWithParameters && params) {
      const subEloqua = this.userService
        .sendELOQUAData(this.elqUrlWithParameters, params)
        .subscribe(() => subEloqua.unsubscribe());
    }
  }

  getMaterialDataSearchParams(materialListRequest: SsabDocolSearchRequest, totalResult: string): HttpParams {
    let params = new HttpParams();
    params = params.set('keyword', materialListRequest.keyword ? materialListRequest.keyword : '');
    if (materialListRequest.types?.length > 0) {
      params = params.set('type', materialListRequest.types?.toString());
    }
    if (materialListRequest.yieldStress) {
      params = params.set('yieldStress', materialListRequest.yieldStress?.from + ' - ' + materialListRequest.yieldStress?.to);
    }
    if (materialListRequest.standards?.length > 0) {
      params = params.set('standards', materialListRequest.standards?.toString());
    }
    params = params.set('numberOfMatchingDocuments', totalResult);
    return params;
  }

  search(from = 0): void {
    this.docolProductList$ = this.productService.getDocolList(this.getDocolRequest(from))
      .pipe(filter(Boolean),
        tap((materialList: SsabDocolSearchResponse) => {
          this.tensileRange = materialList.tensileStress;
          this.yieldRange = materialList.yieldStress;
          this.sendEloquaMaterialDataSearch(
            this.getMaterialDataSearchParams(
              this.getDocolRequest(from), materialList.pagination.totalResults.toString()
            )
          );
        })
      );
    this.cdr.detectChanges();
  }

  getDocolRequest(from = 0): SsabDocolSearchRequest {

    const tensileStress = this.searchDocolForm.get('tensileStress.sliderControl').value == null ? null :
      (this.searchDocolForm.get('tensileStress.sliderControl').value[0] == null &&
      this.searchDocolForm.get('tensileStress.sliderControl').value[1] == null ? null : {
        from: this.searchDocolForm.get('tensileStress.sliderControl').value[0],
        to: this.searchDocolForm.get('tensileStress.sliderControl').value[1]
      } as ValueRange);

    const yieldStress = this.searchDocolForm.get('yieldStress.sliderControl').value == null ? null :
      (this.searchDocolForm.get('yieldStress.sliderControl').value[0] == null &&
      this.searchDocolForm.get('yieldStress.sliderControl').value[1] == null ? null : {
        from: this.searchDocolForm.get('yieldStress.sliderControl').value[0],
        to: this.searchDocolForm.get('yieldStress.sliderControl').value[1]
      } as ValueRange);

    const docolRequest = {
      keyword: this.searchDocolForm.controls.keyword.value,
      types: this.searchDocolForm.controls.types.value,
      standards: this.searchDocolForm.controls.standards.value,
      size: this.defaultPageSize,
      from: from * this.defaultPageSize,
      tensileStress,
      yieldStress,
      sorts: [this.activeSort$.getValue()]
    } as SsabDocolSearchRequest;
    return docolRequest;
  }

  showFilterOptions($event: MouseEvent): void {
    const innerSearch = ($event.target as HTMLElement)
      .closest('.cx-material-search')
      .querySelector('.cx-material-data-form.d-none');
    innerSearch.classList.remove('d-none');
  }

  resetForm(): void {
    this.searchDocolForm.reset();
    this.searchDocolForm.get('yieldStress.sliderControl').setValue([this.yieldRange.from, this.yieldRange.to]);
    this.searchDocolForm.get('tensileStress.sliderControl').setValue([this.tensileRange.from, this.tensileRange.to]);
  }

  pageChange(from: number): void {
    this.search(from);
  }

  sort(sort: Sort): void {
    this.activeSort$.next(sort);
    this.search();
  }

  getParametersFromUrl(): void {
    this.subscriptions.add(this.activatedRoute.queryParams.subscribe((params) => {
      if (params.pageSize) {
        this.defaultPageSize = +params.pageSize;
      }
      Object.keys(this.searchDocolForm.controls).forEach(key => {
        if (params[key]) {
          const paramsArray = params[key].toString().split(',');
          switch (key) {
            case 'standards':
            case 'types':
              if (this.searchDocolForm.get(key).value) {
                paramsArray.push(...this.searchDocolForm.get(key).value);
              }
              this.searchDocolForm.get(key).setValue(paramsArray);
              break;
            case 'tensileStress':
            case 'yieldStress':
              if (paramsArray.length === 2) {
                this.searchDocolForm.get(key + '.sliderControl').setValue([+paramsArray[0], +paramsArray[1]]);
              }
              break;
            default:
              if (params[key]) {
                this.searchDocolForm.controls[key].setValue(params[key]);
              }
              break;
          }
        }
      });
    }));
  }
}

