<div class="d-flex vertical-align-item">
  <span class="text-opacity pr-md-3">{{'productList.pageSize.title' | cxTranslate}}</span>
  <ng-select
    [searchable]="false"
    [clearable]="false"
    dropdownPosition="bottom"
    class="ssab-select"
    placeholder="{{'productList.pageSize.placeholder' | cxTranslate}}"
    [ngModel]="selectedPageSize"
    (change)="changeListingItems($event)"
  >
    <ng-option *ngFor="let pageSize of pageSizes" [value]="pageSize">
      {{pageSize}}
    </ng-option>
  </ng-select>
</div>
