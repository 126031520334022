import {Component, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs';
import {SsabCustomerSearchPage} from '../../../model/asm.model';
import {CustomerSelectionComponent} from '@spartacus/asm/components';


@Component({
  selector: 'ssab-cx-customer-selection',
  templateUrl: './ssab-customer-selection.component.html',
  styleUrls: ['./ssab-customer-selection.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '(document:click)': 'onDocumentClick($event)',
  },
})
export class SsabCustomerSelectionComponent extends CustomerSelectionComponent {

  getSsabSearchResults(): Observable<SsabCustomerSearchPage> {
    return this.searchResults as Observable<SsabCustomerSearchPage>;
  }

  onSubmit(): void {
    if (this.customerSelectionForm.valid && !!this.selectedCustomer) {
      this.submitEvent.emit({ customerId: this.selectedCustomer.displayUid });
    } else {
      this.customerSelectionForm.markAllAsTouched();
    }
  }
}
