import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {provideDefaultConfig} from '@spartacus/core';
import {SsabPaginationComponent} from './ssab-pagination.component';
import {NgModule} from '@angular/core';
import {PaginationModule} from '@spartacus/storefront';
import {defaultPaginationConfig} from '../../cms-components/product/product-list/pagination/ssab-default-pagination.config';


@NgModule({
  imports: [CommonModule, RouterModule],
  providers: [provideDefaultConfig(defaultPaginationConfig)],
  declarations: [SsabPaginationComponent],
  exports: [SsabPaginationComponent],
})
export class SsabPaginationModule extends PaginationModule {}
