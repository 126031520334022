import {Component, Inject, OnInit} from '@angular/core';
import {SsabCdcService} from "../../../../service/cdc/ssab-cdc.service";
import {LanguageService} from "@spartacus/core";
import {SsabScriptService} from "../../../../service/script/ssab-script.service";
import {filter, take, withLatestFrom} from "rxjs/operators";
import {MsalService} from "../../services/msal.service";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'ssab-sso-login',
  templateUrl: './ssab-sso-login.component.html'
})
export class SsabSsoLoginComponent implements OnInit {


  constructor(protected cdcService: SsabCdcService,
              protected scriptService: SsabScriptService,
              private authService: MsalService,
              @Inject(DOCUMENT) private document: Document,
              protected languageService: LanguageService) {
  }

  ngOnInit(): void {
    const sub = this.scriptService.isCdcScriptLoaded().pipe(
      filter(loaded => loaded),
      withLatestFrom(this.languageService.getActive()),
      take(1),
    ).subscribe(
      ([loaded, lang]) => {
        if (this.document.location.href.includes('adlogin=true')) {
          console.log('AD Login');
          this.authService.startAzureLogin();
        } else {
          console.log('SSO Login');
          this.cdcService.ssoLogin(lang);
        }
      }
    );
  }

}
