import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ProductUnitConversion, SsabProduct} from '../../../../model/product.model';
import {CmsService, Page} from '@spartacus/core';
import {Observable} from 'rxjs';

@Component({
  selector: 'ssab-cx-product-list-item',
  templateUrl: './ssab-product-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabProductListItemComponent implements OnInit {
  @Input() product: SsabProduct;
  @Input() productIndex: number;
  selectedUnit: ProductUnitConversion;
  fromUnit: ProductUnitConversion;
  currentQuantity = 1;
  currentPage$: Observable<Page>;
  weight: string;

  constructor(protected cdr: ChangeDetectorRef,
              protected cmsService: CmsService) {
    this.currentPage$ = this.cmsService.getCurrentPage();
  }

  ngOnInit(): void {
    this.init();
  }

  private init() {
    this.currentQuantity = this.product.stock.minimumAvailableQuantity;
    this.selectedUnit = this.product.productUnitConversions?.find(p => p.unit.code === p.fromUnit?.code);
    this.selectedUnit = this.selectedUnit?.bundleSize !== undefined ? this.selectedUnit : this.product.productUnitConversions?.find(p => p.bundleSize !== undefined);
    this.fromUnit = this.selectedUnit;
    if (this.selectedUnit && this.selectedUnit?.conversionToWeightUnit) {
      this.weight = (this.currentQuantity * this.selectedUnit?.conversionToWeightUnit).toFixed(0);
    }
  }

  onSelectedUnitChange(data: SsabProduct): void {
    this.product = data;
    this.init();
    this.cdr.detectChanges();
  }
}
