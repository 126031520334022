<div class="ssab-modal-md ssab-modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="new-address" [formGroup]="addressForm">
        <div class="field-content">
          <label>{{'ssab.cart.header.address.company'|cxTranslate}}</label>
          <div class="field-value">
            <ssab-cx-input
              idName="company" [isNotValidFunction]="isInvalid('company')"
              [labelPlaceholder]="'ssab.cart.header.address.companyPlaceholder'" [nameForm]="'company'"
              [parentForm]="addressForm"></ssab-cx-input>
          </div>
        </div>

        <div class="field-content pt-3">
          <label>{{'ssab.cart.header.address.line1'|cxTranslate}}</label>
          <div class="field-value">
            <ssab-cx-input
              idName="line1" [isNotValidFunction]="isInvalid('line1')"
              [labelPlaceholder]="'ssab.cart.header.address.line1Placeholder'" [nameForm]="'line1'"
              [parentForm]="addressForm"></ssab-cx-input>
          </div>
        </div>

        <div class="field-content pt-3">
          <label>{{'ssab.cart.header.address.postalCode'|cxTranslate}}</label>
          <div class="field-value">
            <ssab-cx-input
              idName="postalCode"
              [isNotValidFunction]="isInvalid('postalCode')"
              [labelPlaceholder]="'ssab.cart.header.address.postalCodePlaceholder'"
              [nameForm]="'postalCode'"
              [errorMessage]="'ssab.address.postalcode.notSupported'"
              [parentForm]="addressForm"></ssab-cx-input>
          </div>
        </div>

        <div class="field-content pt-3">
          <label>{{'ssab.cart.header.address.city'|cxTranslate}}</label>
          <div class="field-value">
            <ssab-cx-input
              idName="city" [isNotValidFunction]="isInvalid('city')"
              [labelPlaceholder]="'ssab.cart.header.address.cityPlaceholder'" [nameForm]="'city'"
              [parentForm]="addressForm"></ssab-cx-input>
          </div>
        </div>

        <div class="field-content pt-3">
          <label>{{'ssab.cart.header.address.country'|cxTranslate}}</label>
          <div class="field-value">
            <ng-select
              [searchable]="false"
              [clearable]="false"
              [closeOnSelect]="true"
              dropdownPosition="bottom"
              bindLabel="name"
              bindValue="isocode"
              formControlName="country"
              class="w-80vw"
              [items]="deliveryCountries$ | async"
              ng-model="selectedCountry"
            ></ng-select>
          </div>
        </div>

        <div class="buttons row pt-3">
          <div class="col-6">
            <button
              type="button"
              (click)="cancel()"
              class="btn btn-secondary btn-block">
              {{ 'ssab.cart.header.address.cancel' | cxTranslate }}
            </button>
          </div>

          <div class="col-6">
            <button
              type="button"
              (click)="add()"
              class="btn btn-primary btn-block"
              [disabled]="!isValid()">
              {{ 'ssab.cart.header.address.add' | cxTranslate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

