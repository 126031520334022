import {SalesInformation} from '../../../../model/user.model';
import {SsabInputRangeComponent} from '../../../../shared/input-range/ssab-input-range.component';

export function openCloseSpinner(doc: Document, show: boolean, element?: HTMLElement) {
  const node: HTMLElement = doc.getElementById('ssab-spinner');
  if (node && node.classList) {
    if (show) {
      node.classList.add('show');
    } else {
      node.classList.remove('show');
    }
  }
}

export function toggleSearchOption(element: HTMLElement): void {
  // window.scrollTo(0, 0);
  element?.classList.toggle('cx-wide-option');
}

export function hideSearchOptions(element: HTMLElement, inputRange?: SsabInputRangeComponent): void {
  element.classList.remove('cx-wide-option');
  if (inputRange) {
    inputRange.toggleRangeContent(null, element);
  }
}

export function getEmails(contacts: SalesInformation[]): string {
  return contacts.map((contact) => contact.email).join(',');
}
