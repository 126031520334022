import {BaseSiteInitializer, CurrencyStatePersistenceService, provideDefaultConfig, SITE_CONTEXT_FEATURE, SiteContextOccModule, SiteContextState} from '@spartacus/core';
import {CommonModule} from '@angular/common';
import {ssabDefaultOccSiteContextConfig} from './ssab-default-occ-site-context-config';
import {InjectionToken, NgModule} from '@angular/core';
import {SsabCurrencyStatePersistenceService} from './ssab-currency-state-persistence.service';
import {SsabBaseSiteInitializer} from './ssab-base-site-initializer';
import {languageReducer} from "./ssab-languages.reducer";
import {ActionReducerMap, StoreModule} from "@ngrx/store";
import {baseSiteReducer} from "./ssab-base-site.reducer";
import {currencyReducer} from "./ssab-currencies.reducer";


export const reducerToken: InjectionToken<ActionReducerMap<SiteContextState>> =
  new InjectionToken<ActionReducerMap<SiteContextState>>('SiteContextReducers');


export function getReducers(): ActionReducerMap<SiteContextState, any> {
  return {
    languages: languageReducer,
    currencies: currencyReducer,
    baseSite: baseSiteReducer,
  };
}

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(SITE_CONTEXT_FEATURE, reducerToken)
  ],
  providers: [
    provideDefaultConfig(ssabDefaultOccSiteContextConfig),
    {
      provide: CurrencyStatePersistenceService,
      useClass: SsabCurrencyStatePersistenceService
    },
    {
      provide: BaseSiteInitializer,
      useClass: SsabBaseSiteInitializer
    },
    {
      provide: reducerToken,
      useFactory: getReducers,
    }
  ],
})
export class SsabSiteContextOccModule extends SiteContextOccModule {
}

