import {FeaturesConfigModule, I18nModule, UrlModule} from '@spartacus/core';
import {RouterModule} from '@angular/router';
import {SsabItemStepperModule} from '../../product/product-list/item-stepper/ssab-item-stepper.module';
import {CommonModule} from '@angular/common';
import {
  DatePickerModule,
  FormErrorsModule,
  ItemCounterModule,
  MediaModule,
  PromotionsModule
} from '@spartacus/storefront';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SsabCartTotalsComponent} from './totals/ssab-cart-totals.component';
import {SsabDatepickerModule} from '../../shared/datepicker/ssab-datepicker.module';
import {SsabCartItemListNormalComponent} from './cart-item-list/cart-item-list-normal/ssab-cart-item-list-normal.component';
import {SsabCartItemListConsignmentComponent} from './cart-item-list/cart-item-list-consignment/ssab-cart-item-list-consignment.component';
import {SsabCartItemConsignmentComponent} from './cart-item/cart-item-consignment/ssab-cart-item-consignment.component';
import {SsabInputModule} from '../../../shared/input/ssab-input.module';
import {SsabCartItemNormalComponent} from './cart-item/cart-item-normal/ssab-cart-item-normal.component';
import {CartSharedModule} from '@spartacus/cart/base/components';
import {SsabBundleSplitToggleModule} from '../../my-account/bundle-split/ssab-bundle-split-toggle.module';
import {SsabProductPriceModule} from "../../product/product-price-data/ssab-product-price.module";
import {SsabAddToCartModule} from "../add-to-cart/ssab-add-to-cart.module";
import {
  SsabCartItemConsignmentLabelComponent
} from "./cart-item/cart-item-consignment/ssab-cart-item-consigment-label/ssab-cart-item-consignment-label.component";
import {
  SsabCartItemConsignmentLabelDialogComponent
} from "./cart-item/cart-item-consignment/ssab-cart-item-consigment-label/ssab-cart-item-consignment-label-dialog.component";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    UrlModule,
    NgbModule,
    I18nModule,
    MediaModule,
    ItemCounterModule,
    FeaturesConfigModule,
    PromotionsModule,
    DatePickerModule,
    FormErrorsModule,
    FormsModule,
    NgSelectModule,
    SsabDatepickerModule,
    SsabItemStepperModule,
    SsabInputModule,
    SsabBundleSplitToggleModule,
    SsabProductPriceModule,
    SsabAddToCartModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule
  ],
  declarations: [
    SsabCartItemListNormalComponent,
    SsabCartItemListConsignmentComponent,
    SsabCartItemNormalComponent,
    SsabCartItemConsignmentComponent,
    SsabCartItemConsignmentLabelComponent,
    SsabCartItemConsignmentLabelDialogComponent,
    SsabCartTotalsComponent
  ],
  exports: [SsabCartItemListNormalComponent,
    SsabCartItemListConsignmentComponent,
    SsabCartItemNormalComponent,
    SsabCartItemConsignmentComponent,
    SsabCartItemConsignmentLabelComponent,
    SsabCartItemConsignmentLabelDialogComponent,
    SsabCartTotalsComponent],
})
export class SsabCartSharedModule extends CartSharedModule {

}
