import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,} from '@angular/core';
import {ButtonAction, SsabProduct, SsabStock, StepperUpdateData} from '../../../model/product.model';
import {SsabProductService} from '../../../service/product/ssab-product.service';
import {SsabUserService} from '../../../service/user/ssab-user.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {BaseSiteService} from '@spartacus/core';
import {LaunchDialogService} from '@spartacus/storefront';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'ssab-cx-product-price',
  templateUrl: './ssab-product-price.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabProductPriceComponent implements OnInit, OnChanges {

  currentQuantity = 1;
  showPricesOrConsignmentPricesStock$: Observable<boolean>;
  showConsignmentPricesStock: boolean;
  currentPriceChanged$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  baseSite$: Observable<string>;

  @Input() isPLPView: boolean = false;
  @Input() product: SsabProduct;
  @Output() update = new EventEmitter<SsabProduct>();
  warehouseForm: UntypedFormGroup;

  constructor(protected cdr: ChangeDetectorRef,
              protected productService: SsabProductService,
              protected userService: SsabUserService,
              protected launchDialogService: LaunchDialogService,
              protected baseSiteService: BaseSiteService,
              protected fb: UntypedFormBuilder) {
    this.baseSite$ = this.baseSiteService.getActive();
  }

  ngOnInit(): void {
    this.currentQuantity = this.product.stock.minimumAvailableQuantity;
    this.currentPriceChanged$.next(!this.isKgSelected());
    this.showPricesOrConsignmentPricesStock$ = this.userService.isShowPricesOrConsignmentStockPrices(!this.product.stock?.consignment);
    this.showConsignmentPricesStock = this.product.buttonAction === ButtonAction.consignmentStock;
    this.warehouseForm = this.fb.group(
      {
        warehouseCode: [this.product.stock.warehouseCode, [Validators.required]],
      }
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  changeStock(stock: SsabStock): void {
    this.product = {...this.product, stock: stock};
    this.selectUnit(this.product.unit.code);
  }

  notifyChange(product: SsabProduct): void {
    this.product = product;
    this.update.emit(product);
    this.cdr.detectChanges();
  }

  selectUnit(unit: string):
    void {
    const priceSubscription = this.productService.getCatalogPrice(this.product, {code: unit}, this.currentQuantity, !this.isPLPView).subscribe(
      p => {
        this.currentPriceChanged$.next(!this.isKgSelected());
        priceSubscription.unsubscribe();
        this.notifyChange(p);
      }
    );
    /* const availabilitySubscription = this.productService.getCatalogAvailability({...this.product, stock: this.currentStock}, this.currentUnit?.unit).subscribe(
       stock => {
         this.product = {...this.product, stock: stock};
         this.currentQuantity = stock.minimumAvailableQuantity;
         availabilitySubscription.unsubscribe();
         this.notifyChange(this.product);
       }
     );*/
  }

  onChange(data: StepperUpdateData): void {
    if (data.qty) {
      this.currentQuantity = data.qty;
    }
    if (data.unit) {
      this.selectUnit(data.unit);
    }
    this.notifyChange({...this.product});
  }

  isKgSelected(): boolean {
    return this.product.unit?.code === 'KG';
  }
}
