import { NgModule } from '@angular/core';
import {UserProfileModule} from '@spartacus/user';
import {SsabMyDetailsModule} from '../cms-components/my-account/my-details/ssab-my-details.module';

@NgModule({
  imports: [
    SsabMyDetailsModule
  ],
})
export class SsabUserProfileModule extends UserProfileModule{}
