import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SsabProduct, SsabUnitOfMeasure} from '../../../model/product.model';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'ssab-unit-picker-cart',
  templateUrl: './ssab-unit-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabUnitPickerComponent implements OnInit {
  @Input() product: SsabProduct;
  @Output() update = new EventEmitter<SsabUnitOfMeasure>();
  @Input() readonly: boolean = false
  @Input() cssClassMulti: string = '';
  @Input() cssClassSingle: string = '';
  @Input() parentForm: FormGroup;
  @Input() nameForm: string;
  @Input() selectedUnit: string;
  @Input() appendTo = 'body'

  units: CodeName[];

  constructor() {
  }

  ngOnInit(): void {
    this.units = this.product.productUnitConversions?.map(u => ({
        code: u.unit.code,
        name: u.unit.name
      } as CodeName)
    );
  }

  unitChange(updatedUnit: SsabUnitOfMeasure): void {
    this.update.emit(updatedUnit);
  }
}

export interface CodeName {
  code: string;
  name: string;
}
