import {filter, map, startWith, switchMap} from 'rxjs/operators';
import {SsabOrderEntry} from '../../../model/cart.model';
import {SsabUserService} from '../../../service/user/ssab-user.service';
import {MiniCartComponent, MiniCartComponentService} from '@spartacus/cart/base/components/mini-cart';
import {SsabActiveCartService} from '../../../service/cart/ssab-active-cart.service';
import {Observable} from 'rxjs';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';


@Component({
  template: ''
})
export abstract class SsabMiniCartBaseComponent extends MiniCartComponent implements OnInit {
  @Output() closeMinicart = new EventEmitter<boolean>();
  items$: Observable<SsabOrderEntry[]>;
  showPricesOrConsignmentPricesStock$: Observable<boolean>;


  constructor(protected activeCartService: SsabActiveCartService,
              protected miniCartComponentService: MiniCartComponentService,
              protected userService: SsabUserService) {
    super(miniCartComponentService);
  }

  ngOnInit(): void {
    this.showPricesOrConsignmentPricesStock$ = this.activeCartService.isConsignmentCart()
      .pipe(switchMap(isConsignmentCart =>
          this.userService.isShowPricesOrConsignmentStockPrices(!isConsignmentCart)
        )
      );

    this.items$ = this.activeCartService.getActive().pipe(
      startWith({entries: null}),
      map((cart) => {
        return cart.entries;
      })
    );

    this.total$ = this.activeCartService.getActive().pipe(
      filter((cart) => !!cart.subTotal),
      map((cart) => cart.subTotal.formattedValue)
    );
  }

  hasCartNoAvailableProducts(entries: SsabOrderEntry[]): boolean {
    let cartHasNoAvailableProducts = false;
    entries.forEach((entry) => {
      if (entry.product?.stock?.stockLevelStatus === 'outOfStock') {
        cartHasNoAvailableProducts = true;
      }
      if (entry.quantity > entry.product?.stock?.stockLevel) {
        cartHasNoAvailableProducts = true;
      }
    });
    return cartHasNoAvailableProducts;
  }

  removeItem(entry: SsabOrderEntry): void {
    this.activeCartService.removeEntry(entry);
  }

  close(close: boolean): void {
    this.closeMinicart.emit(close);
  }
}
