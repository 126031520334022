import {Config} from '@spartacus/core';

export abstract class SsabCdcConfig extends Config{
  cdc: {
    javascript: {
      baseUrl: string
    },
    screenSets: {
      login: {
        screenSet?: string,
        startScreen?: string,
        containerId?: string,
      }
      passwordReset: {
        screenSet?: string,
        startScreen?: string,
        containerId?: string,
      }
    profileUpdate: {
        screenSet?: string,
        startScreen?: string,
        containerId?: string,
      }
      gcpProfileUpdate: {
        screenSet?: string,
        startScreen?: string,
        containerId?: string,
      }
    }
  };
}

export const ssabCdcConfig: SsabCdcConfig = {
  cdc: {
    javascript: {
      baseUrl: 'https://cdns.eu1.gigya.com/js/gigya.js?apikey='
    },
    screenSets: {
      login: {
        screenSet: 'SSAB-RegistrationLogin',
        startScreen: 'gigya-login-screen',
        containerId: 'cdc-login-container'
      },
      passwordReset: {
        screenSet: 'SSAB-RegistrationLogin',
        startScreen: 'gigya-forgot-password-screen',
        containerId: 'cdc-login-container'
      },
      profileUpdate: {
        screenSet: 'SSAB-ProfileUpdate',
        startScreen: 'myssab-my_account-screen',
        containerId: 'cdc-profile-update-container',
      },
      gcpProfileUpdate: {
        screenSet: 'SSAB-ProfileUpdate',
        startScreen: 'myssab-gcp-my_account-screen',
        containerId: 'cdc-profile-update-container',
      }
    }
  }
};
