import {HttpParams} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Feature} from '@spartacus/core';
import {ICON_TYPE, LAUNCH_CALLER, LaunchDialogService} from '@spartacus/storefront';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {filter, map, take, withLatestFrom} from 'rxjs/operators';
import {SsabCart, SsabOrderEntry} from '../../../../model/cart.model';
import {EloquaForms} from '../../../../model/misc.model';
import {QuantityUnitData, SsabUnitOfMeasure} from '../../../../model/product.model';
import {SsabUser} from '../../../../model/user.model';
import {SsabGtmService} from '../../../../service/analytics/gtm/ssab-gtm.service';
import {LoadContactSalesProductListSuccess, LOAD_CONTACTSALES_PRODUCT_LIST_SUCCESS} from '../../../../service/contactsales/ssab-contactsales.action';
import {SsabContactSalesProductListService} from '../../../../service/contactsales/ssab-contactsales.service';
import {SsabProductService} from '../../../../service/product/ssab-product.service';
import {SsabUserService} from '../../../../service/user/ssab-user.service';
import {getEmails, openCloseSpinner} from '../../../shared/utils/functions/ssab-functions-utils';
import {SendStockAvailabilityRequest} from '../../../../service/contactform/ssab-contactform.action';
import {SsabCollectedItemsDialogData} from './ssab-collected-items-layout.config';
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'ssab-cx-collected-items',
  templateUrl: './ssab-collected-items.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsabCollectedItemsComponent implements OnInit, OnDestroy {
  iconTypes = ICON_TYPE;
  items$: Observable<SsabOrderEntry[]>;
  itemsUpdated: Subject<SsabOrderEntry[]> = new Subject();
  user$: Observable<SsabUser>;
  eloquaUrl: string = '';
  features: Feature[] = [];
  contactRepresentativeAssigned: Observable<boolean>;
  tableScroll: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  subscriptions: Subscription = new Subscription();

  constructor(
    protected userService: SsabUserService,
    protected contactSalesProductListService: SsabContactSalesProductListService,
    protected store: Store,
    protected actions$: Actions,
    protected productService: SsabProductService,
    protected gtmService: SsabGtmService,
    protected cdr: ChangeDetectorRef,
    protected launchDialogService: LaunchDialogService,
    @Inject(DOCUMENT) private document: Document,) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.launchDialogService.data$.subscribe((dialogData: SsabCollectedItemsDialogData) => {
        if (dialogData) {
          this.items$ = dialogData.items;
          this.user$ = dialogData.user;
        }
      })
    );
    this.updateItems();
    this.subscriptions.add(
      this.userService.getElOQUABaseUrl(EloquaForms.StockSearch).subscribe((url) => {
        this.eloquaUrl = url;
        this.cdr.detectChanges();
      })
    );
    this.contactRepresentativeAssigned = this.user$.pipe(map((user: SsabUser) => user.salesContacts?.length > 0));
  }

  ngOnDestroy() {
    this.dismiss();
    this.subscriptions.unsubscribe();
  }

  updateItems(): void {
    this.subscriptions.add(
      this.actions$.subscribe((action) => {
        if (action.type === LOAD_CONTACTSALES_PRODUCT_LIST_SUCCESS) {
          openCloseSpinner(this.document,false);
          const savedCart = (action as LoadContactSalesProductListSuccess);
          this.itemsUpdated.next(savedCart.payload.cart.entries);
        }
      })
    );
  }

  public dismiss(reason?: any): void {
    this.launchDialogService.clear(LAUNCH_CALLER.COLLECTED_ITEMS);
    this.launchDialogService.closeDialog('closed');
  }

  updateQuantityOrUnit(data: QuantityUnitData, item: SsabOrderEntry): void {
    if (!item.product.code || data.qty <= 0) {
      return;
    }
    this.contactSalesProductListService.updateEntry({
      product: {code: item.product.code},
      quantity: data.qty,
      unit: {code: data.unit.code} as SsabUnitOfMeasure,
      entryNumber: item.entryNumber,
    } as SsabOrderEntry);
  }

  remove(entry: SsabOrderEntry): void {
    this.items$ = this.itemsUpdated.asObservable();
    this.contactSalesProductListService.removeEntry(entry as SsabOrderEntry);

  }

  sendRequest(message?: string): void {
    openCloseSpinner(this.document,true);
    this.contactSalesProductListService.getContactSalesProductList().pipe(
      filter(Boolean),
      map((cart: SsabCart) => cart.code),
      withLatestFrom(this.user$, this.items$),
      take(1)
    ).subscribe(([cartCode, user, items]) => {
      if (user.uid) {
        this.gtmService.pushContactSales(cartCode);
        this.store.dispatch(new SendStockAvailabilityRequest({userId: user.uid, cartCode: cartCode, message: message}));
      }
      if (this.eloquaUrl) {
        items.forEach((item) => {
          const itemSub = this.productService.get(item.product.code)
            .subscribe((product) => {
            product.classifications.forEach((classification) => this.features.push(...classification.features));
            let params = new HttpParams();
            params = params.set('family', this.getFeatureValue('sub_grade_group_name'));
            params = params.set('type', this.getFeatureValue('family'));
            params = params.set('shape', this.getFeatureValue('geometrydetail'));
            params = params.set('grade', this.getFeatureValue('grade'));
            params = params.set('dimensions', this.getFeatureValue('thickness', '0', '*', true)
              + this.getFeatureValue('width', '0', '*', true)
              + this.getFeatureValue('length', '0', '', true)
            );
            params = params.set('location', product.stock?.name || '');
            params = params.set('quantity', item.quantity?.toString() || '');
            params = params.set('salesRepresentative', getEmails(user.salesContacts));
            params = params.set('message', message);
            const eloquaSub = this.userService.sendELOQUAData(this.eloquaUrl, params).subscribe(() => eloquaSub.unsubscribe());
            this.features = [];
            itemSub.unsubscribe();
          });
        });
      }
    });
    this.dismiss();
  }

  getFeatureValue(featureCode: string, defaultValue: string = '', suffixValue: string = '', removeDecimals?: boolean): string {
    let value = this.features.filter((feature) => feature.code.includes(featureCode))[0]?.featureValues[0]?.value;
    value = removeDecimals ? value?.split(',')[0] : value;
    return (value ? value : defaultValue) + suffixValue;
  }

  hideSelects(event): void {
    this.tableScroll.next(event);
  }
}
