import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CmsConfig, ConfigModule, I18nModule, UrlModule} from '@spartacus/core';
import {SsabProductFacetNavigationComponent} from './ssab-product-facet-navigation.component';
import {ActiveFacetsModule, IconModule} from '@spartacus/storefront';
import {SsabFacetListModule} from './facet-list/ssab-facet-list.module';
import {SsabActiveFacetsModule} from './active-facet/ssab-active-facets.module';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    SsabFacetListModule,
    ActiveFacetsModule,
    IconModule,
    I18nModule,
    ConfigModule.withConfig(<CmsConfig> {
      cmsComponents: {
        ProductRefinementComponent: {
          component: SsabProductFacetNavigationComponent,
        },
      },
    } as CmsConfig),
    SsabActiveFacetsModule,
    UrlModule,
    RouterModule,
  ],
  declarations: [SsabProductFacetNavigationComponent],
  exports: [SsabProductFacetNavigationComponent],
})
export class SsabProductFacetNavigationModule {
}
