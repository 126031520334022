<ng-container *ngIf="data$ | async as  linkColumn">
  <ng-container *ngIf="linkColumn.isDocumentColumn ==='true'; else normalLinkColumn">
    <div class="row d-flex align-items-center link-document no-gutters">
      <div class="col-9 col-md-10 col-lg-11">
        <h4 *ngIf="linkColumn.headline">{{linkColumn.headline}}</h4>
        <p class="pr-5 link-document-content mb-0">
          {{linkColumn.content }}
        </p>
      </div>
      <div class="col-3 col-md-2 col-lg-1 vertical-align-item-end">
        <i *ngIf="linkColumn.ctaIcon" (click)="downloadDocument(linkColumn.documentFile)"
           class="{{linkColumn.ctaIcon}} icon-inline icon-cursor"></i>
      </div>
    </div>
    <hr class="hr-gray">
  </ng-container>
  <ng-template #normalLinkColumn>
    <ng-container *ngIf="user$ |async as user">
      <h2 class="pb-2" *ngIf="linkColumn.headline">{{linkColumn.headline }}</h2>
      <p [ngClass]="{'pb-4': linkColumn.isUrlButton === 'false'}" *ngIf="linkColumn.content">{{linkColumn.content}}</p>
      <cx-generic-link
        class="link-underline text-default"
        *ngIf="linkColumn.urlLink && linkColumn.isUrlButton === 'false' && linkColumn.uid !== 'HomePageInfoLinkContact'"
        [url]="linkColumn.urlLink"
        [target]="linkColumn.urlLink.includes('http') ? '_blank': ''">
        <h5 class="text-uppercase">{{linkColumn.ctaText }}</h5>
      </cx-generic-link>
      <a *ngIf="linkColumn.uid === 'HomePageInfoLinkContact'"
         class="link-underline text-default text-underline-black"
         [href]="'mailto:' + emails(user.salesContacts)">
        <h5 class="text-uppercase">{{linkColumn.ctaText }}</h5>
      </a>
      <cx-generic-link
        class="text-primary"
        *ngIf="linkColumn.isUrlButton && linkColumn.isUrlButton === 'true'"
        [url]="linkColumn.urlLink">
        <button class="btn btn-download btn-default-height">
          {{linkColumn.ctaText}}
        </button>
      </cx-generic-link>
      <h5 class="text-uppercase text-primary"
          *ngIf="!linkColumn.urlLink && linkColumn.ctaText">{{linkColumn.ctaText }}</h5>
    </ng-container>
  </ng-template>

</ng-container>
