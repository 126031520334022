import {DIALOG_TYPE, LayoutConfig} from '@spartacus/storefront';
import {SsabCustomerSelectConfirmComponent} from './ssab-customer-select-confirm.component';

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    CUSTOMER_SELECT_CONFIRM = 'CUSTOMER_SELECT_CONFIRM',
  }
}

export const customerSelectConfirmLayout: LayoutConfig = {
  launch: {
    CUSTOMER_SELECT_CONFIRM: {
      inlineRoot: true,
      component: SsabCustomerSelectConfirmComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};

export interface SsabCustomerSelectConfirmData {
  afterDismiss: boolean;
  afterConfirm: boolean;
}
