import {AuthGuard, CmsConfig, ConfigModule, I18nModule, UrlModule} from '@spartacus/core';
import {SsabCertificatesComponent} from './certificates/ssab-certificates.component';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {SsabTableHeadModule} from '../../shared/table-head/ssab-table-head.module';
import {NgModule} from '@angular/core';
import {SsabFloatingScrollbarModule} from '../../shared/floating-scrollbar/ssab-floating-scrollbar.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {SsabInvoicesComponent} from './invoices/ssab-invoices.component';
import {SsabTransportDocumentsComponent} from './transport-documents/ssab-transport-documents.component';
import {SsabDocumentsResultComponent} from './ssab-documents-result.component';
import {SsabDocumentsSearchComponent} from './ssab-documents-search.component';
import {SsabOrdersConfirmationComponent} from './orders-confirmation/ssab-orders-confirmation.component';
import {SsabInputModule} from '../../../shared/input/ssab-input.module';
import {ReactiveFormsModule} from '@angular/forms';
import {SsabPaginationBottomModule} from '../../../shared/pagination-bottom/ssab-pagination-bottom.module';
import {SsabDatepickerModule} from '../../shared/datepicker/ssab-datepicker.module';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UrlModule,
        I18nModule,
        SsabDatepickerModule,
        SsabInputModule,
        NgSelectModule,
        ReactiveFormsModule,
        NgbPopoverModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                AccountDocumentsComponent: {
                    component: SsabDocumentsSearchComponent,
                    guards: [AuthGuard],
                },
            },
        }),
        SsabTableHeadModule,
        SsabFloatingScrollbarModule,
        SsabPaginationBottomModule,
    ],
    declarations: [
        SsabDocumentsSearchComponent,
        SsabCertificatesComponent,
        SsabInvoicesComponent,
        SsabOrdersConfirmationComponent,
        SsabTransportDocumentsComponent,
        SsabDocumentsResultComponent
    ],
    exports: [
        SsabDocumentsSearchComponent,
        SsabCertificatesComponent,
        SsabInvoicesComponent,
        SsabOrdersConfirmationComponent,
        SsabTransportDocumentsComponent,
        SsabDocumentsResultComponent,
    ]
})
export class SsabDocumentsSearchModule{
}
