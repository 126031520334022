import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CmsConfig, FeaturesConfig, FeaturesConfigModule, I18nModule, provideDefaultConfig, UrlModule,} from '@spartacus/core';
import {SsabOrderDetailHeaderComponent} from './header/ssab-order-detail-header.component';
import { CartSharedModule } from '@spartacus/cart/base/components';
import { OrderDetailsModule } from '@spartacus/order/components';
import {SsabOrderConfirmationModule} from '../../order-confirmation/ssab-order-confirmation.module';
import {SsabCartSharedModule} from '../../cart/cart-shared/ssab-cart-shared.module';
import {SsabOrderDetailItemsComponent} from './items/ssab-order-detail-items.component';


const moduleComponents = [
  SsabOrderDetailHeaderComponent,
  SsabOrderDetailItemsComponent

];

@NgModule({
    imports: [
        CartSharedModule,
        CommonModule,
        I18nModule,
        FeaturesConfigModule,
        UrlModule,
        SsabOrderConfirmationModule,
        SsabCartSharedModule
    ],
    providers: [
        provideDefaultConfig(<CmsConfig | FeaturesConfig>{
            cmsComponents: {
                AccountOrderDetailsItemsComponent: {
                    component: SsabOrderDetailItemsComponent,
                },
                AccountOrderDetailsShippingComponent: {
                    component: SsabOrderDetailHeaderComponent,
                },
            },
            features: {
                consignmentTracking: '1.2',
            },
        }),
    ],
    declarations: [...moduleComponents],
    exports: [...moduleComponents]
})
export class SsabOrderDetailsModule extends OrderDetailsModule {
}
