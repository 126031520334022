import {CurrenciesState, Occ, SiteContextActions} from "@spartacus/core";
import Currency = Occ.Currency;

export const initialState: CurrenciesState = {
  entities: null,
  activeCurrency: null,
};

export function currencyReducer(
  state = initialState,
  action: SiteContextActions.CurrenciesAction | SiteContextActions.SetActiveBaseSite
): CurrenciesState {
  switch (action.type) {
    case SiteContextActions.SET_ACTIVE_BASE_SITE: {
      return {...initialState};
    }
    case SiteContextActions.LOAD_CURRENCIES_SUCCESS: {
      const currencies: Currency[] = action.payload;
      const entities = currencies.reduce(
        (currEntities: { [isocode: string]: Currency }, currency: Currency) => {
          return {
            ...currEntities,
            [currency.isocode ?? '']: currency,
          };
        },
        {}
      );

      return {
        activeCurrency: state.activeCurrency,
        entities,
      };
    }

    case SiteContextActions.SET_ACTIVE_CURRENCY: {
      const isocode: string = action.payload;

      return {
        ...state,
        activeCurrency: isocode,
      };
    }
  }

  return state;
}
