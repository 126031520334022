import {CmsConfig, I18nModule, provideDefaultConfig} from '@spartacus/core';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SsabDelayedOrdersToggleComponent} from './ssab-delayed-orders-toggle.component';


@NgModule({
    imports: [
        CommonModule,
        I18nModule,
    ],
    providers: [
        provideDefaultConfig({
            cmsComponents: {
                DelayedOrdersToggle: {
                    component: SsabDelayedOrdersToggleComponent,
                },
            },
        } as CmsConfig),
    ],
    declarations: [SsabDelayedOrdersToggleComponent],
    exports: [SsabDelayedOrdersToggleComponent]
})
export class SsabDelayedOrdersToggleModule {}
