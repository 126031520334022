import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconModule} from '@spartacus/storefront';
import {I18nModule, UrlModule} from '@spartacus/core';
import {SsabItemStepperModule} from '../../../product/product-list/item-stepper/ssab-item-stepper.module';
import {RouterModule} from '@angular/router';
import {SsabCollectedItemComponent} from '../collected-item/ssab-collected-item.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {SsabAddToCartModule} from "../../add-to-cart/ssab-add-to-cart.module";

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        I18nModule,
        UrlModule,
        SsabItemStepperModule,
        RouterModule,
        NgSelectModule,
        FormsModule,
        SsabAddToCartModule,
    ],
  declarations: [SsabCollectedItemComponent],
  exports: [SsabCollectedItemComponent],
})
export class SsabCollectedItemModule {
}
