import {SsabOrderDataTablesComponent} from './order-data-tables/ssab-order-data-tables.component';
import {RouterModule} from '@angular/router';
import {AuthGuard, CmsConfig, ConfigModule, I18nModule, UrlModule} from '@spartacus/core';
import {CommonModule, DatePipe} from '@angular/common';
import {IconModule, ListNavigationModule} from '@spartacus/storefront';
import {OrderModule} from '@spartacus/order';
import {SsabTableHeadModule} from '../../../shared/table-head/ssab-table-head.module';
import {SsabOrderHistoryFormComponent} from './orders-form/ssab-order-history-form.component';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SsabDocumentsSearchModule} from '../../documents-search/ssab-documents-search.module';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {SsabInputRangeModule} from '../../../../shared/input-range/ssab-input-range.module';
import {SsabOrderHistoryTablesComponent} from './orders/ssab-order-history-tables.component';
import {SsabPipesModule} from '../../../shared/utils/safe-html/ssab-pipes.module';
import {NgPipesModule} from 'ngx-pipes';
import {SsabOrderHistoryComponent} from './ssab-order-history.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {SsabInputModule} from '../../../../shared/input/ssab-input.module';
import {SsabDatepickerModule} from '../../../shared/datepicker/ssab-datepicker.module';
import {SsabPaginationBottomModule} from '../../../../shared/pagination-bottom/ssab-pagination-bottom.module';
import {OrderOccModule} from '@spartacus/order/occ';
import {OrderComponentsModule} from '@spartacus/order/components';
import {OrderCoreModule} from '@spartacus/order/core';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        NgSelectModule,
        ListNavigationModule,
        UrlModule,
        I18nModule,
        SsabInputModule,
        NgSelectModule,
        FormsModule,
        SsabDatepickerModule,
        IconModule,
        NgbPopoverModule,
        SsabPipesModule,
        NgPipesModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                AccountOrderHistoryComponent: {
                    component: SsabOrderHistoryComponent,
                    guards: [AuthGuard],
                },
            },
        }),
        ReactiveFormsModule,
        SsabInputRangeModule,
        SsabTableHeadModule,
        SsabDocumentsSearchModule,
        SsabPaginationBottomModule,
        OrderCoreModule,
        OrderOccModule,
        OrderComponentsModule
    ],
    declarations: [
        SsabOrderHistoryComponent,
        SsabOrderHistoryFormComponent,
        SsabOrderHistoryTablesComponent,
        SsabOrderDataTablesComponent
    ],
    exports: [
        SsabOrderHistoryComponent,
        SsabOrderHistoryFormComponent,
        SsabOrderHistoryTablesComponent,
        SsabOrderDataTablesComponent
    ],
    providers: [DatePipe]
})
export class SsabOrderHistoryModule extends OrderModule {
}
