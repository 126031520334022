import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SsabInputComponent} from './ssab-input.component';
import {I18nModule} from '@spartacus/core';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        I18nModule,
    ],
    declarations: [SsabInputComponent],
    exports: [SsabInputComponent]
})
export class SsabInputModule {
}
