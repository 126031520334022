import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {OrderTrackingTables, OrderTrackingTypes, SsabOrder, SsabOrderHistoryEntry} from '../../../../../model/order.model';
import {ICON_TYPE} from '@spartacus/storefront';
import {Sort} from '../../../../../model/misc.model';

@Component({
  selector: 'ssab-cx-order-history-tables',
  templateUrl: './ssab-order-history-tables.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabOrderHistoryTablesComponent{

  constructor(protected cdr: ChangeDetectorRef) {
  }

  @Input() orders: SsabOrder[];
  @Input() selectedView: OrderTrackingTypes;
  @Input() activeSort: Sort;
  orderTypes = OrderTrackingTypes;
  @Output() sortChange = new EventEmitter<Sort>();
  @Output() downloadEvent = new EventEmitter<void>();
  iconTypes = ICON_TYPE;
  selectedTable: string = OrderTrackingTables.Order;


  toggleEntries(event: MouseEvent, orderIcon: HTMLElement, orderTable: HTMLTableRowElement, orderTr: HTMLTableRowElement): void {
    const element = (event.target as HTMLElement);
    if(element.classList.contains('ssab-icon') && element.classList.contains('icon-cursor')){
      orderTable.classList.toggle('d-none');
      orderIcon.classList.toggle('icon-rotate-180');
      orderTr.classList.toggle('active');
    }
    if(!element.classList.contains('icon-product-truck')){
      this.selectedTable = OrderTrackingTables.Order;
      this.cdr.detectChanges();
    }
  }

  orderItemDownload(){
    this.downloadEvent.emit();
  }

  sort(sort: Sort): void {
    this.sortChange.emit(sort);
  }

  hasDelayedEntries(entries: SsabOrderHistoryEntry[]): boolean{
    return entries.filter((entry) => entry.delayed).length > 0;
  }

  hasTrackAndTrace(order: SsabOrder): boolean {
    return order.entries.filter(e => e.trackAndTrace.length > 0).length > 0;
  }

  changeSelectedTableToTrackAndTrace(): void{
    this.selectedTable = OrderTrackingTables.TrackAndTrace;
    this.cdr.detectChanges();
  }
}

