import {ChangeDetectionStrategy, Component} from '@angular/core';
import {LAUNCH_CALLER, LaunchDialogService} from "@spartacus/storefront";
import {SsabCoilsComparisonService} from "../../service/product/ssab-coils-comparison.service";


@Component({
  selector: 'ssab-cx-coils-clear',
  templateUrl: './ssab-coils-clear.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabCoilsClearComponentComponent {

  constructor(
    protected launchDialogService: LaunchDialogService,
    protected coilsComparisonService: SsabCoilsComparisonService,
  ) {
  }

  clear(): void {
    this.coilsComparisonService.clear();
    this.close();
  }

  closeDialog(): void {
    this.launchDialogService.clear(LAUNCH_CALLER.COILS_CLEAR);
    this.launchDialogService.closeDialog('closed');
  }

  close(): void {
    this.launchDialogService.closeDialog('closed');
  }
}
