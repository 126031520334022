import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from "@spartacus/core";
import { BulkPricingRootModule, PRODUCT_BULK_PRICING_FEATURE } from "@spartacus/product/bulk-pricing/root";

@NgModule({
  declarations: [],
  imports: [
    BulkPricingRootModule
  ],
  providers: [provideConfig(<CmsConfig>{
    featureModules: {
      [PRODUCT_BULK_PRICING_FEATURE]: {
        module: () =>
          import('@spartacus/product/bulk-pricing').then((m) => m.BulkPricingModule),
      },
    }
  })]
})
export class ProductBulkPricingFeatureModule { }
