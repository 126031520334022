<ng-container *ngIf="selectedView == orderTypes.Orders; else OrderItemTracking">
  <div class="table-responsive">
    <table class="table cx-order-history-table">
      <thead table-head
             (sortChange)="sort($event)"
             [activeSort]="activeSort"
             [label]="'ssab.order.history.'"
             [tableHeadType]="orderTypes.Orders">
      </thead>
      <tbody>
      <ng-container *ngFor="let order of orders">
        <tr #orderTr (click)="toggleEntries($event,orderIcon,orderTable,orderTr)">
          <td class="col-md-2 col-auto" (click)="toggleEntries($event,orderIcon,orderTable,orderTr)">
            <i class="ssab-icon icon-chevron-down icon-inline icon-cursor"
               (click)="toggleEntries( $event, orderIcon, orderTable, orderTr)"
               #orderIcon></i>
            <span class="pl-4">{{ order?.code }}</span>
          </td>

          <td class="col-md-2 col-auto">
            {{ order?.purchaseOrderNumber  }}
          </td>

          <td class="col-md-2 col-auto">
            {{ order?.deliveryAddress?.formattedAddress  }}
          </td>

          <td class="text-nowrap col-md-2 col-auto">
            <span *ngIf="order.statusDisplay"
                  [ngClass]="order.statusDisplay ? 'status-'+order.statusDisplay.replace(' ', '') : ''">
              <cx-icon [type]="iconTypes.CIRCLE" class="pr-3"></cx-icon>
              {{("ssab.order.history.status." + order.statusDisplay.replace(' ', '')) | cxTranslate}}
            </span>
            <i *ngIf="hasDelayedEntries(order?.entries)"
               ngbPopover="{{ 'ssab.order.history.delayedInfo' | cxTranslate }}"
               triggers="mouseenter:mouseleave"
               container="body"
               placement="bottom"
               popoverClass="popover-sm popover-info"
               class="ssab-icon icon-system-clock icon-inline ml-2 icon-royal-blue"></i>
            <i *ngIf="hasTrackAndTrace(order)"
               (click)="changeSelectedTableToTrackAndTrace()"
               class="ssab-icon icon-product-truck icon-inline ml-2 icon-cursor"></i>
          </td>

          <td class="col-md-2 col-auto">
            {{ ('ssab.order.history.salesChannel_' + (order.salesChannel | lowercase))|cxTranslate  }}
          </td>

          <td class="col-md-2 col-auto">
            <span *ngIf="order?.created">{{ order?.created | cxDate: 'longDate' }}</span>
          </td>
        </tr>
        <tr class="d-none" #orderTable>
          <td class="order-data-table" colspan="6">
            <ssab-cx-order-data-tables [order]="order"
                                       [selectedTable]="selectedTable"
                                       [hasTrackAndTrace]="hasTrackAndTrace(order)">
            </ssab-cx-order-data-tables>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
</ng-container>


<ng-template #OrderItemTracking>
  <div class="table-responsive">
    <!--TABLE Order Item -->
    <table class="table cx-order-history-table cx-order-history-order-item mb-5"
           *ngIf="selectedView == orderTypes.OrderItem">
      <thead table-head
             (sortChange)="sort($event)"
             [activeSort]="activeSort"
             [label]="'ssab.order.history.'"
             [tableHeadType]="orderTypes.OrderItem">
      </thead>
      <tbody>
      <ng-container *ngFor="let order of orders">
        <tr *ngFor="let entry of order.entries"
            [ngClass]="{'text-delayed':entry.delayed}">
          <td>{{ order?.code}}</td>

          <td>{{order?.purchaseOrderNumber}}</td>

          <td>{{entry?.entryNumber}}</td>

          <td class="text-nowrap" [ngClass]="entry.status ? 'status-'+entry.status.replace(' ', '').toLowerCase() : ''">
            <ng-container *ngIf="entry.status">
              <cx-icon [type]="iconTypes.CIRCLE" class="pr-3"></cx-icon>
              {{( "ssab.order.history.status."+ entry.status) | cxTranslate }}
            </ng-container>
            <i *ngIf="entry.delayed"
               ngbPopover="{{ 'ssab.order.history.delayedInfo' | cxTranslate }}"
               triggers="mouseenter:mouseleave"
               container="body"
               placement="bottom"
               popoverClass="popover-sm popover-info"
               class="ssab-icon icon-system-clock icon-inline ml-2 icon-royal-blue"></i>
          </td>

          <td>{{entry?.product?.name}}</td>

          <td>{{entry?.dimensions}}</td>

          <td>
            {{entry?.quantity}}
            <span [innerHTML]="entry?.unit?.name"></span>
          </td>

          <td>
            {{entry.inProductionQuantity ? entry.inProductionQuantity : 0}}
            <span [innerHTML]="entry.unit?.name"></span>
          </td>

          <td>
            {{entry.readyToShipQuantity ? entry.readyToShipQuantity : 0}}
            <span [innerHTML]="entry.unit?.name"></span>
          </td>

          <td>
            {{entry.deliveredAmount}}
            <span [innerHTML]="entry.unit?.name"></span>
          </td>

          <td>{{ entry?.confirmedWeek }}</td>

          <td>
            {{ entry?.estimatedWeek }}
          </td>

          <td class="text-space-nowrap">{{entry?.warehouse.name}}</td>

          <td>{{entry?.infoLine}}</td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
</ng-template>
