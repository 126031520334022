<ng-container *ngIf="user$ | async as user">
  <ng-container *ngIf="user.externalUser">
    <h4 class="pt-2 pb-3">{{'ssab.user.myAccount.subscription'|cxTranslate}}</h4>
    <div class="row" >
      <span class="col-8 col-md-9">{{ 'ssab.order.history.delayed.orders.subscription' | cxTranslate }}</span>
      <div class="vertical-align-item col-4 col-md-3">
        <i class="ssab-icon icon-inline icon-cursor"
           [ngClass]="user.lateOrderSubscription ? 'icon-switch-blue': 'icon-switch'"
           (click)="activateDeactivateDelayedEmails(!user.lateOrderSubscription)"></i>
      </div>
    </div>
  </ng-container>
</ng-container>
