import {CarouselModule, MediaModule, OutletModule} from '@spartacus/storefront';
import {CmsConfig, provideDefaultConfig} from '@spartacus/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SsabProductImagesComponent} from './ssab-product-images.component';
import {SsabAddToWishListModule} from '../../cart/add-to-wishlist/ssab-add-to-wish-list.module';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MediaModule,
        OutletModule,
        CarouselModule,
        SsabAddToWishListModule,
    ],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                ProductImagesComponent: {
                    component: SsabProductImagesComponent,
                },
            },
        }),
    ],
    declarations: [SsabProductImagesComponent],
    exports: [SsabProductImagesComponent]
})
export class SsabProductImagesModule {}
