<ng-container *ngIf="data$| async as data">
  <article class="row no-gutters">
    <div class="col-12 col-md-4 col-xxl-6">
      <div class="banner-content">
        <section>
          <h2 class="pb-4">{{data.headline}}</h2>
          <p class="preamble">{{data.content}} </p>
          <a href="{{data.urlLink}}" target="_self" class="btn btn-primary btn-lg">{{data.ctaText}}</a>
        </section>
      </div>
    </div>
    <cx-media class="col-12 col-md-8 col-xxl-6" [container]="data.media"></cx-media>
  </article>
</ng-container>


