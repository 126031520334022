import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, NotAuthGuard, UrlModule } from '@spartacus/core';
import { FormErrorsModule } from '@spartacus/storefront';
import { ForgotPasswordModule } from "@spartacus/user/profile/components";
import { SsabForgotPasswordComponent } from './ssab-forgot-password.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        UrlModule,
        I18nModule,
        FormErrorsModule,
        ConfigModule.withConfig({
            cmsComponents: {
                ForgotPasswordComponent: {
                    component: SsabForgotPasswordComponent,
                    guards: [NotAuthGuard],
                },
            },
        } as CmsConfig)
    ],
    declarations: [SsabForgotPasswordComponent],
    exports: [SsabForgotPasswordComponent]
})
export class SsabForgotPasswordModule extends ForgotPasswordModule {
}
