import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {I18nModule} from '@spartacus/core';
import {RouterModule} from '@angular/router';
import {IconModule} from '@spartacus/storefront';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {SsabPageSizeComponent} from './ssab-page-size.component';
@NgModule({
  declarations: [SsabPageSizeComponent],
  imports: [
    CommonModule,
    I18nModule,
    RouterModule,
    IconModule,
    NgSelectModule,
    FormsModule,
  ],
  exports: [SsabPageSizeComponent]
})
export class SsabPageSizeModule {
}
