import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {SsabNgbDateParserFormatter} from '../../../shared/datepicker/ssab-datepicker-parser-formatter';
import {SsabUserService} from '../../../../service/user/ssab-user.service';
import {OrderTrackingTypes, SsabOrderHistory, SsabOrderHistoryRequest} from '../../../../model/order.model';
import {DateUtils} from '../../../../shared/util/date-utils';
import {LanguageService} from '@spartacus/core';
import {NgSelectComponent} from '@ng-select/ng-select';
import {B2BUnitOption, SsabUser} from '../../../../model/user.model';
import {DateRange, Sort} from '../../../../model/misc.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {SsabInputRangeComponent} from '../../../../shared/input-range/ssab-input-range.component';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {saveAs} from 'file-saver';
import {catchError, filter, tap} from 'rxjs/operators';
import {SsabOrderHistoryFormComponent} from './orders-form/ssab-order-history-form.component';
import {SsabActiveCartService} from '../../../../service/cart/ssab-active-cart.service';
import {openCloseSpinner} from '../../../shared/utils/functions/ssab-functions-utils';
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'ssab-cx-order-history',
  templateUrl: './ssab-order-history.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabOrderHistoryComponent implements OnInit, OnDestroy {

  @ViewChild('customers') customers: NgSelectComponent;
  @ViewChild('inputRangeComponent') inputRange: SsabInputRangeComponent;
  @ViewChild('inputRangeComponent') orderHistoryFormComponent: SsabOrderHistoryFormComponent;

  searchOrderHistoryForm: UntypedFormGroup;
  orders$: Observable<SsabOrderHistory>;
  activeSort$ = new BehaviorSubject<Sort>({field: 'createdAt', descending: true});
  selectedView: OrderTrackingTypes = OrderTrackingTypes.Orders;
  orderTypes = OrderTrackingTypes;
  defaultPageSize = 25;
  thicknessMin = 0;
  thicknessMax = 0;
  user$: Observable<SsabUser>;
  loadingComplete$ = new BehaviorSubject(false);
  subscriptions = new Subscription();
  halfYearBack = new Date(new Date().getFullYear(), new Date().getMonth() - 6, new Date().getDay());
  oneYearForward = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

  constructor(
    protected cdr: ChangeDetectorRef,
    protected fb: UntypedFormBuilder,
    protected userService: SsabUserService,
    protected ngbDateParserFormatter: SsabNgbDateParserFormatter,
    protected language: LanguageService,
    private activatedRoute: ActivatedRoute,
    protected router: Router,
    protected activeCartService: SsabActiveCartService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.user$ = this.userService.getUserDetails();
    this.initializeForm();
  }

  ngOnInit(): void {
    this.getParametersFromUrl();
    this.search();
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  checkOrderType(orderType: string): void {
    switch (orderType) {
      case OrderTrackingTypes.Orders:
        this.selectedView = OrderTrackingTypes.Orders;
        break;
      case OrderTrackingTypes.OrderItem:
        this.selectedView = OrderTrackingTypes.OrderItem;
        break;
    }
    this.cdr.detectChanges();
  }

  public changeOrderType(orderType: string): void {
    const queryParams: Params = {orderType};

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams,
        replaceUrl: true
      });

    if (orderType === this.selectedView) {
      this.resetForm();
      this.search();
    }
  }


  sort(sort: Sort): void {
    this.activeSort$.next(sort);
    this.search();
  }

  search(from = 0): void {
    openCloseSpinner(this.document, true);
    this.orders$ = this.userService.getOrderHistory(this.selectedView, this.getOrderHistoryRequest(from))
      .pipe(
        filter(Boolean),
        tap((orderHistory: SsabOrderHistory) => {
          this.thicknessMin = orderHistory.thicknessMin;
          this.thicknessMax = orderHistory.thicknessMax;
          this.loadingComplete$.next(true);
          openCloseSpinner(this.document, false);
        }),
        catchError(() => {
          openCloseSpinner(this.document, false);
          this.loadingComplete$.next(true);
          return of({});
        }), // empty on error
      );
    this.cdr.detectChanges();
  }

  protected getBuyerIds(): string[] {
    if (this.searchOrderHistoryForm.controls.customers.value) {
      return [this.searchOrderHistoryForm.controls.customers.value as string];
    }
    if (this.customers) {
      return this.customers.selectedValues.map((value: B2BUnitOption) => value.uid);
    }
    return [];
  }

  convertNgbDateToOccString(d: any): string {
    if (d) {
      if (d.year && d.month && d.day) {
        return DateUtils.convertDateToOccString(this.language, this.ngbDateParserFormatter.toDate(d));
      }
      return DateUtils.convertDateToOccString(this.language, d);
    }
    return null;
  }

  pageChange(from: number): void {
    this.search(from);
  }

  showFilterOptions($event: MouseEvent): void {
    const innerSearch = ($event.target as HTMLElement)
      .closest('.cx-order-history-search')
      .querySelector('.cx-order-history-form.d-none');
    innerSearch.classList.remove('d-none');
  }

  resetForm(): void {
    this.searchOrderHistoryForm.reset({
      sliderControl: [this.thicknessMin, this.thicknessMax],
      creationDateStart: [this.halfYearBack], // 6  month backwards
      creationDateEnd: [new Date()],
      confirmedWeekDateStart: [this.halfYearBack],
      confirmedWeekDateEnd: [this.oneYearForward],
    });
  }

  initializeForm(): void {
    this.searchOrderHistoryForm = this.fb.group(
      {
        orderReference: [null],
        customers: [null],
        company: [null],
        shipToLocation: [null],
        status: [null],
        grade: [null],
        sliderControl: [null, null],
        salesChannel: [null],
        creationDateStart: [this.halfYearBack], // 6  months backwards
        creationDateEnd: [new Date()],
        confirmedWeekDateStart: [this.halfYearBack],
        confirmedWeekDateEnd: [this.oneYearForward],
        estimatedWeekDateStart: [null],
        estimatedWeekDateEnd: [null],
        lateOrderNotification: [null],
        size: [25]
      }
    );
  }

  orderItemDownload(totalResults: number): void {
    const sub = this.userService.getOrderHistoryDownload(this.selectedView, this.getOrderHistoryRequest(0, totalResults))
      .subscribe(
        (response) => {
          this.downloadFile(response);
          sub.unsubscribe();
        }
      );
  }

  getOrderHistoryRequest(from = 0, totalResults = 0): SsabOrderHistoryRequest {
    const thickness = this.searchOrderHistoryForm.controls.sliderControl.value == null ? null :
      (this.searchOrderHistoryForm.controls.sliderControl.value[0] == null &&
      this.searchOrderHistoryForm.controls.sliderControl.value[1] == null ? null : {
        from: parseFloat(this.searchOrderHistoryForm.controls.sliderControl.value[0]),
        to: parseFloat(this.searchOrderHistoryForm.controls.sliderControl.value[1])
      } as DoubleRange);

    const estimatedWeekDate = this.searchOrderHistoryForm.controls.estimatedWeekDateStart.value == null && this.searchOrderHistoryForm.controls.estimatedWeekDateEnd.value == null ? null : {
      from: this.convertNgbDateToOccString(this.searchOrderHistoryForm.controls.estimatedWeekDateStart.value),
      to: this.convertNgbDateToOccString(this.searchOrderHistoryForm.controls.estimatedWeekDateEnd.value)
    } as DateRange;

    const confirmedWeekDate = this.searchOrderHistoryForm.controls.confirmedWeekDateStart.value == null && this.searchOrderHistoryForm.controls.confirmedWeekDateEnd.value == null ? null : {
      from: this.convertNgbDateToOccString(this.searchOrderHistoryForm.controls.confirmedWeekDateStart.value),
      to: this.convertNgbDateToOccString(this.searchOrderHistoryForm.controls.confirmedWeekDateEnd.value)
    } as DateRange;

    const creationDate = this.searchOrderHistoryForm.controls.creationDateStart.value == null && this.searchOrderHistoryForm.controls.creationDateEnd.value == null ? null : {
      from: this.convertNgbDateToOccString(this.searchOrderHistoryForm.controls.creationDateStart.value),
      to: this.convertNgbDateToOccString(this.searchOrderHistoryForm.controls.creationDateEnd.value)
    } as DateRange;

    const orderHistoryRequest = {
      lateOrderNotification: this.searchOrderHistoryForm.controls.lateOrderNotification.value,
      orderIdOrPoReference: this.searchOrderHistoryForm.controls.orderReference.value,
      sorts: [this.activeSort$.getValue()],
      status: this.searchOrderHistoryForm.controls.status.value,
      buyerIds: this.getBuyerIds(),
      salesChannels: this.createArray(this.searchOrderHistoryForm.controls.salesChannel.value)
        ?.filter(s => s?.startsWith('salesChannel'))
        .map(s => s?.replace('salesChannel_', '')),
      plants: this.createArray(this.searchOrderHistoryForm.controls.salesChannel.value)
        ?.filter(p => p.startsWith('plant'))
        .map(p => p.replace('plant_', '')),
      grades: this.searchOrderHistoryForm.controls.grade.value,
      size: this.defaultPageSize,
      thickness,
      from,
      creationDate: this.selectedView === this.orderTypes.Orders ? creationDate : null,
      estimatedWeekDate: this.selectedView === this.orderTypes.OrderItem ? estimatedWeekDate : null,
      confirmedWeekDate: this.selectedView === this.orderTypes.OrderItem ? confirmedWeekDate : null,
      companyId: this.searchOrderHistoryForm.controls.company?.value,
      consigneeId: this.searchOrderHistoryForm.controls.shipToLocation?.value,
    } as SsabOrderHistoryRequest;

    if (totalResults > 0) {
      return {...orderHistoryRequest, size: totalResults, from: 0};
    }
    return orderHistoryRequest;
  }

  createArray(val) {
    if (val == null) {
      return [];
    }
    return Array.isArray(val) ? val : [val];
  }

  downloadFile(data: any): void {
    const csv = new Blob([data.body], {
      type: 'text/plain;charset=utf-8' // must match the Accept type
    });
    saveAs(csv, data.headers.get('content-disposition').split('filename=')[1]);
  }

  getParametersFromUrl(): void {
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params) => {
        if (params.orderType) {
          this.checkOrderType(params.orderType);
        }
        if (params.pageSize) {
          this.defaultPageSize = +params.pageSize;
        }
        Object.keys(this.searchOrderHistoryForm.controls).forEach(key => {
          if (key.includes('Date') && params[key]) {
            this.searchOrderHistoryForm.controls[key].setValue(new Date(params[key]));
          } else {
            if ((key.includes('status') || key.includes('grade') || key.includes('sliderControl')) && params[key]) {
              let paramsArray = params[key].toString().split(',');
              if (this.searchOrderHistoryForm.controls[key].value) {
                paramsArray.push(...this.searchOrderHistoryForm.controls[key].value);
              }
              if (key.includes('sliderControl')) {
                paramsArray = paramsArray.map(p => parseFloat(p));
              }
              this.searchOrderHistoryForm.controls[key].setValue(paramsArray);
            } else {
              if (params[key]) {
                this.searchOrderHistoryForm.controls[key].setValue(params[key]);
              }
            }
          }
        });
      })
    );
  }

  activateDeactivateDelayedEmails(isLateOrderSubscription: boolean): void {
    this.userService.delayedOrderSubscription(isLateOrderSubscription);
  }
}

