import {Inject, Injectable} from '@angular/core';
import {
  I18nConfig,
  I18nextTranslationService,
  OccEndpointsService,
  TranslationChunkService,
  TranslationService,
  WindowRef
} from '@spartacus/core';
import {i18n} from 'i18next';
import {Observable, of} from 'rxjs';
import {I18NEXT_INSTANCE} from './i18next-instance';
import {HttpClient, HttpParams} from '@angular/common/http';


@Injectable({providedIn: 'root'})
export class SsabI18nextTranslationService implements TranslationService {

  constructor(
    private i18nextTranslationService: I18nextTranslationService,
    @Inject(I18NEXT_INSTANCE) protected i18next: i18n,
    protected occEndpointService: OccEndpointsService,
    private http: HttpClient,
    protected aWinRef: WindowRef
  ) {
  }

  translate(
    key: string,
    options: any = {},
    whitespaceUntilLoaded: boolean = false
  ): Observable<string> {
    if (this.getParamValueQueryString('showtranslationkeys') === 'true') {

      return of(key);
    }
    return this.i18nextTranslationService.translate(key, options, whitespaceUntilLoaded);
  }

  loadChunks(chunkNames: string | string[]): Promise<any> {
    return this.i18nextTranslationService.loadChunks(chunkNames);
  }

  getParamValueQueryString(paramName: string): string {
    const url = this.aWinRef.location.href;
    if (url?.includes('?')) {
      const httpParams = new HttpParams({fromString: url.split('?')[1]});
      return httpParams.get(paramName);
    }
    return null;
  }

  /*
   * if expectedLanguage is not specified,cached bundles in all languages will be verified
   */
  verifyCachedTranslationBundles(expectedLanguage?: string): void {
    const languages: string[] = expectedLanguage ? [expectedLanguage] : Object.keys(this.i18next?.store?.data);
    const reloadedLanguages: string[] = [];

    // first verify language in bundle and cached translations:
    // problem background: when the site is opened with non i18nConfig.i18n.fallbackLang language in url (e.g. /pl/EUR/search)
    // I18n (i18next) is not initialized properly (change language is called with a fallback language, not with languageService.getActive()).
    // so languageService.getActive != i18n.language
    // This can be tested by adding to the  SsabI18nInterceptor#intercept after this line: if (this.isI18nPlaceholderUrl(request)) {
    // console.log(this.i18nextTranslationService.getI18n().language + '/' + this.lang);
    // the output will be e.g. en/pl which means: interceptor is loading PL translations but i18n will cache loaded bundle as EN.
    // so just clear it up and let i18n reload it again when spartacus.activeLanguage is synchronized with i18n.language
    languages.forEach(lang => {
      const langBundle: any = this.i18next.store?.data[lang];
      if (langBundle && langBundle?.common?.bundle?.language != lang) {
        this.clearCachedBundle(langBundle, lang);
        // if bundle is cached and it's language does not match, mark language as reloaded to not check version because it's redundand
        reloadedLanguages.push(lang);
      }
    });

    // after languages are verified, check bundle version and if it does not match (is outdated) - clear cached bundles, so they are reloaded on the very next access to i18n translations
    this.http.get<string>(this.occEndpointService.buildUrl('translationBundleVersion')).toPromise().then((langVersionMap: any) => {
      // skip bundles already cleared by mismatched language - they will get anyway the latest server version/latest translations
      languages.filter(lang => !reloadedLanguages.includes(lang)).forEach(lang => {
        const langBundle: any = this.i18next.store?.data[lang];
        // language bundle is cached (not null) and version does not match
        if (langBundle && langVersionMap[lang] !== langBundle?.common?.bundle?.version) {
          this.clearCachedBundle(langBundle, lang);
        }
      });
    });
  }

  private clearCachedBundle(languageBundle: any, language: string): void {
    Object.keys(languageBundle).forEach(bundleName => this.i18next.removeResourceBundle(language, bundleName));
  }
}
