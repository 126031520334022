import {SsabCollectedItemsComponent} from './ssab-collected-items.component';
import {DIALOG_TYPE, LayoutConfig} from '@spartacus/storefront';
import {SsabOrderEntry} from '../../../../model/cart.model';
import {Observable} from 'rxjs';
import {SsabUser} from '../../../../model/user.model';

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    COLLECTED_ITEMS = 'COLLECTED_ITEMS',
  }
}

export const collectedItemsLayout: LayoutConfig = {
  launch: {
    COLLECTED_ITEMS: {
      inlineRoot: true,
      component: SsabCollectedItemsComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};

export interface SsabCollectedItemsDialogData {
  items: Observable<SsabOrderEntry[]>;
  user: Observable<SsabUser>;
}
