import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconModule} from '@spartacus/storefront';
import {I18nModule, provideDefaultConfig} from '@spartacus/core';
import {SsabSessionExpiredComponent} from './ssab-session-expired.component';
import {sessionExpiredLayout} from './ssab-session-expired-layout.config';

@NgModule({
  declarations: [SsabSessionExpiredComponent],
  imports: [
      CommonModule,
      IconModule,
      I18nModule,
  ],
  exports: [SsabSessionExpiredComponent],
  providers: [provideDefaultConfig(sessionExpiredLayout)]
})
export class SsabSessionExpiredModule {
}
