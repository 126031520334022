<div class="entries">
  <div class="pb-4 entries-tabs">
    <h4 class="entries-data-tab d-inline-block cursor-pointer"
        [ngClass]="{'active text-underline-black':selectedTable==orderTables.Order}"
        (click)="changeTab(orderTables.Order)">{{ 'ssab.order.history.orderID.title' | cxTranslate }}</h4>
    <h4 class="entries-data-tab d-inline-block cursor-pointer"
        [ngClass]="{'active text-underline-black':selectedTable==orderTables.Documents}"
        (click)="changeTab(orderTables.Documents)">{{ 'ssab.order.history.documents.title' | cxTranslate }}</h4>
    <h4 class="entries-data-tab d-inline-block cursor-pointer" *ngIf="order.salesChannel==orderTables.Mill"
        [ngClass]="{'active text-underline-black':selectedTable==orderTables.Mill}"
        (click)="changeTab(orderTables.Mill)">{{ 'ssab.order.history.mill.title' | cxTranslate }}</h4>
    <h4 class="entries-data-tab d-inline-block cursor-pointer"
        [ngClass]="{'active text-underline-black':selectedTable==orderTables.TrackAndTrace}"
        (click)="changeTab(orderTables.TrackAndTrace)">{{ 'ssab.order.history.trackAndTrace.title' | cxTranslate }}</h4>
  </div>

  <div class="wrapper-entries-table table-responsive">
    <table class="table entries-data table-lg" [ngClass]="{'d-none':selectedTable!=orderTables.Order}">
      <tr class=" text-uppercase">
        <th [innerHTML]="'ssab.order.history.item.label' | cxTranslate">
        </th>
        <th class="break-line-minw-180">
          {{ 'ssab.order.history.productId.label' | cxTranslate }}
        </th>
        <th [innerHTML]="'ssab.order.history.dimensions.label' | cxTranslate">
        </th>
        <ng-container *ngIf="order.salesChannel!=='MILL';else millHeaders">
          <th [innerHTML]=" 'ssab.order.history.location' | cxTranslate">
          </th>
          <th [innerHTML]="'ssab.order.history.ordered' | cxTranslate">
          </th>
          <th [innerHTML]="'ssab.order.history.delivery' | cxTranslate">
          </th>
          <th [innerHTML]="'ssab.order.history.confirmedDelivery' | cxTranslate ">
          </th>
          <th [innerHTML]="'ssab.order.history.estimatedDelivery' | cxTranslate">
          </th>
        </ng-container>

        <ng-template #millHeaders>
          <th [innerHTML]="'ssab.order.history.ordered' | cxTranslate ">
          </th>
          <th [innerHTML]="'ssab.order.history.inProduction' | cxTranslate">
          </th>
          <th [innerHTML]="'ssab.order.history.readyToBeShipped' | cxTranslate">
          </th>
          <th [innerHTML]="'ssab.order.history.delivery' | cxTranslate">
          </th>
          <th>
            <span [innerHTML]="'ssab.order.history.CdwConfirmedDispatchWeek.label' | cxTranslate "></span>
            <i ngbPopover="{{ 'ssab.order.history.confirmedWeek.info' | cxTranslate }}"
               triggers="mouseenter:mouseleave"
               container="body"
               placement="bottom"
               popoverClass="popover-sm popover-info"
               class="ssab-icon icon-system-info icon-inline">
            </i>
          </th>
          <th>
            <span [innerHTML]="'ssab.order.history.CdwEstimatedDispatchWeek.label' | cxTranslate"></span>
            <i ngbPopover="{{ 'ssab.order.history.estimatedWeek.info' | cxTranslate }}"
               triggers="mouseenter:mouseleave"
               container="body"
               placement="bottom"
               popoverClass="popover-sm popover-info"
               class="ssab-icon icon-system-info icon-inline">
            </i>
          </th>
          <th [innerHTML]="'ssab.order.history.site.label' | cxTranslate">
          </th>
          <th [innerHTML]="'ssab.order.history.infoLine.label' | cxTranslate ">
          </th>
        </ng-template>
      </tr>

      <tr *ngFor="let entry of (order.entries | orderBy: 'entryNumber')"
          [ngClass]="{'text-delayed':entry.delayed}">
        <td>
          {{ entry.entryNumber }}
        </td>
        <td class="break-line-minw-180">
          {{ entry.product.name }}
        </td>
        <td>
          {{ entry.dimensions }}
        </td>
        <ng-container *ngIf="order.salesChannel!=='MILL';else millContent">
          <td class="break-line-maxw-100">
            {{ entry.warehouse?.name }}
          </td>
          <td>
            {{ entry.quantity }}
            <span [innerHTML]="entry.unit.name"></span>
          </td>
          <td>
            {{ entry.deliveredAmount }}
            <span [innerHTML]="entry.unit.name"></span>
          </td>
          <td>
            <span *ngIf="entry.confirmedDate">
              {{ convertUIDate(entry.confirmedDate)|cxDate: (getDateFormat()|async) }}
            </span>
          </td>
          <td>
            <i *ngIf="entry.delayed"
               ngbPopover="{{ 'ssab.order.history.delayedInfo' | cxTranslate }}"
               triggers="mouseenter:mouseleave"
               container="body"
               placement="bottom"
               popoverClass="popover-sm popover-info"
               class="ssab-icon icon-system-clock icon-inline mr-2 icon-royal-blue"></i>
            <span *ngIf="entry.estimatedDate">
              {{ convertUIDate(entry.estimatedDate)|cxDate: (getDateFormat()|async) }}
            </span>
          </td>
        </ng-container>
        <ng-template #millContent>
          <td>
            {{ entry.quantity }}
            <span [innerHTML]="entry.unit.name"></span>
          </td>
          <td>
            {{ entry.inProductionQuantity ? entry.inProductionQuantity : 0 }}
            <span [innerHTML]="entry.unit.name"></span>
          </td>
          <td>
            {{ entry.readyToShipQuantity ? entry.readyToShipQuantity : 0 }}
            <span [innerHTML]="entry.unit.name"></span>
          </td>
          <td>
            {{ entry.deliveredAmount }}
            <span [innerHTML]="entry.unit.name"></span>
          </td>
          <td>
            {{ entry?.confirmedWeek }}
          </td>
          <td>
            {{ entry?.estimatedWeek }}
          </td>
          <td>
            {{ entry?.warehouse.name }}
          </td>
          <td>
            {{ entry?.infoLine }}
          </td>
        </ng-template>
      </tr>
    </table>
  </div>

  <ng-container *ngIf="selectedTable==orderTables.Mill">
    <div class="wrapper-entries-table" [ngClass]="hasDispatch()? 'table-responsive': ''">
      <table class="table entries-data"
             *ngIf="hasDispatch(); else emptyData"
             [ngClass]="{'d-none':selectedTable!=orderTables.Mill}">
        <tr class="text-uppercase">
          <th [innerHTML]="'ssab.order.history.item.label' | cxTranslate">
          </th>
          <th [innerHTML]=" 'ssab.order.history.productId.label' | cxTranslate">
          </th>
          <th [innerHTML]="'ssab.order.history.dispatched' | cxTranslate">
          </th>
          <th [innerHTML]="'ssab.order.history.dispatchedDate' | cxTranslate">
          </th>
          <th [innerHTML]="'ssab.order.history.infoLine.label' | cxTranslate">
          </th>
        </tr>

        <ng-container *ngFor="let entry of (order.entries | orderBy: ['entryNumber', 'date'] )">
          <tr *ngFor="let deli of entry.deliveries">
            <td>
              {{ entry.entryNumber }}
            </td>
            <td class="break-line-w-180">
              {{ entry.product.name }}
            </td>
            <td>
              {{ deli.amount }}
            </td>
            <td>
              <span *ngIf="deli.date">
                {{ convertUIDate(deli.date)|cxDate: (getDateFormat()|async) }}
              </span>
            </td>
            <td>
              <a class="text-primary text-underline-blue" href="{{deli.infoLine}}" *ngIf="deli.infoLine">
                {{ 'ssab.order.history.infoLine.label' | cxTranslate }}
              </a>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </ng-container>

  <div *ngIf="selectedTable==orderTables.Documents">
    <ssab-cx-documents-result
      *ngIf="$documentList|async as documentList"
      [certificates]="documentList.certificates"
      [invoices]="documentList.invoices"
      [ordersConfirmations]="documentList.orderConfirmations"
      [transports]="documentList.transports"
      [isSimpleView]="true"
    ></ssab-cx-documents-result>
  </div>

  <ng-container *ngIf="selectedTable==orderTables.TrackAndTrace">
    <div class="wrapper-entries-table" [ngClass]="hasTrackAndTrace ? 'table-responsive': ''">
      <!-- Track and Trace -->
      <table class="table entries-data"
             *ngIf="hasTrackAndTrace else emptyData">
        <tr class="text-uppercase">
          <th>
            {{ 'ssab.order.history.trackAndTrace.item' | cxTranslate }}
          </th>
          <th class="break-line-w-180">
            {{ 'ssab.order.history.trackAndTrace.product' | cxTranslate }}
          </th>
          <!-- Remove for the moment 12533
          <th>
              {{ 'ssab.order.history.trackAndTrace.deliveryNote' | cxTranslate }}
          </th>
           -->
          <th>
            {{ 'ssab.order.history.trackAndTrace.loadNr' | cxTranslate }}
          </th>
          <th>
            {{ 'ssab.order.history.trackAndTrace.origin' | cxTranslate }}
          </th>
          <th>
            {{ 'ssab.order.history.trackAndTrace.destination' | cxTranslate }}
          </th>
          <th>
            {{ 'ssab.order.history.trackAndTrace.quantity' | cxTranslate }}
          </th>
          <th>
            {{ 'ssab.order.history.trackAndTrace.eta' | cxTranslate }}
          </th>
          <th>
            {{ 'ssab.order.history.trackAndTrace.status' | cxTranslate }}
          </th>
          <th>
            <span class="text-initial">
              {{ 'ssab.order.history.trackAndTrace.eUrl' | cxTranslate }}
            </span>
          </th>
        </tr>
        <ng-container *ngFor="let entry of order.entries">
          <tr *ngFor="let track of entry.trackAndTrace">
            <td>
              {{ entry.entryNumber }}
            </td>
            <td class="break-line-w-180">
              {{ entry.product?.name }}
            </td>
            <!-- Remove for the moment task 12533
            <td class="">
                {{track.deliveryNote}}
            </td>-->
            <td>
              {{ track.loadNumber }}
            </td>
            <td>
              <ng-container *ngIf="track.originCity && track.originCountry">
                {{ track.originCity }}, {{ track.originCountry }}
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="track.destinationCity && track.destinationCountry">
                {{ track.destinationCity }}, {{ track.destinationCountry }}
              </ng-container>
            </td>
            <td>
              {{ track.quantity }} {{ track.unit }}
            </td>
            <td>
              <span *ngIf="track.eta">
                {{ track.eta|cxDate: 'mediumDate' }}
              </span>
            </td>
            <td>
              <span>
                {{ track.status }}
              </span>
            </td>
            <td>
              <span *ngIf="track.url">
                <a href="{{track.url}}" class="text-underline text-default" target="_blank" rel="noopener noreferrer">{{ 'ssab.order.history.trackAndTrace.eUrlTitle'|cxTranslate }}</a>
              </span>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </ng-container>
</div>

<ng-template #emptyData>
  <div class="entries-data empty-data-div">
    <h4
      class="text-center text-primary">
      {{ 'ssab.order.history.' + selectedTable + '.noFound' | cxTranslate }}
    </h4>
  </div>
</ng-template>
