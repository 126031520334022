import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CmsConfig, FeaturesConfigModule, I18nModule, provideDefaultConfig, UrlModule} from '@spartacus/core';
import {SsabCartDetailsComponent} from './ssab-cart-details.component';
import {PromotionsModule} from '@spartacus/storefront';
import {SsabCartSharedModule} from '../cart-shared/ssab-cart-shared.module';
import {CartSharedModule} from '@spartacus/cart/base/components';


@NgModule({
    imports: [
        CartSharedModule,
        CommonModule,
        RouterModule,
        UrlModule,
        FeaturesConfigModule,
        I18nModule,
        PromotionsModule,
        SsabCartSharedModule
    ],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                CartComponent: {
                    component: SsabCartDetailsComponent,
                },
            },
        }),
    ],
    declarations: [SsabCartDetailsComponent],
    exports: [SsabCartDetailsComponent]
})
export class SsabCartDetailsModule {
}
