import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NavigationEnd, Router} from '@angular/router';
import {Actions} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
  ActivatedRouterStateSnapshot,
  Address,
  BaseSiteService,
  EventService,
  GlobalMessageService,
  GlobalMessageType, HttpErrorModel,
  LanguageService,
  RoutingService
} from '@spartacus/core';
import {CustomFormValidators, LAUNCH_CALLER, LaunchDialogService} from '@spartacus/storefront';
import {OrderFacade} from "@spartacus/order/root";
import {Observable, Subscription} from 'rxjs';
import {pluck, take} from 'rxjs/operators';
import {PaymentMode, SsabCart, SsabDeliveryMode, UnloadingMethod} from '../../../model/cart.model';
import {SsabGtmService} from '../../../service/analytics/gtm/ssab-gtm.service';
import {SsabGlobalconfigurationService} from '../../../service/general/configuration/ssab-globalconfiguration.service';
import {CreditCheckPerformAction} from '../../../service/user/ssab-user.action';
import {SsabUserService} from '../../../service/user/ssab-user.service';
import {EmailType, SsabGlobalConfiguration} from '../../../model/misc.model';
import {SsabActiveCartService} from '../../../service/cart/ssab-active-cart.service';
import {SsabOrderEvent} from '../../../checkout/events/ssab-order-event';
import {SsabCartNewAddressDialogData} from './address/ssab-cart-new-address-layout.config';
import {getEmails, openCloseSpinner} from '../../shared/utils/functions/ssab-functions-utils';
import {SsabOrder} from "../../../model/order.model";
import {SsabUser} from "../../../model/user.model";
import {DOCUMENT} from "@angular/common";
import {SsabClearCartModalData} from "./ssab-clear-cart-modal-layout.config";

@Component({
  selector: 'ssab-cx-cart-header',
  templateUrl: './ssab-cart-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabCartHeaderComponent implements OnInit, OnDestroy {
  cart$: Observable<SsabCart>;
  showPricesOrConsignmentPricesStock$: Observable<boolean>;
  headerForm: UntypedFormGroup;
  submitted = false;
  termsAndConditionsAccepted = false;
  subscriptions: Subscription = new Subscription();
  configuration$: Observable<SsabGlobalConfiguration>;
  emailTypes = EmailType;
  // Change to true if you wanna see consignment cart
  isConsignmentCart$: Observable<boolean>;
  user$: Observable<SsabUser>;

  protected routeState$: Observable<ActivatedRouterStateSnapshot>;

  constructor(
    protected orderFacade: OrderFacade,
    protected fb: UntypedFormBuilder,
    protected activeCartService: SsabActiveCartService,
    private actions$: Actions,
    private routingService: RoutingService,
    protected globalMessageService: GlobalMessageService,
    protected store: Store,
    protected userService: SsabUserService,
    protected router: Router,
    protected gtmService: SsabGtmService,
    private globalConfigurationService: SsabGlobalconfigurationService,
    protected baseSiteService: BaseSiteService,
    protected languageService: LanguageService,
    protected cdr: ChangeDetectorRef,
    protected eventService: EventService,
    protected launchDialogService: LaunchDialogService,
    @Inject(DOCUMENT) private document: Document,) {
    this.routeState$ = this.routingService
      .getRouterState()
      .pipe(pluck('state'));
    this.isConsignmentCart$ = this.activeCartService.isConsignmentCart();
    this.user$ = userService.get();
  }

  ngOnInit() {
    this.prepareConfiguration();
    this.cart$ = this.activeCartService.getActive();
    this.subscriptions.add(
      this.cart$.subscribe(cart => {

        this.showPricesOrConsignmentPricesStock$ = this.userService.isShowPricesOrConsignmentStockPrices(!cart.consignment);

        this.submitted = false;

        this.headerForm = this.fb.group(
          {
            paymentMode: [cart.paymentMode?.code],
            freeTextForInvoice: [cart.freeTextForInvoice],
            orderConfirmationEmail: [cart.orderConfirmationEmail, cart.orderConfirmationReceivers?.length == 0 ? [Validators.required, CustomFormValidators.emailValidator] : []],
            deliveryNoteEmail: [cart.deliveryNoteEmail, cart.deliveryNoteReceivers?.length == 0 ? [Validators.required, CustomFormValidators.emailValidator] : []],
            materialCertificateEmail: [cart.materialCertificateEmail, cart.materialCertificateEmailEnteredManually && cart.materialCertificateReceivers?.length == 0 ? [Validators.required, CustomFormValidators.emailValidator] : []],
            billingAddress: [cart.billingAddress?.id, cart.consignment ? [] : [Validators.required]],
            purchaseOrderNumber: [cart.purchaseOrderNumber],
            deliveryMode: [cart.deliveryMode?.code, cart.consignment ? [] : [Validators.required]],
            warehousePhoneNumber: [cart.warehousePhoneNumber],
            unloadingMethod: [(cart.unloadingMethod?.code ? cart.unloadingMethod?.code : 'forkLift')],
            deliveryAddress: [(cart.deliveryAddress?.id ? cart.deliveryAddress?.id : 0), cart.consignment ? [] : [validateDeliveryModeField]],
            freeTextForTransportation: [cart.freeTextForTransportation],
            freeTextForWarehouse: [cart.freeTextForWarehouse],
            orderConfirmationEmailEnteredManually: [cart.orderConfirmationEmailEnteredManually],
            deliveryNoteEmailEnteredManually: [cart.deliveryNoteEmailEnteredManually],
            materialCertificateEmailEnteredManually: [cart.materialCertificateEmailEnteredManually]
          });

        for (const key in this.headerForm.controls) {
          this.headerForm.controls[key].updateValueAndValidity();
        }

        if (this.isCartPage() && (cart.creditBlocked || cart.salesBlocked)) {
          this.store.dispatch(new CreditCheckPerformAction({
            stickyNotification: false,
            clearOldNotifications: false,
            customMessageKey: 'ssab.cart.notification.' + (cart.creditBlocked ? 'creditBlocked' : 'salesBlocked') + '.content'
          }));

        }

        cart.errorMessages?.forEach(msg => {
            if (msg.severity) {
              this.globalMessageService.add(
                {
                  key: msg.key,
                },
                this.checkSeverity(msg.severity)
              )
            }
          }
        );
      })
    );

    // Terms and conditions should not block Consignment cart
    this.subscriptions.add(
      this.isConsignmentCart$.subscribe(isConsignment => {
        if (isConsignment) {
          this.termsAndConditionsAccepted = true;
          this.cdr.detectChanges();
        }
      })
    );

    // Check actions
    this.subscriptions.add(
      this.eventService.get(SsabOrderEvent).subscribe((e) => {
        this.routingService.go('/order-confirmation');
      })
    );

  }

  clear(code: string, uid: string): void {
    this.launchDialogService.closeDialog(null);
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.REMOVE_CART, undefined, {
      cartCode: code,
      userId: uid
    } as SsabClearCartModalData);
  }

  prepareConfiguration(): void {
    this.configuration$ = this.globalConfigurationService.getGlobalConfiguration();
  }

  checkSeverity(severity: string): GlobalMessageType {
    if (severity === 'INFO') {
      return GlobalMessageType.MSG_TYPE_INFO;
    } else if (severity === 'ERROR') {
      return GlobalMessageType.MSG_TYPE_ERROR;
    }
    return GlobalMessageType.MSG_TYPE_WARNING;
  }

  postBack(code: string, $event?: any, runErpSimulation?: boolean): void {
    this.submitted = true;
    this.activeCartService.updateCartHeader({
        code: code,
        paymentMode: {code: this.headerForm.controls.paymentMode.value} as PaymentMode,
        deliveryMode: {code: this.headerForm.controls.deliveryMode.value} as SsabDeliveryMode,
        unloadingMethod: {code: this.headerForm.controls.unloadingMethod.value} as UnloadingMethod,
        orderConfirmationEmail: this.headerForm.controls.orderConfirmationEmail.value,
        deliveryNoteEmail: this.headerForm.controls.deliveryNoteEmail.value,
        materialCertificateEmail: this.headerForm.controls.materialCertificateEmail.value,
        billingAddress: {id: this.headerForm.controls.billingAddress.value} as Address,
        warehousePhoneNumber: this.headerForm.controls.warehousePhoneNumber.value,
        deliveryAddress: {id: this.headerForm.controls.deliveryAddress.value} as Address,
        purchaseOrderNumber: this.headerForm.controls.purchaseOrderNumber.value,
        freeTextForWarehouse: this.headerForm.controls.freeTextForWarehouse.value,
        freeTextForTransportation: this.headerForm.controls.freeTextForTransportation.value,
        freeTextForInvoice: this.headerForm.controls.freeTextForInvoice.value,
        orderConfirmationEmailEnteredManually: this.headerForm.controls.orderConfirmationEmailEnteredManually.value,
        deliveryNoteEmailEnteredManually: this.headerForm.controls.deliveryNoteEmailEnteredManually.value,
        materialCertificateEmailEnteredManually: this.headerForm.controls.materialCertificateEmailEnteredManually.value
      } as SsabCart,
      runErpSimulation === undefined ? true : runErpSimulation);
  }

  isInvalid(fieldName: string): boolean {
    return this.headerForm.get(fieldName) && this.headerForm.get(fieldName).invalid;
  }

  placeOrder(): void {
    this.submitted = true;
    if (this.headerForm.valid) {
      openCloseSpinner(this.document, true);
      this.orderFacade.placeOrder(true)
        .pipe(take(1))
        .subscribe({
          next: () => this.onSuccess(),
          error: (errors) => {
            this.onError(errors)
          }
        });
    } else {
      this.headerForm.markAllAsTouched();
    }
  }

  onSuccess(): void {
    openCloseSpinner(this.document, false);
    this.activeCartService.reLoadCart(false);
    this.routingService.go({cxRoute: 'orderConfirmation'});
  }

  onError(errors: HttpErrorModel): void {
    openCloseSpinner(this.document, false);

    // @TODO check errors.status 502 & 504 errors - this happens when we triggered timeout on the "API" SAP portal endpoint

    // split the messages from the backend and pass them one by one to the GlobalMessageService
    // the backend will always return at least one message
    let isReloadCart = false;
    errors.details[0].message.split(",").forEach(error => {
      if(error !== "ssab.cart.notification.productOutOfStock"){
        this.addGlobalMessage(error);
      }
      isReloadCart = this.errorRequiresCartReload(error);

    });
    if (isReloadCart) {
      this.activeCartService.reLoadCart(false);
    }
  }

  addGlobalMessage(error: string) {
    this.globalMessageService.add(
      {
        key: error,
      },
      this.checkSeverity('ERROR')
    )
  }

  errorRequiresCartReload(error: string): boolean {
    if (error === "ssab.cart.notification.orderFailed.timeout" || error === "ssab.cart.notification.productOutOfStock") {
      return true;
    }
    return false;
  }

  addNewAddress(code: string): void {
    this.launchDialogService.closeDialog(null);
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CART_NEW_ADDRESS, undefined, undefined);
    this.subscriptions.add(
      this.launchDialogService.data$.subscribe((dataDialog: SsabCartNewAddressDialogData) => {
        if (dataDialog) {
          if (dataDialog.newAddress) {
            this.activeCartService.updateCartHeader({
                code: code,
                deliveryAddress: dataDialog.newAddress
              } as SsabCart, true
            );
          }
        }
      })
    );
  }

  resetAdditionalEmail(code: string, emailType: any): void {
    switch (emailType) {
      case EmailType.orderConfirmation:
        this.headerForm.controls.orderConfirmationEmail.reset();
        this.headerForm.controls.orderConfirmationEmailEnteredManually.setValue(false);
        break;
      case EmailType.deliveryNote:
        this.headerForm.controls.deliveryNoteEmail.reset();
        this.headerForm.controls.deliveryNoteEmailEnteredManually.setValue(false);
        break;
      case EmailType.materialCertificate:
        this.headerForm.controls.materialCertificateEmail.reset();
        this.headerForm.controls.materialCertificateEmailEnteredManually.setValue(false);
        break;
    }
    this.postBack(code, undefined, false);
  }

  postBackEmail(code: string, emailEnteredManually: boolean, emailType: string): void {
    switch (emailType) {
      case EmailType.orderConfirmation:
        this.headerForm.controls.orderConfirmationEmailEnteredManually.setValue(emailEnteredManually);
        break;
      case EmailType.deliveryNote:
        this.headerForm.controls.deliveryNoteEmailEnteredManually.setValue(emailEnteredManually);
        break;
      case EmailType.materialCertificate:
        this.headerForm.controls.materialCertificateEmailEnteredManually.setValue(emailEnteredManually);
        break;
    }
    this.postBack(code, undefined, false);
  }

  ngOnDestroy(): void {
    this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
    this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_INFO);
    this.subscriptions?.unsubscribe();
  }

  isCartPage(): boolean {
    let isCartPage = false;
    this.subscriptions.add(
      this.routeState$.subscribe(state => {
        if (state.context.id.includes('cart')) {
          isCartPage = true;
        }
      })
    );
    return isCartPage;
  }

  @HostListener('document:click', ['$event'])
  clickout(event): void {
    const elementClassName = event.target.className;
    if (elementClassName.includes('terms-pdf-checkout')) {
      this.document.getElementById('ecom_conditions').click();
    }
  }

  termsAndConditionsChange(isChecked: boolean): void {
    this.termsAndConditionsAccepted = isChecked;
    this.cdr.detectChanges();
  }

  emails(user: SsabUser): string {
    return getEmails(user.salesContacts);
  }
}

function validateDeliveryModeField(control: AbstractControl): { [key: string]: any } | null {
  if ((!control.value || control.value?.length === 0) &&
    (control.root as UntypedFormGroup)?.controls &&
    (control.root as UntypedFormGroup).controls.deliveryMode?.value === 'delivery') {
    return {required: true};
  }
  return null;
}
