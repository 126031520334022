import { Injectable } from '@angular/core';
import { GlobalMessageType } from '@spartacus/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import {Alert} from '../../model/misc.model';

@Injectable({ providedIn: 'root' })
export class SsabAlertService{
  private subject = new Subject<Alert>();
  private defaultId = 'default-alert';

  // enable subscribing to alerts observable
  onAlert(): Observable<Alert> {
    return this.subject.asObservable();
  }

  // convenience methods
  info(message: string, options?: any): void {
    this.alert(new Alert({ ...options, type: GlobalMessageType.MSG_TYPE_INFO, message }));
  }

  error(message: string, options?: any): void{
    this.alert(new Alert({ ...options, type: GlobalMessageType.MSG_TYPE_ERROR, message }));
  }

  confirmation(message: string, options?: any): void {
    this.alert(new Alert({ ...options, type: GlobalMessageType.MSG_TYPE_CONFIRMATION, message }));
  }

  warning(message: string, options?: any): void {
    this.alert(new Alert({ ...options, type: GlobalMessageType.MSG_TYPE_WARNING, message }));
  }

  // main alert method
  alert(alert: Alert): void {
    alert.id = alert.id || this.defaultId;
    this.subject.next(alert);
  }

  // clear alerts
  clear(id = this.defaultId): void {
    this.subject.next(new Alert({ id }));
  }
}
