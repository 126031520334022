import {SsabNavigationComponent} from './ssab-navigation.component';
import {CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';
import {GenericLinkModule, IconModule, NavigationModule} from '@spartacus/storefront';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SsabNavigationUiModule} from '../navigation-ui/ssab-navigation-ui.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        IconModule,
        GenericLinkModule,
        I18nModule,
        SsabNavigationUiModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                NavigationComponent: {
                    component: SsabNavigationComponent,
                },
            },
        }),
    ],
    declarations: [SsabNavigationComponent],
    exports: [SsabNavigationComponent]
})
export class SsabNavigationModule extends NavigationModule {
}
