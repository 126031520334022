import {Injectable} from '@angular/core';
import {
  Converter,
  ConverterService,
  Occ,
  PRODUCT_NORMALIZER,
} from '@spartacus/core';
import {OrderEntry} from '@spartacus/cart/base/root';
import {Order} from '@spartacus/order/root';
import {SsabCart} from "../../model/cart.model";


@Injectable({providedIn: 'root'})
export class SsabOccOrderNormalizer implements Converter<Occ.Order, Order> {
  constructor(private converter: ConverterService) {
  }

  convert(source: Occ.Order, target?: Order): Order {
    if (target === undefined) {
      target = {...(source as any)};
    }
    target.entries?.forEach(e => e.quantity = (e as any).doubleQuantity);

    if (source?.entries) {
      target.entries = source.entries.map((entry) =>
        this.convertOrderEntry(
          entry,
          source.code
        )
      );
    }
    (target as SsabCart).consignmentEntries?.flatMap(e => e.batches)
      .forEach(e => {
        e.quantity = (e as any).doubleQuantity;
        e.product = this.converter.convert(e.product, PRODUCT_NORMALIZER);
      });

    return target;
  }

  convertOrderEntry(
    source?: Occ.OrderEntry,
    code?: string
  ): OrderEntry {
    return {
      ...source,
      product: this.converter.convert(source?.product, PRODUCT_NORMALIZER),
      orderCode: code,
    };
  }
}
