import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideDefaultConfig } from "@spartacus/core";
import { ssabOccContactFormConfig } from "./ssab-occ-contactform-config";
import { EffectsModule } from "@ngrx/effects";
import { ContactFormEffects } from './ssab-contactform.effect';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([ContactFormEffects])
  ],
  providers: [
    provideDefaultConfig(ssabOccContactFormConfig)
  ]
})
export class SsabContactFormModule {}
