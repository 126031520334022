import {SpartacusFeaturesModule} from './spartacus-features.module';
import {NgModule} from '@angular/core';
import {SpartacusConfigurationModule} from './spartacus-configuration.module';
import {BaseStorefrontModule} from '@spartacus/storefront';


@NgModule({
    declarations: [],
    imports: [BaseStorefrontModule,
        SpartacusFeaturesModule,
        SpartacusConfigurationModule],
    exports: [BaseStorefrontModule],
    bootstrap: []
})
export class SpartacusModule {
}
