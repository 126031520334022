import {
  Component,
  EventEmitter,
  Input,
  OnDestroy, OnInit,
  Output,
} from '@angular/core';
import {Subscription} from 'rxjs';
import {SsabCertificateDocument} from "../../model/document.model";
import {SsabScannerService} from "../../service/scanner/ssab-scanner.service";


@Component({
  selector: 'app-cart-table',
  templateUrl: './cart-table.component.html',
})
export class ScannerCartTableComponent implements OnInit {
  @Input() document: boolean;
  @Output() valueChange = new EventEmitter<SsabCertificateDocument[]>();
  @Output() download = new EventEmitter<SsabCertificateDocument[]>();
  @Input() cartItems: SsabCertificateDocument[] = [];
  selectedObjects: SsabCertificateDocument[] = [];

  constructor(private scannerService: SsabScannerService) {
  }

  ngOnInit(): void {
    this.onCheckAll();
  }

  isChecked(product: SsabCertificateDocument): boolean {
    return this.selectedObjects.filter(c => c.materialId === product.materialId).length > 0;
  }

  onCheckAll() {
    if (this.cartItems) {
      if (this.selectedObjects.length === this.cartItems.length) {
        this.selectedObjects = [];
      } else {
        this.selectedObjects = [...this.cartItems];
      }
      this.valueChange.emit(this.selectedObjects);
    }
  }

  onCheckboxChange(product: SsabCertificateDocument) {
    const index = this.selectedObjects.findIndex(
      (item) => item.materialId === product.materialId
    );
    if (index !== -1) {
      this.selectedObjects.splice(index, 1);
    } else {
      this.selectedObjects.push(product);
    }
    this.valueChange.emit(this.selectedObjects);
  }

  onDeleteFromTable(item: SsabCertificateDocument) {
    this.scannerService.removeFromCart(item);
  }

  downloadSingle(item: SsabCertificateDocument): void {
    this.download.emit([item]);
  }
}
