<ul class="email-list">
  <li *ngFor="let receiver of receivers">
    {{receiver.email}}
  </li>
</ul>
<a
  class="btn-link"
  (click)="toggleAddEmail()"
  *ngIf="!isAdditionalEmailVisible && !showAdditionalEmail">
  {{emailAddressLabel|cxTranslate}}
</a>
<ssab-cx-input
  idName="{{emailManuallyName}}"
  [nameForm]="emailManuallyName"
  typeInput="hidden"
  [parentForm]="parentForm">
</ssab-cx-input>

<div class="field-value form-inline" *ngIf="isAdditionalEmailVisible || showAdditionalEmail">
  <ssab-cx-input
    [ngClass]="showAdditionalEmail ? 'w-100': ''"
    idName="{{emailName}}"
    [isNotValidFunction]="isInvalid()"
    [errorMessage]="'common.invalid.email'"
    [nameForm]="emailName"
    (afterChange)="postBackOutput()"
    [parentForm]="parentForm"
    [labelPlaceholder]="emailAddressPlaceholder">
  </ssab-cx-input>
  <span
    *ngIf="!showAdditionalEmail"
    class="ssab-icon icon-system-trash ml-2 icon-inline icon-cursor"
    role="button"
    (click)="resetAdditionalEmail()">
  </span>
</div>
