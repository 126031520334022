import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BannerCarouselComponent, CmsComponentData} from '@spartacus/storefront';
import {SsabCMSBannerCarouselComponent, SsabCMSCarouselBannerComponent} from '../../../model/cms.model';
import {CmsBannerCarouselComponent, CmsService, ContentSlotComponentData, ProductSearchConnector, WindowRef} from '@spartacus/core';
import {merge, Observable, of} from 'rxjs';
import {filter, map, switchMap, take, tap} from 'rxjs/operators';
import {SsabFacet, SsabFacetValue} from "../../../model/product.model";
import {SsabSearchConfig} from "../../../model/misc.model";


@Component({
  selector: 'ssab-cx-carousel-container',
  templateUrl: './ssab-carousel-container.component.html'
})
export class SsabCarouselContainerComponent extends BannerCarouselComponent implements OnInit, OnChanges {

  public aComponentData$: Observable<SsabCMSBannerCarouselComponent>;
  isTablet: boolean;
  isMobile: boolean;
  filteredItems$: Observable<Observable<SsabContentSlotComponentData>[]>;


  constructor(data: CmsComponentData<SsabCMSBannerCarouselComponent>,
              protected productSearchConnector: ProductSearchConnector,
              cmsService: CmsService,
              protected winRef: WindowRef) {
    super(data, cmsService);

    this.aComponentData$ = data.data$.pipe(
      filter(Boolean),
      tap((d: CmsBannerCarouselComponent) => (this.theme = `${d.effect}-theme`))
    );

  }

  ngOnInit(): void {
    this.isTablet = this.winRef.nativeWindow.matchMedia('only screen and (max-width: 1020px)').matches;
    this.isMobile = this.winRef.nativeWindow.matchMedia('only screen and (max-width: 760px)').matches;
    this.setCarouselItemsByAllowedProductForms();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setCarouselItemsByAllowedProductForms();
  }

  private setCarouselItemsByAllowedProductForms(): void {
    const productForms$ = this.productSearchConnector.search('*', {pageSize: 1, disableSpinner: true, suggestiveMode: true} as SsabSearchConfig)
      .pipe(
        take(1),
        map(result => result.facets
          ?.filter((f: SsabFacet) => f.code === 'productForm')
          .flatMap(f => f.values)
          .filter(fv => fv.count > 0)
          .map((fv: SsabFacetValue) => fv.code.toLowerCase())
        )
      );

    this.filteredItems$ = productForms$.pipe(
      switchMap(productForms =>
        this.getItems().pipe(
          map(items =>
            items.map(item =>
              item.pipe(
                map((data: SsabCMSCarouselBannerComponent) =>
                  ({
                    ...data,
                    hide: data?.productForm !== undefined &&
                      productForms?.find(form => form === data?.productForm.toLowerCase()) === undefined
                  })
                ),
              )
            )
          )
        )
      )
    );
  }

  sizeOfPicture(): string {
    let size = '188px';
    if (this.isTablet) {
      size = '160px';
    }
    if (this.isMobile) {
      size = '140px';
    }
    return size;
  }
}

export interface SsabContentSlotComponentData extends ContentSlotComponentData {
  hide: boolean;
}
