import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router";
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from "@spartacus/core";
import { PageSlotModule } from "@spartacus/storefront";
import { LoginModule } from "@spartacus/user/account/components";
import { SsabLoginComponent } from "./ssab-login.component";

@NgModule({
    declarations: [SsabLoginComponent],
    imports: [
        CommonModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                LoginComponent: {
                    component: SsabLoginComponent,
                },
            },
        }),
        RouterModule,
        PageSlotModule,
        I18nModule,
        UrlModule,
    ],
    exports: [SsabLoginComponent]
})
export class SsabLoginModule extends LoginModule {
}
