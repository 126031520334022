import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ChangeContext, Options} from '@angular-slider/ngx-slider';
import {WindowRef} from '@spartacus/core';

@Component({
  selector: 'ssab-cx-input-range',
  templateUrl: './ssab-input-range.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabInputRangeComponent implements OnInit {
  @Input() inputValueFrom: number;
  @Input() inputValueTo: number;
  @Input() inputValueStep: number;
  @Input() idName: string;
  @Input() sliderForm: UntypedFormGroup;
  @Input() controlName = 'sliderControl';
  @Input() showUnique = false;
  @Output() inputRangeOpen = new EventEmitter<boolean>();
  @Output() inputRangeClosed = new EventEmitter<boolean>();

  options: Options;

  isTablet: boolean;
  uniqueSelected = false;
  previousFrom: number;
  previousTo: number;

  constructor(protected winRef: WindowRef) {
  }

  ngOnInit(): void {
    this.options = {
      floor: this.inputValueFrom ?? 0,
      ceil: this.inputValueTo ?? 0,
      step: this.inputValueStep ?? 0
    };
    this.setForm();
    this.isTablet = this.winRef.nativeWindow.matchMedia('only screen and (max-width: 1020px)').matches;
    this.previousFrom = this.inputValueFrom;
    this.previousTo = this.inputValueTo;
  }

  setForm(): void {
    if (!this.sliderForm.controls[this.controlName].value) {
      this.resetForm();
    }
  }

  resetForm(element?: Element): void {
    this.sliderForm.controls[this.controlName].setValue([this.inputValueFrom, this.inputValueTo]);
    if (element != null) {
      this.closeRange();
      this.toggleRangeContent(null, element, true);
    }
  }

  setValueFrom($event: KeyboardEvent): void {
    const value = ($event.target as HTMLInputElement).value;
    if (value === '0') {
      return;
    }
    const valueFrom = parseFloat(value);
    let valueTo = this.sliderForm.controls[this.controlName].value[1];
    if (this.uniqueSelected) {
      valueTo = valueFrom;
    }
    this.setSliderValues(valueFrom, valueTo);
  }

  setValueTo($event: KeyboardEvent): void {
    const value = ($event.target as HTMLInputElement).value;
    if (value === '0') {
      return;
    }
    const valueTo = parseFloat(value);
    let valueFrom = this.sliderForm.controls[this.controlName].value[0];
    if (this.uniqueSelected) {
      valueFrom = valueTo;
    }
    this.setSliderValues(valueFrom, valueTo);
  }

  setSliderValuesManually($event: ChangeContext): void {
    if (this.uniqueSelected) {
      this.setSliderValues(this.previousFrom === $event.value ? $event.highValue : $event.value, this.previousTo === $event.highValue ? $event.value : $event.highValue);
    }
  }

  setSliderValues(valueFrom: number, valueTo: number): void {
    this.previousFrom = valueFrom;
    this.previousTo = valueTo;
    this.sliderForm.controls[this.controlName].setValue([valueFrom, valueTo]);
  }

  toggleRangeContent($event: MouseEvent, element?: Element, forceClose?: boolean): void {
    const rangeLabel = element
      ? element.querySelector('.range-label')
      : ($event.target as HTMLElement).closest('.range-div').querySelector('.range-label');
    const rangeContent = element
      ? element.querySelector('.range-content')
      : ($event.target as HTMLElement).closest('.range-div').querySelector('.range-content');
    rangeLabel.classList.toggle('d-none');
    rangeContent.classList.toggle('d-none');
    if (forceClose) {
      this.closeRange();
      rangeLabel.classList.remove('d-none');
      rangeContent.classList.add('d-none');
    }
  }

  clickout(element?: Element): void {
    if (!this.isTablet) {
      this.toggleRangeContent(null, element, true);
    }
  }

  openRange(): void {
    this.inputRangeOpen.emit(true);
  }

  closeRange(): void {
    this.inputRangeClosed.emit(true);
  }

  toggleUnique(): void {
    this.uniqueSelected = !this.uniqueSelected;
    if (this.uniqueSelected) {
      this.setSliderValues(this.sliderForm.controls[this.controlName].value[0], this.sliderForm.controls[this.controlName].value[0]);
    }
  }
}
