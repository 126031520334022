import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DocumentTypes, SsabDocumentIdentifier} from '../../../model/document.model';
import {HeadTable, Sort, TablesTypes} from '../../../model/misc.model';
import {OrderTrackingTypes} from '../../../model/order.model';
import {TranslationService} from '@spartacus/core';
import {Observable, of} from 'rxjs';
import {concatMap} from 'rxjs/operators';

@Component({
  selector: '[table-head]',
  templateUrl: './ssab-table-head.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabTableHeadComponent implements OnInit {

  @Input() activeSort: Sort;
  @Input() label: string;
  @Input() backuplabel: string;
  @Output() sortChange = new EventEmitter<Sort>();
  @Input() tableHeadType: string;
  @Input() tableClass: string;
  @Output() selectAllDocuments = new EventEmitter<boolean>();
  documentTypes = DocumentTypes;
  orderTypes = OrderTrackingTypes;
  tableHeads: HeadTable[];
  isDocumentType: boolean;

  constructor(protected translationService: TranslationService) {
  }

  isSortDescending(column: string): boolean {
    return this.activeSort?.field === column ? this.activeSort?.descending : null;
  }

  sort(column: string): void {
    let desc = true;
    if (this.activeSort?.field === column) {
      desc = !this.activeSort.descending;
    } else {
      desc = !desc;
    }
    this.activeSort = {field: column, descending: desc} as Sort;
    this.sortChange.emit(this.activeSort);
  }

  ngOnInit(): void {
    this.setTableHeads();
  }

  getText(head: HeadTable, suffix: string = ''): Observable<string> {
    const key = this.label + (head.label != null ? head.label : head.field) + suffix;
    return this.translationService.translate(key)
      .pipe(
        concatMap(t => {
            if (t == null || t === '' || t.indexOf(key) > 0) {
              return this.translationService.translate(this.backuplabel + (head.label != null ? head.label : head.field));
            }
            return of(t);
          }
        ));
  }

  onChange(isChecked: boolean): void {
    this.selectAllDocuments.emit(isChecked);
  }

  setTableHeads(): void {
    switch (this.tableHeadType) {
      case this.documentTypes.Certificate:
        this.tableHeads = [
          {field: 'deliveryNumber', className: 'th-word-break'},
          {field: 'orderNumber', className: 'th-word-break'},
          {field: 'customerRef', className: 'th-word-break'},
          {field: 'invoiceNumber', className: 'th-word-break'},
          {field: 'materialId'},
          {field: 'grade'},
          {field: 'dimension'},
          {field: 'documentDate', className: 'th-word-break'},
          {field: 'sourcePlant', className: 'th-word-break'},
          {field: 'deliveryDate', className: 'th-word-break'},
          {field: 'certificateId', className: 'th-word-break'}
        ];
        this.isDocumentType = true;
        break;
      case this.documentTypes.Invoice:
        this.tableHeads = [
          {field: 'orderNumber', className: 'th-word-break'},
          {field: 'customerRef', className: 'th-word-break'},
          {field: 'invoiceNumber', className: 'th-word-break'},
          {field: 'invoiceDate', className: 'th-word-break', label: 'documentDate'},
        ];
        this.isDocumentType = true;
        break;
      case this.documentTypes.Confirmation:
        this.tableHeads = [
          {field: 'orderNumber', className: 'th-word-break'},
          {field: 'customerRef', className: 'th-word-break'},
          {field: 'documentVersion'},
          {field: 'documentDate', className: 'th-word-break'},
          {field: 'orderDate', className: 'th-word-break'},
        ];
        this.isDocumentType = true;
        break;
      case this.documentTypes.Transport:
        this.tableHeads = [
          {field: 'orderNumber', className: 'th-word-break'},
          {field: 'customerRef', className: 'th-word-break'},
          {field: 'documentNumber', className: 'th-word-break'},
          {field: 'sendingUnit', className: 'th-word-break'},
          {field: 'documentDate', className: 'th-word-break'}
        ];
        this.isDocumentType = true;
        break;

      case this.orderTypes.Orders:
        this.tableHeads = [
          {field: 'orderId'},
          {field: 'purchaseOrderNumber', className: 'text-space-normal'},
          {field: 'shippingAddress.streetname', isNotSortable: true},
          {field: 'status'},
          {field: 'salesChannel', className: 'break-words-md'},
          {field: 'createdAt', className: 'break-words-md'}
        ];
        break;
      case this.orderTypes.OrderItem:
        this.tableHeads = [
          {field: 'orderId', label: 'orderItemId', labelClass: 'pr-4'},
          {field: 'purchaseOrderNumber', className: 'text-space-normal', label: 'customerReference', labelClass: 'pr-4'},
          {field: 'items.itemNumber', label: 'itemNumber', labelClass: 'pr-4'},
          {field: 'items.status', label: 'status'},
          {field: 'items.productId', label: 'productId', isNotSortable: true},
          {field: 'items.dimensions', label: 'dimensions'},
          {field: 'items.quantity', label: 'quantity', className: 'text-space-normal', labelClass: 'pr-4'},
          {field: 'items.inProductionQuantity', label: 'inProductionQuantity', className: 'text-space-normal', labelClass: 'pr-4'},
          {field: 'items.readyToShipQuantity', label: 'readyToShipQuantity', labelClass: 'pr-4'},
          {field: 'items.deliveries.deliveredQuantity', label: 'deliveredAmount', className: 'text-space-normal', labelClass: 'pr-4'},
          {
            field: 'items.scheduleLines.deliveryDate', className: 'text-space-prewrap',
            label: 'CdwConfirmedDispatchWeek', tooltipInfo: 'ssab.order.history.confirmedWeek.info'
          },
          {
            field: 'items.CdwEstimatedDispatchDate', className: 'text-space-prewrap',
            label: 'CdwEstimatedDispatchWeek', tooltipInfo: 'ssab.order.history.estimatedWeek.info'
          },
          {field: 'items.warehouseName', label: 'site', isNotSortable: true},
          {field: 'items.infoLine', isNotSortable: true, label: 'infoLine'}
        ];
        break;
      case TablesTypes.MaterialData:
        this.tableHeads = [
          {field: 'grade'},
          {field: 'coating'},
          {field: 'type'},
          {field: 'yieldStress', label: 'yield', className: 'text-space-normal'},
          {field: 'tensileStress', label: 'tensile', className: 'text-space-normal'},
          {field: 'standards', label: 'standard'},
          {field: 'engStress', isNotSortable: true},
          {field: 'autoForm', isNotSortable: true, className: 'text-space-normal'},
          {field: 'forming', isNotSortable: true, className: 'text-space-normal'},
        ];
        break;

      case TablesTypes.PreviousOrders:
        this.tableHeads = [
          {field: 'orderId', labelClass: 'pl-5'},
          {field: 'purchaseOrderNumber', label: 'reference'},
          {field: 'createdAt', label: 'orderCreation'}
        ]
        break;

      case TablesTypes.Coils:
        this.tableHeads = [
          {field: 'compare', isNotSortable: true},
          {field: 'COIL_ID'},
          {field: 'COATING_CODE_TOP'},
          {field: 'COATING_COLOR_TOP'},
          {field: 'dimensions', isNotSortable: true},
          {field: 'ORDER_NUMBER'},
          {field: 'SHIPTO_ADDRESS'},
          {field: 'PRODUCTION_DATE'},
        ]
        break;
    }
  }
}

