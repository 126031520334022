import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SsabProduct, SsabProductBatch, SsabStock, SsabUnitOfMeasure, StepperUpdateData} from '../../../../model/product.model';
import {SsabProductService} from '../../../../service/product/ssab-product.service';
import {filter, map} from 'rxjs/operators';
import {ICON_TYPE, LAUNCH_CALLER, LaunchDialogService} from '@spartacus/storefront';
import {BehaviorSubject, combineLatest, Observable, of, Subscription} from 'rxjs';
import {SsabCart, SsabOrderEntry, SsabSplitBatch} from '../../../../model/cart.model';
import {SsabActiveCartService} from '../../../../service/cart/ssab-active-cart.service';
import {BaseSiteService} from '@spartacus/core';
import {SsabBaseSite} from '../../../../model/misc.model';
import {SsabConsignmentListDialogData} from './ssab-consignment-list-layout.config';
import {DOCUMENT} from "@angular/common";


@Component({
  selector: 'ssab-consignment-list',
  templateUrl: './ssab-consignment-list.component.html'
})
export class SsabConsignmentListComponent implements OnInit, OnDestroy, AfterViewChecked {
  iconTypes = ICON_TYPE;
  product: SsabProduct;
  batches$: Observable<SsabProductBatch[]> = of([]);
  batchesSelected: SsabProductBatch[] = [];
  selectedAll: boolean;
  clearCheck: boolean;
  cart$: Observable<SsabCart>;
  minQuantity: number;
  maxQuantity: number;
  warehouseCode: string;
  canSplitBundle: boolean = false;
  daysInStockThreshold$: Observable<number>;
  batchesID: string[] = [];
  splitBatches: SsabSplitBatch [] = [];
  batchFilter$ = new BehaviorSubject<string>('');
  checkAddedToCart: boolean;
  @ViewChild('splitBundleReadyElem') splitBundleReadyElem: ElementRef<HTMLElement>;
  subscriptions: Subscription = new Subscription();

  constructor(
    protected productService: SsabProductService,
    protected cdr: ChangeDetectorRef,
    protected activeCartService: SsabActiveCartService,
    private baseSiteService: BaseSiteService,
    protected launchDialogService: LaunchDialogService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.cart$ = this.activeCartService.getActive();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.launchDialogService.data$.subscribe((dialogData: SsabConsignmentListDialogData) => {
        if (dialogData) {
          this.product = dialogData.product;
          this.batchesID = dialogData.batchesID;
        }
      })
    );

    this.daysInStockThreshold$ = this.baseSiteService.get().pipe(
      filter(Boolean),
      map((baseSiteData: SsabBaseSite) => {
        return baseSiteData.baseStore?.daysInStockConsignmentThreshold;
      })
    );
    this.batches$ = combineLatest([this.getAvailability(), this.cart$, this.batchFilter$]).pipe(
      map(([stock, cart, filter]) => {
        this.canSplitBundle = stock.batches.find(b => b.canSplitBatch) !== undefined;
        this.minQuantity = stock.bundleSize;
        this.maxQuantity = stock.stockLevel;
        this.warehouseCode = stock.warehouseCode;
        const filteredBatches: SsabProductBatch[] = stock.batches
          .filter(batch => {
            batch.addedToCart = this.getEntriesFromCart(cart.entries, batch.batchId);
            this.checkAddedToCart = true;
            return !cart.entries?.find(entry => entry.batchId === batch.batchId && this.product.code === entry.product.code && this.isEntryMaxedOut(entry.quantity, batch.quantity));
          })
          .filter(batch =>
            !filter ||
            '' === filter ||
            (batch.batchId.toUpperCase().indexOf(filter.toUpperCase()) > -1 ||
              batch.vendorBatchId.toUpperCase().indexOf(filter.toUpperCase()) > -1
            )
          );
        return filteredBatches;
      })
    );
    this.cdr.detectChanges();
  }

  getAvailability(): Observable<SsabStock> {
    return this.productService.getCatalogAvailability(this.product, this.product.unit);
  }

  ngOnDestroy(): void {
    this.close();
    this.subscriptions.unsubscribe();
  }

  ngAfterViewChecked(): void {
    if (this.checkAddedToCart && this.splitBundleReadyElem?.nativeElement != null) {
      setTimeout(() => {
        var children = Array.from(this.splitBundleReadyElem?.nativeElement.children);
        children.forEach((item) => {
          const batchId = (item as HTMLElement).id
          const toggle = this.document.getElementById(batchId + '_toggle').querySelector('.icon-switch') as HTMLElement;
          if (toggle) {
            toggle.click();
          }
        });
      }, 100);
      this.checkAddedToCart = false;
    }
  }

  close(): void {
    this.launchDialogService.clear(LAUNCH_CALLER.CONSIGNMENT_LIST);
    this.launchDialogService.closeDialog('closed');
  }

  onChange($event, isChecked: boolean, batch: SsabProductBatch): void {
    if (isChecked) {
      this.addBatch(batch);
    } else {
      this.clearToggle($event);
      this.removeBatch(batch);
    }
  }

  addBatch(batch: SsabProductBatch): void {
    this.batchesSelected[batch.batchId] = batch;
  }

  removeBatch(toRemove: SsabProductBatch): void {
    delete this.batchesSelected[toRemove.batchId];
  }

  clearToggle($event): void {
    // When batch row checkbox is cleared find closest table row element and active toggle element inside it and trigger click to clear the toggle
    const row = ($event.target as HTMLElement).closest('tr');
    const toggle = row.querySelector('ssab-cx-bundle-split-toggle .icon-switch-blue') as HTMLElement;
    if (toggle) {
      this.clearCheck = true;
      toggle.click();
    }
  }

  selectRow($event, batch: SsabProductBatch): void {
    // Check batch row checkbox only when user clicked on toggle element
    // When toggle.click() is triggered after batch row checkbox is cleared don't check the checkbox again
    if (!this.clearCheck) {
      const row = ($event.target as HTMLElement).closest('tr');
      const checkbox = row.querySelector('.form-check-input') as HTMLInputElement;
      checkbox.checked = true
      this.addBatch(batch)
    }
    this.clearCheck = false;
  }

  selectAll(isChecked: boolean, batches: SsabProductBatch[]): void {
    this.selectedAll = isChecked;
    if (isChecked) {
      batches.forEach(batch => this.addBatch(batch));
    } else {
      batches.forEach(batch => this.removeBatch(batch));
    }
    this.cdr.detectChanges();
  }

  addToCallOffCart(cart: SsabCart): void {
    //We can set 1 here since the quantity for each batch will be set in the back end in SsabCartFacade
    const entryQuantity: number = 1;

    if (!this.addToCallOffCartDisabled(cart)) {
      this.activeCartService?.addSsabEntry({
        product: {code: this.product?.code, stock: {warehouseCode: this.warehouseCode}},
        quantity: entryQuantity,
        unit: {code: this.product?.unit?.code} as SsabUnitOfMeasure,
        batches: Object.values(this.batchesSelected).filter(batch => batch !== null && batch !== undefined),
        splitBatch: this.splitBatches,
      } as SsabOrderEntry);
    }
    this.launchDialogService?.closeDialog('closed');
  }

  hasEntryWithWarehouseDifferent(entries: SsabOrderEntry[] = []): boolean {
    return entries.filter((entry) => entry.warehouse.code !== this.warehouseCode).length > 0;
  }

  changeBatchFilter(query: string): void {
    this.batchFilter$.next(query);
  }

  quantityChange(updatedQuantity: StepperUpdateData, batchId: string): void {
    if (updatedQuantity.qty) {
      const targetBatchIndex = this.splitBatches.findIndex(batch => batch.batchId === batchId);
      if (targetBatchIndex !== -1) {
        this.splitBatches[targetBatchIndex].splitBatchQuantity = updatedQuantity.qty;
      }
      this.cdr.detectChanges();
    }
  }

  addRemoveToSplitBatches(isSplitBundle: boolean, batchId: string): void {
    if (isSplitBundle) {
      if (!this.isSplitBatchOnList(batchId)) {
        this.splitBatches.push({batchId, splitBatchQuantity: this.minQuantity});
      }
    } else {
      this.splitBatches = this.splitBatches
        .filter(function (batch) {
          return batch.batchId !== batchId;
        });
    }
  }

  isSplitBatchOnList(batchId: string): boolean {
    return this.splitBatches.some(el => el.batchId === batchId);
  }

  isEntryMaxedOut(entryQuantity: number, batchQuantity: number): boolean {
    // if the entire entry is added to cart do not display it in the consignment popup
    return entryQuantity >= batchQuantity;
  }

  alignBatchQuantity(batchQuantity: number, entries: SsabOrderEntry[], batchID: string): number {
    // it can happen that the batch is smaller than the minimum quantity. In that case we should allow batchQuantity to be visible
    // Bug https://dev.azure.com/ssab-projects/Digital%20Commerce/_workitems/edit/29390 - point 2.
    if (this.minQuantity > batchQuantity) {
      return batchQuantity;
    } else if (entries?.length > 0) {
      const entry = entries.find((entry: SsabOrderEntry) => entry.batchId === batchID && entry.splitBundle);
      return entry?.quantity ? entry?.quantity : this.minQuantity;
    }
    return this.minQuantity;
  }

  getEntriesFromCart(entries: SsabOrderEntry[], batchID: string): number {
    if (entries?.length > 0) {
      const entry = entries.find((entry: SsabOrderEntry) => entry.batchId === batchID && entry.splitBundle);
      return entry?.quantity ? entry?.quantity : 0;
    }
    return 0;
  }

  isSelected(batch: SsabProductBatch): boolean {
    return this.batchesSelected[batch.batchId] !== undefined;
  }

  addToCallOffCartDisabled(cart: SsabCart): boolean {
    return this.hasEntryWithWarehouseDifferent(cart.entries) || Object.keys(this.batchesSelected).length === 0;
  }
}
