<div class="cx-order-items" *ngIf="order$ | async as order">

  <div class="items-details header-block">
    <ssab-cx-cart-item-list
      [items]="order.entries"
      [readonly]="true"
      [cart]="order"
      [showCartTotals]="true"
      [showPrices]="showPrices$ | async"
    ></ssab-cx-cart-item-list>
  </div>
</div>
