import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { SmartEditService } from '@spartacus/smartedit/core';
import { SmartEditConfig, SmartEditRootModule } from '@spartacus/smartedit/root';

import { smartEditDecorators } from './decorators';
import {SsabAuthService} from "../service/user-auth/ssab-auth.service";

@NgModule({
  declarations: [],
  imports: [SmartEditRootModule],
  providers: [
    ...smartEditDecorators,
    provideConfig({
      featureModules: {
        smartEdit: {
          module: () => import('@spartacus/smartedit').then((m) => m.SmartEditModule),
        },
      },
    } as CmsConfig),
    provideConfig(<SmartEditConfig>{
      smartEdit: {
        storefrontPreviewRoute: 'cx-preview',
        allowOrigin: 'localhost:9002,api-dev-shop.ssab.com:443,admin-dev-shop.ssab.com:443,api-test-shop.ssab.com:443,admin-test-shop.ssab.com:443,api-shop.ssab.com:443,admin-shop.ssab.com:443,cdns.gigya.com:443'
      },
    })
  ]
})
export class SmartEditFeatureModule {
  constructor(private smartEditService: SmartEditService, protected ssabAuthService: SsabAuthService) {
    if (!ssabAuthService.isAzureLoginInProgress()) {
      this.smartEditService.processCmsPage();
    }
  }
}

