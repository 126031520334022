import {Injectable} from '@angular/core';
import {Converter, Occ, OccConfig, Product} from "@spartacus/core";

@Injectable({providedIn: 'root'})
export class SsabProductStockNormalizer implements Converter<Occ.Product, Product> {
  constructor(protected config: OccConfig) {
  }

  convert(source: Occ.Product, target?: Product): Product {
    if (target === undefined) {
      target = {...(source as any)};
    }
    // fix for 
    // Error passed to normalizeHttpError is not HttpErrorResponse instance TypeError: "stockLevel" is read-only
    return {...target, stock: {...target.stock, stockLevel: (target.stock as any)?.stockLevelDouble}};
  }
}
