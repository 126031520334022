<div class="row align-items-center no-margin"
     [ngClass]="{
     'cx-compact': compact,
     'item-disabled': (getSsabItem().availabilityMessage && getSsabItem().availabilityMessage.key && getSsabItem().availabilityMessage.severity === 'ERROR' ) || (getSsabItem().product.stock?.stockLevelStatus === 'outOfStock'),
     'item-alert' : (getSsabItem().product.stock?.stockLevelStatus === 'outOfStock' || isHighlightedBecauseMinimumWeightNotFulfilled(getSsabItem()) )}"
     [formGroup]="itemForm">
  <!-- Item Information -->
  <div [ngClass]="showPricesOrConsignmentPricesStock? 'cx-item-list-desc col-4 row' : 'cx-item-list-desc col-6 row'">
    <!--Item image -->
    <div class="col-4 cx-image-container col-no-padding">
      <a
        [routerLink]="{ cxRoute: 'product', params: getSsabItem().product } | cxUrl"
        cxModal="dismiss"
        tabindex="-1"
      >
        <cx-media
          [container]="getSsabItem().product.images?.PRIMARY"
          format="cartIcon"
        ></cx-media>
      </a>
    </div>
    <!--Product name -->
    <div class="col-8">
      <div class="text-opacity">{{getSsabItem().product.code}}</div>
      <div class="text-opacity" *ngIf="getSsabItem().product.customerArticleIds">
        {{getSsabItem().product.customerArticleIds.join(',')}}
      </div>
      <a
        class="cx-link"
        [routerLink]="{ cxRoute: 'product', params: getSsabItem().product } | cxUrl"
        cxModal="dismiss"
      >{{ getSsabItem().product.name }}</a
      >
    </div>
  </div>

  <div class="cx-item-list-qty col-2 item-update">
    <div class="row align-items-center h-100">
      <div class="col-8 col-no-padding" [ngClass]="getSsabItem().availabilityMessage && getSsabItem().availabilityMessage.key ? 'item-update' : ''">
        <ssab-cx-item-stepper
          *ngIf="!readonly"
          [isValueChangeable]="true"
          [showButtons]="true"
          [max]="getSsabItem().product.stock?.stockLevel"
          [min]="minQuantity"
          [value]="getSsabItem().quantity"
          [step]="minQuantity"
          [stepperTimeout]="0"
          [id]="getSsabItem().product.code+'_stepper'"
          (update)="postBackQuantity($event)"
          [ngClass]="getSsabItem().availabilityMessage && getSsabItem().availabilityMessage.key ? 'is-invalid item-update': ''"
          [isDisabled]="getSsabItem().product.stock?.stockLevelStatus === 'outOfStock'"
        ></ssab-cx-item-stepper>
        <span *ngIf="readonly" class="pl-3">
          {{getSsabItem().quantity}}
        </span>
      </div>

      <div class="col-4 pl-2 col-no-padding" [ngClass]="getSsabItem().availabilityMessage && getSsabItem().availabilityMessage?.key ? 'item-update' : ''">
        <ssab-unit-picker-cart
          [product]="getSsabItem().product"
          (update)="postBack()"
          [nameForm]="'unit'"
          [parentForm]="itemForm"
          [readonly]="getSsabItem().product.stock?.stockLevelStatus === 'outOfStock'"
          [cssClassMulti]="'ssab-select '+getSsabItem().availabilityMessage && getSsabItem().availabilityMessage?.key ? 'is-invalid item-update': ''"
          [cssClassSingle]="'pl-1'"
        ></ssab-unit-picker-cart>
      </div>

      <div *ngIf="getSsabItem().availabilityMessage"
           class="col-12 pt-1 item-update col-no-padding message {{getSsabItem().availabilityMessage.severity?.toLowerCase()}}">
        {{('ssab.cart.item.message.' + getSsabItem().availabilityMessage.key)|cxTranslate: {arg1: getSsabItem().availabilityMessage.arg1} }}
      </div>
    </div>
  </div>

  <div class="col-1 ">
    {{getSsabItem().totalDisplayWeight}} {{getSsabItem()?.product?.unitOfWeight.name}}
  </div>

  <div class="col-3 date-picker align-items-center row">
    <div class="col-6 cx-item-list-dates">
      <!--ssab-datepicker-input *ngIf="!readonly"
                       [errorMessage]="'ssab.calendar.error'"
                       [nameForm]="'requestedDeliveryDate'"
                       [idName]="'requestedDeliveryDate_'+getSsabItem().entryNumber"
                       [typeInput]="'text'"
                       [parentForm]="itemForm"
                       [isNotValidFunction]="isRequestedDateInvalid()"
                       [customDayTemplate]="customDay"
                       [selectedDate]="convertDate(getSsabItem().requestedDeliveryDate)"
                       (onChangeEvent)="setRequestedDate($event)"
        >
        </ssab-datepicker-input>

        <span *ngIf="readonly">
          {{ convertUIDate(getSsabItem().requestedDeliveryDate,''|cxTranslate) }}
        </span-->

      <ng-container *ngFor="let line of getSsabItem().expectedSchedules">
        <div *ngIf="line.date">
          {{convertUIDate(line.date)|cxDate: (getDateFormat()|async) }} {{line.amount}} <span [innerHTML]="line.unit?.name"></span>
        </div>
      </ng-container>
    </div>
    <div class="col-6">
      {{ getSsabItem().warehouse?.name }}
    </div>


  </div>

  <!-- Template for days -->
  <ng-template #customDay let-date let-currentMonth="currentMonth" let-today="today" let-disabled="disabled" let-selected="selected">
    <ssab-datepicker-day
      [today]="today"
      [disabled]="!isDateAllowed(date)"
      [selected]="selected"
      [hidden]="date.month !== currentMonth"
      [hasContent]="isDateAllowed(date)"
      [date]="date"
    >
    </ssab-datepicker-day>
  </ng-template>

  <ng-container *ngIf="showPricesOrConsignmentPricesStock">
    <div class="cx-item-list-price col-1">
      <div class="mr-2">
        <ssab-cx-product-comparison-price
          [product]="getSsabItem().product"
          [currentPrice]="getSsabItem().basePrice"
          [selectedUnit]="getSsabItem().unit"
          [orderPage]="true">
        </ssab-cx-product-comparison-price>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showPricesOrConsignmentPricesStock">
    <div class="cx-item-list-price col-1 text-bold">
      {{ getSsabItem().totalPrice?.formattedValue }}
    </div>
  </ng-container>

  <button type="button"
          class="float-right ssab-icon icon-system-trash icon-cursor"
          [ngClass]="{'icon-red': itemHasErrors(getSsabItem())}"
          *ngIf="!readonly"
          (click)="deleteEntry(getSsabItem())">
  </button>
  <div class="offset-1 col-11 col-no-padding pt-2" *ngIf="!readonly">
    <ssab-cx-bundle-split-toggle
      *ngIf="getSsabItem().canSplitBundle"
      [isSplitBundle]="getSsabItem().splitBundle"
      [label]="'ssab.user.bundleSplit.toggle' + (this.getSsabItem().splitSurchargeExists ? '' : 'NoSurcharge')"
      (updateSplitBundle)="setMinQuantity($event)"
    ></ssab-cx-bundle-split-toggle>
  </div>
</div>

