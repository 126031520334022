import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {I18nModule} from '@spartacus/core';
import {SsabInputRangeComponent} from './ssab-input-range.component';
import {SsabInputModule} from '../input/ssab-input.module';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {SharedModule} from '../shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        I18nModule,
        SsabInputModule,
        NgxSliderModule,
        SharedModule
    ],
    declarations: [SsabInputRangeComponent],
    exports: [SsabInputRangeComponent]
})
export class SsabInputRangeModule {
}
