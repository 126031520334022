export const ssabOccUserConfig = {
  backend: {
    occ: {
      endpoints: {
        selectUnit: 'units/selectUnit?uid=${uid}&cartId=${cartId}',
        selectUnitNoCart: 'units/selectUnit?uid=${uid}&customerEmulationParameter=true',
        searchConsigneesForDocuments: 'units/searchConsigneesForDocuments?customerEmulationParameter=true',
        searchUnits: 'units/searchActiveUnits?customerEmulationParameter=true',
        searchUnitsForDocuments: 'units/searchUnitsForDocuments?customerEmulationParameter=true',
        searchUnitsForOrderHistory: 'units/searchUnitsForOrderHistory?customerEmulationParameter=true',
        getSelectedUnit: 'units/getSelectedUnit',
        getCertificateDocuments: 'users/${userId}/documents/certificate?spinner=true',
        getOrderConfirmationDocuments: 'users/${userId}/documents/orderconfirmation?spinner=true',
        getInvoiceDocuments: 'users/${userId}/documents/invoice?spinner=true',
        getTransportDocuments: 'users/${userId}/documents/transport?spinner=true',
        downloadDocument: 'users/${userId}/documents/download/single?spinner=true',
        downloadDocuments: 'users/${userId}/documents/download/many?spinner=true',
        downloadAllDocuments: 'users/${userId}/documents/download/all?spinner=true',
        relatedDocuments: 'users/${userId}/documents/related/${orderId}/${customerId}?spinner=true',
        delayedOrderEmail: '/users/${uid}/subscriptions/lateorder',
        storeSessionLanguage: '/users/${userId}/storeSessionLanguage?customerEmulationParameter=true',
        setShowInches: '/users/${uid}/setShowInches',
        searchByMaterialId: 'users/${userId}/documents/searchByMaterialId/${materialId}?spinner=true',
        searchByOcr: 'users/${userId}/documents/searchByOcr?spinner=true',
        sendDocumentsAsEmail: '/users/${userId}/documents/email',
        orderHistorySearch: 'users/${userId}/orders?fields=pagination(FULL),allowedStatuses,allowedSalesChannels,allowedShipToCustomers,allowedGrades,allowedPlants,thicknessMin,thicknessMax,allowedCustomers(BASIC),allowedCompanies,defaultCustomer,firstDate,lastDate,firstDeliveryDate,lastDeliveryDate,orders(code,user(BASIC),unit(BASIC),statusDisplay,salesChannel,created,purchaseOrderNumber,deliveryAddress(FULL),entries(DEFAULT))&spinner=true',
        orderHistoryDownload: 'users/${userId}/orders/items/download?fields=pagination(FULL),allowedStatuses,allowedSalesChannels,allowedShipToCustomers,allowedGrades,thicknessMin,thicknessMax,allowedCustomers(BASIC),allowedCompanies,firstDate,lastDate,firstDeliveryDate,lastDeliveryDate,orders(code,user(BASIC),unit(BASIC),statusDisplay,salesChannel,created,purchaseOrderNumber,deliveryAddress(FULL),entries(product(BASIC),expectedSchedules(FULL),confirmedSchedules(FULL),estimatedDate,estimatedWeek,confirmedDate,confirmedWeek,deliveredAmount,readyToShipQuantity,inProductionQuantity,deliveries(DEFAULT),site,dimensions,warehouse(BASIC),entryNumber,doubleQuantity,unit(FULL)))&spinner=true',
        orderDetail: 'users/${userId}/orders/${orderId}?fields=DEFAULT'
      }
    }
  }
};
