<div
  class="inner"
  *ngIf="(facetList$ | async)?.facets as facets"
  [cxFocus]="isDialog ? dialogFocusConfig : {}"
  (esc)="close($event)"
  (click)="block($event)"
>
  <span type="button" class="pt-3 w-100 d-lg-none" aria-label="Close" (click)="close()">
    <i class="ssab-icon icon-md icon-navigation-close float-right"></i>
  </span>

  <ssab-cx-active-facets class="d-block d-lg-none pb-3"></ssab-cx-active-facets>

  <!--
      Here we'd like to introduce configurable facet components,
      either by using specific configuration or generic sproutlets
  -->
  <div class="list-facet">
    <ssab-cx-show-inches-toggle [myAccountStyle]="false"></ssab-cx-show-inches-toggle>
    <ssab-cx-facet
      *ngFor="let facet of facets"
      #facetRef
      [facet]="facet"
      [cxFocus]="{ lock: true, trap: true, autofocus: 'a' }"
      (unlock)="expandFacetGroup(facet, facetRef)"
      [class.expanded]="facet.expanded ? true : isExpanded(facet) | async"
      [class.collapsed]="isCollapsed(facet) | async"
    ></ssab-cx-facet>
  </div>


  <button
    class="btn btn-block mt-5 btn-default-height btn-primary d-block d-lg-none d-xl-none"
    (click)="close()">
    {{'productList.filterBy.showResults' | cxTranslate }}
  </button>
</div>
