import {Inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from, Observable} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {LoggerService, normalizeHttpError} from '@spartacus/core';
import {SUBSCRIBE_PRODUCT_AVAILABILITY_REQUEST, SubscribeProductAvailabilityRequest, SubscribeProductAvailabilityRequestFail, SubscribeProductAvailabilityRequestSuccess} from './ssab-subscription.action';
import {SsabSubscriptionsConnector} from './ssab-subscriptions.connector';
import {openCloseSpinner} from '../../cms-components/shared/utils/functions/ssab-functions-utils';
import {LAUNCH_CALLER, LaunchDialogService} from '@spartacus/storefront';
import {SsabProductAvailabilityModalData} from '../../cms-components/subscriptions/ssab-product-availability-modal/ssab-product-availability-modal-layout.config';
import {SsabUserService} from '../user/ssab-user.service';
import {DOCUMENT} from "@angular/common";


@Injectable()
export class SsabSubscriptionEffects {
  subscribeProductAvailability$: Observable<SubscribeProductAvailabilityRequestFail | SubscribeProductAvailabilityRequestSuccess>;

  constructor(
    private actions$: Actions,
    private connector: SsabSubscriptionsConnector,
    protected launchDialogService: LaunchDialogService,
    protected userService: SsabUserService,
    protected logger: LoggerService,
    @Inject(DOCUMENT) private document: Document,
  ) {

    this.subscribeProductAvailability$ = createEffect(() =>
      this.actions$.pipe(
        ofType(SUBSCRIBE_PRODUCT_AVAILABILITY_REQUEST),
        map((action: SubscribeProductAvailabilityRequest) => action.payload),
        switchMap((payload) => {
          return this.connector.subscribeProductAvailability(payload.userId, payload.productCode).pipe(
            map(() => {
              openCloseSpinner(this.document, false);
              this.launchDialogService.closeDialog(null);
              this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.PRODUCT_AVAILABILITY_MODAL, undefined, {
                productCode: payload.productCode,
                isSubscriptionSuccess: true,
                isSubscriptionFail: false,
                user: this.userService.get()
              } as SsabProductAvailabilityModalData);
              return new SubscribeProductAvailabilityRequestSuccess({
                userId: payload.userId,
                contactFormType: 'subscribeProductAvailability',
                productCode: payload.productCode
              });
            }),
            catchError((error) => {
              openCloseSpinner(this.document, false);
              this.launchDialogService.closeDialog(null);
              this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.PRODUCT_AVAILABILITY_MODAL, undefined, {
                productCode: payload.productCode,
                isSubscriptionSuccess: false,
                isSubscriptionFail: true,
                user: this.userService.get()
              } as SsabProductAvailabilityModalData);
              return from([
                new SubscribeProductAvailabilityRequestFail({
                  contactFormType: 'subscribeProductAvailability',
                  error: normalizeHttpError(error, this.logger),
                }),
              ]);
            })
          );
        })
      ));
  }


}
