<ng-container *ngIf="units?.length > 1 && !readonly">
  <ng-container *ngIf="parentForm">
    <div [formGroup]="parentForm">
      <ng-select
        (change)="unitChange($event)"
        [searchable]="false"
        [clearable]="false"
        [closeOnSelect]="true"
        [ngClass]="cssClassMulti"
        [readonly]="readonly"
        [items]="units"
        [bindLabel]="'name'"
        [bindValue]="'code'"
        [formControlName]="nameForm"
        dropdownPosition="bottom"
        [appendTo]="appendTo"
        class="ssab-select"
        placeholder="{{ 'common.selectOption' | cxTranslate }}"
      >
        <ng-template ng-label-tmp let-item="item">
          <span [innerHTML]="item.name"></span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          <span [innerHTML]="item.name"></span>
        </ng-template>
      </ng-select>
    </div>
  </ng-container>

  <ng-container *ngIf="!parentForm">
    <ng-select
      (change)="unitChange($event)"
      [searchable]="false"
      [clearable]="false"
      [closeOnSelect]="true"
      [ngModel]="selectedUnit"
      [ngClass]="cssClassMulti"
      [readonly]="readonly"
      [items]="units"
      [bindLabel]="'name'"
      [bindValue]="'code'"
      dropdownPosition="bottom"
      [appendTo]="appendTo"
      class="ssab-select"
      placeholder="{{ 'common.selectOption' | cxTranslate }}"
    >
      <ng-template ng-label-tmp let-item="item">
        <span [innerHTML]="item.name"></span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <span [innerHTML]="item.name"></span>
      </ng-template>
    </ng-select>
  </ng-container>
</ng-container>
<!-- If there is just one unit in the list of units we don't display a ng-select -->
<ng-container *ngIf="units?.length ==1 || readonly">
  <div [ngClass]="cssClassSingle" [innerHTML]="units[0].name"></div>
</ng-container>
