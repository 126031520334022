import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {OCRResult, SsabCertificateDocumentList, SsabDocumentIdentifier, SsabDocumentIdentifierList, SsabDocumentSearchRequest, SsabInvoiceDocumentList, SsabOrderConfirmationDocumentList, SsabRelatedDocumentList, SsabTransportDocumentList} from '../../model/document.model';
import {SsabOccUserDocumentAdapter} from './ssab-occ-user-document.adapter';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SsabUserDocumentConnector {
  constructor(protected adapter: SsabOccUserDocumentAdapter) {
  }

  public getRelatedDocuments(userId: string, orderId: string, customerId: string, searchRelevantIdsFromOrders: boolean): Observable<SsabRelatedDocumentList> {
    return this.adapter.getRelatedDocuments(userId, orderId, customerId, searchRelevantIdsFromOrders);
  }

  public getCertificateDocumentsList(userId: string, params: SsabDocumentSearchRequest, trimMaterialIds?: string): Observable<SsabCertificateDocumentList> {
    return this.adapter.loadCertificateDocuments(userId, params, trimMaterialIds);
  }

  public getOrderConfirmationDocumentsList(userId: string, params: SsabDocumentSearchRequest): Observable<SsabOrderConfirmationDocumentList> {
    return this.adapter.loadOrderConfirmationDocuments(userId, params);
  }

  public getInvoiceDocumentsList(userId: string, params: SsabDocumentSearchRequest): Observable<SsabInvoiceDocumentList> {
    return this.adapter.loadInvoiceDocuments(userId, params);
  }

  public getTransportDocumentsList(userId: string, params: SsabDocumentSearchRequest): Observable<SsabTransportDocumentList> {
    return this.adapter.loadTransportDocuments(userId, params);
  }

  public downloadDocument(userId: string, params: SsabDocumentIdentifier, showHeaders?: boolean): Observable<any> {
    return this.adapter.downloadDocument(userId, params, showHeaders);
  }

  public downloadDocuments(userId: string, params: SsabDocumentIdentifierList): Observable<any> {
    return this.adapter.downloadDocuments(userId, params);
  }

  public downloadAllDocuments(userId: string, params: SsabDocumentSearchRequest): Observable<any> {
    return this.adapter.downloadAllDocuments(userId, params);
  }

  public sendELOQUAData(url: string, params?: HttpParams): Observable<any> {
    return this.adapter.sendELOQUAData(url, params);
  }

  searchByMaterialId(userId: string, materialId: string): Observable<SsabCertificateDocumentList> {
    return this.adapter.searchByMaterialId(userId, materialId);
  }

  sendDocumentsAsEmail(userId: string, params: SsabDocumentIdentifierList, email: string): Observable<void> {
    return this.adapter.sendDocumentsAsEmail(userId, params, email);
  }

  searchByOcr(userId: string, data: Blob): Observable<OCRResult> {
    return this.adapter.searchByOcr(userId, data);
  }
}
