import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from "@ngrx/effects";
import { provideDefaultConfig } from '@spartacus/core';
import { ContactSalesProductListEffects } from "./ssab-contactsales.effect";
import { SsabContactSalesProductListService } from './ssab-contactsales.service';
import { ssabOccContactSalesConfig } from "./ssab-occ-contactsales-config";

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([ContactSalesProductListEffects])
  ],
  providers: [
    provideDefaultConfig(ssabOccContactSalesConfig),
    {
      provide: SsabContactSalesProductListService,
      useClass: SsabContactSalesProductListService
    }
  ],
})
export class SsabContactSalesModule { }
