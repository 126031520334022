import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {OrderHistoryConnector} from '@spartacus/order/core';
import {OrderTrackingTypes, SsabOrderHistory, SsabOrderHistoryRequest} from '../../model/order.model';
import {SsabOccUserOrderAdapter} from './ssab-occ-user-order.adapter';

@Injectable({
  providedIn: 'root',
})
export class SsabUserOrderConnector extends OrderHistoryConnector {
  constructor(protected adapter: SsabOccUserOrderAdapter) {
    super(adapter);
  }

  public getOrderHistory(userId: string, selectedView: OrderTrackingTypes, params: SsabOrderHistoryRequest): Observable<SsabOrderHistory> {
    return this.adapter.loadOrderHistory(userId, selectedView, params);
  }

  public getOrderHistoryDownload(userId: string, selectedView: OrderTrackingTypes, params: SsabOrderHistoryRequest): Observable<any> {
    return this.adapter.downloadOrderHistory(userId, selectedView, params);
  }

}
