import {Component, OnInit} from '@angular/core';
import {HamburgerMenuComponent, HamburgerMenuService} from "@spartacus/storefront";
import {AuthService} from "@spartacus/core";
import {Observable} from "rxjs";

@Component({
  selector: 'ssab-cx-hamburger-menu',
  templateUrl: './ssab-hamburger-menu.component.html'
})
export class SsabHamburgerMenuComponent extends HamburgerMenuComponent implements OnInit {

  loggedIn$: Observable<boolean>;

  constructor(hamburgerMenuService: HamburgerMenuService, protected auth: AuthService) {
    super(hamburgerMenuService);
  }

  ngOnInit(): void {
    this.loggedIn$ = this.auth.isUserLoggedIn();
  }

}
