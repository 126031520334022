import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SsabFacetListComponent} from './ssab-facet-list.component';
import {IconModule, KeyboardFocusModule} from '@spartacus/storefront';
import {SsabFacetModule} from '../facet/ssab-facet.module';
import {SsabActiveFacetsModule} from '../active-facet/ssab-active-facets.module';
import {I18nModule} from '@spartacus/core';
import {SsabShowInchesToggleModule} from '../../../../my-account/show-inches/ssab-show-inches-toggle.module';

@NgModule({
    imports: [
        CommonModule,
        I18nModule,
        IconModule,
        SsabFacetModule,
        KeyboardFocusModule,
        SsabActiveFacetsModule,
        SsabShowInchesToggleModule,
    ],
  declarations: [SsabFacetListComponent],
  exports: [SsabFacetListComponent],
})
export class SsabFacetListModule {
}
