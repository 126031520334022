<ng-container *ngIf="isSimpleView; else detailDocumentsView">
  <table class="table entries-data" *ngIf="allDocuments.length > 0 ; else noDocuments">
    <tr class="text-uppercase">
      <th class="w-150">
        {{ 'ssab.order.history.documents.page' | cxTranslate }}
      </th>
      <th>
        {{ 'ssab.order.history.documents.download' | cxTranslate }}
      </th>
      <th>
        {{ 'ssab.order.history.documents.type' | cxTranslate }}
      </th>
      <th>
        {{ 'ssab.order.history.documents.number' | cxTranslate }}
      </th>
      <th>
        {{ 'ssab.order.history.documents.date' | cxTranslate }}
      </th>
    </tr>
    <tr *ngFor="let document of allDocuments">
      <td>
        <div class="form-check mb-0 text-center">
          <input #selectedDocument
                 class="form-check-input position-relative"
                 type="checkbox" aria-label="..."
                 (change)="onChange(selectedDocument.checked, document)">
        </div>
      </td>
      <td>
        <i (click)="downloadSingleDocument(document)"
           class=" ml-4 ssab-icon icon-system-download icon-cursor"></i>
      </td>
      <td>
        {{'ssab.order.history.' + (document.docType | lowercase)| cxTranslate}}
      </td>
      <td>
        {{document.fileName}}
      </td>
      <td>
        {{convertUIDate(document.documentDate)|cxDate: (getDateFormat()|async)}}
      </td>
    </tr>
  </table>
</ng-container>

<ng-template #detailDocumentsView>
  <div class="cx-documents-data mt-lg-4">
    <ng-container *ngIf="(certificates || invoices || transports || ordersConfirmations); else noDocuments">
      <ssab-cx-certificates
        *ngIf="certificates"
        [certificateDocuments]="certificates"
        [activeSort]="activeSort"
        (sortChange)="sortChange($event)"
        (selectDocument)="addDocumentToDownload($event)"
        (unselectDocument)="removeDocumentToDownload($event)"
        (downloadDocument)="downloadSingleDocument($event)"
        (selectAll)="selectAll($event)"
      ></ssab-cx-certificates>
      <ssab-cx-invoices
        *ngIf="invoices"
        [invoiceDocuments]="invoices"
        [activeSort]="activeSort"
        (sortChange)="sortChange($event)"
        (selectDocument)="addDocumentToDownload($event)"
        (unselectDocument)="removeDocumentToDownload($event)"
        (downloadDocument)="downloadSingleDocument($event)"
        (selectAll)="selectAll($event)"
      ></ssab-cx-invoices>
      <ssab-cx-orders-confirmation
        *ngIf="ordersConfirmations"
        [orderConfirmationDocuments]="ordersConfirmations"
        [activeSort]="activeSort"
        (sortChange)="sortChange($event)"
        (selectDocument)="addDocumentToDownload($event)"
        (unselectDocument)="removeDocumentToDownload($event)"
        (downloadDocument)="downloadSingleDocument($event)"
        (selectAll)="selectAll($event)"
      ></ssab-cx-orders-confirmation>
      <ssab-cx-transport-documents
        *ngIf="transports"
        [transportDocuments]="transports"
        [activeSort]="activeSort"
        (sortChange)="sortChange($event)"
        (selectDocument)="addDocumentToDownload($event)"
        (unselectDocument)="removeDocumentToDownload($event)"
        (downloadDocument)="downloadSingleDocument($event)"
        (selectAll)="selectAll($event)"
      ></ssab-cx-transport-documents>
    </ng-container>
  </div>

  <!-- Select Form and Pagination Bottom -->
  <ssab-cx-pagination-bottom
    [pagination]="pagination"
    (viewPageEvent)="pageChange($event)"
  ></ssab-cx-pagination-bottom>
</ng-template>

<!--- Buttons for download documents ---->
<div class="cx-documents-download row justify-content-end no-margin" [ngClass]="{'is-sticky': documentsSelectedToDownload && documentsSelectedToDownload.length > 0}"
     *ngIf="(certificates || invoices || transports || ordersConfirmations || allDocuments.length > 0)">
  <ng-container *ngIf="pagination; else simpleViewButtons">
    <div class="col-12 col-md-6 col-lg-4 col-xl-3" *ngIf=" pagination?.totalResults > 0">
      <button class="btn btn-download btn-default-height btn-block mb-4 mb-md-0 p-0"
              (click)="downloadDocumentsSelected()"
              [disabled]="documentsSelectedToDownload && documentsSelectedToDownload.length == 0">
        <i class="ssab-icon icon-system-download icon-inline pr-2"
           [ngClass]="documentsSelectedToDownload && documentsSelectedToDownload.length == 0 ? 'icon-white': ''"></i>
        {{'ssab.documents.search.download.selected' | cxTranslate}}
        <ng-container *ngIf="documentsSelectedToDownload && documentsSelectedToDownload.length > 0"> ({{documentsSelectedToDownload.length}})</ng-container>
      </button>
    </div>
    <!--Documents: Hide "Download All"-button from all views-->
    <div class="col-12 col-md-6 col-lg-3" *ngIf="false">
      <button class="btn btn-primary btn-default-height btn-block" (click)="downloadAllDocuments()">
        <i class="ssab-icon icon-system-download icon-inline pr-2"></i>
        {{'ssab.documents.search.download.all' | cxTranslate}}
      </button>
    </div>
  </ng-container>
  <ng-template #simpleViewButtons>
    <div class="col-12 col-md-6 col-lg-4 col-no-padding pt-4" *ngIf="allDocuments.length > 0">
      <button class="btn btn-info btn-default-height btn-block mb-4 mb-md-0"
              (click)="downloadDocumentsSelected()"
              [disabled]="documentsSelectedToDownload && documentsSelectedToDownload.length == 0">
        <i class="ssab-icon icon-system-download icon-inline pr-2"
           [ngClass]="documentsSelectedToDownload && documentsSelectedToDownload.length == 0 ? 'icon-white': ''"></i>
        {{'ssab.documents.search.download.selected' | cxTranslate}}
      </button>
    </div>
  </ng-template>
</div>

<ng-template #noDocuments>
  <div class="entries-data empty-data-div ">
    <h4
      class="text-center text-primary empty-documents">
      <ng-container *ngIf="isSimpleView">{{ 'ssab.order.history.documents.noFound' | cxTranslate }}</ng-container>
      <ng-container *ngIf="!isSimpleView">{{ 'ssab.documents.search.noDocuments.' + (selectedDocument | lowercase) | cxTranslate }}</ng-container>
    </h4>
  </div>
</ng-template>
