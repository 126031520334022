import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SsabCustomerSelectConfirmComponent} from './ssab-customer-select-confirm.component';
import {IconModule} from '@spartacus/storefront';
import {I18nModule, provideDefaultConfig} from '@spartacus/core';
import {customerSelectConfirmLayout} from './ssab-customer-select-confirm-layout.config';

@NgModule({
  declarations: [SsabCustomerSelectConfirmComponent],
  imports: [
      CommonModule,
      IconModule,
      I18nModule,
  ],
  exports: [SsabCustomerSelectConfirmComponent],
  providers: [provideDefaultConfig(customerSelectConfirmLayout)]
})
export class SsabCustomerSelectConfirmModule {
}
