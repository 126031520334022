<div class="ssab-modal" [ngClass]="'ssab-modal-lg'">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" *ngIf="cart$ | async as cart">
      <ng-container *ngIf="batches$ | async as batches; else loadingConsignmentList">
        <div class="table-responsive p-2"
             *ngIf="batches.length > 0 || (batchFilter$ | async).length > 0; else emptyConsignmentList">
          <h4 class="text-bold mb-0">{{ 'ssab.consignment.list.title' | cxTranslate}}
            <span class="float-right icon-md ssab-icon icon-navigation-close icon-inline icon-cursor"
                  (click)="close()"></span>
          </h4>
          <p class="text-paragraph-md pb-2">{{'ssab.consignment.list.description'  | cxTranslate}}</p>

          <ssab-filter
            class="col-6 col-lg-5 mb-3 p-0 filter-gray"
            [labelPlaceHolder]="'ssab.consignment.list.materialID' | cxTranslate"
            (filterValue)="changeBatchFilter($event)"
          >
          </ssab-filter>

          <div class="table-scroll mb-3">
            <table class="table mt-0 mb-0">
              <thead>
              <tr>
                <th>
                  <div class="form-check mb-1 text-center">
                    <input #selectAllBatches
                           class="form-check-input check-color-inverse position-relative"
                           type="checkbox" aria-label="..."
                           (change)="selectAll(selectAllBatches.checked, batches)">
                  </div>
                </th>
                <th>{{'ssab.consignment.list.materialID' | cxTranslate}}</th>
                <th *ngIf="canSplitBundle">{{'ssab.consignment.list.addedToCart' | cxTranslate}}</th>
                <th>{{'ssab.consignment.list.amount' | cxTranslate}}</th>
                <th>{{'ssab.consignment.list.days' | cxTranslate}}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
                <tr *ngFor="let batch of batches">
                  <td>
                    <div class="form-check mb-1 text-center">
                      <input #selectedBatch
                             class="form-check-input position-relative"
                             type="checkbox" aria-label="..."
                             [checked]="isSelected(batch)"
                             (change)="onChange($event, selectedBatch.checked, batch)">
                    </div>
                  </td>
                  <td>{{!batch.vendorBatchId || batch.batchId === batch.vendorBatchId ? batch.batchId : batch.batchId + ' / ' + batch.vendorBatchId}}</td>
                  <td *ngIf="batch.canSplitBatch">
                    {{batch.addedToCart}} / {{batch.quantity}}&nbsp;<span [innerHTML]="batch.unit?.name"></span>
                  </td>
                  <td *ngIf="!batch.canSplitBatch && canSplitBundle"></td>
                  <td>
                    <ng-container *ngIf="!isSplitBatchOnList(batch.batchId)">
                      {{batch.quantity}}&nbsp;<span [innerHTML]="batch.unit?.name"></span>
                    </ng-container>
                    <ng-container *ngIf="isSplitBatchOnList(batch.batchId)">
                      <div class="row no-gutters align-items-center">
                        <div class="col-10 pr-2">
                          <ssab-cx-item-stepper
                            [isValueChangeable]="true"
                            [showButtons]="true"
                            [max]="batch.quantity"
                            [min]="minQuantity"
                            [value]="alignBatchQuantity(batch.quantity, cart.entries, batch.batchId)"
                            [step]="minQuantity"
                            [stepperTimeout]="0"
                            [id]="product.code+'_stepper_consignment_item'"
                            (update)="quantityChange($event, batch.batchId)"
                            class="stepper-price"
                          ></ssab-cx-item-stepper>
                        </div>
                        <div class="col-2 text-right">
                          <span [innerHTML]="batch.unit?.name"></span>
                        </div>
                      </div>
                    </ng-container>
                  </td>
                  <td [ngClass]="{'text-danger': (daysInStockThreshold$ | async) <= batch.daysInStock}">
                    {{batch.daysInStock}}
                  </td>
                  <td >
                    <ssab-cx-bundle-split-toggle *ngIf="batch.canSplitBatch"
                      [id]="batch.batchId+'_toggle'"
                      [label]="'ssab.user.bundleSplit.consignment.toggle'"
                      (click)="selectRow($event, batch)"
                      (updateSplitBundle)="addRemoveToSplitBatches($event, batch.batchId)"
                    ></ssab-cx-bundle-split-toggle>
                  </td>
                </tr>
              </tbody>
            </table>
            <div #splitBundleReadyElem *ngIf="canSplitBundle">
              <ng-container *ngFor="let batch of batches">
                <span [id]="batch.batchId" *ngIf="batch.addedToCart > 0"></span>
              </ng-container>
            </div>
          </div>

          <div class="text-right pt-3 pb-4 pr-3 row">
            <div *ngIf="cart.entries?.length > 0" class="col-6">
              <button [routerLink]="{ cxRoute: 'cart' } | cxUrl" (click)="close()"
                      class="btn btn-primary btn-default-height btn-block">
                {{ 'ssab.consignment.list.proceedtocheckout' | cxTranslate | uppercase}}
              </button>
            </div>
            <div class="col-6 ml-auto">
              <button class="btn btn-primary btn-default-height btn-block" (click)="addToCallOffCart(cart)"
                      [disabled]="addToCallOffCartDisabled(cart)">
                {{ ((cart?.consignment || cart?.entries?.length === 0) ? 'ssab.consignment.list.addToCart' : 'ssab.consignment.list.continue') | cxTranslate | uppercase}}
              </button>
            </div>
          </div>
          <span class="text-center text-error" *ngIf="hasEntryWithWarehouseDifferent(cart.entries)">
              {{'ssab.consignment.list.error.oneLocation' | cxTranslate}}
            </span>
          <span class="text-center text-error" *ngIf="!cart.consignment && cart.entries?.length > 0">
              {{'ssab.consignment.list.error.cart' | cxTranslate}}
          </span>
        </div>
      </ng-container>

      <ng-template #loadingConsignmentList>
        <div class="p-2 text-center empty-data-div-modal">
    <span class="float-right icon-md ssab-icon icon-navigation-close icon-inline icon-cursor"
          (click)="close()"></span>
          <h3>{{ 'ssab.consignment.list.loading' | cxTranslate}}...</h3>

        </div>
      </ng-template>

      <ng-template #emptyConsignmentList>
        <div class="p-2 text-center">
    <span class="float-right icon-md ssab-icon icon-navigation-close icon-inline icon-cursor"
          (click)="close()"></span>
          <h3 class="empty-data-div">{{ 'ssab.consignment.list.empty' | cxTranslate}}</h3>

        </div>
      </ng-template>
    </div>
  </div>
</div>
