import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {OccEndpointsService} from '@spartacus/core';
import {Observable} from 'rxjs';
import {SsabNotification} from '../../model/misc.model';

const headers = new HttpHeaders({
  'Content-Type': 'application/json',
});

@Injectable({providedIn: 'root'})
export class SsabNotificationsAdapter {
  constructor(
    private http: HttpClient,
    protected occEndpointsService: OccEndpointsService
  ) {
  }

  public getNotifications(userId: string): Observable<SsabNotification[]> {
    const url = this.occEndpointsService.buildUrl('getNotifications', {urlParams: {userId}});
    return this.http.get<SsabNotification[]>(url, {headers}) as Observable<SsabNotification[]>;
  }

  public dismissNotification(userId: string, uid: string): Observable<SsabNotification[]> {
    const url = this.occEndpointsService.buildUrl('dismissNotification', {urlParams: {userId, uid}});
    return this.http.get<SsabNotification[]>(url, {headers}) as Observable<SsabNotification[]>;
  }
}
