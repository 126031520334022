import {I18nModule, UrlModule} from '@spartacus/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {SsabActiveFacetsComponent} from './ssab-active-facets.component';
import {NgModule} from '@angular/core';
import {IconModule, KeyboardFocusModule} from '@spartacus/storefront';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    IconModule,
    KeyboardFocusModule,
    UrlModule,
  ],
  declarations: [SsabActiveFacetsComponent],
  exports: [SsabActiveFacetsComponent],
})
export class SsabActiveFacetsModule {}
