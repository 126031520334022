<h4 class="option-header" *ngIf="labelPlaceholder">
  <i class="ssab-icon icon-navigation-chevron-left icon-inline pr-3"></i>
  {{labelPlaceholder | cxTranslate}}
  <i class="float-right ssab-icon icon-navigation-close icon-inline"></i>
</h4>
<div class="input-group">
  <label class="text-lg" *ngIf="labelPlaceholder">{{labelPlaceholder| cxTranslate}}</label>
  <div class="input-group-append input-calendar-icon text-center">
    <span class="ssab-icon icon-system-calendar icon-inline ml-3"
          (click)="disableDates(d);d.toggle();fixWeekHeader();">
    </span>
  </div>
  <div [formGroup]="parentForm" class="input-label"
       [class.is-invalid]="isNotValidFunction ? isNotValidFunction : isNotValid(nameForm)"
       [ngClass]="hasValue() ? 'has-value' : ''">
    <input
      type="{{typeInput ? typeInput: 'text'}}"
      name="{{nameForm}}"
      formControlName="{{nameForm}}"
      id="{{idName}}"
      (navigate)="navigate($event)"
      (ngModelChange)="onChange($event)"
      [dayTemplate]="customDayTemplate"
      [navigation]="navigation"
      [showWeekNumbers]="showWeekNumbers"
      value="{{getFormattedDate()}}"
      class="form-control"
      placeholder="{{ getFormattedDate()? getFormattedDate(): 'ssab.dateSelect'|cxTranslate}}"
      name="dp"
      placement="bottom"
      readonly
      ngbDatepicker #d="ngbDatepicker"
      (click)="disableDates(d);d.toggle();fixWeekHeader()"
    />
  </div>
  <div class="input-group-append text-center">
    <span class="ssab-icon icon-chevron-down icon-inline"
          (click)="disableDates(d);d.toggle();fixWeekHeader();">
    </span>
  </div>
</div>

<div class="invalid-feedback d-block text-right" *ngIf="(isNotValidFunction ? isNotValidFunction :isNotValid(nameForm)) && errorMessage">
  <span>{{ errorMessage| cxTranslate}}</span>
</div>
<div class="extra-info text-right" *ngIf="extraInfoBottom">
  {{extraInfoBottom| cxTranslate}}
</div>
