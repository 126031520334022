import {Injectable} from '@angular/core';
import {OCRResult, OCRResultWord} from "../../model/document.model";

interface Line {
  content: string;
  boundingBox: any[];
  spans: any[];
}

//Scanner and ocr-Logistics (Move to service-layer)

@Injectable({
  providedIn: 'root',
})
export class SsabScannerDataValidationService {
  constructor() {
  }

  convertMaterialId(materialId: string): string {
    return materialId.replace(/-| /g, '');
  }

  //Edge-cases for scanner
  validateSerialNumber(serialNumber: string): string {
    switch (true) {
      case serialNumber.includes('HCH'):
        serialNumber = serialNumber.slice(1);
        break;
      case serialNumber.includes('p.ssab.com/g/'):
        serialNumber = serialNumber.substring(serialNumber.length - 10);
        break;
      case serialNumber.startsWith('H'):
      case serialNumber.startsWith('S'):
      case serialNumber.startsWith('Q'):
      case serialNumber.startsWith('V'):
        serialNumber = serialNumber.slice(1);
        break;
      case serialNumber.endsWith('00M'):
        serialNumber = serialNumber.slice(0, -3);
        break;
      case serialNumber.endsWith('00A'):
        serialNumber = serialNumber.slice(0, -3);
        break;
      case serialNumber.endsWith('00B'):
        serialNumber = serialNumber.slice(0, -3);
        break;
      default:
        break;
    }

    return serialNumber;
  }

  //Edge-cases for OCR
  validateOCR(data: OCRResult): string {
    let serialNumber = '';
    data.readResult.pages.flatMap(p => p.lines)
      .map((line: Line) => line.content)
      .filter((content: string) => {
        switch (true) {
          case content.includes('SP'):
            const item = content.replace(/\s/g, '');
            let endIndex = item.indexOf('SP');
            let result = item.substring(0, endIndex);
            let finalResult = result.substring(0, 5) + result.substring(6);
            serialNumber = finalResult.substring(0, 8);

            break;
          case content.includes('ITZ'):
            serialNumber = content.replace(/\s/g, '');
            break;
          case /^[a-zA-Z0-9]{6}-[a-zA-Z0-9]{3}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{3}$/.test(
            content
          ):
            const parts = content.split('-');
            if (parts.length === 4) {
              parts.splice(1, 1);
              serialNumber = parts.join('-').replace(/-/g, '').slice(0, -3);
            }

            break;
          case content.includes('SSAB USA') && !serialNumber:
            const word = content.replace(/\s/g, '').replace('SSABUSA', '');
            serialNumber = word;
            break;
          case content.includes('SSAB') &&
          !content.includes('MO SSAB') &&
          !serialNumber &&
          !content.includes('DOMEX'):
            serialNumber = content.replace(/\s/g, '').replace('SSAB', '');
            break;
          case content.includes(' A ') ||
          content.includes(' 4 ') ||
          content.includes(' R '):
            serialNumber = content
              .replace(' A ', '')
              .replace(' 4 ', '')
              .replace(' R ', '')
              .substring(0, 8);
            break;
          default:
            return false;
        }
        return true;
      });

    if (!serialNumber) {
      const allContent = data.readResult.pages.flatMap(p => p.words)
        .map((item: any) => item.content)
        .join(' ')
        .replace(/\s/g, '');
      const result = allContent.substring(0, allContent.indexOf('SP'));

      serialNumber =
        result.substring(0, 5) + result.substring(6).replace(/\D/g, '');
    }
    if (!serialNumber) {
      data.readResult.pages.flatMap(p => p.words)
        .map((item: any) => {
          if (/^P\d+/.test(item.content)) {
            serialNumber = item.content;
          }
          if (
            item.content.length === 13 &&
            item.content.split('-').length === 2
          ) {
            serialNumber = item.content.replace(/-/g, '');
          }
          if (/^\d+$/.test(item.content) && item.content.length === 7) {
            serialNumber = item.content;
          }
          if (/^\d+$/.test(item.content) && item.content.length === 8) {
            serialNumber = item.content;
          }
        });
    }
    if (!serialNumber) {
      const result: string = this.findStringWithOnlyNumbers(data.readResult.pages.flatMap(p => p.words));
      serialNumber = result;
    }

    return serialNumber;
  }

  findStringWithOnlyNumbers(array: OCRResultWord[]) {
    let result = '';
    array.find((str: any) => {
      if (/^\d+$/.test(str.content) && str.content > 3) {
        result = str.content;
      }
    });
    return result.replace(/\s/g, '').replace(/\D/g, '');
  }
}
