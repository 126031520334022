import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {OCC_USER_ID_CURRENT, OccEndpointsService, UserIdService} from '@spartacus/core';
import {SsabSearchCriteria} from '../../../model/misc.model';
import {switchMap, take} from 'rxjs/operators';
import {SsabOrderEntry} from "../../../model/cart.model";

const headers = new HttpHeaders({
  'Content-Type': 'application/json',
});

@Injectable({
  providedIn: 'root',
})
export class SsabOccGtmAdapter {

  constructor(
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected userIdService: UserIdService
  ) {
  }

  public getCartEntryData(orderEntry: String, doubleQuantity: number) {
    const url = this.occEndpointsService.buildUrl(
      'getGTMDeletedCartEntry',
      {queryParams: {orderEntry, doubleQuantity}}
    );
    return this.http
      .get<any[]>(url, {headers});
  }

  public getProducts(products: string[], pageLabel?: string, quantity?: number): Observable<any[]> {
    const url = this.occEndpointsService.buildUrl(
      'getGTMProducts',
      {queryParams: {products, pageLabel, quantity}}
    );
    return this.http
      .get<any[]>(url, {headers});
  }

  public getProductsWithPosition(products: string[], position?: number): Observable<any[]> {
    const url = this.occEndpointsService.buildUrl(
      'getGTMProducts',
      {queryParams: {products, position}}
    );
    return this.http
      .get<any[]>(url, {headers});
  }

  public getCartEntries(userId: string = OCC_USER_ID_CURRENT, cartId: string, entryNumber?: number): Observable<any[]> {
    const url = this.occEndpointsService.buildUrl(
      'getGTMCartEntries',
      {
        urlParams: {userId, cartId},
        queryParams: {entryNumber}
      }
    );
    return this.http
      .get<any[]>(url, {headers});
  }

  public getContactSalesEntries(cartCode: string): Observable<any[]> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap(user => {
        const url = this.occEndpointsService.buildUrl(
          'getGTMContactSalesEntries',
          {
            urlParams: {userId: user},
            queryParams: {cartCode}
          }
        );
        return this.http
          .get<any[]>(url, {headers});
      })
    );

  }


  public getUser(event: string): Observable<any[]> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap(user => {
        const url = this.occEndpointsService.buildUrl(
          'getUser',
          {
            urlParams: {userId: user},
            queryParams: {event}
          }
        );
        return this.http
          .get<any[]>(url, {headers});
      })
    );
  }
}
