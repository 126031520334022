import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewChild} from '@angular/core';
import {SsabProduct} from '../../../model/product.model';
import {Observable, of, Subscription} from 'rxjs';
import {SsabCurrentProductService} from '../../../service/product/ssab-current-product.service';
import {SsabProductPriceComponent} from '../product-price-data/ssab-product-price.component';
import {Actions} from '@ngrx/effects';
import {SiteContextActions} from '@spartacus/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SsabGtmService} from '../../../service/analytics/gtm/ssab-gtm.service';

@Component({
  selector: 'ssab-cx-product-summary',
  templateUrl: './ssab-product-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabProductSummaryComponent implements OnDestroy {

  @ViewChild('productPrice') productPrice: SsabProductPriceComponent;
  product$: Observable<SsabProduct>;
  subscriptions: Subscription = new Subscription();

  constructor(protected currentProductService: SsabCurrentProductService,
              protected router: Router,
              protected gtmService: SsabGtmService,
              protected route: ActivatedRoute,
              protected cdr: ChangeDetectorRef,
              protected actions$: Actions) {
    this.product$ = this.currentProductService.getProduct();
    this.subscriptions.add(
      this.actions$.subscribe((action) => {
        switch (action.type) {
          case SiteContextActions.CURRENCY_CHANGE:
          case SiteContextActions.LANGUAGE_CHANGE:
            this.reload();
            break;
        }
      })
    );
  }

  public reload(): void {
    this.router.navigated = false;
    this.router.navigate(
      ['./'],
      {
        relativeTo: this.route
      });
  }

  onSelectedUnitChange(data: SsabProduct): void {
    this.product$ = of(data);
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
