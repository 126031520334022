import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CmsConfig, ConfigModule} from "@spartacus/core";
import {CMSFooterContainerComponent} from './ssab-footer-container.component';
import {SsabFooterColumnModule} from '../footer-column/ssab-footer-column.module';
import {SsabCoilsModule} from "../../coils-comparison/ssab-coils.module";

@NgModule({
  imports: [
    CommonModule,
    SsabFooterColumnModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CMSFooterContainer: {
          component: CMSFooterContainerComponent
        }
      },
    } as CmsConfig),
    SsabCoilsModule,
  ],
  declarations: [CMSFooterContainerComponent],
  exports: [CMSFooterContainerComponent]
})
export class SsabFooterContainerModule {
}
