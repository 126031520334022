import {SsabAddToCartModule} from '../../cart/add-to-cart/ssab-add-to-cart.module';
import {ssabDefaultViewConfig} from '../../../service/product/ssab-default-view-config';
import {
  ListNavigationModule,
  MediaModule,
  ProductListModule,
  ViewConfig
} from '@spartacus/storefront';
import {RouterModule} from '@angular/router';
import { AddToCartModule } from '@spartacus/cart/base/components/add-to-cart';
import {SsabProductListItemComponent} from './product-list-item/ssab-product-list-item.component';
import {CommonModule} from '@angular/common';
import {SsabProductPriceModule} from '../product-price-data/ssab-product-price.module';
import {NgModule} from '@angular/core';
import {CmsConfig, FeaturesConfigModule, I18nModule, provideDefaultConfig, UrlModule} from '@spartacus/core';
import {FormsModule} from '@angular/forms';
import {SsabProductListComponent} from './container/ssab-product-list.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {SsabItemStepperModule} from './item-stepper/ssab-item-stepper.module';
import {SsabAddToWishListModule} from '../../cart/add-to-wishlist/ssab-add-to-wish-list.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {SsabPaginationBottomModule} from '../../../shared/pagination-bottom/ssab-pagination-bottom.module';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        InfiniteScrollModule,
        FeaturesConfigModule,
        AddToCartModule,
        UrlModule,
        MediaModule,
        ListNavigationModule,
        ProductListModule,
        SsabItemStepperModule,
        NgSelectModule,
        FormsModule,
        SsabAddToCartModule,
        SsabProductPriceModule,
        I18nModule,
        SsabAddToWishListModule,
        SsabPaginationBottomModule
    ],
    providers: [
        provideDefaultConfig(<ViewConfig>ssabDefaultViewConfig),
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                CMSProductListComponent: {
                    component: SsabProductListComponent,
                },
                ProductGridComponent: {
                    component: SsabProductListComponent,
                },
                SearchResultsListComponent: {
                    component: SsabProductListComponent,
                },
            },
        }),
    ],
    declarations: [
        SsabProductListItemComponent,
        SsabProductListComponent
    ],
    exports: [
        SsabProductListItemComponent,
        SsabProductListComponent
    ]
})
export class SsabProductListModule {
}
