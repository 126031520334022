<ng-container *ngIf="orderData$ | async as orderData">
  <scanner-header-main title=" {{'ssab.scanner.product.orderId'|cxTranslate}} {{ orderNumber }}"
                       [backUrl]="'scanner/product'"
                       [backUrlParams]="{certificateId:originalCertificateId, searchTerm:searchTerm}">

  </scanner-header-main>

  <div class="bg-white pb-4">
    <div class="pb-4">
      <table class="table">
        <tr class="header">
          <th class="first text-left">{{'ssab.scanner.product.materialId'|cxTranslate}}</th>
          <th class="text-left">{{'ssab.scanner.product.name'|cxTranslate}}</th>
          <th class="last text-left" colspan="2">{{'ssab.scanner.product.documentDate'|cxTranslate}}</th>
          <th></th>
        </tr>

        <tr
          class="content no-border"
          *ngFor="let item of orderData.documents"
        >
          <td class="first">
            <ul class="list-unstyled text-left text-sm-right">
              <li class="" *ngFor="let i of item.materialId.split(',')">
                {{i}}
              </li>
            </ul>
          </td>
          <td class="text-left">{{ item.grade }}</td>
          <td class="text-left">{{ item.documentDate| cxDate:  (getDateFormat()|async)  }}</td>
          <td class="last">
            <button [routerLink]="'/scanner/product'" [queryParams]="{certificateId:item.certificateId,noBackLink:true, searchTerm: convertMaterialId(item.materialId)}">
              <i class="ssab-icon icon-navigation-chevron-right icon-inline"></i>
            </button>
          </td>
        </tr>
      </table>
    </div>

    <div class="footer">
      <button class="btn btn-primary btn-default-height btn-block" (click)="onScanMoreProducts()">
        <i class="ssab-icon icon-scanner icon-white icon-inline mr-2"></i>
        <span>{{'ssab.scanner.qr.scanAgain'|cxTranslate}}</span>
      </button>
    </div>
  </div>

</ng-container>
