import {ModuleWithProviders, NgModule} from '@angular/core';
import {provideDefaultConfig} from '@spartacus/core';
import {ssabOccNotificationsConfig} from './ssab-occ-notifications-config';
import {SsabNotificationsAdapter} from './ssab-occ-notifications.adapter';

@NgModule({
  providers: [
    provideDefaultConfig(ssabOccNotificationsConfig),
    {
      provide: SsabNotificationsAdapter,
      useClass: SsabNotificationsAdapter
    }
  ]
})
export class SsabNotificationsOccModule {
  static forRoot(): ModuleWithProviders<SsabNotificationsOccModule> {
    return {
      ngModule: SsabNotificationsOccModule
    };
  }
}
