import {Component, Inject, PLATFORM_ID} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import {
  GlobalMessageEntities, GlobalMessageService, GlobalMessageType, Translatable
} from '@spartacus/core';
import { Observable } from 'rxjs';
import {GlobalMessageComponent, ICON_TYPE} from '@spartacus/storefront';

@Component({
  selector: 'ssab-cx-global-message',
  templateUrl: './ssab-global-message.component.html',
})
export class SsabGlobalMessageComponent extends  GlobalMessageComponent{
  iconTypes = ICON_TYPE;
  messageType: typeof GlobalMessageType = GlobalMessageType;

  constructor(protected globalMessageService: GlobalMessageService,
              @Inject(PLATFORM_ID) private platformId: Object) {
    super(globalMessageService)
  }

  protected validateDomIsRenderedAndMessageValid(key: Translatable): boolean {
    return isPlatformBrowser(this.platformId);
  }
}
