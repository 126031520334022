import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {UntypedFormGroup} from '@angular/forms';
import {WindowRef} from '@spartacus/core';
import {hideSearchOptions, toggleSearchOption} from '../../../shared/utils/functions/ssab-functions-utils';
import {NgSelectComponent} from '@ng-select/ng-select';
import {SsabDocolSearchResponse} from '../../../../model/docol.model';
import {SsabInputRangeComponent} from '../../../../shared/input-range/ssab-input-range.component';

@Component({
  selector: 'ssab-cx-material-data-form',
  templateUrl: './ssab-material-data-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabMaterialDataFormComponent{

  @Input() docolProductList: SsabDocolSearchResponse;
  @Input() searchDocolForm: UntypedFormGroup;
  @Output() resetForm = new EventEmitter<void>();

  constructor(
    protected cdr: ChangeDetectorRef,
    protected router: Router,
    private activatedRoute: ActivatedRoute,
    protected aWinRef: WindowRef
  ) {
  }

  hideFilterOptions($event: MouseEvent): void {
    const innerSearch = ($event.target as HTMLElement)
      .closest('.cx-material-search')
      .querySelector('.cx-material-data-form');
    innerSearch.classList.add('d-none');
  }

  search(): void {
    const queryParams: Params = this.setQueryParams();
    Object.keys(queryParams).forEach(key => {
      if (queryParams[key] == null || (Array.isArray(queryParams[key]) && queryParams[key].length === 0)) {
        delete queryParams[key];
      }
    });
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams
      });
  }

  setQueryParams() {
    return {
      keyword:
        this.searchDocolForm.controls.keyword.value ? this.searchDocolForm.controls.keyword.value : null,
      types:
        this.searchDocolForm.controls.types.value ? this.searchDocolForm.controls.types.value : null,
      standards:
        this.searchDocolForm.controls.standards.value ? this.searchDocolForm.controls.standards.value : null,
      tensileStress:
        (this.searchDocolForm.get('tensileStress.sliderControl').value &&
          (this.searchDocolForm.get('tensileStress.sliderControl').value[0] !== this.docolProductList.tensileStress.to ||
            this.searchDocolForm.get('tensileStress.sliderControl').value[1] !== this.docolProductList.tensileStress.from)) ? this.searchDocolForm.get('tensileStress.sliderControl').value : null,
      yieldStress:
        (this.searchDocolForm.get('yieldStress.sliderControl').value &&
          (this.searchDocolForm.get('yieldStress.sliderControl').value[0] !== this.docolProductList.yieldStress.to ||
            this.searchDocolForm.get('yieldStress.sliderControl').value[1] !== this.docolProductList.yieldStress.from)) ? this.searchDocolForm.get('yieldStress.sliderControl').value : null
    };
  }

  afterEnter(keyword: string): void {
    const queryParams: Params = {keyword: keyword ? keyword : null};
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams,
        queryParamsHandling: 'merge'
      }).then(() => {
      this.aWinRef.location.reload();
    });
  }

  reset(): void {
    this.resetForm.emit();
    this.search();
  }

  isInvalid(fieldName: string): boolean {
    return this.searchDocolForm.get(fieldName) && this.searchDocolForm.get(fieldName).invalid;
  }

  hideSearchOptions(divElement: HTMLDivElement, inputRange?: SsabInputRangeComponent): void {
    hideSearchOptions(divElement, inputRange);
  }

  toggleFilter(divElement: HTMLDivElement): void {
    toggleSearchOption(divElement);
  }

  resetSelect(ngSelectComponent: NgSelectComponent): void {
    ngSelectComponent.clearModel();
    ngSelectComponent.close();
  }
}

