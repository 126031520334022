<div class="show-password text-right" *ngIf="typeInput && typeInput == 'password'">
  <a (click)="toggleVisibility(idName)">{{ 'updatePasswordForm.showPassword' | cxTranslate}}</a>
</div>

<ng-container *ngIf="parentForm; else formlessInput">
  <div [formGroup]="parentForm" class="input-label"
       [class.is-invalid]="isNotValidFunction ? isNotValidFunction : isNotValid(nameForm)"
       [ngClass]="hasValue() ? 'has-value' : ''">
    <input
      *ngIf="!isTextArea"
      type="{{typeInput ? typeInput: 'text'}}"
      class="form-control ssab-input mb-0"
      name="{{nameForm}}"
      placeholder="{{labelPlaceholder ? ( labelPlaceholder |cxTranslate) : ''}}"
      formControlName="{{nameForm}}"
      id="{{idName}}"
      [readOnly]="readonly"
      (blur)="onBlur($event)"
      (change)="onChange($event)"
      (keypress)="onKeypress($event)"
      (keydown.enter)="onEnter($event.target.value)"
      maxlength="{{maxlength}}"
    />
    <textarea
      *ngIf="isTextArea"
      class="form-control ssab-input"
      name="{{nameForm}}"
      placeholder="{{labelPlaceholder ? ( labelPlaceholder |cxTranslate) : ''}}"
      formControlName="{{nameForm}}"
      id="{{idName}}"
      [readOnly]="readonly"
      (blur)="onBlur($event)"
      (change)="onChange($event)"
      (keypress)="onKeypress($event)"
      (keydown.enter)="onEnter($event.target.value)"
    ></textarea>
  </div>
</ng-container>

<ng-template #formlessInput>
  <div class="input-label" [ngClass]="hasValue() ? 'has-value' : ''">
    <input
      *ngIf="!isTextArea"
      type="{{typeInput ? typeInput: 'text'}}"
      class="form-control ssab-input"
      name="{{nameForm}}"
      placeholder="{{labelPlaceholder ? ( labelPlaceholder |cxTranslate) : ''}}"
      value="{{inputValue}}"
      id="{{idName}}"
      [readOnly]="readonly"
      (blur)="onBlur($event)"
      (change)="onChange($event)"
      (keypress)="onKeypress($event)"
      (keydown.enter)="onEnter($event.target.value)"
    />
    <textarea
      *ngIf="isTextArea"
      class="form-control ssab-input"
      name="{{nameForm}}"
      value="{{inputValue}}"
      id="{{idName}}"
      [readOnly]="readonly"
      (blur)="onBlur($event)"
      (change)="onChange($event)"
      (keypress)="onKeypress($event)"
      (keydown.enter)="onEnter($event.target.value)"
    ></textarea>
  </div>
</ng-template>

<div class="invalid-feedback d-block text-right" *ngIf="(isNotValidFunction ? isNotValidFunction : isNotValid(nameForm)) && errorMessage">
  <span>{{ errorMessage| cxTranslate}}</span>
</div>
<div class="extra-info text-right" *ngIf="extraInfoBottom">
  {{extraInfoBottom| cxTranslate}}
</div>
