import {Injectable, Optional} from '@angular/core';
import {
  BasePageMetaResolver,
  PageLinkService,
  ProductPageMetaResolver,
  ProductScope,
  ProductService,
  RoutingService,
  TranslationService
} from '@spartacus/core';
import {distinctUntilChanged, filter, map, switchMap, take} from 'rxjs/operators';

import {Observable} from "rxjs";
import {SsabProduct} from '../../model/product.model';

@Injectable({
  providedIn: 'root'
})
export class SsabProductPageMetaResolver extends ProductPageMetaResolver {

  constructor(
    protected routingService: RoutingService,
    protected productService: ProductService,
    protected translation: TranslationService,
    protected basePageMetaResolver: BasePageMetaResolver,
    protected pageLinkService: PageLinkService
  ) {
    super(routingService, productService, translation, basePageMetaResolver, pageLinkService);
  }

  getPriority(): number {
    return 9;
  }

  resolveTitle(): Observable<string> {
    return this.routingService.getRouterState().pipe(
      distinctUntilChanged((x, y) => {
        // router emits new value also when the anticipated `nextState` changes
        // but we want to perform search only when current url changes
        return x.state.url === y.state.url;
      }),
      map((state) => state.state.params['productCode']),
      filter((code) => !!code),
      take(1),
      distinctUntilChanged(),
      switchMap((code) => this.productService.get(code, ProductScope.DETAILS)),
      filter(Boolean)
    ).pipe(
      map((p: SsabProduct) =>
        p.name
      )
    );
  }

}
