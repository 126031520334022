import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SsabInvoiceDocument} from '../../../../model/document.model';
import {SsabDocumentsBaseComponent} from '../documents/ssab-documents-base-component';
import {SsabGlobalconfigurationService} from "../../../../service/general/configuration/ssab-globalconfiguration.service";

@Component({
  selector: 'ssab-cx-invoices',
  templateUrl: './ssab-invoices.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabInvoicesComponent extends SsabDocumentsBaseComponent {
  @Input() invoiceDocuments: SsabInvoiceDocument[];

  constructor(protected globalConfigurationService: SsabGlobalconfigurationService,) {
    super(globalConfigurationService);
  }
}

