import {CmsConfig, ConfigModule, I18nModule, NotAuthGuard, UrlModule} from '@spartacus/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormErrorsModule, SpinnerModule} from '@spartacus/storefront';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SsabAzureAuthenticationModule} from '../../../shared/authentication/ssab-azure-authentication.module';
import {SsabLoginFormComponent} from './ssab-login-form.component';
import {LoginFormModule} from '@spartacus/user/account/components';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    SpinnerModule,
    I18nModule,
    FormErrorsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ReturningCustomerLoginComponent: {
          component: SsabLoginFormComponent,
          guards: [NotAuthGuard],
        },
      },
    } as CmsConfig),
    SsabAzureAuthenticationModule
  ],
  declarations: [SsabLoginFormComponent],
  exports: [SsabLoginFormComponent]
})
export class SsabLoginFormModule extends LoginFormModule {
}
