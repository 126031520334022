<ng-container *ngIf="state$ | async as state">
  <button class="heading" (click)="toggleGroup($event)">
    <span>{{ ssabFacet.name }}</span>
    <cx-icon class="collapse-icon ssab-icon icon-navigation-minus-small icon-sm"></cx-icon>
    <cx-icon class="expand-icon ssab-icon icon-navigation-plus-small icon-sm"></cx-icon>
  </button>

  <ng-container *ngIf="false && !ssabFacet.multiSelect && !ssabFacet.range && !ssabFacet.search; else multiSelectFacet">
    <div class="facet-dropdown pt-3 pb-2"
         #facetValue>
      <ng-select [items]="ssabFacet.values"
                 [clearable]="false"
                 dropdownPosition="bottom"
                 bindLabel="name"
                 bindValue="value">
        <ng-template ng-option-tmp let-item="item">
          <a
            routerLink="./"
            [queryParams]="getLinkParams(item)"
            [class.selected]="item.selected"
            [cxFocus]="item.name"
            (keydown.space)="openLink($event)"
            (click)="removeModalClassToBody()"
          >
            <span>
              <span class="label">{{ item.name }}</span>
              <span class="count"> &nbsp; ({{ item.count }})</span>
            </span>
          </a>
        </ng-template>
        <ng-template ng-label-tmp let-item="item">
          {{item.name}}
        </ng-template>
      </ng-select>
    </div>
  </ng-container>

  <ng-template #multiSelectFacet>
    <ng-container *ngIf="!ssabFacet.range && !ssabFacet.search; else customRangeFacet">
      <a
        *ngFor="let value of ssabFacet.values | slice: 0:state.topVisible"
        #facetValue
        routerLink="./"
        [queryParams]="getLinkParams(value)"
        class="value"
        [class.selected]="value.selected"
        [cxFocus]="value.name"
        (keydown.space)="openLink($event)"
        (click)="removeModalClassToBody()"
      >
    <span>
      <span class="label">{{ value.name }}</span>
      <span class="count">{{ value.count }}</span>
    </span>
      </a>

      <div class="more">
        <a
          *ngFor="let value of ssabFacet.values | slice: state.topVisible:state.maxVisible      "
          #facetValue
          routerLink="./"
          [queryParams]="getLinkParams(value)"
          class="value"
          [class.selected]="value.selected"
          [cxFocus]="value.name"
          (keydown.space)="openLink($event)"
          (click)="removeModalClassToBody()"
        >
      <span
      >{{ value.name }}<span class="count">{{ value.count }}</span></span
      >
        </a>

        <button
          *ngIf="state.maxVisible > state.topVisible"
          (click)="decreaseVisibleValues()"
          class="cx-action-link"
          cxFocus="moreorless"
        >
          {{ 'productList.showLess' | cxTranslate }}
        </button>

        <button
          *ngIf="state.maxVisible > 0 && state.maxVisible < ssabFacet.values.length"
          (click)="increaseVisibleValues()"
          class="cx-action-link"
          cxFocus="moreorless"
        >
          {{ 'productList.showMore' | cxTranslate }}
        </button>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #customRangeFacet>
    <ng-container *ngIf="!ssabFacet.search; else inputSearchFacet">
      <div #facetValue class="value facet-range row pt-3 vertical-align-item">
        <ssab-cx-input class="col-5 pr-0"
                       [typeInput]="'number'"
                       [idName]="'minQty_'+ ssabFacet.code"
                       [inputValue]="ssabFacet.rangeMin"
                       [labelPlaceholder]="'common.min'"></ssab-cx-input>
        <span class="col-2 ssab-icon icon-inline icon-navigation-minus-large"></span>
        <ssab-cx-input class="col-5 pl-0"
                       [typeInput]="'number'"
                       [idName]="'maxQty_' + ssabFacet.code"
                       [inputValue]="ssabFacet.rangeMax"
                       [labelPlaceholder]="'common.max'"></ssab-cx-input>
        <div class="col-12 pt-2 pb-2">
          <button class="btn-primary btn btn-block"
                  (click)="getLinkParamsForFacetRange(facetValue, ssabFacet)">
            {{'common.set' | cxTranslate}}
          </button>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #inputSearchFacet>
    <div #facetValue class="value facet-input-search  col-no-margin pt-3">
      <ng-select
        class="col-no-padding"
        (change)="clickInputSearch($event)"
        [clearable]="true"
        [closeOnSelect]="true"
        [items]="ssabFacet.values"
        [searchable]="true"
        dropdownPosition="bottom"
        bindLabel="name"
        bindValue="query"
      ></ng-select>
    </div>
  </ng-template>
</ng-container>
