export const ssabOccGtmConfig = {
  backend: {
    occ: {
      endpoints: {
        getGTMDeletedCartEntry: '/gtm/deletedEntry',
        getGTMProducts: '/gtm/products?customerEmulationParameter=true',
        getGTMCartEntries: '/users/${userId}/carts/${cartId}/gtm/cart',
        getGTMConfirmationEntries: '/users/${userId}/gtm/orderEntries',
        getGTMContactSalesEntries: '/users/${userId}/gtm/contactSales',
        getUser: '/users/${userId}/gtm/user'
      }
    }
  }
};

