import {ProductScope} from '@spartacus/core';


const PRODUCT_FIELDS = 'fields=DEFAULT,buttonAction,images(FULL),classifications,manufacturer,numberOfReviews,categories(FULL),baseOptions,baseProduct,variantOptions,variantType,stock(FULL),description,availableForPickup,code,url,splitSurcharge,price(DEFAULT),numberOfReviews,manufacturer,categories(FULL),priceRange,multidimensional,configuratorType,configurable,manufacturerTypeDescription,tags,images(FULL),unit(DEFAULT),productUnitConversions(FULL),unit(DEFAULT),unitOfWeight(DEFAULT),nonSapProduct,bundleSplitActivatedByDefault,showOfflineProductText,productDataSheetUrl&spinner=true&customerEmulationParameter=true';

export const ssabOccProductConfig = {
  backend: {
    occ: {
      endpoints: {
        product: {
          default: 'products/${productCode}?' + PRODUCT_FIELDS,
          details: 'products/${productCode}?' + PRODUCT_FIELDS,
          variants: 'products/${productCode}?' + PRODUCT_FIELDS,
          list: 'products/${productCode}?fields=code,name,summary,price(formattedValue),images(DEFAULT,galleryIndex),buttonAction&customerEmulationParameter=true',
        },
        productReferences: 'products/${productCode}/references?fields=DEFAULT,references(target(images(FULL)))&customerEmulationParameter=true',
        // tslint:disable:max-line-length
        productSearch:
          'products/search?fields=products(code,name,summary,density8,suggestiveMode,price(FULL),splitSurcharge,images(DEFAULT),stock(FULL),selectableStocks(FULL),purchasable,buttonAction,variantOptions,productUnitConversions(FULL),unit(DEFAULT),comparisonPrice(FULL),comparisonPriceEur(FULL),comparisonUnit,unitOfWeight,classifications(name,code),nonSapProduct,productForm,customerArticleIds,myFavorite,bundleSplitActivatedByDefault, showOfflineProductText),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery&customerEmulationParameter=true&spinner=true',
        // tslint:enable
        productSuggestions: 'products/suggestions?customerEmulationParameter=true',
        catalogAvailability: 'products/${productCode}/${unit}/catalogAvailability/${warehouseCode}?customerEmulationParameter=true&spinner=true',
        catalogPrice: 'products/${productCode}/${unit}/catalogPrice?customerEmulationParameter=true&' + PRODUCT_FIELDS,
        stockAvaliabilityDownload: 'products/availability/download?fields=products(code,name,summary,price(FULL),splitSurcharge,images(DEFAULT),stock(DEFAULT),purchasable,variantOptions,productUnitConversions(FULL),unit(DEFAULT),comparisonPrice(FULL),comparisonPriceEur(FULL),comparisonUnit,unitOfWeight,classifications(name,code)),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery&customerEmulationParameter=true&spinner=true',
        getDocolList: 'products/docol/search?spinner=true&customerEmulationParameter=true',
        coilSearch: 'coils/search?spinner=true&customerEmulationParameter=true',
        coilCompare: 'coils/compare?customerEmulationParameter=true',
      },
    },
    loadingScopes: {
      product: {
        details: {
          include: [ProductScope.LIST, ProductScope.VARIANTS],
        },
      },
    },
  },
};
