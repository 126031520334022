import {Component} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {SsabCMSBannerGridTileComponent} from '../../../../model/cms.model';
import {Observable} from "rxjs";

@Component({
  selector: 'ssab-cx-banner-grid-tile',
  templateUrl: './ssab-banner-grid-tile.component.html'
})
export class SsabBannerGridTileComponent {

  data$: Observable<SsabCMSBannerGridTileComponent>;

  constructor(protected component: CmsComponentData<SsabCMSBannerGridTileComponent>) {
    this.data$ = this.component.data$;
  }

  isFullGridTile(fullGridTile): boolean {
    return fullGridTile === 'true';
  }
}
