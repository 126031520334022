import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {Order} from '@spartacus/order/root';
import { OrderDetailsService } from '@spartacus/order/components';
import {SsabUserService} from '../../../../service/user/ssab-user.service';


@Component({
  selector: 'ssab-cx-order-details-items',
  templateUrl: './ssab-order-detail-items.component.html',
})
export class SsabOrderDetailItemsComponent implements OnInit {

  order$: Observable<Order>;
  showPrices$: Observable<boolean>;

  constructor(protected orderDetailsService: OrderDetailsService, protected userService: SsabUserService) {
  }

  ngOnInit() {
    this.order$ = this.orderDetailsService.getOrderDetails();
    this.showPrices$ = this.userService.isShowPrices();
  }

}
