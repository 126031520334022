<div class="ssab-modal-md ssab-modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <ng-container *ngIf="!isSubscriptionFail && !isSubscriptionSuccess">
        <span class="w-100 d-flex justify-content-end">
          <i class="ssab-icon icon-navigation-close icon-md icon-cursor"
             aria-label="Close" (click)="dismiss()"></i>
        </span>
        <div class="text-center">
          <h3>{{ 'ssab.notifyme.productavailability.modal.title' | cxTranslate }}</h3>
          <div class="w-100 pt-4" *ngIf="user$ | async as user">
            <span [innerHTML]="'ssab.notifyme.productavailability.modal.confirmation' | cxTranslate"></span>
            <span class="text-primary"> {{ user.email }}</span>
          </div>
          <div class="pt-4 pr-md-5 pl-md-5">
            <button class="btn btn-primary btn-default-height text-uppercase mt-4 btn-block" (click)="sendRequest()">
              <h5 class="mb-0">{{ 'ssab.notifyme.productavailability.modal.sendBtn' | cxTranslate | uppercase}}</h5>
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="isSubscriptionFail">
  <span class="w-100 d-flex justify-content-end">
    <i class="ssab-icon icon-navigation-close icon-md icon-cursor"
       aria-label="Close" (click)="dismiss()"></i>
  </span>
        <div class="text-center pb-4">
          <h3>{{ 'ssab.notifyme.productavailability.modal.error.title' | cxTranslate }}</h3>
          <div class="w-100 pt-4" *ngIf="user$ | async as user">
            <span [innerHTML]="'ssab.notifyme.productavailability.modal.error.text' | cxTranslate"></span>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="isSubscriptionSuccess">
  <span class="w-100 d-flex justify-content-end">
    <i class="ssab-icon icon-navigation-close icon-md icon-cursor"
       aria-label="Close" (click)="dismiss()"></i>
  </span>
        <div class="text-center">
          <h3>{{ 'ssab.notifyme.productavailability.modal.success.title' | cxTranslate }}</h3>
          <div class="pt-4">
            <ng-container *ngIf="user$ | async as user">
              <i class="ssab-icon icon-system-checkbox icon-inline icon-green mr-4"></i>
              <span class="text-opacity" [innerHTML]="'ssab.notifyme.productavailability.modal.success.text' | cxTranslate"></span>
            </ng-container>
          </div>
          <div class="pt-4 pr-5 pl-5">
            <button class="btn btn-primary btn-default-height text-uppercase btn-block" (click)="dismiss()">
              <h5 class="mb-0">{{ 'ssab.notifyme.productavailability.modal.okBtn' | cxTranslate | uppercase}}</h5>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>




