import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SsabCategoryNavigationComponent} from './ssab-category-navigation.component';
import {CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';
import {SsabNavigationUiModule} from '../navigation-ui/ssab-navigation-ui.module';
import {PageSlotModule} from '@spartacus/storefront';

@NgModule({
  providers: [],
  declarations: [SsabCategoryNavigationComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig(<CmsConfig> {
      cmsComponents: {
        CategoryNavigationComponent: {
          component: SsabCategoryNavigationComponent,
        },
      },
    }),
    SsabNavigationUiModule,
    I18nModule,
    PageSlotModule
  ],
  exports: [SsabCategoryNavigationComponent]
})
export class SsabCategoryNavigationModule {
}
