import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {I18nModule} from '@spartacus/core';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {SsabTableHeadComponent} from './ssab-table-head.component';

@NgModule({
    imports: [
        CommonModule,
        I18nModule,
        NgbPopoverModule,
    ],
    providers: [],
    declarations: [SsabTableHeadComponent],
    exports: [SsabTableHeadComponent]
})
export class SsabTableHeadModule {}
