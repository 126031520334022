export const ssabOccGlobalConfiguration = {
  backend: {
    occ: {
      endpoints: {
        getGlobalConfiguration: 'globalConfiguration?customerEmulationParameter=true'
      }
    }
  }
};

