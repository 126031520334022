import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {SsabFloatingScrollbarComponent} from "./ssab-floating-scrollbar.component";

@NgModule({
  imports: [CommonModule],
  declarations: [SsabFloatingScrollbarComponent],
  exports: [SsabFloatingScrollbarComponent],
})
export class SsabFloatingScrollbarModule{}
