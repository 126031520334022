import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {CmsConfig, ConfigModule} from '@spartacus/core';
import {SsabNotFoundComponent} from './ssab-not-found.component';

@NgModule({
  imports: [
    CommonModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        NotFoundPageComponent: {
          component: SsabNotFoundComponent,
        },
      },
    }),
  ],
  declarations: [SsabNotFoundComponent],
  exports: [SsabNotFoundComponent],
})
export class SsabNotFoundModule{}
