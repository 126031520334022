import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {SsabOccGtmAdapter} from './ssab-occ-gtm.adapter';
import {SsabOrderEntry} from "../../../model/cart.model";

@Injectable({
  providedIn: 'root',
})
export class SsabGtmConnector {
  constructor(protected adapter: SsabOccGtmAdapter) {
  }

  public getCartEntry(orderEntry: String, doubleQuantity: number) {
    return this.adapter.getCartEntryData(orderEntry, doubleQuantity)
  }

  public getProducts(
    products: string[],
    pageLabel?: string,
    quantity?: number
  ): Observable<any> {
    return this.adapter.getProducts(products, pageLabel, quantity);
  }

  public getProductsWithPosition(
    products: string[],
    position?: number,
  ): Observable<any> {
    return this.adapter.getProductsWithPosition(products,  position);
  }

  public getCartEntries(
    userId: string,
    cartId: string,
    entryNumber?: number): Observable<any> {
    return this.adapter.getCartEntries(userId, cartId, entryNumber);
  }

  public getContactSalesEntries(cartCode: string): Observable<any> {
    return this.adapter.getContactSalesEntries(cartCode);
  }

  public getUser(
    event: string): Observable<any> {
    return this.adapter.getUser(event);
  }
}
