import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {SsabGtmEffects} from './ssab-gtm.effect';
import {provideDefaultConfig} from '@spartacus/core';
import {ssabOccGtmConfig} from './ssab-occ-gtm-config';


@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([SsabGtmEffects])
  ],
  providers: [provideDefaultConfig(ssabOccGtmConfig)],
})
export class SsabGtmModule {
}
