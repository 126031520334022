import {AsmToggleUiComponent} from '@spartacus/asm/components';
import {Component, ViewEncapsulation} from '@angular/core';


@Component({
  selector: 'ssab-cx-asm-toggle-ui',
  templateUrl: './ssab-asm-toggle-ui.component.html',
  styleUrls: ['./ssab-asm-toggle-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SsabAsmToggleUiComponent extends AsmToggleUiComponent{

}
