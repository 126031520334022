import {I18nModule} from '@spartacus/core';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {IconModule} from '@spartacus/storefront';
import {SsabAddEmailComponent} from './ssab-add-email.component';
import {SsabInputModule} from '../../../shared/input/ssab-input.module';


@NgModule({
  imports: [CommonModule, IconModule, I18nModule, SsabInputModule],
  declarations: [SsabAddEmailComponent],
  exports: [SsabAddEmailComponent],
})
export class SsabAddEmailModule{}
