export const ssabOccI18nConfig = {
  backend: {
    occ: {
      endpoints: {
        i18n: 'translations/${ns}',
        translationBundleVersion: 'translations/version'
      },
    }
  }
};
