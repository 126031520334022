import {
  BASE_SITE_CONTEXT_ID,
  BaseSiteInitializer,
  BaseSiteService,
  ConfigInitializerService,
  getContextParameterDefault, SiteContextConfig,
  WindowRef
} from '@spartacus/core';
import {Inject, Injectable} from '@angular/core';
import {GlobalLogin} from '../../model/misc.model';
import {DOCUMENT} from "@angular/common";

@Injectable({providedIn: 'root'})
export class SsabBaseSiteInitializer extends BaseSiteInitializer {
  constructor(
    protected baseSiteService: BaseSiteService,
    protected configInit: ConfigInitializerService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(baseSiteService, configInit);
  }

  /**
   * Sets the active base site value based on the default value from the config,
   * unless the active base site has been already initialized or there is a value in local storage
   */
  protected setDefaultFromConfig(config: SiteContextConfig): void {
    if (!this.baseSiteService.isInitialized() && this.getLocalStorage()?.getItem(GlobalLogin.BaseSiteActive) == null) {
      this.baseSiteService.setActive(
        getContextParameterDefault(config, BASE_SITE_CONTEXT_ID)
      );
    }
  }

  protected getLocalStorage(): Storage {
    return this.document.defaultView?.localStorage;
  }
}
