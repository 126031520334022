<ng-container *ngIf="!product.comparisonPriceEur">
  <div [ngClass]="{'h3':!orderPage}">
    <ng-container *ngTemplateOutlet="comparisonPriceSnippet;context:{comparisonPrice:product.comparisonPrice}"></ng-container>
  </div>
</ng-container>

<ng-container *ngIf="product.comparisonPriceEur">
  <div [ngClass]="{'h3':!orderPage}">
    <ng-container *ngTemplateOutlet="comparisonPriceSnippet;context:{comparisonPrice:product.comparisonPriceEur}"></ng-container>
  </div>
  <div [ngClass]="{'h5':!orderPage}">
    <ng-container *ngTemplateOutlet="comparisonPriceSnippet;context:{comparisonPrice:product.comparisonPrice}"></ng-container>
  </div>
</ng-container>

<ng-template #comparisonPriceSnippet let-comparisonPrice="comparisonPrice">
  <ng-container *ngIf="comparisonPrice ">
    {{ comparisonPrice?.formattedValue }} / <span [innerHTML]="product.comparisonUnit?.name"></span>
  </ng-container>
</ng-template>
