import { NgModule } from '@angular/core';
import { ImpersonationGuard } from './ssab-impersonation.guard';
import { RouterModule } from '@angular/router';
import { ConfigModule } from '@spartacus/core';
import { PageLayoutComponent } from '@spartacus/storefront';

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild([
      {
        data: {
          cxRoute: 'impersonate'
        },
        path: null,
        component: PageLayoutComponent,
        canActivate: [ImpersonationGuard]
      }
    ])
    ,
    ConfigModule.withConfig({
      routing: {
        routes: {
          impersonate: {
            paths: ['impersonate'],
            // TODO This makes error on Spartacus 4
            // canActivate: [ImpersonationGuard],
            protected: true
          }
        }
      }
    })
  ],
  providers: [
    {
      provide: ImpersonationGuard
    }
  ]
})
export class SsabImpersonationModule { }
