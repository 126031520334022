import {ChangeDetectionStrategy, Component, Input,} from '@angular/core';
import {SsabPrice, SsabProduct, SsabUnitOfMeasure} from '../../../model/product.model';

@Component({
  selector: 'ssab-cx-product-comparison-price',
  templateUrl: './ssab-product-comparison-price.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabProductComparisonPriceComponent {

  @Input() product: SsabProduct;
  @Input() currentPrice: SsabPrice;
  @Input() selectedUnit: SsabUnitOfMeasure;
  @Input() orderPage=false;

}
