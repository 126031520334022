import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService, CmsService, ProtectedRoutesService, SemanticPathService,} from '@spartacus/core';
import {LogoutGuard} from "@spartacus/storefront";
import {AuthRedirectStorageService} from "@spartacus/core";


@Injectable({
  providedIn: 'root',
})
export class SsabLogoutGuard extends LogoutGuard {
  constructor(
    protected auth: AuthService,
    protected cms: CmsService,
    protected semanticPathService: SemanticPathService,
    protected protectedRoutes: ProtectedRoutesService,
    protected router: Router,
    protected authRedirectStorageService: AuthRedirectStorageService
  ) {
    super(auth, cms, semanticPathService, protectedRoutes, router);
  }

  protected logout(): Promise<any> {
    this.authRedirectStorageService.setRedirectUrl('/login');
    return this.auth.coreLogout();
  }

}
