import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SsabCertificateDocument, SsabDocumentIdentifierList} from "../../model/document.model";
import {SsabUserService} from "../../service/user/ssab-user.service";
import {SsabScannerService} from "../../service/scanner/ssab-scanner.service";
import {Observable, Subscription} from "rxjs";
import {GlobalMessageService, GlobalMessageType} from "@spartacus/core";
import {take} from "rxjs/operators";
import {LAUNCH_CALLER, LaunchDialogService} from "@spartacus/storefront";
import {SsabScannerClearDocumentsDialogData} from "../dropdown-menu/dropdown-menu-layout.config";
import {SsabScannerSendEmailDialogData} from "../email-dialog/email-dialog-layout.config";


@Component({
  selector: 'app-document-page',
  templateUrl: './document-page.component.html',
})
export class ScannerDocumentPageComponent implements OnInit, OnDestroy {
  cartItems$: Observable<SsabCertificateDocument[]>;
  selectedObjects: SsabCertificateDocument[];
  subscription = new Subscription();

  constructor(
    private router: Router,
    private userService: SsabUserService,
    private scannerService: SsabScannerService,
    protected globalMessageService: GlobalMessageService,
    protected cdr: ChangeDetectorRef,
    protected launchDialogService: LaunchDialogService,
  ) {
    this.cartItems$ = this.scannerService.cartData$;
  }

  ngOnInit() {
  }

  downloadPDF() {
    const cartItems = this.scannerService.getInitialCartData();
    if (cartItems.length > 0) {
      if (cartItems.length > 1) {
        this.subscription.add(
          this.userService.downloadDocuments(
            {
              documentIdentifiers: cartItems,
              docType: cartItems[0].docType
            } as SsabDocumentIdentifierList)
            .pipe(take(1))
            .subscribe((data) => {
              this.userService.downloadFile(data, 'documents', 'zip');
              this.openScannerClearDocumentsModal();
              this.cdr.detectChanges();
            })
        );
      } else {
        this.subscription.add(
          this.userService.downloadDocument(cartItems[0])
            .pipe(take(1))
            .subscribe((data) => {
              this.userService.downloadFile(data, cartItems[0].documentId, 'pdf');
              this.openScannerClearDocumentsModal();
              this.cdr.detectChanges();
            })
        );
      }
    }
  }

  onScanAgain() {
    this.router.navigate(['/scanner']);
  }

  setSelectedItems(items: SsabCertificateDocument[]) {
    this.selectedObjects = items;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openScannerClearDocumentsModal(): void {
    this.launchDialogService.closeDialog(null);
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.SCANNER_CLEAR_DOCUMENTS, undefined, {} as SsabScannerClearDocumentsDialogData);
  }

  openScannerSendEmailModal(): void {
    this.launchDialogService.closeDialog(null);
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.SCANNER_SEND_EMAIL, undefined, {} as SsabScannerSendEmailDialogData);
  }
}
