import {Component, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MsalService} from '../../../shared/authentication';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SsabBaseSite} from '../../../model/misc.model';
import {BaseSiteService} from '@spartacus/core';
import {SsabAuthService} from '../../../service/user-auth/ssab-auth.service';
import {CSAgentLoginFormComponent} from '@spartacus/asm/components';

@Component({
  selector: 'ssab-cx-csagent-login-form',
  templateUrl: './ssab-csagent-login-form.component.html',
  styleUrls: ['./ssab-csagent-login-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SsabCsagentLoginFormComponent extends CSAgentLoginFormComponent {

  constructor(protected formBuilder: UntypedFormBuilder,
              protected authService: MsalService,
              protected ssabAuthService: SsabAuthService,
              protected baseSiteService: BaseSiteService) {

    super(formBuilder);
  }

  public isAzureLoginActive(): Observable<boolean> {
    return this.baseSiteService.get().pipe(
      map((baseSiteData: SsabBaseSite) => baseSiteData.azureLogin)
    );
  }

  public isCommerceLoginActive(): Observable<boolean> {
    return this.baseSiteService.get().pipe(
      map((baseSiteData: SsabBaseSite) => baseSiteData.commerceLogin)
    );
  }

  public startAzureLogin(): void {
    this.authService.startAzureLogin();
  }

  public isAzureLoginInProgress(): boolean {
    return this.ssabAuthService.isAzureLoginInProgress();
  }

}
