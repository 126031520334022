<ssab-floating-scrollbar>
  <div class="table-responsive"
       *ngIf="orderConfirmationDocuments && orderConfirmationDocuments.length> 0; else noOrderConfirmationDocuments">
    <table class="table cx-documents-table">
      <thead table-head
             (sortChange)="sort($event)"
             [activeSort]="activeSort"
             [label]="'ssab.documents.search.'"
             [tableHeadType]="documentTypes.Confirmation"
             (selectAllDocuments)="checkAllDocuments($event)">
      </thead>
      <tbody>
      <tr *ngFor="let orderConfirmation of orderConfirmationDocuments">
        <td>
          <div class="form-check mb-0 text-center">
            <input #selectedDocument
                   class="form-check-input position-relative"
                   type="checkbox" aria-label="..."
                   [checked]="selectAllDocuments"
                   (change)="onChange(selectedDocument.checked, orderConfirmation)">
          </div>
        </td>
        <td class="text-center">
          <i class="ssab-icon icon-system-download w-auto icon-cursor"
             (click)="onDownload(orderConfirmation)"></i>
        </td>
        <td>{{orderConfirmation.orderNumber}}</td>
        <td>{{orderConfirmation.customerRef}}</td>
        <td>{{orderConfirmation.documentVersion}}</td>
        <td>{{orderConfirmation.documentDate ? (convertUIDate(orderConfirmation.documentDate)|cxDate: (getDateFormat()|async)) : ''}}</td>
        <td>{{orderConfirmation.orderDate ? (convertUIDate(orderConfirmation.orderDate)|cxDate: (getDateFormat()|async)) : ''}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</ssab-floating-scrollbar>
<ng-template #noOrderConfirmationDocuments>
  <div *ngIf="activeSort"
       class="empty-data-div row no-margin text-center">
    <div [ngClass]="'col-6'"
    >
      <h4 class="text-primary">{{ 'ssab.documents.search.noDocuments.' + (documentTypes.Confirmation | lowercase) | cxTranslate }}</h4>
    </div>
  </div>
</ng-template>

