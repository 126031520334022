import {PaginationModel} from '@spartacus/core';
import {UserGroup} from '@spartacus/organization/administration/core';
import {DateRange, Sort} from './misc.model';


export enum DocumentTypes {
  Certificate = 'Certificate',
  Confirmation = 'OrderConfirmation',
  Invoice = 'Invoice',
  Transport = 'Transport',
  DocolMaterialData = 'DocolMaterialData',
  DocolEngStress = 'DocolEngStress',
  DocolFlp = 'DocolFlp',
  DocolProduct = 'Docol',
  GTMEngStress = 'Eng stress',
  GTMMaterialCard = 'Material card for autoform',
  GTMFormingLimit = 'Forming limit diagram'
}

export interface SsabDocumentSearchRequest {
  dateRange?: DateRange;
  sort?: Sort;
  keyword?: string;
  customerIds?: string[];
  pageNumber?: number;
  pageSize?: number;
  docType?: string;
  addLeadingWildCard?: boolean;
  addTrailingWildCard?: boolean;
}

export interface SsabDocumentIdentifier {
  buyerId?: string;
  consigneeId?: string;
  customerRef?: string;
  documentId?: string;
  tdsDocumentId?: number;
  documentDate?: Date;
  sourceSystem?: string;
  version?: number;
  fileId?: number;
  docType?: string;
  documentSource?: string;
  fileName?: string;
  docOrderNumber?: string;
}

export interface SsabDocumentIdentifierList {
  documentIdentifiers?: SsabDocumentIdentifier[];
  docType?: string;
}

export interface SsabCertificateDocument extends SsabDocumentIdentifier {
  certificateId?: string;
  grade?: string;
  dimension?: string;
  deliveryNumber?: string;
  heatId?: string;
  materialId?: string;
  deliveryDate?: Date;
  thickness?: string;
  orderConfirmation?: string;
  invoiceNumber?: string;
  sourcePlant?: string;
  orderNumber?: string;
  items?: string;
  certificateType?: string;
  manufacturerMaterialId?: string;
  brand?: string;
}

export interface SsabOrderConfirmationDocument extends SsabDocumentIdentifier {
  customerId?: string;
  payerId?: string;
  orderNumber?: string;
  orderDate?: Date;
  documentVersion?: string;
}


export interface SsabInvoiceDocument extends SsabDocumentIdentifier {
  customerId?: string;
  payerId?: string;
  invoiceNumber?: string;
  orderNumber?: string;
  invoiceDate?: Date;
}

export interface SsabTransportDocument extends SsabDocumentIdentifier {
  customerId?: string;
  orderNumber?: string;
  sendingUnit?: string;
  documentNumber?: string;
}

export interface SsabDocumentList {
  pagination?: SsabPaginationModel;
  documents?: SsabDocumentIdentifier[];
  allowedCustomers?: UserGroup[];
  allowedShipToCustomers?: UserGroup[];
}

export interface SsabPaginationModel extends PaginationModel {
  sortDescending?: boolean;
}

export interface SsabCertificateDocumentList extends SsabDocumentList {
  documents?: SsabCertificateDocument[];
}

export interface SsabOrderConfirmationDocumentList extends SsabDocumentList {
  documents?: SsabOrderConfirmationDocument[];
}

export interface SsabInvoiceDocumentList extends SsabDocumentList {
  documents?: SsabInvoiceDocument[];
}

export interface SsabTransportDocumentList extends SsabDocumentList {
  documents?: SsabTransportDocument[];
}

export interface SsabRelatedDocumentList {
  orderConfirmations?: SsabOrderConfirmationDocument[];
  certificates?: SsabCertificateDocument[];
  transports?: SsabTransportDocument[];
  invoices?: SsabInvoiceDocument[]
}

export interface OCRResult {
  readResult: OCRReadResult;
}

export interface OCRReadResult {
  pages: OCRResultPage[];
}

export interface OCRResultPage {
  lines: OCRResultLine[];
  words: OCRResultWord[];
}

export interface OCRResultLine {
  content: string;
}

export interface OCRResultWord {
  content: string;
}
