import {Component, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {SsabUser} from '../../../model/user.model';
import {SsabUserService} from '../../../service/user/ssab-user.service';
import {SsabGlobalConfiguration} from "../../../model/misc.model";
import {SsabGlobalconfigurationService} from "../../../service/general/configuration/ssab-globalconfiguration.service";


@Component({
  selector: 'ssab-cx-show-inches-toggle',
  templateUrl: './ssab-show-inches-toggle.component.html',
})
export class SsabShowInchesToggleComponent {
  user$: Observable<SsabUser>;
  configuration$: Observable<SsabGlobalConfiguration>;
  @Input() myAccountStyle = true;

  constructor(protected userService: SsabUserService, protected globalConfiguration: SsabGlobalconfigurationService) {
    this.user$ = this.userService.getUserDetails();
    this.configuration$ = this.globalConfiguration.getGlobalConfiguration();
  }

  toggleShowInches(isShowInches: boolean, customerId: string): void {
    this.userService.setShowInches(isShowInches, customerId, !this.myAccountStyle);
  }
}
