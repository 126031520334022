import { Provider } from '@angular/core';
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { OccFunctionRequestInterceptor } from './occ-function-request.interceptor';
import { SsabHttpErrorInterceptor } from './ssab-http-error.interceptor';

export const ssabInterceptors: Provider[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useExisting: OccFunctionRequestInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useExisting: SsabHttpErrorInterceptor,
    multi: true,
  }
];
