import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';
import {SsabProductPriceComponent} from './ssab-product-price.component';
import {SsabAddToCartModule} from "../../cart/add-to-cart/ssab-add-to-cart.module";
import {SsabProductComparisonPriceComponent} from "./ssab-product-comparison-price.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@NgModule({
    imports: [
        CommonModule,
        I18nModule,
        ConfigModule.withConfig({
            cmsComponents: {
                ProductPriceComponent: {
                    component: SsabProductPriceComponent
                }
            },
        } as CmsConfig),
        SsabAddToCartModule,
        ReactiveFormsModule,
        FormsModule,
    ],
  declarations: [SsabProductPriceComponent, SsabProductComparisonPriceComponent],
  exports: [SsabProductPriceComponent, SsabProductComparisonPriceComponent]
})
export class SsabProductPriceModule {
}
