import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRouterStateSnapshot, GlobalMessageType, RoutingService} from '@spartacus/core';
import {Alert} from '../../../model/misc.model';
import {Observable, Subscription} from 'rxjs';
import {SsabAlertService} from '../../../service/general/ssab-alert.service';
import {Router} from '@angular/router';
import {ICON_TYPE} from '@spartacus/storefront';
import {pluck} from 'rxjs/operators';

@Component({
  selector: 'ssab-cx-alert',
  templateUrl: './ssab-alert.component.html',
})
export class SsabAlertComponent implements OnInit, OnDestroy {
  @Input() id = 'default-alert';
  @Input() fade = false;
  iconTypes = ICON_TYPE;

  alerts: Alert[] = [];
  alertSubscription: Subscription;

  constructor(private router: Router,
              private alertService: SsabAlertService,
              private routingService: RoutingService) {
  }

  protected routeState$: Observable<ActivatedRouterStateSnapshot>;

  ngOnInit(): void {
    this.routeState$ = this.routingService
      .getRouterState()
      .pipe(pluck('state'));
    // subscribe to new alert notifications
    this.alertSubscription = this.alertService.onAlert()
      .subscribe(alert => {
        const alertRepeated = this.alerts.filter(x => x.id === alert.id);

        if (alertRepeated.length === 0) {
          // clear alerts when an empty alert is received
          if (!alert.message) {
            // filter out alerts without 'keepAfterRouteChange' flag
            this.alerts = this.alerts.filter(x => x.keepAfterRouteChange);

            // remove 'keepAfterRouteChange' flag on the rest
            this.alerts.forEach(x => delete x.keepAfterRouteChange);
            return;
          }

          // add alert to array
          this.alerts.push(alert);

          // auto close alert if required
          if (alert.autoClose) {
            setTimeout(() => this.removeAlert(alert), 3000);
          }
        }
      });
  }

  ngOnDestroy(): void {
    // unsubscribe to avoid memory leaks
    this.alertSubscription?.unsubscribe();
  }

  removeAlert(alert: Alert): void {
    // check if already removed to prevent error on auto close
    if (!this.alerts.includes(alert)) {
      return;
    }

    if (this.fade) {
      // fade out alert
      this.alerts.find(x => x === alert).fade = true;

      // remove alert after faded out
      setTimeout(() => {
        this.alerts = this.alerts.filter(x => x !== alert);
      }, 250);
    } else {
      // remove alert
      this.alerts = this.alerts.filter(x => x !== alert);
    }
  }

  cssClass(alert: Alert): string {
    if (!alert) {
      return;
    }

    const classes = ['alert', 'alert-dismissable'];

    const alertTypeClass = {
      [GlobalMessageType.MSG_TYPE_INFO]: 'alert-success',
      [GlobalMessageType.MSG_TYPE_ERROR]: 'alert-danger',
      [GlobalMessageType.MSG_TYPE_CONFIRMATION]: 'alert-info',
      [GlobalMessageType.MSG_TYPE_WARNING]: 'alert-warning'
    };

    classes.push(alertTypeClass[alert.type]);

    if (alert.fade) {
      classes.push('fade');
    }

    return classes.join(' ');
  }

  hideAlert(alert: Alert): boolean {
    let hide = false;
    if (alert.hidePages && alert.hidePages.length > 0) {
      alert.hidePages.forEach((hidePage) => {
        hide = this.isHidePage(hidePage);
      });
    }

    return hide;
  }

  isHidePage(page: string): boolean {
    let isHidePage = false;

    this.routeState$.subscribe(state => {
      if (state.context.id.includes(page)) {
        isHidePage = true;
      }
    }).unsubscribe();
    return isHidePage;
  }
}
