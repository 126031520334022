import {ActiveFacetsComponent} from '@spartacus/storefront';
import {ChangeDetectionStrategy, Component} from '@angular/core';


@Component({
  selector: 'ssab-cx-active-facets',
  templateUrl: './ssab-active-facets.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SsabActiveFacetsComponent extends ActiveFacetsComponent{

}
