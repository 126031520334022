import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CmsConfig, ConfigModule, I18nModule, provideDefaultConfig, UrlModule} from '@spartacus/core';
import {CarouselModule, IconModule, MediaModule, OutletModule, PageSlotModule, ProductImagesModule} from '@spartacus/storefront';
import {CoilsSearchComponent} from "./ssab-coils-search.component";
import {SsabInputModule} from "../../shared/input/ssab-input.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {SsabTableHeadModule} from "../shared/table-head/ssab-table-head.module";
import {ReactiveFormsModule} from "@angular/forms";
import {SsabCoilsComparisonComponentComponent} from "./ssab-coils-comparison.component";
import {coilComparisonModalLayout} from "./ssab-coils-comparison-modal-layout.config";
import {SsabCoilsComparisonPreviewComponent} from "./ssab-coils-comparison-preview.component";
import {SsabPaginationBottomModule} from '../../shared/pagination-bottom/ssab-pagination-bottom.module';
import {NgbPopoverModule} from "@ng-bootstrap/ng-bootstrap";
import {SsabCoilsClearComponentComponent} from "./ssab-coils-clear.component";
import {SsabInputRangeModule} from "../../shared/input-range/ssab-input-range.module";


@NgModule({
    imports: [CommonModule,
        OutletModule,
        I18nModule,
        ConfigModule.withConfig({
            cmsComponents: {
                CoilsSearchComponent: {
                    component: CoilsSearchComponent
                },
                CoilsComparisonPreviewComponent: {
                    component: SsabCoilsComparisonPreviewComponent
                }
            },
        } as CmsConfig), ProductImagesModule, CarouselModule, SsabInputModule, NgSelectModule, SsabTableHeadModule, ReactiveFormsModule, MediaModule, NgbPopoverModule, SsabPaginationBottomModule, UrlModule, IconModule, PageSlotModule, SsabInputRangeModule,
    ],
  declarations: [CoilsSearchComponent, SsabCoilsComparisonComponentComponent, SsabCoilsComparisonPreviewComponent, SsabCoilsClearComponentComponent],
  exports: [CoilsSearchComponent, SsabCoilsComparisonComponentComponent, SsabCoilsComparisonPreviewComponent, SsabCoilsClearComponentComponent],
  providers: [provideDefaultConfig(coilComparisonModalLayout)]
})
export class SsabCoilsModule {
}
