import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { OccEndpointsService } from "@spartacus/core";
import { SsabUserService } from '../user/ssab-user.service';
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SsabSubscriptionsAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected userService: SsabUserService
  ) { }

  subscribeProductAvailability(userId: string, productCode: string): Observable<{}> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .post(this.occEndpointsService.buildUrl('subscribeProductAvailability', { urlParams:  {userId, productCode }}), '', { headers })
      .pipe(catchError((error) => throwError(error)));
  }
}
