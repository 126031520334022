import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {ICON_TYPE, LAUNCH_CALLER, LaunchDialogService} from '@spartacus/storefront';
import {Observable, Subscription} from 'rxjs';
import {SsabUserService} from '../../../service/user/ssab-user.service';
import {SsabSubscriptionService} from '../../../service/subscription/ssab-subscription.service';
import {SsabProductService} from '../../../service/product/ssab-product.service';
import {filter} from 'rxjs/operators';
import {SsabGtmService} from '../../../service/analytics/gtm/ssab-gtm.service';
import {SsabProduct} from '../../../model/product.model';
import {SsabUser} from '../../../model/user.model';
import {SsabProductAvailabilityModalData} from './ssab-product-availability-modal-layout.config';

@Component({
  selector: 'ssab-cx-product-availability-modal',
  templateUrl: './ssab-product-availability-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsabProductAvailabilityModalComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  iconTypes = ICON_TYPE;
  productCode: string;
  isSubscriptionSuccess: boolean = false;
  isSubscriptionFail: boolean = false;
  user$: Observable<SsabUser>;

  constructor(
    protected userService: SsabUserService,
    protected subscribeService: SsabSubscriptionService,
    protected productService: SsabProductService,
    protected gtmService: SsabGtmService,
    protected launchDialogService: LaunchDialogService) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.launchDialogService.data$.subscribe((dialogData: SsabProductAvailabilityModalData) => {
        if (dialogData) {
          this.productCode = dialogData.productCode;
          this.isSubscriptionSuccess = dialogData.isSubscriptionSuccess;
          this.isSubscriptionFail = dialogData.isSubscriptionFail;
          this.user$ = dialogData.user;
        }
      })
    );
    if (this.isSubscriptionSuccess) {
      this.subscriptions.add(
        this.productService.get(this.productCode, null)
          .pipe(filter(Boolean))
          .subscribe((product: SsabProduct) => {
            this.gtmService.pushNotifyMe(product);
          })
      );
    }
  }

  ngOnDestroy() {
    this.dismiss();
    this.subscriptions.unsubscribe();
  }

  public dismiss(reason?: any): void {
    this.launchDialogService.clear(LAUNCH_CALLER.PRODUCT_AVAILABILITY_MODAL);
    this.launchDialogService.closeDialog('closed');
  }

  sendRequest(): void {
    this.subscribeService.subscribeToProductAvailability(this.productCode);
  }
}
