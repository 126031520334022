import {Component} from '@angular/core';
import {SsabScannerService} from "../../service/scanner/ssab-scanner.service";
import {LAUNCH_CALLER, LaunchDialogService} from "@spartacus/storefront";
import {SsabScannerHowToScanDialogData} from "./how-to-scan-layout.config";


@Component({
  selector: 'app-how-to-scan',
  templateUrl: './how-to-scan.component.html',
})
export class ScannerHowToScanComponent {
  emailSent: boolean;

  constructor(
    private scannerService: SsabScannerService,
    protected launchDialogService: LaunchDialogService,
  ) {
    this.launchDialogService.data$
      .subscribe((dialogData: SsabScannerHowToScanDialogData) => {
        this.emailSent = dialogData.emailSent;
      })
      .unsubscribe();
  }

  public dismiss(reason?: any): void {
    this.launchDialogService.clear(LAUNCH_CALLER.SCANNER_HOW_TO_SCAN);
    this.launchDialogService.closeDialog('closed');
  }

  deleteCartList() {
    this.scannerService.clearList();
    this.dismiss();
  }
}
