import {AuthGuard, CmsConfig, ConfigModule, I18nModule, UrlModule} from '@spartacus/core';
import {SsabAddToCartModule} from '../../cart/add-to-cart/ssab-add-to-cart.module';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {SsabTableHeadModule} from '../../shared/table-head/ssab-table-head.module';
import {NgModule} from '@angular/core';
import {SsabPreviousOrdersComponent} from './ssab-previous-orders.component';
import {SsabPreviousOrdersEntryComponent} from './previous-entry/ssab-previous-orders-entry.component';
import {SsabPreviousOrdersTableComponent} from './previous-orders-table/ssab-previous-orders-table.component';
import {MediaModule} from '@spartacus/storefront';
import {SsabPaginationBottomModule} from '../../../shared/pagination-bottom/ssab-pagination-bottom.module';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UrlModule,
        I18nModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                AccountMyPreviousOrdersComponent: {
                    component: SsabPreviousOrdersComponent,
                    guards: [AuthGuard],
                },
            },
        }),
        SsabTableHeadModule,
        SsabAddToCartModule,
        MediaModule,
        SsabPaginationBottomModule,
    ],
    declarations: [SsabPreviousOrdersComponent,
        SsabPreviousOrdersTableComponent,
        SsabPreviousOrdersEntryComponent],
    exports: [SsabPreviousOrdersComponent,
        SsabPreviousOrdersTableComponent,
        SsabPreviousOrdersEntryComponent]
})
export class SsabPreviousOrdersModule{
}
