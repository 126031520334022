import { CommonModule } from '@angular/common';
import {BannerModule, GenericLinkModule, MediaModule} from '@spartacus/storefront';
import {CmsConfig, provideDefaultConfig} from '@spartacus/core';
import {RouterModule} from '@angular/router';
import {SsabBannerComponent} from './ssab-banner.component';
import {NgModule} from '@angular/core';


@NgModule({
    imports: [CommonModule, RouterModule, GenericLinkModule, MediaModule],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                SimpleResponsiveBannerComponent: {
                    component: SsabBannerComponent,
                },
                BannerComponent: {
                    component: SsabBannerComponent,
                },
                SimpleBannerComponent: {
                    component: SsabBannerComponent,
                },
            },
        }),
    ],
    declarations: [SsabBannerComponent],
    exports: [SsabBannerComponent]
})
export class SsabBannerModule extends BannerModule{}
