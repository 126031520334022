import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SsabOccUserProfileAdapter} from './ssab-occ-user-profile.adapter';
import {B2BUnitOption} from '../../model/user.model';
import {UserProfileConnector} from '@spartacus/user/profile/core';
import {OrderTrackingTypes} from "../../model/order.model";

@Injectable({
  providedIn: 'root',
})
export class SsabUserProfileConnector extends UserProfileConnector {

  constructor(protected adapter: SsabOccUserProfileAdapter) {
    super(adapter);
  }

  public searchUnitForShipToFilter(ctx: string, term: string, id: string, id2?: string, selectedView?: OrderTrackingTypes): Observable<B2BUnitOption[]> {
    return this.adapter.searchUnitForShipToFilter(ctx, term, id, id2, selectedView);
  }

  public searchUnitForCompanyFilter(ctx: string, term: string, selectedView: OrderTrackingTypes): Observable<B2BUnitOption[]> {
    return this.adapter.searchUnitForCompanyFilter(ctx, term, selectedView);
  }

  public searchUnitForCustomerIdFilter(ctx: string, term: string, id?: string, selectedView?: OrderTrackingTypes): Observable<B2BUnitOption[]> {
    return this.adapter.searchUnitForCustomerIdFilter(ctx, term, id, selectedView);
  }

  public searchUnits(term: string): Observable<B2BUnitOption[]> {
    return this.adapter.searchUnit(term);
  }

  selectUnit(uid: string): Observable<B2BUnitOption> {
    return this.adapter.selectUnit(uid);
  }

  delayedOrderSubscription(uid: string, isLateOrderSubscription: boolean): Observable<any> {
    return this.adapter.delayedOrderSubscription(uid, isLateOrderSubscription);
  }

  setShowInches(uid: string, isShowInches: boolean): Observable<any> {
    return this.adapter.setShowInches(uid, isShowInches);
  }

  public storeSessionLanguage(userId: string): Observable<any> {
    return this.adapter.storeSessionLanguage(userId);
  }
}
