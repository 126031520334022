<div class="cx-page" id="facetNavigation" [class.not-shown]="loading$ | async">
  <div class="row no-margin">
    <div class="col-lg-12 col-md-4 col-12">
      <button
          #trigger
          class="btn btn-action btn-block dialog-trigger p-0"
          (click)="launch()"
      >
        <span class="float-right">
          {{ 'productList.filterBy.label' | cxTranslate }}
          <i class="ssab-icon icon-filter-group icon-inline ml-3"></i>
        </span>
      </button>
      <p [routerLink]="{ cxRoute: 'search', params: { query: '' } } | cxUrl"
         class="text-sm cursor-pointer text-underline text-primary float-right d-none d-md-block d-lg-none pt-3 text-bold">
        {{'productList.clearFilter' | cxTranslate}}
    </div>


    <ssab-cx-active-facets
      class="col-lg-12 col-8 order-first d-none d-md-block">
    </ssab-cx-active-facets>
  </div>
  <hr class="d-md-block d-none d-lg-none hr-gray">


  <ssab-cx-facet-list
    *ngIf="isOpen$ | async"
    [isDialog]="hasTrigger"
    (closeList)="close()"
    [class.active]="isActive$ | async"
    [class.dialog]="hasTrigger"
  ></ssab-cx-facet-list>
</div>
