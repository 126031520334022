import { NgModule } from '@angular/core';
import { CmsConfig, I18nConfig, provideConfig } from '@spartacus/core';
import { UserAccountRootModule, USER_ACCOUNT_FEATURE } from '@spartacus/user/account/root';
import { UserProfileRootModule, USER_PROFILE_FEATURE } from '@spartacus/user/profile/root';
import {i18nConfig} from '../../../../assets/i18n-assets/i18nConfig';
import {SsabUserProfileModule} from './ssab-user-profile.module';

@NgModule({
  declarations: [],
  imports: [
    UserAccountRootModule,
    UserProfileRootModule
  ],
  providers: [provideConfig(<CmsConfig>{
    featureModules: {
      [USER_ACCOUNT_FEATURE]: {
        module: () =>
          import('@spartacus/user/account').then((m) => m.UserAccountModule),
      },
    }
  }),
  provideConfig(<I18nConfig>{
    ...i18nConfig
  }),
  provideConfig(<CmsConfig>{
    featureModules: {
      [USER_PROFILE_FEATURE]: SsabUserProfileModule
    }
  }),
  provideConfig(<I18nConfig>{
    ...i18nConfig
  })
  ]
})
export class UserFeatureModule { }
