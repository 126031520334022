import {Injectable} from '@angular/core';
import {ProductScope, RoutingService,} from '@spartacus/core';
import {Observable, of} from 'rxjs';
import {distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';
import {SsabProductService} from './ssab-product.service';
import {CurrentProductService} from '@spartacus/storefront';
import {SsabProduct} from '../../model/product.model';

@Injectable({
  providedIn: 'root',
})
export class SsabCurrentProductService extends CurrentProductService {
  constructor(
    protected lRoutingService: RoutingService,
    protected lProductService: SsabProductService
  ) {
    super(lRoutingService, lProductService);
  }

  getProduct(
    scopes?: (ProductScope | string)[] | ProductScope | string
  ): Observable<SsabProduct | null> {
    return this.lRoutingService.getRouterState().pipe(
      map((state) => state.state.params['productCode']),
      distinctUntilChanged(),
      switchMap((productCode: string) => {
        return productCode ? this.lProductService.get(productCode, null) : of(null);
      }),
      filter((product) => product !== undefined)
    );
  }
}
