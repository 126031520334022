import { Component} from '@angular/core';
import {BannerComponent, CmsComponentData} from '@spartacus/storefront';
import {SsabCMSHeroBannerComponent} from '../../../model/cms.model';
import {CmsService, SemanticPathService} from '@spartacus/core';
@Component({
  selector: 'ssab-cx-hero-banner',
  templateUrl: './ssab-hero-banner.component.html'
})
export class SsabHeroBannerComponent extends BannerComponent {

  data: CmsComponentData<SsabCMSHeroBannerComponent>;

  constructor(protected component: CmsComponentData<SsabCMSHeroBannerComponent>,
              protected urlService: SemanticPathService,
              protected cmsService: CmsService) {
    super(component, urlService, cmsService);
  }
}
