import {provideDefaultConfig, TranslationService} from '@spartacus/core';
import {NgModule} from '@angular/core';
import {SsabI18nextTranslationService} from './ssab-i18next-translation.service';
import {SsabEmptyToStringPipe} from './ssab-empty-to-string.pipe';
import {ssabOccI18nConfig} from './ssab-occ-i18n-config';

@NgModule({
  declarations: [SsabEmptyToStringPipe],
  exports: [SsabEmptyToStringPipe],
  providers: [
    provideDefaultConfig(ssabOccI18nConfig),
    {
      provide: TranslationService,
      useExisting: SsabI18nextTranslationService
    }
  ]
})
export class SsabI18nModule{
}
