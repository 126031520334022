import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {SsabFilterComponent} from './ssab-filter.component';
import {IconModule} from '@spartacus/storefront';

@NgModule({
  imports: [CommonModule, IconModule],
  declarations: [SsabFilterComponent],
  exports: [SsabFilterComponent],
})
export class SsabFilterModule{}
