import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {Receiver} from '../../../model/cart.model';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'ssab-cx-add-email',
  templateUrl: './ssab-add-email.component.html',
})
export class SsabAddEmailComponent {
  @Input() receivers: Receiver[];
  @Input() isAdditionalEmailVisible: boolean;
  @Input() emailAddressLabel: string;
  @Input() emailAddressPlaceholder: string;
  @Input() parentForm: UntypedFormGroup;
  @Input() emailName: string;
  @Input() emailManuallyName: string;
  @Input() showAdditionalEmail = false;

  @Output() postBack = new EventEmitter<boolean>();
  @Output() resetEmail = new EventEmitter<void>();

  constructor(protected cdr: ChangeDetectorRef){}

  postBackOutput(){
    if(this.showAdditionalEmail){
      this.isAdditionalEmailVisible = true;
    }
    this.postBack.emit(this.isAdditionalEmailVisible);
  }

  isInvalid(): boolean {
    return this.parentForm.get(this.emailName) && this.parentForm.get(this.emailName).invalid;
  }

  resetAdditionalEmail(): void{
    this.resetEmail.emit();
  }

  toggleAddEmail(){
    this.isAdditionalEmailVisible = !this.isAdditionalEmailVisible;
    this.cdr.detectChanges();
  }
}
