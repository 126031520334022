<ng-container>
  <h4 class="text-primary pb-3">{{ 'productDetails.attributes.title' | cxTranslate }}</h4>
  <!--Product ID on table just for following the structure of all attributes -->
  <table class="table-borderless" *ngIf="product.code">
    <tr *ngIf="!product.nonSapProduct">
      <th [innerHTML]="'productDetails.productID' | cxTranslate"></th>
      <td  [innerHTML]="product.code"></td>
    </tr>
    <tr *ngIf="product.customerArticleIds">
      <th [innerHTML]="'product.customerMaterialNumbers.name' | cxTranslate"></th>
      <td  [innerHTML]="product.customerArticleIds.join(',')"></td>
    </tr>
    <tr *ngIf="product.manufacturerTypeDescription">
      <th [innerHTML]="'productDetails.manufacturer' | cxTranslate"></th>
      <td [innerHTML]="product.manufacturerTypeDescription"></td>
    </tr>
  </table>
  <table *ngFor="let class of product?.classifications" class="table-borderless">
    <tr *ngFor="let feature of class.features">
      <th *ngIf="feature.name" [innerHTML]="feature.name"></th>
      <th *ngIf="!feature.name" [innerHTML]="'productDetails.attributes.' + feature.code | cxTranslate"></th>
      <td>
        <ng-container *ngIf="feature.featureValues.length == 2 && feature.featureUnit?.symbol?.length >0; else listFeatureValues">
          {{ 'productDetails.attributes.range'
          | cxTranslate :
          {
            startRange: feature.featureValues[0].value,
            symbol: feature.featureUnit.symbol, endRange: feature.featureValues[1].value
          } }}
        </ng-container>
        <ng-template #listFeatureValues>
          <ul *ngIf="feature.featureValues.length > 1">
            <li *ngFor="let featureValue of feature?.featureValues">
              {{ featureValue?.value }}
              <span
                *ngIf="feature.featureUnit?.symbol?.length > 0">
              {{ feature.featureUnit.symbol }}
            </span>
            </li>
          </ul>
        </ng-template>
        <ng-container *ngIf="feature.featureValues.length == 1">
          {{ formatDouble(feature.featureValues[0].value) }}
          <span
            *ngIf="feature.featureUnit?.symbol?.length > 0">
              {{ feature.featureUnit.symbol }}
            </span>
        </ng-container>
      </td>
    </tr>
  </table>
  <table class="table-borderless">
    <ng-container *ngIf="product.productUnitConversions as puc">
      <tr *ngIf="puc[0] && pickCorrectBundle(puc)?.bundleSize">
        <th [innerHTML]="pickCorrectBundle(puc).bundleSizeTitle ? pickCorrectBundle(puc).bundleSizeTitle : ''"></th>
        <td [innerHTML]="pickCorrectBundle(puc).bundleSize"></td>
      </tr>
    </ng-container>
  </table>
</ng-container>
