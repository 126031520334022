import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {StateWithProcess, StateWithUser} from '@spartacus/core';
import {SubscribeProductAvailabilityRequest} from '../subscription/ssab-subscription.action';


@Injectable({
  providedIn: 'root',
})
export class SsabSubscriptionService {

  constructor(
    protected store: Store<StateWithUser | StateWithProcess<void>>,
  ) {
  }

  subscribeToProductAvailability(productCode: string): void{
    this.store.dispatch(
      new SubscribeProductAvailabilityRequest({userId: 'current', productCode})
    );
  }
}
