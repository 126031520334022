import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from, Observable} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {GlobalMessageService, GlobalMessageType, LoggerService, normalizeHttpError} from '@spartacus/core';
import {SsabContactFormConnector} from './ssab-contactform.connector';
import {LoadContactFormFail, SEND_STOCK_AVAILABILITY_REQUEST, SendStockAvailabilityRequest} from './ssab-contactform.action';
import {LoadContactSalesProductList, RemoveContactSalesProductList} from '../contactsales/ssab-contactsales.action';
import {LAUNCH_CALLER, LaunchDialogService} from '@spartacus/storefront';
import {SsabBaseModalDialogData} from '../../cms-components/modals/ssab-base-modal/ssab-base-modal-layout.config';


@Injectable()
export class ContactFormEffects {
  sendStockAvailabilityRequest$: Observable<RemoveContactSalesProductList | LoadContactFormFail | LoadContactSalesProductList>;

  constructor(
    private actions$: Actions,
    protected globalMessageService: GlobalMessageService,
    private connector: SsabContactFormConnector,
    protected launchDialogService: LaunchDialogService,
    protected logger: LoggerService
  ) {

    this.sendStockAvailabilityRequest$ = createEffect(() =>
      this.actions$.pipe(ofType(SEND_STOCK_AVAILABILITY_REQUEST), map((action: SendStockAvailabilityRequest) => action.payload), switchMap((payload) => {
        return this.connector.sendStockAvailabilityRequest(payload.userId, payload.cartCode, payload.message).pipe(
          switchMap(() => {
            this.launchDialogService.closeDialog(null);
            this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.BASE_MODAL, undefined, {
              titleText: 'ssab.sendContactForm.stockAvailabilityRequest.modal.success.title',
              bodyText: 'ssab.sendContactForm.stockAvailabilityRequest.modal.success.body',
              buttonText: 'ssab.notifyme.productavailability.modal.okBtn',
            } as SsabBaseModalDialogData);
            return [
              new RemoveContactSalesProductList({cartId: payload.cartCode}),
              new LoadContactSalesProductList({
                userId: payload.userId
              }),
            ];
          }),
          catchError((error) => {
            this.globalMessageService.add({key: 'ssab.sendContactForm.stockAvailabilityRequest.notification.failed'}, GlobalMessageType.MSG_TYPE_ERROR);
            return from([
              new LoadContactFormFail({
                userId: payload.userId,
                contactFormType: 'stockAvailabilityRequest',
                cartCode: payload.cartCode,
                error: normalizeHttpError(error, this.logger),
              }),

            ]);
          })
        );
      })));
  }


}
