import { Action } from '@ngrx/store';
import { SsabCart } from '../../model/cart.model';

export const CREATE_CONTACTSALES_PRODUCT_LIST = "[ContactSalesProductList] Create";
export const REMOVE_CONTACTSALES_PRODUCT_LIST = "[ContactSalesProductList] Remove";
export const CREATE_CONTACTSALES_PRODUCT_LIST_FAIL = "[ContactSalesProductListCart] Create Fail";
export const CREATE_CONTACTSALES_PRODUCT_LIST_SUCCESS = "[ContactSalesProductList] Create Success";
export const LOAD_CONTACTSALES_PRODUCT_LIST = "[ContactSalesProductList] Load SavedCart";
export const LOAD_CONTACTSALES_PRODUCT_LIST_SUCCESS = "[ContactSalesProductList] Load Success";
export const LOAD_CONTACTSALES_PRODUCT_LIST_FAIL = "[ContactSalesProductList] Load Fail";

export class CreateContactSalesProductList implements Action {
  readonly type = CREATE_CONTACTSALES_PRODUCT_LIST;
  constructor(public payload: {
    userId: string;
    rethrowLoadCartSuccess?: boolean;
  }) { this.payload = payload; }
}

export class RemoveContactSalesProductList implements Action {
  readonly type = REMOVE_CONTACTSALES_PRODUCT_LIST;
  constructor(public payload: {
    cartId: string;
  }) { this.payload = payload; }
}

export class CreateContactSalesProductListSuccess {
  readonly type = CREATE_CONTACTSALES_PRODUCT_LIST_SUCCESS;
  constructor(public payload: {
    cart: SsabCart;
    userId: string;
  }) { this.payload = payload; }
}

export class CreateContactSalesProductListFail {
  readonly type = CREATE_CONTACTSALES_PRODUCT_LIST_FAIL;
  constructor(public payload: {
    error?: any;
  }) { this.payload = payload; }
}

export class LoadContactSalesProductList implements Action {
  readonly type = LOAD_CONTACTSALES_PRODUCT_LIST;
  constructor(public payload: {
    userId: string;
  }) { this.payload = payload; }
}
export class LoadContactSalesProductListSuccess {
  readonly type = LOAD_CONTACTSALES_PRODUCT_LIST_SUCCESS;
  constructor(public payload: {
    cart: SsabCart;
    userId: string;
    cartId: string;
  }) { this.payload = payload; }
}

export class LoadContactSalesProductListFail {
  readonly type = LOAD_CONTACTSALES_PRODUCT_LIST_FAIL;
  constructor(public payload: {
    userId: string;
    error: any;
  }) { this.payload = payload; }
}

export declare type ContactSalesProductListActions = CreateContactSalesProductList | CreateContactSalesProductListSuccess | CreateContactSalesProductListFail | LoadContactSalesProductList | LoadContactSalesProductListSuccess | LoadContactSalesProductListFail;
export { };
