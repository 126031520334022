export enum GTMEvent {
  ProductsImpressionView = 'eec.impressionView',
  ProductImpressionClick = 'eec.impressionClick',
  ProductViewDetails = 'eec.detail',
  AddToCart = 'eec.add',
  CheckoutView= 'eec.checkout',
  OrderConfirmationView= 'eec.purchase',
  RemoveFromCart = 'eec.remove',
  UserLogIn = 'userLogin',
  CustomerSwitch = 'customerSwitch',
  DocumentsDownload = 'Document download',
  NotifyMe = 'notifyMe',
  ContactSales = 'sendRequest',
  ErrorNotification = 'error',
  ProductListingPageID = 'searchList',
  StockFunctionality = 'stockFunctionality',
  MaterialData = 'materialData'
}
