<ng-container *ngIf="items?.length > 0 && (size$ | async) as size">
  <h3 *ngIf="title">{{ title }}</h3>
  <div class="carousel-panel" [ngClass]="'size-' + size">
    <button
      *ngIf="size < itemsLength"
      class="previous"
      (click)="activeSlide = activeSlide - size"
      [disabled]="activeSlide === 0"
    >
      <i class="ssab-icon icon-navigation-chevron-left"></i>
    </button>

    <div class="slides">
      <ng-container *ngFor="let _ of items; let i = index">
        <div
          class="slide"
          *ngIf="i % size === 0"
          [class.active]="i === activeSlide"
        >
          <ng-container
            *ngFor="let item of items | slice: i:i + size; let j = index"
          >
            <ng-container *ngIf="item | async as data">
              <div
                class="item"
                [class.active]="i === activeSlide"
                [class.lastSlide]="data.hide"
              >
                <ng-container *ngIf="!showBanners">
                  <ng-container
                    *ngTemplateOutlet="template; context: { item: data }"
                  ></ng-container>
                </ng-container>

                <ssab-cx-banner-column *ngIf="showBanners" [data]="data">
                </ssab-cx-banner-column>
              </div>
            </ng-container>

          </ng-container>
        </div>
      </ng-container>
    </div>

    <button
      *ngIf="size < itemsLength"
      class="next"
      (click)="activeSlide = activeSlide + size"
      tabindex="-1"
      [disabled]="activeSlide > itemsLength - size - 1"
    >
      <i class="ssab-icon icon-navigation-chevron-right"></i>
    </button>
  </div>

  <div *ngIf="!hideIndicators && size < itemsLength" class="indicators">
    <ng-container *ngFor="let _ of items; let i = index">
      <button
        *ngIf="i % size === 0"
        (focus)="activeSlide = i"
        [disabled]="i === activeSlide"
        tabindex="-1"
      >
        <cx-icon [type]="indicatorIcon"></cx-icon>
      </button>
    </ng-container>
  </div>
</ng-container>
