import {NgModule} from '@angular/core';
import {CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';

import {SsabStoreSelectorComponent} from './ssab-store-selector.component';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';

@NgModule({
    declarations: [SsabStoreSelectorComponent],
    imports: [
        ConfigModule.withConfig({
            RouterModule,
            cmsComponents: {
                StoreSelectorComponent: {
                    component: SsabStoreSelectorComponent,
                },
            },
        } as CmsConfig),
        CommonModule,
        NgSelectModule,
        I18nModule
    ],
    exports: [SsabStoreSelectorComponent]
})

export class SsabStoreSelectorModule {
}
