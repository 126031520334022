import {Component, HostListener, Inject} from '@angular/core';
import {AuthService, WindowRef} from '@spartacus/core';
import {LoginComponent} from '@spartacus/user/account/components';
import {UserAccountFacade} from '@spartacus/user/account/root';
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'ssab-cx-login',
  templateUrl: './ssab-login.component.html',

})
export class SsabLoginComponent extends LoginComponent {

  constructor(auth: AuthService,
              userAccount: UserAccountFacade,
              @Inject(DOCUMENT) private document: Document) {
    super(auth, userAccount);
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    const logInNavigation = this.document.querySelector("ssab-cx-login").querySelector("ssab-cx-navigation-ui");
    const logInIconParent = this.document.querySelector('[aria-label="My Account"]');
    if (logInNavigation && event.target.parentElement !== logInIconParent) {
      logInNavigation?.classList.remove("is-open");
    } else {
      logInNavigation?.classList.add("is-open");
    }
  }

}
