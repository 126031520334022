import {Component} from '@angular/core';
import {SsabActiveCartService} from '../../../service/cart/ssab-active-cart.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'ssab-cx-minicart',
  templateUrl: './ssab-mini-cart.component.html'
})
export class SsabMiniCartComponent {
  quantity$: Observable<number>;

  isConsignmentCart$: Observable<boolean>;

  constructor(protected activeCartService: SsabActiveCartService) {
    this.quantity$ = this.activeCartService.getActive().pipe(
      map((cart) => {
        return cart.entries ? cart.entries.length : 0;
      })
    );
    this.isConsignmentCart$ = this.activeCartService.getActive().pipe(map(cart => cart.consignment));
  }
}
