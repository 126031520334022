import {I18nConfig} from '@spartacus/core';
import {translationChunksConfig} from '@spartacus/assets';
import {I18N_URL_PLACEHOLDER} from '../../app/spartacus/features/shared/i18/ssab-i18n-constants';
import {bulkPricingTranslationChunksConfig} from '@spartacus/product/bulk-pricing/assets';
import {productImageZoomTranslationChunksConfig} from '@spartacus/product/image-zoom/assets';
import {productVariantsTranslationChunksConfig} from '@spartacus/product/variants/assets';
import {savedCartTranslationChunksConfig} from '@spartacus/cart/saved-cart/assets';
import {importExportTranslationChunksConfig} from '@spartacus/cart/import-export/assets';
import {quickOrderTranslationChunksConfig} from '@spartacus/cart/quick-order/assets';
import {asmTranslationChunksConfig} from '@spartacus/asm/assets';
import {orderTranslationChunksConfig} from '@spartacus/order/assets';
import {checkoutTranslationChunksConfig} from '@spartacus/checkout/base/assets';
import {orderApprovalTranslationChunksConfig} from '@spartacus/organization/order-approval/assets';
import {configuratorTranslationChunksConfig} from '@spartacus/product-configurator/common/assets';
import {storeFinderTranslationChunksConfig} from '@spartacus/storefinder/assets';
import {organizationTranslationChunksConfig} from '@spartacus/organization/administration/assets';
import {userAccountTranslationChunksConfig, userAccountTranslations} from '@spartacus/user/account/assets';
import {userProfileTranslationChunksConfig} from '@spartacus/user/profile/assets';

export const i18nConfig: I18nConfig = {
  i18n: {
    chunks: {
      ...translationChunksConfig,
      ...bulkPricingTranslationChunksConfig,
      ...productImageZoomTranslationChunksConfig,
      ...productVariantsTranslationChunksConfig,
      ...savedCartTranslationChunksConfig,
      ...importExportTranslationChunksConfig,
      ...quickOrderTranslationChunksConfig,
      ...importExportTranslationChunksConfig,
      ...savedCartTranslationChunksConfig,
      ...asmTranslationChunksConfig,
      ...orderTranslationChunksConfig,
      ...checkoutTranslationChunksConfig,
      ...orderApprovalTranslationChunksConfig,
      ...configuratorTranslationChunksConfig,
      ...storeFinderTranslationChunksConfig,
      custom: ['ssab'],
      user: ['loginForm', 'miniLogin'],
      cart: ['cartDetails', 'cartItems', 'orderCost', 'voucher', 'wishList', 'saveForLaterItems'],
      userProfile: ['updateEmailForm', 'register', 'forgottenPassword'],
      address: ['addressForm', 'addressBook', 'addressCard'],
    }
  }
};
