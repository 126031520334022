import { Action } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';

export const SEND_STOCK_AVAILABILITY_REQUEST = "[ContactForms] StockAvailabilityRequest";
export const SEND_CONTACTFORM_REQUEST_FAIL = "[ContactForms] Send Contact Form Fail";

export class SendStockAvailabilityRequest implements Action {
  payload: {
    userId: string;
    cartCode: string;
    message?: string;
  };
  readonly type = SEND_STOCK_AVAILABILITY_REQUEST;
  constructor(payload: {
    userId: string;
    cartCode: string;
    message?: string;
  }) {
    this.payload = payload;
  }
}

export class LoadContactFormFail extends StateUtils.EntityFailAction {
  payload: {
    userId: string;
    contactFormType: string;
    cartCode: string;
    error: any;
  };
  readonly type = SEND_CONTACTFORM_REQUEST_FAIL;
  constructor(payload: {
    userId: string;
    contactFormType: string;
    cartCode: string;
    error: any;
  }) {
    super('contactForm', payload.contactFormType, payload.error);
    this.payload = payload;
  }
}

export declare type ContactFormActions = SendStockAvailabilityRequest | LoadContactFormFail;
export { };
