<ng-template cxOutlet="cx-storefront" cxPageTemplateStyle>
  <ng-template cxOutlet="cx-header">
    <header
      cxSkipLink="cx-header"
      [cxFocus]="{ disableMouseFocus: true }"
      [class.is-expanded]="isExpanded$ | async"
      (keydown.escape)="collapseMenu()"
      (click)="collapseMenuIfClickOutside($event)"
    >
      <ng-container *ngIf="user$ | async as user">
        <ssab-cx-notifications></ssab-cx-notifications>
      </ng-container>
      <div id="header-area">
        <cx-page-layout section="header"></cx-page-layout>
      </div>
      <cx-page-layout section="navigation"></cx-page-layout>
    </header>
    <ssab-cx-alert></ssab-cx-alert>
    <ssab-cx-global-message></ssab-cx-global-message>
    <cx-page-slot position="BottomHeaderSlot"></cx-page-slot>

  </ng-template>

  <main cxSkipLink="cx-main" [cxFocus]="{ disableMouseFocus: true }">
    <router-outlet></router-outlet>
  </main>

  <ng-template cxOutlet="cx-footer">
    <footer cxSkipLink="cx-footer" [cxFocus]="{ disableMouseFocus: true }">
      <cx-page-layout section="footer"></cx-page-layout>
    </footer>
  </ng-template>
</ng-template>
