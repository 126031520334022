import {HamburgerMenuService, KeyboardFocusService, LAUNCH_CALLER, LaunchDialogService, StorefrontComponent} from '@spartacus/storefront';
import {AfterContentChecked, AfterViewChecked, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRouterStateSnapshot, AuthStorageService, AuthToken, RoutingService} from '@spartacus/core';
import {SsabSessionExpiredComponent} from '../cms-components/session-expired/ssab-session-expired.component';
import {Observable, of, Subscription} from 'rxjs';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {SsabAuthService} from '../service/user-auth/ssab-auth.service';
import {GlobalLogin} from '../model/misc.model';
import {SsabSessionExpiredDialogData} from '../cms-components/session-expired/ssab-session-expired-layout.config';
import {SsabActiveCartService} from "../service/cart/ssab-active-cart.service";
import {pluck, switchMap} from "rxjs/operators";
import {DOCUMENT} from "@angular/common";
import {User, UserAccountFacade} from "@spartacus/user/account/root";

@Component({
  selector: 'ssab-cx-storefront',
  templateUrl: './ssab-storefront.component.html',
})
export class SsabStorefrontComponent extends StorefrontComponent implements AfterViewChecked, OnInit, AfterContentChecked, OnDestroy {
  subscriptions: Subscription = new Subscription();
  protected routeState$: Observable<ActivatedRouterStateSnapshot>;
  user$: Observable<User | undefined>;
  @ViewChild('footer') footer: ElementRef;

  constructor(
    hamburgerMenuService: HamburgerMenuService,
    routingService: RoutingService,
    protected elementRef: ElementRef<HTMLElement>,
    protected keyboardFocusService: KeyboardFocusService,
    @Inject(DOCUMENT) private document: Document,
    protected router: Router,
    private authStorageService: AuthStorageService,
    protected ssabAuthService: SsabAuthService,
    protected launchDialogService: LaunchDialogService,
    protected activeCartService: SsabActiveCartService,
    private userAccount: UserAccountFacade
  ) {
    super(hamburgerMenuService, routingService, elementRef, keyboardFocusService);

    this.routeState$ = routingService
      .getRouterState()
      .pipe(pluck('state'));
  }

  testEnvironments = ['test-shop', 'test-my'];
  private tokenExpirationTimer: any;
  protected modal: SsabSessionExpiredComponent;

  ngAfterViewChecked(): void {
    this.addIfTestEnvironment();

  }

  ngAfterContentChecked(): void {
    const footer = this.document.querySelector('ssab-cx-footer-container') as HTMLElement;
    const main = this.document.querySelector('main') as HTMLElement;
    // We get the footer container height + 6 * 1rem from padding bootom and top
    // that is set up  in cx-page-slot plus one aditional rem
    if (footer !== null && footer !== undefined) {
      main.style.paddingBottom = (footer.offsetHeight + (6 * 16) + 16) + 'px';
    }

    this.subscriptions.add(
      this.routeState$.subscribe(state => {
        if (state.context.id.indexOf('coil-comparison') != -1) {
          this.document.body.classList.add('coil-comparison-page');
        } else {
          this.document.body.classList.remove('coil-comparison-page');
        }
      })
    );
  }

  addIfTestEnvironment(): void {
    if (this.testEnvironments.some(testEnvironment => this.document.location.hostname.includes(testEnvironment))) {
      // There's at least one
      const siteLogo = this.document.getElementById('header-area').querySelector('.header > .SiteLogo');
      if (siteLogo && !siteLogo.querySelector('#testParagraph')) {
        const paragraph = this.document.createElement('h4');
        paragraph.setAttribute('id', 'testParagraph');
        paragraph.innerHTML = 'Test';
        siteLogo.append(paragraph);
      }
    }
  }

  ngOnInit(): void {

    this.user$ = this.ssabAuthService.isUserLoggedIn().pipe(
      switchMap((isUserLoggedIn) => {
        if (isUserLoggedIn) {
          return this.userAccount.get();
        } else {
          return of(undefined);
        }
      })
    );

    //Session Expired function moved to NgOninit
    const fiveMinutesInMiliseconds = 300000; // show notification in 5 minutes
    let expirationInMiliSeconds: number = 0;

    const token: Observable<AuthToken> = this.authStorageService.getToken();
    const windowVar: Window = this.document.defaultView?.window;
    this.subscriptions.add(
      this.router.events.subscribe((val) => {
        // always scroll to top on new navigation End
        if (val instanceof NavigationEnd && typeof windowVar?.scrollTo === 'function') {
          windowVar?.scrollTo(0, 0);
        }
        if (val instanceof NavigationStart) {
          this.getSessionStorage().setItem(GlobalLogin.SessionActiveTime, (new Date()).getTime().toString());
        }
      })
    );
    this.subscriptions.add(
      token.subscribe((tokenData) => {
        if (this.tokenExpirationTimer) {
          clearTimeout(this.tokenExpirationTimer);
        }
        if (tokenData.expires_at && tokenData.access_token_stored_at) {
          expirationInMiliSeconds = (+tokenData.expires_at) - (+tokenData.access_token_stored_at) - fiveMinutesInMiliseconds;
          if (expirationInMiliSeconds >= 0) {
            this.tokenExpirationTimer = setTimeout(() => {
              // current time minus 1 minute should be bigger than current access token so that the refresh token can be implemented
              // this is due to the 2 seconds wait of refresh, sessionActiveTime was always bigger than access_token_store_at
              if ((+this.getSessionStorage().getItem(GlobalLogin.SessionActiveTime) - 60000) > (+tokenData.access_token_stored_at)) {
                clearTimeout(this.tokenExpirationTimer);
                this.ssabAuthService.refreshToken();
                setTimeout(() => {
                  this.document.location.reload();
                }, 2000);
              } else {
                this.launchDialogService.closeDialog(null);
                this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.SESSION_EXPIRED, undefined, undefined);

                this.launchDialogService.data$.subscribe((dataDialog: SsabSessionExpiredDialogData) => {
                  if (dataDialog && dataDialog?.sessionUpdated) {
                    setTimeout(() => {
                      this.document.location.reload();
                    }, 2000);
                  }
                });
              }
            }, expirationInMiliSeconds);
          }
        }
      })
    );

    // code that calls order simulation on each new visit to the cart page
    this.subscriptions.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if ((event as NavigationEnd).url.endsWith('/cart')) {
            this.activeCartService.reLoadCart(false);
          }
        }
      })
    );
  }

  protected getSessionStorage(): Storage {
    return this.document.defaultView?.sessionStorage;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
