import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';

import {getGlobalConfigurationState, SsabLoadGlobalConfiguration, SsabStateWithGlobalConfiguration} from "./ssab-globalconfiguration-actions";
import {SsabGlobalConfiguration} from "../../../model/misc.model";


@Injectable({
  providedIn: 'root',
})
export class SsabGlobalconfigurationService {

  constructor(
    protected store: Store<SsabStateWithGlobalConfiguration>
  ) {
  }

  getGlobalConfiguration(): Observable<SsabGlobalConfiguration> {
    return this.store.pipe(
      select(getGlobalConfigurationState),
      tap((details) => {
        if (!details.loaded) {
          this.loadGlobalConfiguration();
        }
      }),
      map((details) => details.configuration),
      filter((c) => c !== undefined && c !== null),
    );
  }

  loadGlobalConfiguration(): void {
    this.store.dispatch(new SsabLoadGlobalConfiguration());
  }

  getDateFormat(): Observable<string> {
    return this.getGlobalConfiguration().pipe(
      map(c => c.dateFormat)
    );
  }
}
