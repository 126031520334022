import {SsabAsmMainUiComponent} from './main/ssab-asm-main-ui.component';
import {LayoutConfig} from '@spartacus/storefront';

export const ssabDefaultAsmLayoutConfig: LayoutConfig = {
  launch: {
    ASM: {
      outlet: 'cx-storefront',
      component: SsabAsmMainUiComponent,
    },
  },
};
