import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';
import {SsabProductIntroComponent} from './ssab-product-intro.component';
import {ProductIntroModule} from '@spartacus/storefront';

@NgModule({
    imports: [
        CommonModule,
        I18nModule,
        ConfigModule.withConfig({
            cmsComponents: {
                ProductIntroComponent: {
                    component: SsabProductIntroComponent
                }
            },
        } as CmsConfig),
    ],
    declarations: [SsabProductIntroComponent],
    exports: [SsabProductIntroComponent]
})
export class SsabProductIntroModule extends ProductIntroModule {
}
