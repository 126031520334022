import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {SsabGlobalconfigurationAdapter} from './ssab-globalconfiguration.adapter';
import {SsabGlobalConfiguration} from '../../../model/misc.model';

@Injectable({
  providedIn: 'root'
})
export class SsabGlobalconfigurationConnector {
  constructor(protected adapter: SsabGlobalconfigurationAdapter) {
  }

  getGlobalConfiguration(): Observable<SsabGlobalConfiguration>{
    return this.adapter.getGlobalConfiguration();
  }
}
