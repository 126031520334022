<!--<ng-container *ngIf="materials && materials.length > 0">-->
<ng-container *ngIf="true">
  <div class="table-responsive">
    <table class="table cx-material-data-table mb-0">
      <thead table-head
             (sortChange)="sort($event)"
             [activeSort]="activeSort"
             [backuplabel]="'ssab.material.data.'"
             [label]="'ssab.material.data.'"
             [tableHeadType]="'materialData'">
      </thead>
      <tbody>
      <tr *ngFor="let material of materials">
        <td class="text-nowrap">{{material.grade}}</td>
        <td>{{material.coating}}</td>
        <td>{{material.type}}</td>
        <td>{{material.yieldStress}}</td>
        <td>{{material.tensileStress}}</td>
        <td>{{material.standards}}</td>
        <td>
          <i *ngIf="material.engStressDocId"
             class="ssab-icon icon-system-download w-auto icon-cursor"
             (click)="onDownload(material, documentTypes.DocolEngStress, material.grade)"></i>
        </td>
        <td>
          <i *ngIf="material.mtbDocId"
             class="ssab-icon icon-system-download w-auto icon-cursor"
             (click)="onDownload(material, documentTypes.DocolMaterialData, material.grade)"></i>
        </td>
        <td>
          <i *ngIf="material.fldDocId"
             class="ssab-icon icon-system-download w-auto icon-cursor"
             (click)="onDownload(material, documentTypes.DocolFlp, material.grade)"></i>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</ng-container>
