<table *ngIf="productDataList" class="table">
  <tr class="header">
    <th class="first text-left">{{'ssab.scanner.product.materialId'|cxTranslate}}</th>
    <th class="text-left">{{'ssab.scanner.product.name'|cxTranslate}}</th>
    <th class="last text-left" colspan="2">{{'ssab.scanner.product.documentDate'|cxTranslate}}</th>
  </tr>

  <tr
    class="content no-border"
    *ngFor="let item of productDataList"
    (click)="sendValueToParent(item)"
  >
    <td class="first">
      <ul class="list-unstyled text-left text-sm-right">
        <li class="" *ngFor="let i of item.materialId.split(',')">
          {{i}}
        </li>
      </ul>
    </td>
    <td class="text-left">{{ item.grade }}</td>
    <td class="text-left">{{ item.documentDate| cxDate:  (getDateFormat()|async)  }}</td>
    <td class="last text-right"><i class="ssab-icon icon-navigation-chevron-right icon-inline"></i></td>
  </tr>
</table>

<div *ngIf="productDataList.length==0" class="no-product-div">
  <p class="mb-0">
    {{'ssab.scanner.product.notfound'|cxTranslate:{code: scannedCode} }}
  </p>
</div>
