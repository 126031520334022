import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';

import { SsabOrderEntry } from '../../../model/cart.model';
import { GTMEvent } from '../../../model/gtm/gtm-product.model';
import { SsabSearchCriteria } from '../../../model/misc.model';
import { SsabProduct } from '../../../model/product.model';
import { PageHistoryService } from '../../util/page-history.service';

import { SsabGtmConnector } from './ssab-gtm.connector';

@Injectable({
    providedIn: 'root',
})
export class SsabGtmService {
    constructor(
        protected gtmService: GoogleTagManagerService,
        private pageHistoryService: PageHistoryService,
        private gtmConnector: SsabGtmConnector,
    ) {}

    protected productList: string[] = [];
    protected pageLabel: string;
    protected event: string;

    public addToDom() {
        this.gtmService.addGtmToDom().catch((err) => console.log('gtmservice: ' + err.message));
    }

    // USER TAGS //

    pushUserLogin() {
        this.gtmConnector
            .getUser(GTMEvent.UserLogIn)
            .pipe(take(1))
            .subscribe((result) => {
                this.pushData(result);
            });
    }

    pushUserSwitch() {
        this.gtmConnector
            .getUser(GTMEvent.CustomerSwitch)
            .pipe(take(1))
            .subscribe((result) => this.pushData(result));
    }

    //Order related
    pushCheckoutCart(userId: string, cartId: string): void {
        this.gtmConnector
            .getCartEntries(userId, cartId, null)
            .pipe(take(1))
            .subscribe((result) => {
                if (result.length > 0) {
                    this.push(result, GTMEvent.CheckoutView);
                }
            });
    }

    pushOrderConfirmation(
        userId: string,
        orderCode: string,
        entries: any,
        affiliation?: string,
        revenue?: number,
        tax?: number,
        shipping?: number,
        coupon?: string,
    ): void {
        const actionField = { id: orderCode, affiliation, revenue, tax, shipping, coupon };
        this.push(entries, GTMEvent.OrderConfirmationView, null, actionField);
    }

    //Product related

    setProductListData(products: string[], pageLabel: string, event: string): void {
        this.productList = products;
        this.pageLabel = pageLabel;
        this.event = event;
    }

    pushProductsFromListingPage() {
        if (this.productList && this.productList.length > 0) {
            this.pushProducts(this.productList, this.pageLabel, null, this.event, null);
            this.productList = [];
            this.pageLabel = '';
            this.event = '';
        }
        return of(this.productList);
    }

    pushRemoveCartEntry(entry: SsabOrderEntry): void {
        this.gtmConnector
            .getCartEntry(entry.product.code, entry.quantity)
            .pipe(take(1))
            .subscribe((result) => {
                if (result) {
                    this.push(result, GTMEvent.RemoveFromCart);
                }
            });
    }

    pushProductClick(productCode: string, componentPageName: string, position: number): void {
        this.gtmConnector
            .getProductsWithPosition([productCode], position)
            .pipe(take(1))
            .subscribe((result) => this.push(result, GTMEvent.ProductImpressionClick, componentPageName));
    }

    pushProducts(
        products: string[],
        pageLabel?: string,
        quantity?: number,
        event?: string,
        componentPageLabel?: string,
    ): void {
        this.gtmConnector
            .getProducts(products, pageLabel, quantity)
            .pipe(take(1))
            .subscribe((result) => this.push(result, event, componentPageLabel));
    }

    pushDocumentDownload(documentType?: string, orderId?: string): void {
        const result = {
            event: GTMEvent.DocumentsDownload,
            fileType: documentType,
            orderId,
        };
        this.pushData(result);
    }

    pushStockAvailabilityDownload(event?: string, criteria?: SsabSearchCriteria, pageSize?: number): void {
        const thickness = this.getCriteriaValues(criteria.query, 'thickness');
        const width = this.getCriteriaValues(criteria.query, 'width');
        const length = this.getCriteriaValues(criteria.query, 'length');
        const height = this.getCriteriaValues(criteria.query, 'height');
        const base = this.getCriteriaValues(criteria.query, 'base');

        const result = {
            event,
            availability: {
                noOfProducts: pageSize,
                filters: {
                    stockSituation: criteria.query.includes('inStockFlag:inStockFlag') ? 'yes' : '',
                    productForm: this.getCriteriaValues(criteria.query, 'productForm'),
                    productCategory: this.getCriteriaValues(criteria.query, 'family'),
                    materialFamily: this.getCriteriaValues(criteria.query, 'materialFamily'),
                    brand: this.getCriteriaValues(criteria.query, 'productBrand'),
                    grade: this.getCriteriaValues(criteria.query, 'gradeName'),
                    shape: this.getCriteriaValues(criteria.query, 'shape'),
                    thicknessMin: thickness ? thickness.split(' - ')[0] : '',
                    thicknessMax: thickness ? thickness.split(' - ')[1] : '',
                    widthMin: width ? width.split(' - ')[0] : '',
                    widthMax: width ? width.split(' - ')[1] : '',
                    lengthMin: length ? length.split(' - ')[0] : '',
                    lengthMax: length ? length.split(' - ')[1] : '',
                    heightMin: height ? height.split(' - ')[0] : '',
                    heightMax: height ? height.split(' - ')[1] : '',
                    baseMin: base ? base.split(' - ')[0] : '',
                    baseMax: base ? base.split(' - ')[1] : '',
                    myProducts: this.getCriteriaValues(criteria.query, 'lastPurchased'),
                },
            },
        };
        this.pushData(result);
    }

    pushMaterialDataDownload(event?: string, documentType?: string, grade?: string): void {
        const result = {
            event,
            documentType,
            grade,
        };

        this.pushData(result);
    }

    getCriteriaValues(query?: string, criteriaName?: string): string {
        const params = query.split(':');
        let values = '';
        params.forEach((param, index) => {
            if (param.toLowerCase() === criteriaName.toLowerCase()) {
                values += params[index + 1] + ',';
            }
        });
        return values ? values.slice(0, -1) : '';
    }

    pushNotifyMe(product: SsabProduct): void {
        const result = {
            event: GTMEvent.NotifyMe,
            notification: {
                product: [
                    {
                        id: product.code,
                        price: product.price.formattedValue,
                        name: product.name,
                        brand: product.productForm,
                        category: product.categories.map((cat) => cat.name).join(','), // tree format
                    },
                ],
            },
        };

        this.pushData(result);
    }

    pushContactSales(cartCode: string): void {
        this.gtmConnector
            .getContactSalesEntries(cartCode)
            .pipe(take(1))
            .subscribe((result) => {
                if (result && result.length > 0) {
                    this.push(result, GTMEvent.ContactSales);
                }
            });
    }

    pushErrorNotification(errorType?: string, errorMessage?: string): void {
        const result = {
            event: GTMEvent.ErrorNotification,
            errorType,
            errorMessage,
        };
        this.pushData(result);
    }

    push(payload: any[], event: string, componentPageName?: string, actionField?: any): void {
        const entryData = this.createEventData(payload, event, componentPageName, actionField);
        this.pushData(entryData);
    }

    createEventData(products: any[], event: string, componentPageName?: string, actionField?: any): any {
        switch (event) {
            case GTMEvent.ProductsImpressionView:
                return {
                    event,
                    ecommerce: {
                        impressions: products,
                        currencyCode: this.pageHistoryService.getActiveCurrencyIso(),
                    },
                };
                break;

            case GTMEvent.ProductViewDetails:
                return {
                    event,
                    ecommerce: {
                        currencyCode: this.pageHistoryService.getActiveCurrencyIso(),
                        detail: { products },
                    },
                };
                break;

            case GTMEvent.ProductImpressionClick:
                return {
                    event,
                    ecommerce: {
                        currencyCode: this.pageHistoryService.getActiveCurrencyIso(),
                        click: {
                            actionField: { list: componentPageName },
                            products,
                        },
                    },
                };
                break;

            case GTMEvent.AddToCart:
                return {
                    event,
                    ecommerce: {
                        currencyCode: this.pageHistoryService.getActiveCurrencyIso(),
                        add: {
                            actionField: { list: componentPageName },
                            products,
                        },
                    },
                };
                break;

            case GTMEvent.CheckoutView:
                return {
                    event,
                    ecommerce: {
                        currencyCode: this.pageHistoryService.getActiveCurrencyIso(),
                        checkout: {
                            actionField: { step: '1' },
                            products,
                        },
                    },
                };
                break;
            case GTMEvent.OrderConfirmationView:
                return {
                    event,
                    ecommerce: {
                        currencyCode: this.pageHistoryService.getActiveCurrencyIso(),
                        purchase: {
                            actionField,
                            products,
                        },
                    },
                };
                break;
            case GTMEvent.RemoveFromCart:
                return {
                    event,
                    ecommerce: {
                        currencyCode: this.pageHistoryService.getActiveCurrencyIso(),
                        remove: {
                            products,
                        },
                    },
                };
                break;

            case GTMEvent.ContactSales:
                return {
                    event,
                    request: {
                        products,
                    },
                };
                break;
        }
        return {};
    }

    private pushData(eventData: any): void {
        this.gtmService.pushTag(eventData);
    }
}
