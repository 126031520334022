import {PaginationConfig} from '@spartacus/storefront';


export const defaultPaginationConfig: PaginationConfig = {
  pagination: {
    addStart: true,
    addEnd: true,
    addFirst: true,
    addLast: true,
    addDots: true,
    rangeCount: 3,
    startLabel: '<i class="ssab-icon icon-navigation-double-arrow-left"></i>',
    endLabel: '<i class="ssab-icon icon-navigation-double-arrow-right"></i>'
  },
};
