<ng-container *ngFor="let alert of alerts">
  <div class="{{cssClass(alert)}}" [ngClass]="{'d-none': hideAlert(alert)}">
    <div class="container">
      <span class="alert-icon">
        <cx-icon [type]="iconTypes.ERROR"></cx-icon>
      </span>
      <span [innerHTML]="alert.message | cxTranslate | safeHtml"></span>
      <button type="button" class="close" (click)="removeAlert(alert)">
        <span class="ssab-icon icon-white icon-navigation-close"></span>
      </button>
    </div>
  </div>
</ng-container>
