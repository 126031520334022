import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {CartEntryConnector} from '@spartacus/cart/base/core';
import {SsabOccCartEntryAdapter} from "./ssab-occ-cart-entry.adapter";
import {SsabCart, SsabOrderEntry} from "../../model/cart.model";

@Injectable({
  providedIn: 'root',
})
export class SsabCartEntryConnector extends CartEntryConnector {
  constructor(protected adapter: SsabOccCartEntryAdapter) {
    super(adapter);
  }

  public addEntry(
    userId: string,
    cartId: string,
    entry: SsabOrderEntry
  ): Observable<SsabCart> {
    return this.adapter.addEntry(userId, cartId, entry);
  }

  public updateEntry(
    userId: string,
    cartId: string,
    entry: SsabOrderEntry
  ): Observable<SsabCart> {
    return this.adapter.updateEntry(userId, cartId, entry);
  }

  updateHeader(userId: string, cartId: string, cart: SsabCart, runErpSimulation: boolean): Observable<SsabCart> {
    return this.adapter.updateHeader(userId, cartId, cart, runErpSimulation);
  }


  addSsabEntries(userId: string, cartId: string, entries: SsabOrderEntry[]): Observable<SsabCart> {
    return this.adapter.addSsabEntries(userId, cartId, entries);
  }
}
