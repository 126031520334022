import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {CmsConfig, FeaturesConfigModule, I18nModule, provideDefaultConfig} from '@spartacus/core';
import {OrderConfirmationGuard} from '@spartacus/order/components';
import { OrderConfirmationModule } from '@spartacus/order/components/order-confirmation/order-confirmation.module';
import {SsabOrderConfirmationThankYouMessageComponent} from './order-confirmation-thank-you-message/ssab-order-confirmation-thank-you-message.component';
import {SsabCartSharedModule} from '../cart/cart-shared/ssab-cart-shared.module';
import {FormErrorsModule} from '@spartacus/storefront';
import {SsabOrderConfirmationItemsComponent} from './order-confirmation-items/ssab-order-confirmation-items.component';
import {SsabOrderConfirmationHeaderComponent} from './order-confirmation-header/ssab-order-confirmation-header.component';


const orderConfirmationComponents = [
  SsabOrderConfirmationItemsComponent,
  SsabOrderConfirmationThankYouMessageComponent,
  SsabOrderConfirmationHeaderComponent,
];

@NgModule({
    imports: [
        CommonModule,
        I18nModule,
        ReactiveFormsModule,
        FeaturesConfigModule,
        FormErrorsModule,
        SsabCartSharedModule,
    ],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                OrderConfirmationThankMessageComponent: {
                    component: SsabOrderConfirmationThankYouMessageComponent,
                    guards: [OrderConfirmationGuard],
                },
                OrderConfirmationItemsComponent: {
                    component: SsabOrderConfirmationItemsComponent,
                    guards: [OrderConfirmationGuard],
                },
                OrderConfirmationTotalsComponent: {
                    component: SsabOrderConfirmationHeaderComponent,
                    guards: [OrderConfirmationGuard],
                },
            },
        }),
    ],
    declarations: [  SsabOrderConfirmationItemsComponent,
        SsabOrderConfirmationThankYouMessageComponent,
        SsabOrderConfirmationHeaderComponent
        ],
    exports: [  SsabOrderConfirmationItemsComponent,
        SsabOrderConfirmationThankYouMessageComponent,
        SsabOrderConfirmationHeaderComponent
        ]
})
export class SsabOrderConfirmationModule {
}
