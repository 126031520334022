import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DocumentTypes, SsabCertificateDocument, SsabDocumentIdentifier, SsabDocumentIdentifierList, SsabInvoiceDocument, SsabOrderConfirmationDocument, SsabTransportDocument} from '../../../model/document.model';
import {EloquaForms, Sort} from '../../../model/misc.model';
import {SsabUserService} from '../../../service/user/ssab-user.service';
import {GlobalMessageService, LanguageService, PaginationModel} from '@spartacus/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {DateUtils} from '../../../shared/util/date-utils';
import {SsabGtmService} from '../../../service/analytics/gtm/ssab-gtm.service';
import {HttpParams} from '@angular/common/http';
import {SsabGlobalconfigurationService} from "../../../service/general/configuration/ssab-globalconfiguration.service";


@Component({
  selector: 'ssab-cx-documents-result',
  templateUrl: './ssab-documents-result.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabDocumentsResultComponent implements OnInit {

  documentsSelectedToDownload: SsabDocumentIdentifier[] = [];
  documentTypes = DocumentTypes;
  allDocuments: SsabDocumentIdentifier[] = [];
  selectedDocuments: SsabDocumentIdentifier[] = [];
  elqUrlWithParameters: string = '';
  subscriptions = new Subscription();

  @Output()
  onSearch = new EventEmitter<number>();
  @Output()
  onSort = new EventEmitter<Sort>();
  @Output()
  downloadAll = new EventEmitter<void>();
  @Input()
  activeSort: Sort;
  @Input()
  pagination: PaginationModel;
  @Input()
  certificates: SsabCertificateDocument[] = [];
  @Input()
  invoices: SsabInvoiceDocument[] = [];
  @Input()
  ordersConfirmations: SsabOrderConfirmationDocument[] = [];
  @Input()
  transports: SsabTransportDocument[] = [];
  @Input()
  isSimpleView = false;
  @Input()
  selectedDocument: string = DocumentTypes.Certificate;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected userService: SsabUserService,
    protected language: LanguageService,
    protected globalMessageService: GlobalMessageService,
    protected router: Router,
    protected gtmService: SsabGtmService,
    private globalConfigurationService: SsabGlobalconfigurationService,) {
  }

  ngOnInit(): void {
    if (this.isSimpleView) {
      this.getAllDocuments();
      this.cdr.detectChanges();
    }
    this.subscriptions.add(
      this.userService.getElOQUABaseUrl(this.getEloquaFormName())
        .subscribe((elqBaseUrl) => {
          this.elqUrlWithParameters = elqBaseUrl;
          this.cdr.detectChanges();
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getEloquaFormName(): string {
    switch (this.selectedDocument) {
      case DocumentTypes.Certificate:
        return EloquaForms.Certificate;
        break;
      case DocumentTypes.Invoice:
        return EloquaForms.Invoice;
        break;
      case DocumentTypes.Confirmation:
        return EloquaForms.Confirmation;
        break;
      case DocumentTypes.Transport:
        return EloquaForms.Transports;
        break;
      default:
        return EloquaForms.Certificate;
        break;
    }
  }

  onChange(isChecked: boolean, document: SsabDocumentIdentifier): void {
    if (isChecked) {
      this.addDocumentToDownload(document);
    } else {
      this.removeDocumentToDownload(document.documentId);
    }
  }

  addDocumentToDownload(document: SsabDocumentIdentifier): void {
    if (!this.documentsSelectedToDownload.some(el => el.documentId === document.documentId)) {
      this.documentsSelectedToDownload.push(document);
    }
  }

  removeDocumentToDownload(documentId: string): void {
    this.documentsSelectedToDownload = this.documentsSelectedToDownload
      .filter(function (document) {
        return document.documentId !== documentId;
      });
  }

  downloadSingleDocument(documentToDownload: SsabDocumentIdentifier): void {
    const sub = this.userService.downloadDocument(documentToDownload)
      .subscribe((data) => {
        this.userService.downloadFile(data, documentToDownload.fileName, 'pdf');
        this.gtmService.pushDocumentDownload(documentToDownload.docType, documentToDownload.docOrderNumber);
        this.sendEloquaData(this.getEloquaParams(documentToDownload));
        sub.unsubscribe();
      });
  }

  downloadDocumentsSelected(): void {
    if (this.documentsSelectedToDownload != null && this.documentsSelectedToDownload.length > 0) {
      if (this.documentsSelectedToDownload.length > 1) {
        const sub = this.userService.downloadDocuments(
          {
            documentIdentifiers: this.documentsSelectedToDownload,
            docType: this.selectedDocument
          } as SsabDocumentIdentifierList)
          .subscribe((data) => {
            this.userService.downloadFile(data, 'documents', 'zip');
            this.documentsSelectedToDownload.forEach((documentToDownload) => {
              this.gtmService.pushDocumentDownload(this.documentsSelectedToDownload[0].docType, documentToDownload.docOrderNumber);
              this.sendEloquaData(this.getEloquaParams(documentToDownload));
            });
            sub.unsubscribe();
          });
      } else {
        const sub = this.userService.downloadDocument(this.documentsSelectedToDownload[0])
          .pipe(
            map((data: any) => {
              this.userService.downloadFile(data, this.documentsSelectedToDownload[0].fileName, 'pdf');
              this.gtmService.pushDocumentDownload(this.documentsSelectedToDownload[0].docType, this.documentsSelectedToDownload[0].docOrderNumber);
              this.sendEloquaData(this.getEloquaParams(this.documentsSelectedToDownload[0]));
              sub.unsubscribe();
            })
          ).subscribe();
      }
    }
  }

  sendEloquaData(params: HttpParams): void {
    if (this.elqUrlWithParameters && params) {
      const subEloqua = this.userService
        .sendELOQUAData(this.elqUrlWithParameters, params)
        .subscribe(() => subEloqua.unsubscribe());
    }
  }

  downloadAllDocuments(): void {
    this.downloadAll.emit();
  }

  sortChange(sort: Sort): void {
    this.activeSort = sort;
    this.onSort.emit(sort);
  }

  search(): void {
    this.onSearch.emit();
  }

  pageChange(currentPage: number): void {
    this.onSearch.emit(currentPage);
  }

  convertUIDate(d: Date): Date {
    return DateUtils.convertDate(d);
  }

  getAllDocuments(): void {
    this.allDocuments.push(...(this.invoices ? this.invoices as SsabDocumentIdentifier[] : []));
    this.allDocuments.push(...(this.transports ? this.transports as SsabDocumentIdentifier[] : []));
    this.allDocuments.push(...(this.ordersConfirmations ? this.ordersConfirmations as SsabDocumentIdentifier[] : []));
    this.allDocuments.push(...(this.certificates ? this.certificates as SsabDocumentIdentifier[] : []));
    this.allDocuments = this.allDocuments.sort((a, b) => new Date(b.documentDate).getTime() - new Date(a.documentDate).getTime());
  }

  selectAll(isChecked: boolean): void {
    if (isChecked) {
      switch (this.selectedDocument) {
        case DocumentTypes.Certificate:
          this.selectedDocuments.push(...this.certificates as SsabDocumentIdentifier[]);
          break;
        case DocumentTypes.Invoice:
          this.selectedDocuments.push(...this.invoices as SsabDocumentIdentifier[]);
          break;
        case DocumentTypes.Confirmation:
          this.selectedDocuments.push(...this.ordersConfirmations as SsabDocumentIdentifier[]);
          break;
        case DocumentTypes.Transport:
          this.selectedDocuments.push(...this.transports as SsabDocumentIdentifier[]);
          break;
      }
      this.selectedDocuments.forEach((document) => this.addDocumentToDownload(document));
    } else {
      this.selectedDocuments.forEach((document) => this.removeDocumentToDownload(document.documentId));
      this.selectedDocuments = [];
    }

  }

  getEloquaParams(document: SsabDocumentIdentifier): HttpParams {
    let params = new HttpParams();
    switch (this.selectedDocument) {
      case DocumentTypes.Certificate:
        const certificate = document as SsabCertificateDocument;
        params = params.set('certificateNumber', certificate.certificateId);
        params = params.set('deliveryNumber', certificate.deliveryNumber);
        params = params.set('customerReference', certificate.customerRef);
        params = params.set('invoiceID', certificate.invoiceNumber);
        params = params.set('orderID', certificate.orderNumber);
        params = params.set('materialID', certificate.materialId);
        params = params.set('heatID', certificate.heatId);
        params = params.set('grade', certificate.grade);
        params = params.set('dimensions', certificate.dimension);
        params = params.set('documentDate', certificate.documentDate.toString());
        params = params.set('documentId', certificate.documentId);
        break;
      case DocumentTypes.Invoice:
        const invoice = document as SsabInvoiceDocument;
        params = params.set('orderID', invoice.orderNumber);
        params = params.set('invoiceID', invoice.invoiceNumber);
        break;
      case DocumentTypes.Confirmation:
        const confirmation = document as SsabOrderConfirmationDocument;
        params = params.set('orderID', confirmation.orderNumber);
        break;
      case DocumentTypes.Transport:
        const transport = document as SsabTransportDocument;
        params = params.set('orderID', transport.orderNumber);
        params = params.set('deliveryNoteID', transport.documentNumber);
        params = params.set('sendingUnit', transport.sendingUnit);
        break;
    }
    return params;
  }

  getDateFormat(): Observable<string> {
    return this.globalConfigurationService.getDateFormat();
  }
}
