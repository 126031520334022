import {SsabOrderHistoryEntry} from '../../../../model/order.model';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ProductUnitConversion, SsabStock, StepperUpdateData} from '../../../../model/product.model';
import {SsabProductService} from '../../../../service/product/ssab-product.service';
import {Observable, of} from 'rxjs';


@Component({
  selector: 'ssab-cx-previous-orders-entry',
  templateUrl: './ssab-previous-orders-entry.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabPreviousOrdersEntryComponent implements OnInit {
  @Input() entry: SsabOrderHistoryEntry;
  selectedUnit: ProductUnitConversion;
  currentStock$: Observable<SsabStock>;

  constructor(protected cdr: ChangeDetectorRef,
              protected productService: SsabProductService) {
  }

  ngOnInit(): void {
    this.currentStock$ = of(this.entry.product.stock);
    this.determineUnitConversion(this.entry.unit.code);
  }

  determineUnitConversion(unit: string): void {
    this.selectedUnit = this.entry.product.productUnitConversions?.filter(pc => pc.unit.code === unit)[0];
  }

  onChange(data: StepperUpdateData): void {
    if (data.unit) {
      this.selectUnit(data.unit);
    }
  }

  selectUnit(unit: string): void {
    this.determineUnitConversion(unit);
    this.currentStock$ = this.productService.getCatalogAvailability(this.entry.product, this.selectedUnit?.unit);
  }

  isOutOfStock(): boolean {
    return this.entry.product.stock?.stockLevel !== undefined && (this.entry.product.stock?.stockLevel < this.entry.product.stock?.bundleSize || this.entry.product.stock?.stockLevel === 0);
  }

  isNotAvailable(): boolean {
    return this.entry.product.stock?.stockLevel === undefined;
  }

  getOutOfStockText(): string {
    return this.isOutOfStock() ? 'myInterests.outOfStock' :
      this.isNotAvailable() ? 'myInterests.notAvailable' : null;
  }
}

