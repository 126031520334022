import {Component, Input} from '@angular/core';
import {SsabOrderEntry} from '../../../../model/cart.model';
import {CartItemComponent} from '@spartacus/cart/base/components';
import {SsabActiveCartService} from '../../../../service/cart/ssab-active-cart.service';
import {CartItemContextSource} from "@spartacus/cart/base/components";

@Component({
  template: ''
})
export abstract class SsabCartItemBaseComponent extends CartItemComponent {
  @Input() showPricesOrConsignmentPricesStock;
  @Input() orderConfirmation = false;

  deleteEntry(item: SsabOrderEntry): void {
    this.activeCartService.removeEntry(item);
  }

  itemHasErrors(item: SsabOrderEntry): boolean {
    return !this.orderConfirmation && !item.validForOrdering;
  }

  constructor(protected activeCartService: SsabActiveCartService,
              protected cartItemContextSource: CartItemContextSource) {
    super(cartItemContextSource);
  }

}
