import {Component} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {CmsService} from '@spartacus/core';
import {combineLatest, Observable} from 'rxjs';
import {map, switchMap, filter} from 'rxjs/operators';
import {SsabCMSFooterContainer} from '../../../model/cms.model';


@Component({
  selector: 'ssab-cx-footer-container',
  templateUrl: './ssab-footer-container.component.html'
})
export class CMSFooterContainerComponent {

  columnList$: Observable<any[]>;

  constructor(
    public componentData: CmsComponentData<SsabCMSFooterContainer>,
    protected cmsService: CmsService,
  ) {
    this.columnList$ = this.componentData.data$.pipe(
      filter(data => data != null),
      switchMap(data =>
        combineLatest(
          data.components.split(' ').map(component =>
            this.cmsService.getComponentData<any>(component).pipe(
              filter(column => column != null),
              map(column => {
                if (!column.flexType) {
                  column = {
                    ...column,
                    headerText: column.headerText,
                    longText: column.longText,
                    linkText: column.linkText,
                    showLink: column.showLink
                  };
                }
                return {
                  ...column
                };
              })
            )
          )
        )
      )
    );
  }

}
