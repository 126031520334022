import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input} from '@angular/core';
import {CarouselComponent, CarouselService} from '@spartacus/storefront';
import {Observable} from "rxjs";

@Component({
  selector: 'ssab-cx-carousel',
  templateUrl: './ssab-carousel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabCarouselComponent extends CarouselComponent {
  @Input()
  showBanners: boolean = false;

  itemsLength: number;

  constructor(protected el: ElementRef,
              protected service: CarouselService,
              protected cdr: ChangeDetectorRef) {
    super(el, service);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  set setItems(inputItems: Observable<any>[]) {
    this.items = inputItems;
    this.itemsLength = this.items?.length ?? 0;
  }

}
