import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Config, provideDefaultConfig} from '@spartacus/core';
import {SsabCdcConfig, ssabCdcConfig} from './ssab-cdc-config';
import {SsabCdcService} from './ssab-cdc.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: SsabCdcConfig,
      useExisting: Config
    },
    provideDefaultConfig(ssabCdcConfig),
    SsabCdcService
  ],
})
export class SsabCdcModule {
}
