<ng-container   *ngIf="data$ | async as data">
  <cx-generic-link
    *ngIf="data.urlLink; else noUrlLink"
    [url]="data.urlLink"
    [target]="getTarget(data)"
  >
    <p class="headline" *ngIf="data.headline" [innerHTML]="data.headline"></p>
    <cx-media [container]="data.media"></cx-media>
    <p class="content" *ngIf="data.content" [innerHTML]="data.content"></p>
  </cx-generic-link>
  <ng-template #noUrlLink>
    <p class="headline" *ngIf="data.headline" [innerHTML]="data.headline"></p>
    <cx-media [container]="data.media"></cx-media>
    <p class="content" *ngIf="data.content" [innerHTML]="data.content"></p>
  </ng-template>
</ng-container>

