<ng-container *ngIf="productData$ | async as productData">
  <scanner-header-main title="{{ productData.grade }}"
                       [backUrl]="noBackLink? 'scanner': ('scanner/products')"
                       [backUrlParams]="{search:searchTerm}">

  </scanner-header-main>
  <div class="product-card-header">

    <b>{{'ssab.scanner.product.materialId'|cxTranslate}}</b>: <span>{{ productData.materialId }}</span>
    <span>&nbsp;|&nbsp;</span>
    <button class="link text-underline" [routerLink]="'/scanner/order'" [queryParams]="{orderNumber:productData.orderNumber,originalCertificateId:certificateId,searchTerm:searchTerm}"><b>
      {{'ssab.scanner.product.orderId'|cxTranslate}}</b>: <span>{{ productData.orderNumber }}</span></button>

  </div>
  <div class="bg-white pb-4">
    <div class="wrapper">
      <div class="data-header">
        <div class="data-image">
          <img style="width:100%" src="assets/scanner/images/brands/{{productData.brand}}.jpg" [alt]="productData.brand"/>
        </div>
        <div class="data-details text-sm">
          <div>
            <b>{{'ssab.scanner.product.dimensions'|cxTranslate}}:</b>
            {{ productData.dimension }}
          </div>
          <div>
            <b>{{'ssab.scanner.product.plant'|cxTranslate}}:</b>
            {{ productData.sourcePlant }}
          </div>
          <div>
            <b>{{'ssab.scanner.product.documentDate'|cxTranslate}}:</b>
            {{ productData.documentDate | cxDate:  (getDateFormat()|async) }}
          </div>
        </div>
      </div>

      <div class="text-lg text-uppercase mt-2 mb-4">{{'ssab.scanner.product.documentTitle'|cxTranslate}}</div>

      <ng-container *ngIf="documentData$|async as documentData">
        <ng-container
          *ngTemplateOutlet="document; context: { documentData: documentData.certificates }"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="document; context: { documentData: documentData.transports }"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="document; context: { documentData: documentData.invoices }"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="document; context: { documentData: documentData.orderConfirmations }"
        ></ng-container>
      </ng-container>

    </div>
    <div class="footer">
      <button class="btn btn-primary btn-default-height btn-block" (click)="onScanMoreProducts()">
        <i class="ssab-icon icon-scanner icon-white icon-inline mr-2"></i>
        <span>{{'ssab.scanner.qr.scanAgain'|cxTranslate}}</span>
      </button>
    </div>
  </div>
</ng-container>

<ng-template #document let-documentData="documentData">
  <div class=" mb-3" *ngIf="documentData?.length>0">
    <div *ngFor="let data of documentData" class="data-certificate">
      <div class="text-uppercase text-nowrap">
        <i class="ssab-icon icon-general-PDF icon-inline mr-2"></i>
        <span>{{data.docType}}</span>
      </div>
      <button class="btn btn-primary btn-inline" (click)="addToList(data, data.docType)">
        <i class="ssab-icon icon-navigation-plus-small icon-sm icon-white icon-inline mr-2"></i>
        <span>{{'ssab.scanner.product.addToList'|cxTranslate}}</span>
      </button>
    </div>
  </div>
</ng-template>
