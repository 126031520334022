import {DIALOG_TYPE, LayoutConfig} from '@spartacus/storefront';
import {ScannerEmailDialogComponent} from "./email-dialog.component";

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    SCANNER_SEND_EMAIL = 'SCANNER_SEND_EMAIL',
  }
}

export const scannerSendEmailLayout: LayoutConfig = {
  launch: {
    SCANNER_SEND_EMAIL: {
      inlineRoot: true,
      component: ScannerEmailDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};

export interface SsabScannerSendEmailDialogData {
}
