import {ChangeDetectionStrategy, Component} from '@angular/core';
import {BannerComponent, CmsComponentData} from '@spartacus/storefront';
import {CmsBannerComponent, CmsService, SemanticPathService} from "@spartacus/core";
import {filter} from "rxjs/operators";


@Component({
  selector: 'ssab-cx-banner',
  templateUrl: './ssab-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabBannerComponent extends BannerComponent {
  constructor(protected component: CmsComponentData<CmsBannerComponent>,
              protected urlService: SemanticPathService,
              protected cmsService: CmsService) {
    super(
      {...component, data$: component.data$.pipe(filter(d => d !== null))},
      urlService,
      cmsService);
  }
}
