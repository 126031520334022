import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SsabOrderConfirmationDocument,} from '../../../../model/document.model';
import {SsabDocumentsBaseComponent} from '../documents/ssab-documents-base-component';
import {SsabGlobalconfigurationService} from "../../../../service/general/configuration/ssab-globalconfiguration.service";

@Component({
  selector: 'ssab-cx-orders-confirmation',
  templateUrl: './ssab-orders-confirmation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabOrdersConfirmationComponent extends SsabDocumentsBaseComponent {
  @Input() orderConfirmationDocuments: SsabOrderConfirmationDocument[];

  constructor(protected globalConfigurationService: SsabGlobalconfigurationService,) {
    super(globalConfigurationService);
  }
}

