import {Injectable} from '@angular/core';
import {EventService, RoutingService, SearchboxService, TranslationService, WindowRef} from '@spartacus/core';
import {SearchBoxComponentService} from '@spartacus/storefront';
import {SsabSearchBoxConfig, SsabSearchConfig} from "../../../model/misc.model";

@Injectable({
  providedIn: 'root',
})
export class SsabSearchBoxComponentService extends SearchBoxComponentService {
  constructor(
    public searchService: SearchboxService,
    protected routingService: RoutingService,
    protected translationService: TranslationService,
    protected winRef: WindowRef,
    protected eventService: EventService
  ) {
    super(searchService, routingService, translationService, winRef, eventService);
  }

  search(query: string, config: SsabSearchBoxConfig): void {
    if (config.displayProducts) {
      this.searchService.search(query, {
        pageSize: config.maxProducts,
        suggestiveMode: true,
        sort: 'relevance'
      } as SsabSearchConfig);
    } else {
      super.search(query, config);
    }
  }
}
