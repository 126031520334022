import {
  ChangeDetectionStrategy,
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import {PaginationModel} from '@spartacus/core';

@Component({
  selector: 'ssab-cx-pagination-bottom',
  templateUrl: './ssab-pagination-bottom.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabPaginationBottomComponent{
  @Input() selectedPageSize = 25;
  @Input() skipLocationChange = false;
  @Input() pagination: PaginationModel;
  @Input() isEventEmitterPagination = true;
  @Output() viewPageEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() pageSizeEvent: EventEmitter<number> = new EventEmitter<number>();

  pageChange(from: number): void {
    this.viewPageEvent.emit(from);
  }

  pageSizeChanged(size: number){
    this.pageSizeEvent.emit(size);
  }
}
